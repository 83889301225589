import {
  HIDE_TOAST_ACTION,
  TOAST_ERROR_ACTION,
  TOAST_INFO_ACTION,
  TOAST_SUCCESS_ACTION,
  TOAST_WARN_ACTION
} from "../actions/toast-actions";

const initialState = {
  open: false,
  vertical: "top",
  horizontal: "center",
  message: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_INFO_ACTION: {
      const message = action.payload;

      return {
        ...state,
        type: "info",
        message: message.message ? message.message : message,
        open: true,
        disableAutoClose: message.disableAutoClose,
        newDocsCount: message.newDocsCount,
        newTicketsCount: message.newTicketsCount,
        displayNotifications: message.displayNotifications
      };
    }

    case TOAST_WARN_ACTION: {
      const message = action.payload;
      return {
        ...state,
        type: "warn",
        message,
        open: true,
        displayNotifications: false
      };
    }

    case TOAST_SUCCESS_ACTION: {
      const message = action.payload;
      return {
        ...state,
        type: "success",
        message,
        open: true,
        displayNotifications: false
      };
    }

    case TOAST_ERROR_ACTION: {
      const message = action.payload;
      return {
        ...state,
        type: "error",
        message: message.message ? message.message : message,
        disableAutoClose: message.disableAutoClose,
        open: true,
        displayNotifications: false
      };
    }

    case HIDE_TOAST_ACTION: {
      return {
        ...state,
        open: false,
        disableAutoClose: false,
        displayNotifications: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
