import {
  TRY_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_MISSING_MFA,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "../actions/login-actions";
import AuthManager from "../lib/authManager";

const initialState = {
  user: null,
  isAuthenticated: AuthManager.isAuthenticated(),
  isLoading: false,
  isCollabSession: AuthManager.isCollabSession(),
  display2FA: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS: {
      const user = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        display2FA: false,
        user
      };
    }

    case USER_LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isLoading: false,
        isCollabSession: false
      };
    }

    case USER_LOGIN_MISSING_MFA: {
      return {
        ...state,
        display2FA: true,
        isLoading: false
      };
    }

    case USER_LOGIN_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }

    case TRY_LOGIN: {
      return {
        ...state,
        isLoading: true
      };
    }

    case "ACCESS_CLIENT_PORTAL_SUCCESS": {
      const {
        tenant,
        tenantId,
        powerslideToken,
        dashboardIds,
        applications
      } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          tenants: [tenant],
          tenantId,
          collabPowerslideToken: state.user.powerslideToken,
          powerslideToken,
          dashboardIds,
          applications
        },
        isCollabSession: true
      };
    }

    case "EXIT_CLIENT_PORTAL_SUCCESS": {
      return {
        ...state,
        user: {
          ...state.user,
          tenants: action.payload.tenants,
          tenantId: "",
          powerslideToken: state.user.collabPowerslideToken,
          applications: action.payload.applications
        },
        isCollabSession: false
      };
    }

    case "UPDATE_CURRENT_TENANT_SYNC": {
      return {
        ...state,
        user: {
          ...state.user,
          tenantId: action.payload.tenantId,
          powerslideToken: action.payload.token,
          missions: action.payload.missions,
          gedNotifications: action.payload.gedNotifications,
          ticketNotifications: action.payload.ticketNotifications,
          role: action.payload.role
        }
      };
    }

    case "UPDATE_GED_NOTIFICATIONS": {
      // gedNotifications : new set of notifications
      // documentsToRemove : documents to remove from notifications
      const { gedNotifications, documentsToRemove } = action.payload;

      let gedNotifs = gedNotifications || state.user.gedNotifications;
      if (documentsToRemove && documentsToRemove.length > 0) {
        gedNotifs = gedNotifs.filter(
          docId => !documentsToRemove.includes(docId)
        );
      }
      return {
        ...state,
        user: {
          ...state.user,
          gedNotifications: gedNotifs
        }
      };
    }

    case "UPDATE_TICKET_NOTIFICATIONS": {
      const { ticketNotifications } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          ticketNotifications
        }
      };
    }

    default:
      return state;
  }
};

export default reducer;
