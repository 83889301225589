/* eslint-disable react/forbid-prop-types */
import "./GedBreadcrumbParentFolder.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { FETCH_DOCUMENTS } from "../../actions/document-actions";

const GedBreadcrumbParentFolder = ({
  history,
  folder,
  isActive,
  selectFolder
}) => {
  if (isActive) {
    return (
      <Typography className="GedBreadcrumbParentFolder" component="span">
        {folder.name}
      </Typography>
    );
  }
  return (
    <Typography
      className="GedBreadcrumbParentFolder link"
      component="a"
      onClick={() => {
        history.push(`/ged/${folder._id}`);
        selectFolder(folder._id);
      }}
    >
      {folder.name}
    </Typography>
  );
};

const dispatchStateToProps = dispatch => {
  return {
    selectFolder: folderId =>
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: { selectedFolderId: folderId }
      })
  };
};

GedBreadcrumbParentFolder.propTypes = {
  folder: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isActive: PropTypes.bool,
  selectFolder: PropTypes.func.isRequired
};

GedBreadcrumbParentFolder.defaultProps = {
  isActive: false
};

export default connect(
  null,
  dispatchStateToProps
)(withRouter(GedBreadcrumbParentFolder));
