import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Loader from "../Loader/Loader";

const DashboardDetail = props => {
  const { dashboardReducer } = props;
  const { dashboardId } = props.match.params;
  const dashboard = (dashboardReducer.dashboards || []).find(
    d => d._id === dashboardId
  );

  if (!dashboard) {
    return <Loader />;
  }
  return (
    <>
      <iframe
        title={dashboard.title}
        width="100%"
        height="102%"
        frameBorder="0"
        allowFullScreen
        src={dashboard.url}
      />
      <div
        id="back"
        style={{
          position: "absolute",
          bottom: "10px",
          color: "#263AD1",
          zIndex: "200",
          backgroundColor: "white",
          paddingRight: "5px"
        }}
      >
        <ButtonBase onClick={() => props.history.push("/tableau-de-bord")}>
          <ArrowLeft />
          Retour aux tableaux de bord
        </ButtonBase>
      </div>
    </>
  );
};

/*  eslint react/forbid-prop-types: "off" */
DashboardDetail.propTypes = {
  dashboardReducer: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dashboardId: PropTypes.string
    })
  }).isRequired
};

const mapStateToProps = state => {
  return {
    dashboardReducer: state.dashboardReducer
  };
};

export default connect(mapStateToProps)(withRouter(DashboardDetail));
