/*  eslint react/forbid-prop-types: "off" */

import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import PropTypes from "prop-types";

import "./HelpButton.scss";

const HelpButton = props => {
  const { label, supportId, history, disabled } = props;
  return (
    <Button
      disabled={disabled}
      className="HelpButton"
      fullWidth
      onClick={() => history.push(`/assistance/contact/${supportId}`)}
    >
      <TouchAppIcon color="primary" />
      {label}
    </Button>
  );
};

HelpButton.propTypes = {
  history: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  supportId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default withRouter(HelpButton);
