import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import MaterialTable from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import {
  authReducerType,
  indicatorReducerType
} from "../../_helpers/appProptypes";

import Loader from "../Loader/Loader";

import "./Indicators.scss";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const localization = {
  body: {
    emptyDataSourceMessage: "Pas de lignes",
    filterRow: {
      filterTooltip: "Filtrer"
    }
  },
  toolbar: {
    exportTitle: "Exporter",
    exportAriaLabel: "Exporter",
    exportName: "Exporter en CSV",
    searchTooltip: "Recherche",
    searchPlaceholder: "Recherche"
  }
};

const Indicators = props => {
  const {
    authReducer,
    indicatorReducer,
    fetchIndicators,
    updateIndicator
  } = props;
  const { indicators } = indicatorReducer;

  /* function editComponentInput(label, value, onChangeFunction) {
    return (
      <TextField
        // id="firstName"
        name={label}
        label=""
        disabled={false}
        fullWidth={false}
        autoComplete=""
        value={value}
        onChange={onChangeFunction}
        style={{ margin: "0px 0px" }}
      />
    );
  }

  function editComponentSelect(value, onChangeFunction, valueList) {
    return (
      <Select value={value} onChange={onChangeFunction}>
        {Object.keys(valueList).forEach(function(k) {
          return (
            <MenuItem value={k}>
              <em>{valueList[k]}</em>
            </MenuItem>
          );
        })}
      </Select>
    );
  } */

  const indicatorsColumns = [
    {
      title: "Position",
      field: "indicateurPosition",
      searchable: true,
      type: "numeric",
      cellStyle: {
        padding: "none",
        size: "small"
      }
    },
    {
      title: "ID",
      field: "_id",
      searchable: true,
      type: "string",
      editable: "never"
    },
    {
      title: "Label",
      field: "indicateurLabel",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string"
    },
    {
      title: "Signe",
      field: "indicateurSigneAffichage",
      searchable: false,
      width: 20,
      headerStyle: { width: 20 },
      type: "string",
      lookup: { POS: "POS", NEG: "NEG" }
    },
    {
      title: "SC",
      field: "SC",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string"
    },
    {
      title: "SD",
      field: "SD",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string"
    },
    {
      title: "SM",
      field: "SM",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string"
    },
    {
      title: "Exclusions",
      field: "comptesExclus",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string"
    },
    {
      title: "Niveau",
      field: "niveau",
      searchable: true,
      width: 20,
      headerStyle: { width: 20 },
      type: "numeric",
      lookup: { 1: 1, 2: 2, 3: 3 }
    }
  ];

  const [searchTerm] = useState("");
  const [indicatorsData, setIndicatorsData] = useState(indicators);

  useEffect(() => {
    if (authReducer && authReducer.user && authReducer.user.role === "admin") {
      fetchIndicators();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducer.user]);

  useEffect(() => {
    setIndicatorsData(indicators);
  }, [indicators]);

  return (
    <div style={{ padding: "2px", maxWidth: "100%" }} className="indicatorBody">
      {indicatorReducer.isLoading && <Loader />}
      {!indicatorReducer.isLoading && (
        <MaterialTable
          key={searchTerm}
          icons={tableIcons}
          options={{
            exportButton: true,
            sorting: true,
            paging: true,
            pageSize: 20,
            pageSizeOptions: [20, 100],
            headerStyle: {
              backgroundColor: "#bbb",
              color: "#fff",
              fontWeight: "bold",
              position: "sticky",
              top: 0
            },
            maxBodyHeight: 450,
            debounceInterval: 400,
            searchText: searchTerm,
            rowStyle: rowData => {
              if (rowData.niveau === 2) {
                return { backgroundColor: "#dde" };
              }
              if (rowData.niveau === 3) {
                return { backgroundColor: "#aac" };
              }
              return {};
            }
          }}
          columns={indicatorsColumns}
          data={indicatorsData}
          title={
            <span className="tableTitle">
              Définition des indicateurs (#{indicatorsData.length})
            </span>
          }
          localization={localization}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise(resolve => {
                const {
                  _id,
                  indicateurPosition,
                  indicateurLabel,
                  indicateurSigneAffichage,
                  SC,
                  SD,
                  SM,
                  comptesExclus,
                  niveau
                } = rowData;
                const indicatorUpdate = {
                  _id,
                  indicateurPosition,
                  indicateurLabel,
                  indicateurSigneAffichage,
                  SC,
                  SD,
                  SM,
                  comptesExclus,
                  niveau,
                  [columnDef.field]: newValue
                };
                updateIndicator({ ...indicatorUpdate });
                resolve();
              });
            }
          }}
        />
      )}
    </div>
  );
};

Indicators.propTypes = {
  authReducer: authReducerType.isRequired,
  indicatorReducer: indicatorReducerType.isRequired,
  fetchIndicators: PropTypes.func.isRequired,
  updateIndicator: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    indicatorReducer: state.indicatorReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchIndicators: () => {
      dispatch({ type: "FETCH_INDICATORS" });
    },
    updateIndicator: indicator => {
      dispatch({
        type: "ASK_INDICATOR_UPDATE",
        payload: { indicator }
      });
    }
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(Indicators));
