const initialState = {
  dashboards: null, // fakeDashboards,
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DASHBOARDS_SYNC": {
      const { dashboards } = action.payload;
      return {
        ...state,
        isLoading: false,
        dashboards
      };
    }

    case "DASHBOARDS_CLEAR": {
      return {
        ...state,
        isAuthenticated: false,
        dashboards: []
      };
    }
    default:
      return state;
  }
};

export default reducer;
