import axios from "axios";
import { saveAs } from "file-saver";
import SpecialFolder from "../enums/special-folder.enum";

class ApiManager {
  static callApi(config) {
    if (!config) {
      throw new Error("Wrong argument");
    }
    const {
      ressource,
      method = "GET",
      isAuthenticated = false,
      data,
      useMock,
      blob = false
    } = config;

    const url = useMock
      ? process.env.REACT_APP_MOCK_URL + ressource
      : process.env.REACT_APP_API_URL + ressource;
    const params = {
      method,
      url
    };

    if (isAuthenticated) {
      params.headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      };
    }

    if (blob) {
      params.responseType = "blob";
    }

    if (data) {
      params.data = data;
    }

    return axios(params);
  }

  static uploadFile({ onUploadProgress, file, ressource, folderId, tenantId }) {
    const data = new FormData();
    data.append("files[0]", file);
    data.append("parentId", folderId || "private");
    data.append("tenantId", tenantId);

    return axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + ressource,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      },
      onUploadProgress
    });
  }

  static async uploadPublicFile({ file }) {
    const data = new FormData();
    data.append("files[0]", file);
    data.append("parentId", SpecialFolder.PUBLIC_FOLDER);
    // data.append("tenantId", tenantId);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/documents`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
  }

  static downloadFile({ ressource, method, data }) {
    return axios({
      method,
      url: process.env.REACT_APP_API_URL + ressource,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      },
      responseType: "blob"
    }).then(response => {
      const fileName = response.headers["content-disposition"].split('"')[1];
      saveAs(new Blob([response.data]), fileName);
    });
  }

  static getPublicDocs = () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/folders/${SpecialFolder.PUBLIC_FOLDER}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
  };

  static get2FASecret = () => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/generate-2fa`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
  };

  static verify2FAToken = token => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/verify-2fa`,
      data: { token },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
  };

  static get2FAActive = () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/auth-2fa`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
  };
}

export default ApiManager;
