import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10vh 10vw",
    height: "100%",
    backgroundColor: "#E5E8F3"
  }
}));

function PageNotFound() {
  const classes = useStyles();

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <Paper className={classes.root}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <Typography variant="h1" component="div">
            {"ERREUR 404"}
          </Typography>
          <Typography component="div">
            {"Cette page n'existe pas..."}
            <br />
            <a href="/">{"Retourner à la page d'accueil"}</a>
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default PageNotFound;
