/* eslint-disable react/forbid-prop-types */
import React from "react";
import {
  FaFilePdf,
  FaFile,
  FaFolder,
  FaFileExcel,
  FaFileWord,
  FaFileImage,
  FaFileCsv,
  FaFilePowerpoint
} from "react-icons/fa";
import PropTypes from "prop-types";

const DocumentIcon = props => {
  const { document } = props;
  const renderIcon = doc => {
    const { mimeType, documentType } = doc;
    if (documentType === "folder")
      return <FaFolder style={{ color: "#134594" }} />;
    if (mimeType === "application/pdf")
      return <FaFilePdf style={{ color: "#e6223f" }} />;
    if (
      mimeType === "application/vnd.ms-excel" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      return <FaFileExcel style={{ color: "#2E7D32" }} />;
    if (
      mimeType === "application/msword" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
      return <FaFileWord style={{ color: "#1565C0" }} />;
    if (["image/png", "image/jpeg", "image/svg+xml"].indexOf(mimeType) !== -1)
      return <FaFileImage />;
    if (mimeType === "text/csv")
      return <FaFileCsv style={{ color: "#2E7D32" }} />;
    if (
      [
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ].indexOf(mimeType) !== -1
    )
      return <FaFilePowerpoint style={{ color: "#e6223f" }} />;
    return <FaFile />;
  };
  return <span className="DocumentIcon">{renderIcon(document)}</span>;
};

DocumentIcon.propTypes = {
  document: PropTypes.object.isRequired
};

export default DocumentIcon;
