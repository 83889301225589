/* eslint-disable react/forbid-prop-types */

import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import "./GedDeleteModal.scss";
import DocumentIcon from "../DocumentIcon/DocumentIcon";

import { documentReducerType } from "../../_helpers/appProptypes";
import {
  REMOVE_DOCUMENT,
  REMOVE_SELECTION
} from "../../actions/document-actions";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: isMobile ? "90%" : 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
}));

const GedDeleteModal = ({
  isOpen,
  onClose,
  removeDocument,
  selection,
  selectionList,
  excludeList,
  multiple = false,
  removeSelection,
  documentReducer
}) => {
  const classes = useStyles();
  if (!selection) return null;

  // if selectAll, selectionList is empty
  // use documents in documentsReducer to check for non empty folders in selection
  const selectedDocuments =
    selectionList.length === 0
      ? // all documents minus excluded ones
        documentReducer.folder.documents.filter(
          doc => !excludeList.map(d => d._id).includes(doc._id)
        )
      : selectionList;
  const selectionContainsNonEmptyFolders = selectedDocuments
    .map(e => e.folderHasFiles)
    .reduce((a, b) => a || b, false);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="GedDeleteModal"
      open={isOpen}
    >
      <div className={clsx(classes.paper, "GedDeleteModalContent")}>
        <h2 id="simple-modal-title">{"Suppression"}</h2>
        <div className="confirmationBody">
          {multiple ? (
            ""
          ) : (
            <div>
              <DocumentIcon document={selection} />
              <span className="documentName">{selection.name}</span>
            </div>
          )}
          <div className="confirmationText">
            {((multiple && selectionList.length > 1) || multiple) && (
              <p>
                {"La suppression de cette sélection sera irréversible."}
                <br />
                {"Êtes-vous sûr(e) de vouloir la supprimer ?"}
                <br />
                {selectionContainsNonEmptyFolders && (
                  <b>
                    {
                      "Attention: La sélection contient des dossiers non-vides, les documents qu'ils contiennent seront également supprimés."
                    }
                  </b>
                )}
              </p>
            )}
            {selectionList.length === 1 && !multiple && (
              <p>
                {"La suppression de ce "}
                {selectionList[0].documentType === "folder"
                  ? "dossier sera irréversible."
                  : "fichier sera irréversible."}
                <br />
                {"Êtes-vous sûr(e) de vouloir le supprimer ?"}
                <br />
                {selectionList[0].documentType === "folder" &&
                  selectionList[0].folderHasFiles && (
                    <b>
                      {
                        "Attention: ce dossier n'est pas vide, les fichiers qu'il contient seront également supprimés."
                      }
                    </b>
                  )}
              </p>
            )}
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "48px" }}>
          <Button
            style={{ marginRight: "6px" }}
            onClick={onClose}
            className="cancel"
          >
            {"Annuler"}
          </Button>
          {multiple ? (
            <Button
              onClick={() => {
                removeSelection();
                onClose();
              }}
              color="primary"
            >
              {"Supprimer"}
            </Button>
          ) : (
            <Button
              onClick={() => {
                removeDocument(selection);
                onClose();
              }}
              color="primary"
            >
              {"Supprimer"}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

GedDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired,
  selection: PropTypes.shape({
    name: PropTypes.string,
    documentType: PropTypes.string,
    folderHasFiles: PropTypes.bool
  }),
  selectionList: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  removeSelection: PropTypes.func.isRequired,
  documentReducer: documentReducerType.isRequired,
  excludeList: PropTypes.array.isRequired
};

GedDeleteModal.defaultProps = {
  selection: null,
  multiple: false
};

const dispatchStateToProps = dispatch => {
  return {
    removeDocument: document =>
      dispatch({ type: REMOVE_DOCUMENT, payload: { document } }),
    removeSelection: () => dispatch({ type: REMOVE_SELECTION })
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(GedDeleteModal);
