const RoleEnum = require("../enums/role.enum");

const utils = {
  /**
   * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
   *
   * @param {Int} num The number of bytes.
   */
  readableBytes(bytes) {
    if (!bytes) return "0 octets";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ["octets", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];

    return `${(bytes / 1024 ** i).toFixed(1) * 1} ${sizes[i]}`;
  },

  getRole(role) {
    switch (role) {
      case RoleEnum.ADMIN:
        return "administrateur";

      case RoleEnum.COLLAB:
        return "collaborateur";

      case RoleEnum.TENANT_ADMIN:
        return "utilisateur principal";

      case RoleEnum.TENANT_USER:
        return "utilisateur secondaire";

      default:
        return "Rôle inconnu";
    }
  },

  isAdmin(userRole) {
    return userRole === RoleEnum.ADMIN;
  },

  isCollab(userRole) {
    return userRole === RoleEnum.COLLAB;
  },

  isCollabOrAdmin(userRole) {
    return [RoleEnum.ADMIN, RoleEnum.COLLAB].includes(userRole);
  }
};

module.exports = utils;
