import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { withStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import PageTitle from "../PageTitle/PageTitle";
import TicketInfos from "./TicketInfos";
import TicketActions from "./TicketActions";
import TicketForm from "./TicketForm";
import Loader from "../Loader/Loader";
import Pdf from "react-to-pdf";
import {
  supportReducerType,
  authReducerType
} from "../../_helpers/appProptypes";

import "./Tickets.scss";
import { useRef } from "react";

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: isMobile ? "100%" : 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

const TicketDetails = ({
  match,
  history,
  supportReducer,
  authReducer,
  fetchTicket
}) => {
  const ticket = supportReducer.ticket || {};
  const ref = useRef();

  useEffect(() => {
    fetchTicket(match.params.ticketId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let collabName = "Collaborateur non-identifié";
  let missionChiefUserName = "Collaborateur non-identifié";
  if (authReducer.user) {
    const currentTenant = authReducer.user.tenants.find(
      t => t._id === ticket.tenantId
    );
    if (currentTenant) {
      const {
        accountingCollabUserName,
        legalCollabUserName,
        socialCollabUserName
      } = currentTenant;

      ({ missionChiefUserName } = currentTenant);

      if (ticket.missionId === "comptable") {
        collabName = accountingCollabUserName;
      } else if (ticket.missionId === "social") {
        collabName = socialCollabUserName;
      } else if (ticket.missionId === "juridique") {
        collabName = legalCollabUserName;
      } else {
        collabName = accountingCollabUserName;
      }
    }
  }

  return (
    <div className="TicketDetails">
      <PageTitle
        title="Assistance"
        onClick={() => history.push("/assistance")}
      />
      <div className="flex justify-between">
        <ButtonBase
          onClick={() => history.push("/assistance")}
          className="backButton"
        >
          <ArrowLeft />
          {"Retour aux demandes"}
        </ButtonBase>
        <Pdf
          targetRef={ref}
          filename={`demande-assistance-${ticket.subject}.pdf`}
        >
          {({ toPdf }) => (
            <ButtonBase className="markAllAsRead" onClick={toPdf}>
              {"Télécharger en PDF"}
            </ButtonBase>
          )}
        </Pdf>
      </div>
      {!Object.keys(ticket).length || supportReducer.isLoading ? (
        <Loader />
      ) : (
        <div
          ref={ref}
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: isMobile ? "auto" : "calc(100% - 60px)"
          }}
        >
          <TicketInfos
            ticket={ticket}
            role={authReducer.user.role}
            collabName={collabName}
            missionChiefUserName={missionChiefUserName}
          />
          <TicketActions ticket={ticket} role={authReducer.user.role} />
          <TicketForm ticket={ticket} role={authReducer.user.role} />
        </div>
      )}
    </div>
  );
};

TicketDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ ticketId: PropTypes.string.isRequired })
      .isRequired
  }).isRequired,
  // searchTerm: PropTypes.func.isRequired,
  authReducer: authReducerType.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  supportReducer: supportReducerType.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.shape({}),
    icon: PropTypes.shape({}),
    input: PropTypes.shape({})
  }).isRequired,
  fetchTicket: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    supportReducer: state.supportReducer,
    authReducer: state.authReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    // searchTerm: searchTerm =>
    //  dispatch({ type: "SEARCH_DOCUMENTS", payload: searchTerm }),
    fetchTicket: ticketId =>
      dispatch({ type: "FETCH_TICKET", payload: { ticketId } })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(withStyles(theme)(TicketDetails)));
