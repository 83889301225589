import { takeLatest, put, call } from "redux-saga/effects";

import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchIndicatorsSync() {
  try {
    yield put({ type: "LOADING_INDICATORS" });
    const response = yield call(ApiManager.callApi, {
      ressource: "/indicators",
      method: "GET",
      isAuthenticated: true
    });
    yield put({ type: "FETCH_INDICATORS_SYNC", payload: response.data });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchIndicators() {
  yield takeLatest("FETCH_INDICATORS", onFetchIndicatorsSync);
}

function* onAskIndicatorUpdateSync({ payload }) {
  try {
    const { indicator } = payload;
    const {
      _id,
      indicateurPosition,
      indicateurLabel,
      indicateurSigneAffichage,
      SC,
      SD,
      SM,
      comptesExclus,
      niveau
    } = indicator;

    yield call(ApiManager.callApi, {
      ressource: `/indicators/${_id}`,
      method: "PUT",
      isAuthenticated: true,
      data: {
        indicateurPosition,
        indicateurLabel,
        indicateurSigneAffichage,
        SC,
        SD,
        SM,
        comptesExclus,
        niveau
      }
    });
    const reducerPayload = {
      indicator
    };
    yield put({ type: "ASK_INDICATOR_UPDATE_SYNC", payload: reducerPayload });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
  }
}

export function* onAskIndicatorUpdate() {
  yield takeLatest("ASK_INDICATOR_UPDATE", onAskIndicatorUpdateSync);
}
