import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const GedRetentionInfo = ({ folder }) => {
  if (!folder) return null;
  const { parents } = folder;

  if (!parents) return null;

  const rootFolder = parents[parents.length - 1];

  return (
    <div className="opacity-60 px-4 text-xs mt-4">
      {rootFolder._id === "private" && (
        <span>
          {
            "Les documents sont conservés indéfiniment, sauf suppression manuelle par le client"
          }
        </span>
      )}
      {rootFolder._id === "depot" && (
        <span>
          {"Les documents sont supprimés automatiquement au bout de 180 jours."}
        </span>
      )}
      {rootFolder._id === "publication" && (
        <span>
          {
            "Les documents sont conservés indéfiniment sauf suppression par HDM (depuis la GED Cegid)"
          }
        </span>
      )}
    </div>
  );
};

GedRetentionInfo.propTypes = {
  folder: PropTypes.object
};

const mapStateToProps = ({ documentReducer }) => {
  return {
    folder: documentReducer.folder
  };
};

export default connect(mapStateToProps)(GedRetentionInfo);
