import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import "./HomePaper.scss";

const HomePaper = ({ title, children, className }) => {
  return (
    <Paper className={`HomePaper ${className}`}>
      <Typography variant="h3" component="h3" className="mb-3">
        <span>{title}</span>
      </Typography>
      {children}
    </Paper>
  );
};

HomePaper.defaultProps = {
  className: ""
};
HomePaper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

export default HomePaper;
