import { FETCH_CLIENTS_SYNC } from "../actions/client-actions";

const initialState = {
  clients: [],
  indicatorDictionnary: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_CLIENTS": {
      return {
        ...state,
        isLoading: true
      };
    }

    case FETCH_CLIENTS_SYNC: {
      const {
        clients,
        indicatorDictionnary,
        previous,
        next,
        totalCount
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        clients,
        indicatorDictionnary,
        previous,
        next,
        totalCount
      };
    }

    case "ASK_DATA_UPDATE_SYNC": {
      const {
        tenantId,
        accountingPublicationStatus,
        accountingPublicationLimitDate
      } = action.payload;
      const tenantIndex = state.clients.findIndex(
        client => client._id === tenantId
      );
      const newClients = state.clients;
      const clientUpdated = {
        ...state.clients[tenantIndex],
        accountingPublicationStatus,
        accountingPublicationLimitDate
      };
      newClients[tenantIndex] = clientUpdated;
      return {
        ...state,
        clients: [...newClients]
      };
    }

    default:
      return state;
  }
};

export default reducer;
