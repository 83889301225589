import React from "react";
import { connect } from "react-redux";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Loader from "../Loader/Loader";
import DownloadLinkLine from "./DownloadLinkLine";
import {
  authReducerType,
  downloadLinkReducerType
} from "../../_helpers/appProptypes";

const DownloadLinks = props => {
  const { downloadLinkReducer, authReducer } = props;
  const { isLoading, downloadLinks } = downloadLinkReducer;

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ color: "#134594" }}>Nom du document</TableCell>
          <TableCell style={{ color: "#134594" }}>Protégé</TableCell>
          <TableCell style={{ color: "#134594" }} align="center">
            Nombre de téléchargements
          </TableCell>
          {["tenantAdmin", "admin"].includes(authReducer.user.role) && (
            <TableCell style={{ color: "#134594" }}>Auteur</TableCell>
          )}
          <TableCell style={{ color: "#134594" }}>
            Date de création du lien
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  if (isLoading) {
    return (
      <div className="DownloadLinks" style={{ paddingTop: "24px" }}>
        <Table>{renderTableHead}</Table>
        <Loader />
      </div>
    );
  }

  return (
    <div className="DownloadLinks">
      {!downloadLinks.length ? (
        <>
          <Table>{renderTableHead}</Table>
          <div className="noLinks">Aucun lien de téléchargement.</div>
        </>
      ) : (
        <>
          <Table stickyHeader>
            {renderTableHead()}
            <TableBody>
              {downloadLinks.map(link => (
                <DownloadLinkLine selectable key={link._id} link={link} />
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

DownloadLinks.propTypes = {
  downloadLinkReducer: downloadLinkReducerType.isRequired,
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    downloadLinkReducer: state.downloadLinkReducer,
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps)(DownloadLinks);
