import { takeLatest, put, call, select } from "redux-saga/effects";
import { USER_LOGOUT } from "../actions/login-actions";
import ApiManager from "../lib/apiManager";
import { handleError } from "./sagaCommon";

function* onFetchDashboardsSync() {
  try {
    const { tenantId } = yield select(state => state.authReducer.user);
    const response = yield call(ApiManager.callApi, {
      ressource: `/dashboards?tenantId=${tenantId}`,
      method: "GET",
      useMock: false,
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_DASHBOARDS_SYNC",
      payload: response.data
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du chargement des dashboards");

    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchDashboards() {
  yield takeLatest("FETCH_DASHBOARDS", onFetchDashboardsSync);
}

function* onOpenDashboardSync({ payload }) {
  try {
    const { dashboardId } = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    yield call(ApiManager.callApi, {
      ressource: `/dashboards/${dashboardId}?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
  } catch (error) {
    yield handleError(error, "Erreur lors de l'ouverture du lien utile'");
  }
}

export function* onOpenDashboard() {
  yield takeLatest("OPEN_DASHBOARD", onOpenDashboardSync);
}
