import React from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { CREATE_FOLDER } from "../../actions/document-actions";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: isMobile ? "90%" : 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
}));

const GedNewFolder = props => {
  const classes = useStyles();
  const { isOpen, onClose, createNewFolder } = props;
  const [folderName, setFolderName] = React.useState("");
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="GedNewFolderModal"
      open={isOpen}
    >
      <div className={clsx(classes.paper, "GedNewFolderModalContent")}>
        <h2 id="simple-modal-title">{"Créer un Dossier"}</h2>
        <label htmlFor="newFolder">
          {"Nom du dossier"}
          <TextField
            margin="dense"
            id="newFolder"
            variant="outlined"
            fullWidth
            inputProps={{ "aria-label": "bare" }}
            value={folderName}
            onChange={e => setFolderName(e.target.value)}
          />
        </label>
        <div style={{ textAlign: "right", marginTop: "48px" }}>
          <Button
            style={{ marginRight: "6px" }}
            onClick={onClose}
            className="cancel"
          >
            {"Annuler"}
          </Button>
          <Button
            onClick={() => {
              createNewFolder(folderName);
              onClose();
            }}
          >
            {"Créer"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

GedNewFolder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createNewFolder: PropTypes.func.isRequired
};

const dispatchStateToProps = dispatch => {
  return {
    createNewFolder: folderName =>
      dispatch({ type: CREATE_FOLDER, payload: { folderName } })
  };
};

export default connect(null, dispatchStateToProps)(GedNewFolder);
