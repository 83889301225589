export const ECHANGE_FOLDERS = [
  "comptable_depot",
  "fiscal_depot",
  "gestion_depot",
  "juridique_depot",
  "social_depot"
];

export const PRIVATE_FOLDERS = [
  "comptable_private",
  "fiscal_private",
  "gestion_private",
  "juridique_private",
  "social_private"
];
