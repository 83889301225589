import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField } from "@material-ui/core";
import { QRCodeSVG } from "qrcode.react";
import { connect } from "react-redux";
import {
  TOAST_ERROR_ACTION,
  TOAST_SUCCESS_ACTION
} from "../../actions/toast-actions";
import PropTypes from "prop-types";

import ApiManager from "../../lib/apiManager";
import Loader from "../Loader/Loader";
import { useMutation } from "react-query";

const Auth2FADialog = ({ toastSuccess, toastError, onClose, onSuccess }) => {
  const [code, setCode] = useState("");
  const [auth2FASecret, setAuth2FASecret] = useState(null);

  const get2FASecretMutation = useMutation(ApiManager.get2FASecret, {
    onSuccess: data => {
      setAuth2FASecret(data.data.otpauth_url);
    },
    onError: error => {
      toastError(error.message);
    }
  });

  const verify2FATokenMutation = useMutation(ApiManager.verify2FAToken, {
    onSuccess: response => {
      if (response.data.verified) {
        onClose();
        toastSuccess("2FA activé avec succès");
        onSuccess();
      } else {
        toastError("Code invalide");
      }
    },
    onError: error => {
      toastError(error.message);
    }
  });

  useEffect(() => {
    get2FASecretMutation.mutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (get2FASecretMutation.isLoading) return <Loader />;
  if (get2FASecretMutation.isError)
    return <div>{get2FASecretMutation.error.message}</div>;

  const handleConfirmCode = () => verify2FATokenMutation.mutate(code);

  if (!auth2FASecret) return null;

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Activer l'authentification 2FA"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: "15px", fontSize: "18px" }}>
          <div>
            <span>
              {
                "1. Veuillez scanner ce QRCode avec votre application Google Authentificator [ "
              }
            </span>
            <a
              href="https://apps.apple.com/fr/app/google-authenticator/id388497605"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"ios"}
            </a>
            <span>{" | "}</span>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=fr&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"android"}
            </a>
            <span>{" ] ou Authy [ "}</span>
            <a
              href="https://apps.apple.com/fr/app/twilio-authy/id494168017"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"ios"}
            </a>
            <span>{" | "}</span>
            <a
              href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=fr&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"android"}
            </a>
            <span>{" ]"}</span>
          </div>
        </DialogContentText>
        <div
          style={{
            marginTop: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <QRCodeSVG value={auth2FASecret} />
        </div>
        <DialogContentText style={{ marginTop: "48px" }}>
          <div>
            <div style={{ fontSize: "18px" }}>
              {"2. Une fois scanné, veuillez entrer le code affiché"}
            </div>

            <div
              style={{
                marginTop: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "8px"
                }}
              >
                <TextField
                  inputProps={{
                    maxLength: 6
                  }}
                  style={{
                    marginRight: "6px"
                  }}
                  type="number"
                  placeholder="Entrer le code affiché"
                  value={code}
                  onChange={e => setCode(e.currentTarget.value)}
                />
                <Button
                  onClick={handleConfirmCode}
                  color="primary"
                  className="primary-button"
                >
                  {"Valider"}
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="secondary-button cancel"
          onClick={onClose}
          color="primary"
        >
          {"Fermer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const dispatchStateToProps = dispatch => {
  return {
    toastSuccess: message =>
      dispatch({
        type: TOAST_SUCCESS_ACTION,
        payload: message
      }),
    toastError: message =>
      dispatch({
        type: TOAST_ERROR_ACTION,
        payload: message
      })
  };
};

Auth2FADialog.propTypes = {
  toastSuccess: PropTypes.func.isRequired,
  toastError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default connect(null, dispatchStateToProps)(Auth2FADialog);
