import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const UnAuthenticatedRoute = pprops => {
  const { authReducer, path, component, ...rest } = pprops;
  const { isAuthenticated } = authReducer;

  if (isAuthenticated) {
    return <Route path={path} render={() => <Redirect to="/" />} />;
  }

  return (
    <Route
      path={path}
      render={props =>
        React.createElement(component, {
          ...props,
          ...rest
        })
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps)(UnAuthenticatedRoute);
