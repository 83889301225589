const initialState = {
  links: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_HELP_LINKS_SYNC": {
      const links = action.payload;
      return {
        ...state,
        links
      };
    }
    default:
      return state;
  }
};

export default reducer;
