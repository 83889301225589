import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Info from "@material-ui/icons/Info";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import TextFormat from "@material-ui/icons/TextFormat";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  documentType,
  favoriteReducerType,
  multiSelectionReducerType,
  documentReducerType,
  userType
} from "../../_helpers/appProptypes";
import utils from "../../_helpers/utils";
import { DOWNLOAD_FILE } from "../../actions/document-actions";

import "./PublicDocsFileLine.scss";
import ApiManager from "../../lib/apiManager";
import SpecialFolder from "../../enums/special-folder.enum";
import { useQuery } from "react-query";

const PublicDocsFileLine = ({
  document,
  onRename,
  downloadFile,
  documentReducer,
  complementaryNameInfo,
  history
}) => {
  const getPublicDocsQuery = useQuery("publicDocs", ApiManager.getPublicDocs);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const documentDownloading =
    documentReducer && documentReducer.downloading
      ? documentReducer.downloading[document._id]
      : false;

  const isFolder = document.documentType === "folder";

  const onRemoveDocument = async () => {
    await ApiManager.callApi({
      ressource: `/documents/delete`,
      method: "POST",
      isAuthenticated: true,
      data: {
        parentId: SpecialFolder.PUBLIC_FOLDER,
        allSelected: false,
        documentIds: [document._id],
        tenantId: document.tenantId
      }
    });
    setDropdownOpen(false);

    getPublicDocsQuery.refetch();
  };

  const onRenameDocument = async () => {
    onRename(document);
    setDropdownOpen(false);
  };

  return (
    <TableRow
      key={document._id}
      className="PublicDocsFileLine"
      style={{
        cursor: "pointer",
        fontWeight: "normal",
        background: ""
      }}
    >
      <TableCell style={{ align: "left", fontWeight: "inherit" }}>
        <div style={{ display: "flex" }}>
          <div>
            <Typography
              component="a"
              className="filename"
              onClick={() => downloadFile(document)}
            >
              {document.name}

              {isFolder && (
                <span className="opacity-40">{` (${document.totalDocs ||
                  0})`}</span>
              )}
            </Typography>
            {complementaryNameInfo}
            {documentDownloading && (
              <CircularProgress
                size="1.3rem"
                style={{ marginLeft: "12px", verticalAlign: "middle" }}
              />
            )}
          </div>
        </div>
      </TableCell>

      <TableCell
        align="left"
        className="DocumentDate"
        style={{ fontWeight: "inherit" }}
      >
        {isFolder
          ? "-"
          : moment(document.lastUpdate || document.creationDate).format(
              "DD/MM/YYYY"
            )}
      </TableCell>

      <TableCell
        className="DocumentSize"
        align="left"
        style={{ fontWeight: "inherit" }}
      >
        {document.size ? utils.readableBytes(document.size) : null}
      </TableCell>

      <TableCell className="DropdownCell">
        <div>
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <div>
              <Typography
                onClick={() => setDropdownOpen(!isDropdownOpen)}
                className={isDropdownOpen ? "DropdownActive" : null}
              >
                ...
              </Typography>
              <div className="DropdownContainer">
                {isDropdownOpen ? (
                  <Paper className="DropdownMenu">
                    {isFolder ? (
                      <div
                        className="DropdownOption"
                        onClick={() => {
                          history.push(`/ged/${document._id}`);
                        }}
                      >
                        <OpenInBrowser />
                        {" Ouvrir"}
                      </div>
                    ) : (
                      <div
                        className="DropdownOption"
                        onClick={() => {
                          history.push(`/document/${document._id}`);
                          setDropdownOpen(false);
                        }}
                      >
                        <Info />
                        {"Informations"}
                      </div>
                    )}

                    <div className="DropdownOption" onClick={onRenameDocument}>
                      <TextFormat />
                      {" Renommer"}
                    </div>
                    <div className="DropdownOption" onClick={onRemoveDocument}>
                      <RemoveCircle />
                      {" Supprimer"}
                    </div>
                  </Paper>
                ) : null}
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </TableCell>
    </TableRow>
  );
};

PublicDocsFileLine.propTypes = {
  favoriteReducer: favoriteReducerType.isRequired,
  document: documentType.isRequired,
  multiSelectionReducer: multiSelectionReducerType.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  toggleSelectDocument: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  documentReducer: documentReducerType.isRequired,
  complementaryNameInfo: PropTypes.element,
  selectable: PropTypes.bool,
  user: userType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isCollabSession: PropTypes.bool.isRequired,
  markSelectionAsRead: PropTypes.func.isRequired
};

PublicDocsFileLine.defaultProps = {
  selectable: false,
  complementaryNameInfo: null
};

const dispatchStateToProps = dispatch => {
  return {
    addToFavorites: doc => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "add",
          favorite: {
            type: doc.documentType === "folder" ? "folder" : "file",
            name: doc.name,
            docId: doc._id
          }
        }
      });
    },
    removeFromFavorites: doc => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "remove",
          favorite: {
            type: "file",
            name: doc.name,
            docId: doc._id
          }
        }
      });
    },
    toggleSelectDocument: doc => {
      dispatch({ type: "TOGGLE_SELECT_DOCUMENT", payload: doc });
    },
    downloadFile: doc => {
      dispatch({ type: DOWNLOAD_FILE, payload: doc });
    },
    markSelectionAsRead: () => dispatch({ type: "MARK_SELECTION_AS_READ" })
  };
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer,
    multiSelectionReducer: state.multiSelectionReducer,
    documentReducer: state.documentReducer,
    isCollabSession: state.authReducer.isCollabSession,
    user: state.authReducer.user
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(PublicDocsFileLine));
