import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import { handleError } from "./sagaCommon";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchDownloadLinksSync() {
  try {
    yield put({ type: "LOAD_DOWNLOAD_LINKS" });

    const response = yield call(ApiManager.callApi, {
      ressource: `/links`,
      method: "GET",
      isAuthenticated: true
    });

    yield put({ type: "FETCH_DOWNLOAD_LINKS_ASYNC", payload: response.data });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Une erreur s'est produite lors de la récupération du lien"
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchDownloadLinks() {
  yield takeLatest("FETCH_DOWNLOAD_LINKS", onFetchDownloadLinksSync);
}

function* onFetchDownloadLinkSync({ payload }) {
  try {
    const linkId = payload;

    yield put({ type: "LOAD_DOWNLOAD_LINKS" });
    const response = yield call(ApiManager.callApi, {
      ressource: `/links/${linkId}`,
      method: "GET",
      isAuthenticated: true
    });

    yield put({ type: "FETCH_DOWNLOAD_LINK_ASYNC", payload: response.data });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Une erreur s'est produite lors de la récupération du lien"
    });
  }
}

export function* onFetchDownloadLink() {
  yield takeLatest("FETCH_DOWNLOAD_LINK", onFetchDownloadLinkSync);
}

function* onUpdateDownloadLinkSync({ payload }) {
  try {
    const {
      linkId,
      password,
      enablePassword,
      maxDownloads,
      passwordChanged
    } = payload;

    const { tenantId } = yield select(state => state.authReducer.user);

    const response = yield call(ApiManager.callApi, {
      ressource: `/links/${linkId}`,
      method: "PUT",
      isAuthenticated: true,
      data: {
        password,
        enablePassword,
        maxDownloads,
        passwordChanged,
        tenantId
      }
    });

    yield put({ type: "UPDATE_DOWNLOAD_LINK_ASYNC", payload: response.data });
  } catch (error) {
    yield handleError(
      error,
      "Une erreur s'est produite lors de la mise à jour du lien"
    );
  }
}

export function* onUpdateDownloadLink() {
  yield takeLatest("UPDATE_DOWNLOAD_LINK", onUpdateDownloadLinkSync);
}

function* onDeleteDownloadLinkSync({ payload }) {
  try {
    const linkId = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    yield put({ type: "LOAD_DOWNLOAD_LINKS" });
    const response = yield call(ApiManager.callApi, {
      ressource: `/links/${linkId}?tenantId=${tenantId}`,
      method: "DELETE",
      isAuthenticated: true
    });

    yield put({ type: "DELETE_DOWNLOAD_LINK_ASYNC", payload: response.data });
    yield put({
      type: "SHARE_DOCUMENT_SYNC",
      payload: {}
    });
  } catch (error) {
    yield handleError(
      error,
      "Une erreur s'est produite lors de la suppression du lien"
    );
  }
}

export function* onDeleteDownloadLink() {
  yield takeLatest("DELETE_DOWNLOAD_LINK", onDeleteDownloadLinkSync);
}
