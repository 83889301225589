import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import logoHDM from "../../images/LOGO_HDM_2.svg";

const useStyles = makeStyles(() => ({
  root: {
    background:
      "linear-gradient(to right bottom, #134594, #263AD1), url('/images/LOGO_HDM_2.svg') left top no-repeat",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    position: "absolute",
    top: "36px",
    left: "36px",
    height: "56px"
  }
}));

const EmptyLayout = props => {
  const classes = useStyles();
  return (
    <div className={clsx("Layout", classes.root)}>
      <a href="/">
        <img alt="logo HDM" src={logoHDM} className={classes.logo} />
      </a>
      {props.children}
    </div>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default EmptyLayout;
