/* eslint-disable react/forbid-prop-types */
import React from "react";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./GedCategory.scss";
import FolderSelector from "./GedFolderSelector";

import { documentReducerType } from "../../_helpers/appProptypes";

const GedCategory = ({
  folderId,
  title,
  folders,
  history,
  documentReducer
}) => {
  let selectedRootFolder;
  if (documentReducer && documentReducer.folder) {
    const { parents } = documentReducer.folder;
    if (parents) {
      selectedRootFolder = (parents[parents.length - 1] || {})._id;
    }
  }

  return (
    <div className="GedCategory">
      <div
        className={
          selectedRootFolder === folderId
            ? "titleContainer active"
            : "titleContainer"
        }
        onClick={() => {
          history.push(`/ged/${folderId}`);
        }}
        aria-hidden="true"
      >
        <Typography component="h3">{title}</Typography>
      </div>
      <div className="content">
        {folders.map(folder => (
          <FolderSelector key={folder._id} folder={folder} />
        ))}
      </div>
    </div>
  );
};

GedCategory.propTypes = {
  folderId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  folders: PropTypes.array.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  documentReducer: documentReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

export default connect(mapStateToProps)(withRouter(GedCategory));
