import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

import "./PageTitle.scss";

const PageTitle = ({ title, children }) => {
  return (
    <div className="PageTitle flex justify-between">
      <Typography component="h3" variant="h3">
        <span className="titleInline">{title}</span>
      </Typography>

      <div>{children}</div>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element
};

PageTitle.defaultProps = {
  children: null
};

export default PageTitle;
