import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import { handleError } from "./sagaCommon";
import {
  FETCH_COLLAB_DOCS_SYNC_ACTION,
  GET_NEW_DOCS
} from "../actions/document-actions";
import { USER_LOGOUT } from "../actions/login-actions";

function* onSearchDocumentsAsync({ payload }) {
  const searchTerm = payload;
  try {
    const { tenantId } = yield select(state => state.authReducer.user);
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/search?term=${searchTerm ||
        ".*"}&tenantId=${tenantId}`,
      method: "GET",
      data: payload,
      isAuthenticated: true
    });
    if (response.data.results) {
      yield put({ type: "SEARCH_DOCUMENT_ASYNC", payload: response.data });
    } else {
      yield put({
        type: FETCH_COLLAB_DOCS_SYNC_ACTION,
        payload: response.data
      });
    }
  } catch (error) {
    yield handleError(error, "Une erreur s'est produite lors de la recherche");

    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onSearchDocuments() {
  yield takeLatest("SEARCH_DOCUMENTS", onSearchDocumentsAsync);
}

function* onGetNewDocsAsync() {
  try {
    const { user } = yield select(state => state.authReducer);
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/new`,
      method: "GET",
      isAuthenticated: true
    });
    if (user.role === "collab") {
      yield put({
        type: FETCH_COLLAB_DOCS_SYNC_ACTION,
        payload: response.data
      });
    } else {
      yield put({ type: "SEARCH_DOCUMENT_ASYNC", payload: response.data });
    }
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Une erreur s'est produite lors de la recherche"
    });
  }
}

export function* onGetNewDocs() {
  yield takeLatest(GET_NEW_DOCS, onGetNewDocsAsync);
}
