/* eslint-disable react/forbid-prop-types */
import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import Badge from "@material-ui/core/Badge";
import Home from "@material-ui/icons/Home";
import ViewModule from "@material-ui/icons/ViewModule";
import Receipt from "@material-ui/icons/Receipt";
import LiveHelp from "@material-ui/icons/LiveHelp";

import { authReducerType } from "../../_helpers/appProptypes";
import Loader from "../Loader/Loader";

import "./Layout.scss";

const MobileFooter = props => {
  const [newDocsCount, setNewDocsCount] = React.useState(0);
  const [newTicketsCount, setNewTicketsCount] = React.useState(0);

  const { history, authReducer, location } = props;

  const activePath = ((location || {}).pathname || "/").split("/")[1];

  React.useEffect(() => {
    if (authReducer.user && authReducer.user.gedNotifications) {
      setNewDocsCount(authReducer.user.gedNotifications.length);
    }

    if (authReducer.user && authReducer.user.ticketNotifications) {
      setNewTicketsCount(authReducer.user.ticketNotifications.length);
    }
  }, [authReducer.user]);

  if (authReducer.isLoading || !authReducer.user) return <Loader />;

  return (
    <Paper
      style={{
        position: "fixed",
        bottom: "0",
        right: "0",
        height: "60px",
        width: "100%",
        zIndex: "1000"
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="row"
        padding="0 5px"
        alignItems="center"
        justifyContent="space-evenly"
        color="primary"
      >
        <IconButton
          color={!activePath ? "primary" : "default"}
          onClick={() => history.push("/")}
        >
          <Home />
        </IconButton>
        <IconButton
          color={activePath === "ged" ? "primary" : "default"}
          onClick={() => history.push("/ged")}
        >
          {newDocsCount && !authReducer.isCollabSession ? (
            <Badge badgeContent={newDocsCount} color="primary">
              <Receipt />
            </Badge>
          ) : (
            <Receipt />
          )}
        </IconButton>
        <IconButton
          color={activePath === "applications" ? "primary" : "default"}
          onClick={() => history.push("/applications")}
        >
          <ViewModule />
        </IconButton>
        <IconButton
          color={activePath === "assistance" ? "primary" : "default"}
          onClick={() => history.push("/assistance")}
        >
          {newTicketsCount ? (
            <Badge badgeContent={newTicketsCount} color="primary">
              <LiveHelp />
            </Badge>
          ) : (
            <LiveHelp />
          )}
        </IconButton>
      </Box>
    </Paper>
  );
};

MobileFooter.propTypes = {
  authReducer: authReducerType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer,
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps, {})(withRouter(MobileFooter));
