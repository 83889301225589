import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import TicketAction from "./TicketAction";
import Loader from "../Loader/Loader";

import { ticketType } from "../../_helpers/appProptypes";

const theme = {
  root: {
    padding: "10px 25px",
    marginBottom: "2px",
    height: "45vh",
    overflowY: "auto"
  }
};

const TicketActions = props => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  });

  const { role, ticket, classes } = props;
  const { actions, companyName } = ticket;

  if (!actions) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  const toDisplay = [
    {
      actionType: "reply",
      date: ticket.creationDate,
      author: ticket.author,
      value: ticket.description
    },
    ...actions
  ];

  return (
    <Paper className={classes.root} square elevation={0}>
      <div
        className="TicketActions"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {toDisplay.map((action, actionIndex) => (
          <TicketAction
            action={action}
            companyName={companyName}
            role={role}
            key={actionIndex}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </Paper>
  );
};

TicketActions.propTypes = {
  ticket: ticketType.isRequired,
  role: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.shape({})
  }).isRequired
};

export default withStyles(theme)(TicketActions);
