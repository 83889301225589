const initialState = {
  indicators: [],
  isLoading: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_INDICATORS": {
      return {
        ...state,
        isLoading: true
      };
    }

    case "FETCH_INDICATORS_SYNC": {
      const { indicators } = action.payload;
      return {
        ...state,
        isLoading: false,
        indicators
      };
    }

    case "ASK_INDICATOR_UPDATE_SYNC": {
      const { indicator } = action.payload;

      const indicatorIndex = state.indicators.findIndex(
        ind => ind._id === indicator._id
      );
      const newIndindicators = [...state.indicators];
      newIndindicators[indicatorIndex] = indicator;

      return {
        ...state,
        indicators: newIndindicators
      };
    }

    default:
      return state;
  }
};

export default reducer;
