import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import {
  authReducerType,
  downloadLinkReducerType,
  downloadLinkType
} from "../../_helpers/appProptypes";

const DownloadLinkLine = props => {
  const { downloadLinkReducer, link, authReducer, history } = props;
  if (!downloadLinkReducer.downloadLinks) return null;

  return (
    <TableRow
      key={link._id}
      className="DownloadLinkLine"
      hover
      style={{ cursor: "pointer" }}
      onClick={() => history.push(`/document/${link.documentId}`)}
    >
      <TableCell>{link.documentName}</TableCell>
      <TableCell>
        <Checkbox disabled checked={link.hasPassword} />
      </TableCell>
      <TableCell align="center">
        {link.downloadsCount} / {link.maxDownloads || "-"}
      </TableCell>
      {["tenantAdmin", "admin"].includes(authReducer.user.role) && (
        <TableCell>{link.author}</TableCell>
      )}
      <TableCell>{moment(link.creationDate).format("DD/MM/YYYY")}</TableCell>
    </TableRow>
  );
};

DownloadLinkLine.propTypes = {
  downloadLinkReducer: downloadLinkReducerType.isRequired,
  link: downloadLinkType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    downloadLinkReducer: state.downloadLinkReducer
  };
};

export default connect(mapStateToProps)(withRouter(DownloadLinkLine));
