/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ApiManager from "../../lib/apiManager";

import "./DashboardItem.scss";

const DashboardItem = props => {
  const {
    favoriteReducer,
    markAsFavorite,
    removeFromFavorite,
    dashboard
  } = props;
  const isFavorite = favoriteReducer.favorites.find(
    favorite => favorite.docId === dashboard._id
  );

  const [imgSrc, setImgSrc] = useState(undefined);

  // update dashboards
  useEffect(() => {
    const fetchImage = async dashboardId => {
      const response = await ApiManager.callApi({
        ressource: `/dashboards/${dashboardId}/thumbnail`,
        method: "GET",
        isAuthenticated: true,
        blob: true
      });
      if (response.status === 200) {
        setImgSrc(URL.createObjectURL(response.data));
      }
    };
    fetchImage(dashboard._id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Paper className="DashboardItem">
      <div>
        <img alt={dashboard.title} src={imgSrc} />
      </div>
      <div style={{ display: "flex", padding: "6px 12px" }}>
        <div style={{ flex: "1 1 auto" }}>
          <Typography component="h4">{dashboard.title}</Typography>
          <small>{dashboard.date}</small>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}
        >
          {isFavorite ? (
            <Star
              onClick={e => {
                e.stopPropagation();
                removeFromFavorite(dashboard);
              }}
            />
          ) : (
            <StarBorder
              onClick={e => {
                e.stopPropagation();
                markAsFavorite(dashboard);
              }}
            />
          )}
        </div>
      </div>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    markAsFavorite: dashboard => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "add",
          favorite: {
            name: dashboard.title,
            type: "dashboard",
            docId: dashboard._id
          }
        }
      });
    },
    removeFromFavorite: dashboard => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "remove",
          favorite: {
            name: dashboard.title,
            type: "dashboard",
            docId: dashboard._id
          }
        }
      });
    }
  };
};

DashboardItem.propTypes = {
  markAsFavorite: PropTypes.func.isRequired,
  removeFromFavorite: PropTypes.func.isRequired,
  favoriteReducer: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired
};

export default connect(mapStateToProps, dispatchStateToProps)(DashboardItem);
