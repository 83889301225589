import _ from "lodash";
import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";

import DocumentIcon from "../DocumentIcon/DocumentIcon";
import {
  documentType,
  multiSelectionReducerType,
  authReducerType
} from "../../_helpers/appProptypes";
import utils from "../../_helpers/utils";

import "./DocumentsLine.scss";
import {
  DOWNLOAD_FILE,
  PROCESS_DOCUMENT_ACTION
} from "../../actions/document-actions";

const DocumentsLine = ({
  document,
  toggleSelectDocument,
  downloadFile,
  processDocument,
  multiSelectionReducer,
  selectable,
  nonProcessed,
  authReducer,
  withoutProcessButton = false
}) => {
  const [open, setOpen] = useState(false);
  const [downloadsCount, setDownloadsCount] = useState(document.downloadsCount);

  let newDocuments = [];

  if (authReducer.user.gedNotifications) {
    newDocuments = authReducer.user.gedNotifications;
  }

  const handleOpenModal = () => setOpen(true);

  const handleProcessDocument = () => {
    setOpen(false);
    processDocument(document._id);
  };

  const isNewDoc = newDocuments.indexOf(document._id) !== -1;

  return (
    <>
      <TableRow
        key={document._id}
        style={{
          fontWeight: isNewDoc ? "bold" : "normal",
          background: isNewDoc ? "#fafaff" : ""
        }}
        className="DocumentsLine"
      >
        {selectable ? (
          <TableCell align="center" component="th" scope="row">
            <Checkbox
              checked={_.some(
                multiSelectionReducer.selectionList,
                doc => doc._id === document._id
              )}
              onClick={() => toggleSelectDocument(document)}
            />
          </TableCell>
        ) : null}
        <TableCell
          align="left"
          component="th"
          scope="row"
          style={{ fontWeight: "inherit", width: "5px", padding: "0px" }}
        >
          {isNewDoc && (
            <b>
              <Badge
                variant="dot"
                color="primary"
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left"
                }}
              />
            </b>
          )}
        </TableCell>
        <TableCell align="center" style={{ fontWeight: "inherit" }}>
          {document.field}
        </TableCell>
        <TableCell align="center" className="TypeCell">
          <DocumentIcon document={document} />
        </TableCell>
        <TableCell align="left" style={{ fontWeight: "inherit" }}>
          {document.name}
        </TableCell>
        <TableCell align="left" style={{ fontWeight: "inherit" }}>
          {document.companyName}
        </TableCell>
        <TableCell align="center" style={{ fontWeight: "inherit" }}>
          {moment(document.creationDate).format("DD/MM/YYYY")}
        </TableCell>
        {nonProcessed ? (
          <TableCell align="left" style={{ fontWeight: "inherit" }}>
            {document.manager}
          </TableCell>
        ) : null}
        <TableCell align="center" style={{ fontWeight: "inherit" }}>
          {document.size ? utils.readableBytes(document.size) : null}
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="primary"
            aria-label="télécharger le document"
            onClick={() => {
              setDownloadsCount(downloadsCount + 1);
              downloadFile(document);
            }}
          >
            <CloudDownload />
          </IconButton>{" "}
          ({downloadsCount})
        </TableCell>

        {!withoutProcessButton && (
          <TableCell align="center">
            <Button onClick={handleOpenModal}>{"Traiter"}</Button>
          </TableCell>
        )}
      </TableRow>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Considérer ce document comme traité ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              {
                "Ce document sera considéré comme traité et ne figurera plus dans cette liste, de façon irréversible."
              }
              <br />
              {downloadsCount === 0 && (
                <b>{"CE DOCUMENT N'A JAMAIS ETE TÉLÉCHARGÉ."}</b>
              )}
            </p>
            <p>{"Confirmez-vous avoir traité ce document ?"}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className="cancel">
            {"Annuler"}
          </Button>
          <Button onClick={handleProcessDocument}>{"Confirmer"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DocumentsLine.propTypes = {
  document: documentType.isRequired,
  toggleSelectDocument: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  processDocument: PropTypes.func.isRequired,
  multiSelectionReducer: multiSelectionReducerType.isRequired,
  selectable: PropTypes.bool,
  nonProcessed: PropTypes.bool,
  withoutProcessButton: PropTypes.bool,
  authReducer: authReducerType.isRequired
};

DocumentsLine.defaultProps = {
  selectable: false,
  nonProcessed: false,
  withoutProcessButton: false
};

const mapStateToProps = state => {
  return {
    multiSelectionReducer: state.multiSelectionReducer,
    authReducer: state.authReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    toggleSelectDocument: doc => {
      dispatch({ type: "TOGGLE_SELECT_DOCUMENT", payload: doc });
    },
    downloadFile: doc => {
      dispatch({ type: DOWNLOAD_FILE, payload: doc });
    },
    processDocument: docId => {
      dispatch({ type: PROCESS_DOCUMENT_ACTION, payload: docId });
    }
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(DocumentsLine);
