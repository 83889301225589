import { FETCH_TICKETS } from "../actions/ticket-actions";

const DEFAULT_PAGE_SIZE = 10;

const initialState = {
  supports: [],
  tickets: [],
  isLoading: false,
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  status: ["opened", "inProgress", "recentlyClosed"],
  mission: ["comptable", "juridique", "social"]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SUPPORTS_ASYNC": {
      const supports = action.payload;
      return {
        ...state,
        supports
      };
    }

    case FETCH_TICKETS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case "FETCH_TICKETS_ASYNC": {
      const { lateTickets, myTickets } = action.payload;
      return {
        ...state,
        lateTickets,
        myTickets,
        isLoading: false,
        unread: false
      };
    }

    case "FETCH_TICKET_ASYNC": {
      const { ticket } = action.payload;

      return {
        ...state,
        ticket,
        isLoading: false
      };
    }

    case "LOAD_TICKETS": {
      return {
        ...state,
        isLoading: true
      };
    }

    case "FETCH_TICKET_HISTORY_ASYNC": {
      const { myTickets } = action.payload;
      return {
        ...state,
        myTickets,
        isLoading: false
      };
    }

    case "UPDATE_TICKET_FILTERS_ASYNC": {
      return {
        ...state,
        ...action.payload
      };
    }

    case "UPDATE_TICKET_ASYNC": {
      const { ticket } = action.payload;

      return {
        ...state,
        ticket
      };
    }

    default:
      return state;
  }
};

export default reducer;
