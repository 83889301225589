import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

import { documentReducerType } from "../../_helpers/appProptypes";
import Loader from "../Loader/Loader";
import DocumentsLine from "./DocumentsLine";
import DocumentListingTable from "./DocumentListingTable";
import DocumentsListingEmptyTable from "./DocumentsListingEmptyTable";

import "./Documents.scss";

const sortByCreationDate = (a, b) =>
  moment(a.creationDate).diff(b.creationDate);

const DocumentsListing = ({ documentReducer, markAllAsRead }) => {
  const {
    isLoading,
    myDocs,
    nonProcessedDocs,
    gedNotifications,
    recentlyProcessedDocuments
  } = documentReducer;

  return (
    <div className="DocumentsListing">
      <div style={{ overflow: "auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#134594"
          }}
        >
          <Typography component="h2">
            {" Documents de plus de 7 jours"}
          </Typography>
          <ButtonBase
            className="markAllAsRead"
            onClick={markAllAsRead}
            disabled={!gedNotifications || !gedNotifications.length}
          >
            {"Tout marquer comme lu"}
          </ButtonBase>
        </div>
        <Table>
          <DocumentListingTable />

          {!isLoading && nonProcessedDocs.length > 0 && (
            <TableBody>
              {nonProcessedDocs.sort(sortByCreationDate).map(document => (
                <DocumentsLine
                  key={document._id}
                  document={document}
                  nonProcessed
                  selectable
                />
              ))}
            </TableBody>
          )}
        </Table>
        {isLoading ? (
          <Loader />
        ) : (
          <>{nonProcessedDocs.length === 0 && <DocumentsListingEmptyTable />}</>
        )}
      </div>

      <div>
        <Typography component="h2">{"Mes documents"}</Typography>
        <Table>
          <DocumentListingTable mine />

          {!isLoading && myDocs.length > 0 && (
            <TableBody>
              {myDocs.sort(sortByCreationDate).map(document => (
                <DocumentsLine
                  key={document._id}
                  document={document}
                  selectable
                />
              ))}
            </TableBody>
          )}
        </Table>

        {isLoading ? (
          <Loader />
        ) : (
          <>{myDocs.length === 0 && <DocumentsListingEmptyTable />}</>
        )}
      </div>

      <div style={{ overflow: "auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#134594"
          }}
        >
          <Typography component="h2">
            {" Documents traités récemment"}
          </Typography>
        </div>
        <Table>
          <DocumentListingTable withoutAction />

          {!isLoading && recentlyProcessedDocuments.length > 0 && (
            <TableBody>
              {recentlyProcessedDocuments
                .sort(sortByCreationDate)
                .map(document => (
                  <DocumentsLine
                    key={document._id}
                    document={document}
                    nonProcessed
                    selectable
                    withoutProcessButton
                  />
                ))}
            </TableBody>
          )}
        </Table>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {recentlyProcessedDocuments.length === 0 && (
              <DocumentsListingEmptyTable />
            )}
          </>
        )}
      </div>
    </div>
  );
};

DocumentsListing.propTypes = {
  documentReducer: documentReducerType.isRequired,
  markAllAsRead: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markAllAsRead: () => dispatch({ type: "MARK_ALL_DOCUMENTS_AS_READ" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsListing);
