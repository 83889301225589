import { Button, Modal } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: isMobile ? "90%" : 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
}));

const HelpModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClose={onClose}
      open={isOpen}
    >
      <div className={clsx(classes.paper, "GedDeleteModalContent")}>
        <h2>{"Problème de connexion"}</h2>

        <div className="mt-4">
          <div>
            {"Vous avez un problème de connexion, voici quelques conseils :"}

            <ul>
              <li className="mt-2">
                {
                  "Essayer de vous connecter ave le mode « Navigation privée » de votre navigateur"
                }
              </li>

              <li className="mt-2">
                {"Effacer sur votre navigateur les cookies du site my.hdm.re"}
              </li>

              <li className="mt-2">
                {"Effacer sur votre navigateur le cache du site my.hdm.re"}
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <span>
              {
                "Si cela ne résout pas votre problème, merci d’envoyer un mail à « "
              }
            </span>
            <a href="mailto:numerique@hdm.re">{"numerique@hdm.re"}</a>
            <span>{" » en précisant :"}</span>

            <ul>
              <li className="mt-2">{"Le nom de votre société"}</li>

              <li className="mt-2">
                {"Le mail avec lequel vous tentez de vous connecter"}
              </li>

              <li className="mt-2">{"Le message d‘erreur"}</li>
            </ul>
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "48px" }}>
          <Button onClick={onClose}>{"Fermer"}</Button>
        </div>
      </div>
    </Modal>
  );
};

HelpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default HelpModal;
