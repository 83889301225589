class AuthManager {
  // eslint-disable-next-line no-warning-comments
  // TODO: implements the refresh token management

  static login(user) {
    const { expirationDate, jwt } = user;
    localStorage.setItem("accessToken", jwt);
    localStorage.setItem("expirationDate", expirationDate);
  }

  static isAuthenticated() {
    const expirationDate =
      Number.parseInt(localStorage.getItem("expirationDate"), 10) || 0;
    return !!localStorage.getItem("accessToken") && Date.now() > expirationDate;
  }

  static startCollabSession(params) {
    const { token, sessionId, powerslideToken } = params;
    localStorage.setItem("accessToken", token);
    localStorage.setItem("collabSession", sessionId);
    localStorage.setItem("powerslideToken", powerslideToken);
  }

  static isCollabSession() {
    const collabSession = localStorage.getItem("collabSession");
    return !!collabSession;
  }

  static stopCollabSession(jwt) {
    localStorage.removeItem("collabSession");
    localStorage.setItem("accessToken", jwt);
    localStorage.removeItem("powerslideToken");
  }

  static logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("collabSession");
    localStorage.removeItem("powerslideToken");
  }
}

export default AuthManager;
