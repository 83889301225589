import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";

import Edit from "@material-ui/icons/Edit";

import { isMobile } from "react-device-detect";
import { supportReducerType, ticketType } from "../../_helpers/appProptypes";
import { FETCH_SUPPORTS } from "../../actions/ticket-actions";

const theme = {
  root: {
    padding: "10px 0 0 0",
    fontSize: "0.8rem"
  },
  ticketStatus: {
    display: "inline-block"
  },
  closed: {
    color: "green"
  },
  opened: {
    color: "red"
  },
  inProgress: {
    color: "orange"
  },
  mission: {
    display: "flex",
    alignItems: isMobile ? "flex-start" : "center",
    flexDirection: isMobile ? "column" : "row",
    color: "gray",
    fontSize: "0.8rem",
    fontWeight: 400,
    lineHeight: 1.66,
    marginBottom: "10px"
  }
};

const statusString = {
  closed: "Clôturé",
  opened: "A traiter",
  inProgress: "En cours"
};

const TicketInfos = props => {
  const {
    ticket,
    role,
    updateTicket,
    fetchSupports,
    supportReducer,
    classes,
    collabName,
    missionChiefUserName
  } = props;

  const canUpdate = ["collab", "admin"].indexOf(role) !== -1;
  const [edit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(ticket.status);
  const [mission, setMission] = React.useState(ticket.missionId);
  const [category, setCategory] = React.useState(ticket.category);
  const [addressee, setAddressee] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [potentialAddressees, setPotentialAddressees] = React.useState([]);
  const [
    showUpdateCategoryButton,
    setShowUpdateCategoryButton
  ] = React.useState(false);

  const ref = useRef(null);

  // update ticket status if changed by another component
  if (status !== ticket.status) {
    setStatus(ticket.status);
  }

  useEffect(() => {
    async function fetchAddressees() {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/tickets/${ticket._id}/addressees`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      setPotentialAddressees(result.data.potentialAddressees);
    }
    if (!supportReducer.supports.length) {
      fetchSupports();
    } else {
      const idx = supportReducer.supports.findIndex(
        support => support._id === mission
      );
      setCategories(supportReducer.supports[idx].categories);
    }
    if (canUpdate) {
      fetchAddressees();
    }
    setStatus(ticket.status);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission]);

  const onUpdateStatus = value => {
    setStatus(value);
    if (value !== ticket.status)
      updateTicket(ticket._id, "updateStatus", value);
  };

  const onUpdateMission = value => {
    setMission(value);
    setCategory(null);
  };

  const onUpdateCategory = value => {
    setCategory(value);
    if (value !== ticket.category && mission) setShowUpdateCategoryButton(true);
  };

  const updateCategory = () => {
    updateTicket(ticket._id, "updateCategory", {
      missionId: mission,
      category
    });
    setShowUpdateCategoryButton(false);
  };

  const onUpdateAddressees = () => {
    updateTicket(ticket._id, "updateAddressee", addressee);
    setOpen(false);
  };

  return (
    <Paper className={classes.root} square elevation={0}>
      <div
        ref={ref}
        className="TicketInfos"
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          padding: "10px"
        }}
      >
        <div>
          <Typography variant="h6" component="div">
            <b>{ticket.subject}</b>
          </Typography>
          <div className={classes.mission}>
            <div>{"Catégorie : "}</div>
            {edit && canUpdate ? (
              <Select
                style={{ marginRight: "10px" }}
                value={mission}
                renderValue={value => `[${value.toUpperCase()}]`}
                onChange={event => onUpdateMission(event.target.value)}
              >
                <MenuItem value="comptable">{"COMPTABLE"}</MenuItem>
                <MenuItem value="social">{"SOCIAL"}</MenuItem>
                <MenuItem value="juridique">{"JURIDIQUE"}</MenuItem>
                <MenuItem value="autres">{"AUTRES"}</MenuItem>
              </Select>
            ) : (
              <div style={{ marginRight: "10px", textTransform: "uppercase" }}>
                [{mission}]
              </div>
            )}
            {edit && canUpdate ? (
              <Select
                style={{ marginRight: "10px" }}
                value={category}
                onChange={event => onUpdateCategory(event.target.value)}
              >
                {categories.map((c, cIndex) => (
                  <MenuItem value={c} key={cIndex}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <div style={{ marginRight: "10px" }}>{ticket.category}</div>
            )}
            {showUpdateCategoryButton && category && (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={updateCategory}
              >
                {"Mettre à jour"}
              </Button>
            )}
          </div>
        </div>
        <div style={{ fontSize: "0.8rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div>{"Statut : "}</div>
            {edit && canUpdate ? (
              <Select
                value={status}
                renderValue={value => (
                  <div className={`${classes.ticketStatus} ${classes[value]}`}>
                    <b>{statusString[value] || "NON DEFINI"}</b>
                  </div>
                )}
                onChange={event => onUpdateStatus(event.target.value)}
              >
                <MenuItem value="opened">{statusString.opened}</MenuItem>
                <MenuItem value="inProgress">
                  {statusString.inProgress}
                </MenuItem>
                <MenuItem value="closed">{statusString.closed}</MenuItem>
              </Select>
            ) : (
              <div className={`${classes.ticketStatus} ${classes[status]}`}>
                <b>{statusString[status] || "NON DEFINI"}</b>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div>{"Date de création : "}</div>
            <div>
              <b>{moment(ticket.creationDate).format("DD/MM/YYYY")}</b>
            </div>
          </div>
          {["collab", "admin"].indexOf(role) === -1 && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div>{"Votre interlocuteur : "}</div>
                <div>
                  <b>{collabName}</b>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div>{"Chef de mission : "}</div>
                <div>
                  <b>{missionChiefUserName}</b>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {edit && canUpdate ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            background: "#fafafa",
            borderTop: "solid 1px #eee",
            padding: "5px",
            boxShadow: "0px 15px 10px -15px #111"
          }}
        >
          <div>Destinataires:</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {ticket.addresseesNames.map((name, nameIndex) => (
              <div
                key={nameIndex}
                style={{
                  borderRadius: "5px",
                  margin: "5px",
                  padding: "5px 10px",
                  background: "#ddd"
                }}
              >
                {name}
              </div>
            ))}
          </div>
          <div>
            <Tooltip title="Modifier le collaborateur">
              <ButtonBase onClick={() => setOpen(true)}>
                <Edit />
              </ButtonBase>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>
                {"Sélectionner le nouveau collaborateur"}
              </DialogTitle>
              <DialogContent>
                <Select
                  value={addressee}
                  onChange={event => setAddressee(event.target.value)}
                >
                  {potentialAddressees.map((ad, adIndex) => (
                    <MenuItem
                      key={adIndex}
                      value={ad._id}
                    >{`${ad.firstname} ${ad.lastname}`}</MenuItem>
                  ))}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} className="cancel">
                  {"Annuler"}
                </Button>
                <Button onClick={onUpdateAddressees}>{"Valider"}</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      ) : null}
    </Paper>
  );
};

TicketInfos.propTypes = {
  ticket: ticketType.isRequired,
  role: PropTypes.string.isRequired,
  supportReducer: supportReducerType.isRequired,
  updateTicket: PropTypes.func.isRequired,
  fetchSupports: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    ticketStatus: PropTypes.shape({}),
    root: PropTypes.shape({}),
    mission: PropTypes.shape({})
  }).isRequired,
  collabName: PropTypes.string.isRequired,
  missionChiefUserName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    supportReducer: state.supportReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    updateTicket: (ticketId, actionType, value) => {
      dispatch({
        type: "UPDATE_TICKET",
        payload: { ticketId, action: { actionType, value } }
      });
    },
    fetchSupports: () => dispatch({ type: FETCH_SUPPORTS })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withStyles(theme)(TicketInfos));
