/* eslint-disable react/forbid-prop-types */

import React from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: isMobile ? "90%" : 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
}));

const GedRenameModal = ({
  isOpen,
  onClose,
  renameDocument,
  onRenameDocument,
  document
}) => {
  const classes = useStyles();
  const defaultName = (document || {}).name || "";
  const [newDocumentName, setNewDocumentName] = React.useState(defaultName);
  if (!document) return null;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="GedRenameModal"
      open={isOpen}
    >
      <div className={clsx(classes.paper, "GedRenameModalContent")}>
        <h2 id="simple-modal-title">{"Renommer"}</h2>
        <label htmlFor="renameDocumentField">
          {"Nom du document"}
          <TextField
            margin="dense"
            id="renameDocumentField"
            variant="outlined"
            fullWidth
            inputProps={{ "aria-label": "bare" }}
            value={newDocumentName}
            onChange={e => setNewDocumentName(e.target.value)}
          />
        </label>
        <div style={{ textAlign: "right", marginTop: "48px" }}>
          <Button
            style={{ marginRight: "6px" }}
            onClick={onClose}
            className="cancel"
          >
            {"Annuler"}
          </Button>
          <Button
            onClick={() => {
              if (onRenameDocument) {
                onRenameDocument(document, newDocumentName);
              } else {
                renameDocument(document, newDocumentName);
              }
              onClose();
            }}
          >
            {"Renommer"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

GedRenameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renameDocument: PropTypes.func.isRequired,
  onRenameDocument: PropTypes.func,
  document: PropTypes.object
};

GedRenameModal.defaultProps = {
  document: null
};

const dispatchStateToProps = dispatch => {
  return {
    renameDocument: (document, newName) =>
      dispatch({
        type: "RENAME_DOCUMENT",
        payload: { document, newName }
      })
  };
};

export default connect(null, dispatchStateToProps)(GedRenameModal);
