import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import {
  TOAST_ERROR_ACTION,
  TOAST_SUCCESS_ACTION
} from "../actions/toast-actions";
import { handleError } from "./sagaCommon";
import {
  FETCH_COLLAB_DOCS_ACTION,
  FETCH_DOCUMENTS,
  REMOVE_DOCUMENTS_SYNC,
  REMOVE_SELECTION
} from "../actions/document-actions";
import SpecialFolder from "../enums/special-folder.enum";

function* onMoveSelectionSync() {
  const { folderId } = yield select(state => state.moveDocumentReducer);
  const { allSelect, selectionList, excludeList } = yield select(
    state => state.multiSelectionReducer
  );
  const { selectedFolderId } = yield select(state => state.documentReducer);
  const { tenantId } = yield select(state => state.authReducer.user);
  const parentId = selectedFolderId || "private";
  const data = {
    parentId,
    allSelected: allSelect,
    tenantId
  };
  if (allSelect) {
    data.excludedDocumentIds = excludeList.map(doc => doc._id);
  } else {
    data.documentIds = selectionList.map(doc => doc._id);
  }
  try {
    yield call(ApiManager.callApi, {
      ressource: `/documents/move/${folderId}`,
      method: "POST",
      isAuthenticated: true,
      data
    });
    yield put({
      type: TOAST_SUCCESS_ACTION,
      payload: "Les documents ont été déplacés"
    });
    yield put({
      type: "RESET_SELECTION"
    });
    yield put({
      type: FETCH_DOCUMENTS,
      payload: { documentId: parentId }
    });
  } catch (error) {
    if (error.response.status === 403) {
      yield put({
        type: TOAST_ERROR_ACTION,
        payload: error.response.data.message
      });
    } else {
      yield handleError(error, "Erreur lors du déplacement des documents");
    }
  }
}

export function* onMoveSelection() {
  yield takeLatest("MOVE_SELECTION", onMoveSelectionSync);
}

function* onRemoveSelectionSync() {
  const { allSelect, selectionList, excludeList } = yield select(
    state => state.multiSelectionReducer
  );
  const { selectedFolderId } = yield select(state => state.documentReducer);
  const parentId = selectedFolderId || "private";
  const { tenantId } = yield select(state => state.authReducer.user);
  const data = {
    parentId,
    allSelected: allSelect,
    tenantId
  };
  if (allSelect) {
    data.excludedDocumentIds = excludeList.map(doc => doc._id);
  } else {
    data.documentIds = selectionList.map(doc => doc._id);
  }
  if (parentId !== SpecialFolder.PUBLIC_FOLDER) {
    yield put({
      type: REMOVE_DOCUMENTS_SYNC,
      payload: data
    });
  }
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/delete`,
      method: "POST",
      isAuthenticated: true,
      data
    });
    yield put({
      type: TOAST_SUCCESS_ACTION,
      payload: "Les documents ont été supprimés"
    });
    if (response.data.favorites) {
      yield put({
        type: "UPDATE_FAVORITES_SYNC",
        payload: response.data.favorites
      });
    }
  } catch (error) {
    yield handleError(error, "Erreur lors de la suppression de la sélection");
  }
}

export function* onRemoveSelection() {
  yield takeLatest(REMOVE_SELECTION, onRemoveSelectionSync);
}

function* onDownloadSelectionSync({ payload }) {
  const collab = payload || false;
  const { allSelect, selectionList, excludeList } = yield select(
    state => state.multiSelectionReducer
  );

  let data = { collab };
  if (!collab) {
    const { selectedFolderId } = yield select(state => state.documentReducer);
    data = {
      ...data,
      parentId: selectedFolderId || "private",
      allSelected: allSelect
    };
    if (allSelect) {
      data.excludedDocumentIds = excludeList.map(doc => doc._id);
    } else {
      data.documentIds = selectionList.map(doc => doc._id);
    }
  } else {
    data.documentIds = selectionList.map(doc => doc._id);
  }
  try {
    yield put({
      type: "RESET_SELECTION"
    });
    yield call(ApiManager.downloadFile, {
      ressource: `/documents/download`,
      method: "POST",
      isAuthenticated: true,
      data
    });
    yield put({
      type: "RESET_SELECTION"
    });
    yield put({
      type: "UPDATE_GED_NOTIFICATIONS",
      payload: { documentsToRemove: data.documentIds }
    });
  } catch (error) {
    /* yield put({
      type: "RENAME_DOCUMENT_SYNC",
      payload: { name: document.name, document },
    }); */
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de télécharger la sélection"
    });
  }
}

export function* onDownloadSelection() {
  yield takeLatest("DOWNLOAD_SELECTION", onDownloadSelectionSync);
}

function* onMarkSelectionAsReadSync() {
  try {
    const { allSelect, selectionList, excludeList } = yield select(
      state => state.multiSelectionReducer
    );
    const { selectedFolderId } = yield select(state => state.documentReducer);
    const data = {
      parentId: selectedFolderId,
      allSelected: allSelect
    };
    if (allSelect) {
      data.excludedDocumentIds = excludeList.map(doc => doc._id);
    } else {
      data.documentIds = selectionList.map(doc => doc._id);
    }

    yield put({
      type: "RESET_SELECTION"
    });
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/read`,
      method: "PUT",
      isAuthenticated: true,
      data
    });

    if (!response.data.gedNotifications) {
      yield put({
        type: TOAST_ERROR_ACTION,
        payload:
          "Impossible de marquer certains documents de la sélection comme lue"
      });
    }
    yield put({
      type: "UPDATE_GED_NOTIFICATIONS",
      payload: { gedNotifications: response.data.gedNotifications }
    });
    // update root folders (for notifications)
    yield put({
      type: "FETCH_ROOT_FOLDERS"
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de marquer la sélection comme lue"
    });
  }
}

export function* onMarkSelectionAsRead() {
  yield takeLatest("MARK_SELECTION_AS_READ", onMarkSelectionAsReadSync);
}

function* onProcessSelectionAsync() {
  try {
    const { selectionList } = yield select(
      state => state.multiSelectionReducer
    );
    const data = { documentIds: selectionList.map(doc => doc._id) };

    yield put({
      type: "RESET_SELECTION"
    });
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/process`,
      method: "PUT",
      isAuthenticated: true,
      data
    });

    if (response.data.updatedDocs !== data.documentIds.length) {
      yield put({
        type: TOAST_ERROR_ACTION,
        payload: "Impossible de traiter certains documents de la sélection"
      });
    }
    yield put({
      type: FETCH_COLLAB_DOCS_ACTION
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de traiter la sélection"
    });
  }
}

export function* onProcessSelection() {
  yield takeLatest("PROCESS_SELECTION", onProcessSelectionAsync);
}
