import React, { useEffect, useRef } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Layout from "./components/Layout/Layout";
import Login from "./components/Login/Login";
import LinkSent from "./components/Login/LinkSent";
import ResetPassword from "./components/Login/ResetPassword";
import ValidateAccount from "./components/Login/ValidateAccount";
import DownloadDocument from "./components/Login/DownloadDocument";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardDetail from "./components/Dashboard/DashboardDetail";
import Ged from "./components/Ged/Ged";
import Share from "./components/Share/Share";
import LinkDetails from "./components/Share/LinkDetails";
import Applications from "./components/Applications/Applications";
import Links from "./components/Links/Links";
import Settings from "./components/Settings/Settings";
import AuthenticatedRoute from "./components/AuthenticatedRoute/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute/UnauthenticatedRoute";
import Contact from "./components/Contact/Contact";
import ApplicationDetail from "./components/Applications/ApplicationDetail";
import DocumentPreview from "./components/Document/DocumentPreview";
import ForgotPassword from "./components/Login/ForgotPassword";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Portfolio from "./components/Collaborator/Portfolio";
import Documents from "./components/Collaborator/Documents";
import Assistance from "./components/Collaborator/Assistance";
import Stats from "./components/Stats/Stats";
import Admin from "./components/Admin/Admin";
import TicketsHistory from "./components/Tickets/TicketsHistory";
import TicketDetails from "./components/Tickets/TicketDetails";
import { authReducerType } from "./_helpers/appProptypes";
import { FETCH_DOCUMENTS } from "./actions/document-actions";
import PublicDocs from "./components/PublicDocs/PublicDocs";
import { isCollabOrAdmin, isAdmin, isCollab } from "./_helpers/utils";
import RoleEnum from "./enums/role.enum";
import { FETCH_TICKETS } from "./actions/ticket-actions";

const Routes = ({
  authReducer,
  location,
  updateEvents,
  updateTickets,
  updateDashboards,
  updateDocuments,
  updateRootFolders,
  history
}) => {
  let accessToDashboards = false;
  const { user } = authReducer;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  let { tenantId } = user || {};

  const activePath = ((location || {}).pathname || "/").split("/")[1];

  if (user) {
    if (!tenantId) {
      tenantId = user.tenants[0]._id;
    }
    const { applications } =
      (user.applications || []).find(app => app.tenantId === tenantId) || {};
    accessToDashboards =
      applications &&
      applications.filter(app => app._id === "bdo_tdb").length > 0;
  }
  const prevTenantId = usePrevious(tenantId);

  React.useEffect(() => {
    // redirect to dashboard homes on tenant switch
    if (
      prevTenantId !== undefined &&
      authReducer.user &&
      prevTenantId.tenantId !== authReducer.user.tenantId
    ) {
      if (activePath === "tableau-de-bord" && !accessToDashboards) {
        history.push("/");
      } else if (
        activePath === "ged" &&
        authReducer.user &&
        authReducer.user.missions.length === 0
      ) {
        history.push("/");
      } else {
        // redirect to module
        history.push(`/${activePath}`);
      }

      if (!isCollab(authReducer.user.role)) updateEvents();
      updateTickets();
      if (!isCollab(authReducer.user.role)) updateDashboards();
      updateDocuments();
      if (!isCollab(authReducer.user.role)) updateRootFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(user || {}).tenantId]);

  return (
    <Layout isAuthenticated={authReducer.isAuthenticated}>
      <Switch location={location}>
        <UnauthenticatedRoute exact path="/connexion" component={Login} />
        <UnauthenticatedRoute exact path="/linkSent" component={LinkSent} />
        <UnauthenticatedRoute
          exact
          path="/forgotPassword"
          component={ForgotPassword}
        />
        <UnauthenticatedRoute
          exact
          path="/passwordReset/:token"
          component={ResetPassword}
        />
        <UnauthenticatedRoute
          exact
          path="/validation/:token"
          component={ValidateAccount}
        />
        <Route
          exact
          path="/document/partage/:linkId"
          component={DownloadDocument}
        />

        {authReducer.user && (
          <>
            {isCollabOrAdmin(authReducer.user) && (
              <AuthenticatedRoute exact path="/stats" component={Stats} />
            )}

            {authReducer.user.role === RoleEnum.ADMIN && (
              <>
                <AuthenticatedRoute
                  exact
                  path="/public-docs"
                  component={PublicDocs}
                />

                <AuthenticatedRoute
                  exact
                  path="/admin/:tabId"
                  component={Admin}
                />
              </>
            )}

            {isAdmin(authReducer.user.role) && (
              <AuthenticatedRoute exact path="/admin" component={Admin} />
            )}

            {accessToDashboards &&
              (authReducer.isCollabSession ||
                !isCollab(authReducer.user.role)) && (
                <AuthenticatedRoute
                  exact
                  path="/tableau-de-bord/:dashboardId"
                  component={DashboardDetail}
                />
              )}

            {accessToDashboards &&
              (authReducer.isCollabSession ||
                !isCollab(authReducer.user.role)) && (
                <AuthenticatedRoute
                  exact
                  path="/tableau-de-bord"
                  component={Dashboard}
                />
              )}

            {!isAdmin(authReducer.user.role) &&
              authReducer.user.missions &&
              authReducer.user.missions.length > 0 && (
                <AuthenticatedRoute
                  exact
                  path="/ged/:folderId"
                  component={Ged}
                />
              )}

            {!isAdmin(authReducer.user.role) &&
              authReducer.user.missions &&
              authReducer.user.missions.length > 0 && (
                <AuthenticatedRoute exact path="/ged" component={Ged} />
              )}

            {[
              RoleEnum.TENANT_USER,
              RoleEnum.TENANT_ADMIN,
              RoleEnum.ADMIN
            ].includes(authReducer.user.role) && (
              <AuthenticatedRoute
                exact
                path="/partage/:linkId"
                component={LinkDetails}
              />
            )}

            {[
              RoleEnum.TENANT_USER,
              RoleEnum.TENANT_ADMIN,
              RoleEnum.ADMIN
            ].includes(authReducer.user.role) && (
              <AuthenticatedRoute exact path="/partage" component={Share} />
            )}

            <AuthenticatedRoute
              exact
              path="/applications/:appId"
              component={ApplicationDetail}
            />

            <AuthenticatedRoute
              exact
              path="/applications"
              component={Applications}
            />

            {(!isCollab(authReducer.user.role) ||
              authReducer.isCollabSession) && (
              <AuthenticatedRoute
                exact
                path="/liens-utiles"
                component={Links}
              />
            )}

            <AuthenticatedRoute
              exact
              path="/parametres"
              component={Settings}
              tenantId={authReducer.user.tenantId}
            />
            {/* {(!isCollab(authReducer.user.role) || authReducer.isCollabSession) && (
            )} */}

            {!isCollab(authReducer.user.role) && (
              <AuthenticatedRoute
                path="/assistance/contact/:supportId/:backurl"
                component={Contact}
              />
            )}

            {!isCollab(authReducer.user.role) && (
              <AuthenticatedRoute
                path="/assistance/contact/:supportId"
                component={Contact}
              />
            )}

            {(!isCollab(authReducer.user.role) ||
              authReducer.isCollabSession) && (
              <AuthenticatedRoute
                exact
                path="/document/:docId"
                component={DocumentPreview}
                tenantId={authReducer.user.tenantId}
              />
            )}

            {isCollab(authReducer.user.role) && (
              <>
                <AuthenticatedRoute
                  exact
                  path="/portefeuille"
                  component={Portfolio}
                />
                <AuthenticatedRoute
                  exact
                  path="/documents"
                  component={Documents}
                />
              </>
            )}

            <AuthenticatedRoute
              exact
              path="/assistance/:ticketId"
              component={TicketDetails}
            />

            {!authReducer.isCollabSession && (
              <AuthenticatedRoute
                exact
                path="/assistance"
                component={Assistance}
              />
            )}

            <AuthenticatedRoute
              exact
              path="/history"
              component={TicketsHistory}
            />

            <AuthenticatedRoute
              exact
              path="/"
              component={
                authReducer &&
                authReducer.user &&
                isCollab(authReducer.user.role) &&
                !authReducer.isCollabSession
                  ? Portfolio
                  : Home
              }
            />

            <Route path="/404" component={PageNotFound} />
          </>
        )}

        <AuthenticatedRoute
          // exact={false}
          path="/"
          component={
            authReducer &&
            authReducer.user &&
            isCollab(authReducer.user.role) &&
            !authReducer.isCollabSession
              ? Portfolio
              : Home
          }
        />

        {authReducer && !authReducer.isAuthenticated && (
          <Redirect to="/connexion" />
        )}

        {authReducer.user && <Redirect to="/404" />}
      </Switch>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    updateTenant: tenantId =>
      dispatch({ type: "UPDATE_CURRENT_TENANT", payload: tenantId }),
    updateEvents: () => dispatch({ type: "FETCH_EVENTS" }),
    updateTickets: () => dispatch({ type: FETCH_TICKETS }),
    updateDashboards: () => dispatch({ type: "FETCH_DASHBOARDS" }),
    updateDocuments: () => dispatch({ type: FETCH_DOCUMENTS }),
    updateRootFolders: () => dispatch({ type: "FETCH_ROOT_FOLDERS" })
  };
};

Routes.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    match: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  authReducer: authReducerType.isRequired,
  updateEvents: PropTypes.func.isRequired,
  updateTickets: PropTypes.func.isRequired,
  updateDashboards: PropTypes.func.isRequired,
  updateDocuments: PropTypes.func.isRequired,
  updateRootFolders: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, dispatchStateToProps)(Routes)
);
