import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Loader from "../Loader/Loader";
import DeleteModal from "./DeleteModal";

function User({
  loading,
  user,
  selectUser,
  deleteUser,
  index,
  missions: allMissions,
  currentUserEmail
}) {
  const { firstname, lastname, email, missions } = user;

  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);

  return (
    <div
      onKeyPress={() => selectUser(user)}
      role="button"
      tabIndex={index}
      onClick={() => selectUser(user)}
      className="settings__users__list-user"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "70px",
          width: "100px",
          alignItems: "center",
          justifyContent: "space-evenly"
        }}
      >
        <Avatar className="settings__users__list-user__avatar">
          <div className="uppercase">
            {loading ? (
              <Loader width="100%" height="10px" />
            ) : (
              `${(firstname || email).substring(0, 1)}  ${(
                lastname || ""
              ).substring(0, 1)}`
            )}
          </div>
        </Avatar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
          minHeight: "70px",
          justifyContent: "space-evenly"
        }}
      >
        <div className="settings__users__list-user__name">
          {loading ? (
            <Loader width="100%" height="10px" />
          ) : (
            `${firstname || email} ${lastname || ""}`
          )}
        </div>
        <div className="settings__users__list-user__missions">
          {loading
            ? ""
            : missions.map((m, index) => {
                if (m) {
                  const i = allMissions.findIndex(mi => mi._id === m);
                  if (i < 0) return null;
                  return (
                    <div
                      key={index}
                      className="missionChip"
                      style={{
                        color: allMissions[i].color,
                        border: `1px solid ${allMissions[i].color}`,
                        display: "inline-block",
                        margin: "3px"
                      }}
                    >
                      {allMissions[i].label}
                    </div>
                  );
                }

                return null;
              })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "20%",
          minHeight: "70px",
          justifyContent: "space-evenly"
        }}
      >
        <IconButton
          className="settings__users__list-user__delete"
          color="#586EE5"
          onClick={() => setDeleteModalOpen(true)}
          disabled={user.role === "tenantAdmin" || email === currentUserEmail}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      {isDeleteModalOpen ? (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          deleteFunction={() => deleteUser(user)}
        />
      ) : null}
    </div>
  );
}

User.defaultProps = {
  loading: false
};

User.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    missions: PropTypes.arrayOf(),
    applications: PropTypes.arrayOf(),
    role: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  selectUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  missions: PropTypes.arrayOf().isRequired,
  currentUserEmail: PropTypes.string.isRequired
};

function UsersList(props) {
  const {
    users,
    fetching,
    selectUser,
    clearUserSelection,
    deleteUser,
    missions,
    currentUserEmail
  } = props;
  return (
    <div className="settings__users__list">
      <div className="settings__users__list-head">
        <div className="settings__users__list-head-action">
          {"Ajouter un utilisateur"}
        </div>
        <Button
          onClick={clearUserSelection}
          title="Ajouter un utilisateur"
          color="primary"
          component="span"
        >
          <AddIcon />
        </Button>
      </div>
      <div className="settings__users__list-details">
        <div style={{ paddingTop: "60px" }}>
          {fetching &&
            [1, 2, 3, 4, 5].map(i => <User key={`k-${i}`} loading user={{}} />)}
          {!fetching &&
            users.map((user, i) => (
              <User
                key={user._id}
                selectUser={selectUser}
                deleteUser={deleteUser}
                user={user}
                index={i}
                missions={missions}
                currentUserEmail={currentUserEmail}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

UsersList.defaultProps = {
  users: []
};

UsersList.propTypes = {
  users: PropTypes.arrayOf(),
  selectUser: PropTypes.func.isRequired,
  clearUserSelection: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  missions: PropTypes.arrayOf().isRequired,
  currentUserEmail: PropTypes.string.isRequired
};

export default UsersList;
