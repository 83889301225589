export const PROCESS_DOCUMENT_ACTION = "PROCESS_DOCUMENT_ACTION";
export const FETCH_COLLAB_DOCS_ACTION = "FETCH_COLLAB_DOCS_ACTION";
export const FETCH_COLLAB_DOCS_SYNC_ACTION = "FETCH_COLLAB_DOCS_SYNC_ACTION";
export const SHARE_DOCUMENT_SYNC = "SHARE_DOCUMENT_SYNC";
export const FETCH_LINK_INFO_SYNC = "FETCH_LINK_INFO_SYNC";
export const DOWNLOAD_FILE_LINK_SYNC = "DOWNLOAD_FILE_LINK_SYNC";
export const FETCH_DOC_INFO_SYNC = "FETCH_DOC_INFO_SYNC";
export const RENAME_DOCUMENT_SYNC = "RENAME_DOCUMENT_SYNC";
export const ADD_DOCUMENT_SYNC = "ADD_DOCUMENT_SYNC";
export const REMOVE_DOCUMENT_SYNC = "REMOVE_DOCUMENT_SYNC";
export const FETCH_ROOT_FOLDERS_SYNC = "FETCH_ROOT_FOLDERS_SYNC";
export const FETCH_DOCUMENTS_SYNC = "FETCH_DOCUMENTS_SYNC";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const UPDATE_DOCUMENTS_FOLDER = "UPDATE_DOCUMENTS_FOLDER";
export const UPLOAD_IN_PROGRESS = "UPLOAD_IN_PROGRESS";
export const UPLOAD_COMPLETED = "UPLOAD_COMPLETED";
export const FETCH_NEW_PAGE = "FETCH_NEW_PAGE";
export const FILE_DOWNLOAD_END = "FILE_DOWNLOAD_END";
export const FILE_DOWNLOAD_START = "FILE_DOWNLOAD_START";
export const LOADING_FOLDER = "LOADING_FOLDER";
export const GET_NEW_DOCS = "GET_NEW_DOCS";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const REMOVE_DOCUMENTS_SYNC = "REMOVE_DOCUMENTS_SYNC";
export const CREATE_FOLDER = "CREATE_FOLDER";
export const CREATE_FOLDER_SYNC = "CREATE_FOLDER_SYNC";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const REMOVE_SELECTION = "REMOVE_SELECTION";
