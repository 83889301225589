/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import Sidebar from "../Sidebar/Sidebar";
import MobileHeader from "./MobileHeader";
import MobileFooter from "./MobileFooter";

const drawerWidth = isMobile ? 0 : 260;
const MainLayoutPadding = isMobile ? "70px 0" : "inherit";

const styles = theme => ({
  root: {
    display: "flex",
    background: "linear-gradient(to right, #F2F2F2, #E5E8F3)",
    height: "100%",
    minHeight: "100vh"
  },
  content: {
    flexGrow: 1,
    overflowX: "hidden"
  },
  contentShift: {
    // marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: "hidden",
    padding: "12px",
    paddingTop: "12px",
    flex: "1 1 auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto"
    }
  }
});

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marginLeft: drawerWidth,
      toggleDrawer: false
    };

    this.changeMarginWidth = this.changeMarginWidth.bind(this);
  }

  componentDidMount() {
    this.props.getUserInfo();
  }

  changeMarginWidth(width) {
    this.setState({ marginLeft: width });
  }

  render() {
    const { marginLeft } = this.state;
    const { classes, children } = this.props;
    return (
      <div className={clsx("Layout", classes.root)}>
        <Sidebar
          changeMarginWidth={this.changeMarginWidth}
          toggleDrawerExternal={this.state.toggleDrawer}
        />
        <section className={classes.contentShift}>
          {isMobile && (
            <MobileHeader
              onMenuClick={() =>
                this.setState(currentState => ({
                  toggleDrawer: !currentState.toggleDrawer
                }))
              }
            />
          )}
          <main
            style={{
              padding: MainLayoutPadding,
              width: isMobile ? "100%" : `calc(100vw - ${marginLeft + 32}px)`,
              height: isMobile ? "calc(100vh - 190px)" : "auto",
              marginLeft
            }}
          >
            {children}
          </main>
          {isMobile && (
            <MobileFooter
              onMenuClick={() =>
                this.setState(currentState => ({
                  toggleDrawer: !currentState.toggleDrawer
                }))
              }
            />
          )}
        </section>
      </div>
    );
  }
}

MainLayout.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    getUserInfo: () => dispatch({ type: "GET_USER_INFO" })
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(MainLayout));
