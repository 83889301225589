import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import MaterialTable from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { authReducerType, userReducerType } from "../../_helpers/appProptypes";

import Loader from "../Loader/Loader";

import "./Users.scss";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const localization = {
  body: {
    emptyDataSourceMessage: "Pas de lignes",
    filterRow: {
      filterTooltip: "Filtrer"
    }
  },
  toolbar: {
    exportTitle: "Exporter",
    exportAriaLabel: "Exporter",
    exportName: "Exporter en CSV",
    searchTooltip: "Recherche",
    searchPlaceholder: "Recherche"
  }
};

const Users = ({ authReducer, userReducer, fetchUsers, updateUser }) => {
  const { users } = userReducer;

  const usersColumns = [
    {
      title: "Email",
      field: "email",
      searchable: true,
      type: "string",
      editable: "never"
    },
    {
      title: "Dossiers",
      field: "tenantList",
      searchable: true,
      type: "string",
      cellStyle: {
        padding: "none",
        size: "small"
      },
      editable: "never",
      render: rowData => (
        <div
          title={`${rowData.tenantList.slice(0, 10).join(",")} ${
            rowData.tenantList.length > 10 ? ", ..." : ""
          }`}
        >
          {`${rowData.tenantList.slice(0, 6).join(",")} ${
            rowData.tenantList.length > 6 ? ", ..." : ""
          }`}
        </div>
      )
    },
    {
      title: "Rôle",
      field: "role",
      searchable: true,
      type: "string",
      editable: "never",
      lookup: {
        admin: "administrateur",
        collab: "collaborateur",
        tenantAdmin: "utilisateur principal",
        tenantUser: "utilisateur secondaire"
      }
    },
    {
      title: "Prénom",
      field: "firstname",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string",
      editable: "never"
    },
    {
      title: "Nom",
      field: "lastname",
      searchable: false,
      width: 20,
      headerStyle: { width: 20 },
      type: "string",
      editable: "never"
    },
    {
      title: "Statut",
      field: "status",
      searchable: true,
      width: 40,
      headerStyle: { width: 40 },
      type: "string",
      lookup: {
        active: "actif",
        suspended: "suspendu",
        pending: "attente d'activation",
        deleted: "supprimé"
      }
    },
    {
      title: "2FA",
      field: "auth2FAEnabled",
      searchable: false,
      width: 20,
      headerStyle: { width: 20 },
      type: "boolean"
    },
    {
      title: "Debug",
      field: "debugMode",
      searchable: false,
      width: 20,
      headerStyle: { width: 20 },
      type: "boolean"
    }
  ];

  const [searchTerm] = useState("");
  const [usersData, setUsersData] = useState(users);

  useEffect(() => {
    if (authReducer && authReducer.user && authReducer.user.role === "admin") {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducer.user]);

  useEffect(() => {
    setUsersData(users);
  }, [users]);

  return (
    <div style={{ padding: "2px", maxWidth: "100%" }} className="usersBody">
      {userReducer.isLoading && <Loader />}
      {!userReducer.isLoading && (
        <MaterialTable
          key={searchTerm}
          editable={{
            isEditable: true,
            isEditHidden: false
          }}
          icons={tableIcons}
          options={{
            exportButton: true,
            sorting: true,
            paging: true,
            pageSize: 20,
            pageSizeOptions: [20, 100],
            headerStyle: {
              backgroundColor: "#bbb",
              color: "#fff",
              fontWeight: "bold",
              position: "sticky",
              top: 0
            },
            maxBodyHeight: 450,
            debounceInterval: 400,
            searchText: searchTerm,
            rowStyle: rowData => {
              if (rowData.niveau === 2) {
                return { backgroundColor: "#dde" };
              }
              if (rowData.niveau === 3) {
                return { backgroundColor: "#aac" };
              }
              return {};
            }
          }}
          columns={usersColumns}
          data={usersData}
          title={
            <span className="tableTitle">
              {`Liste des utilisateurs (${usersData.length})`}
            </span>
          }
          localization={localization}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                if (columnDef.enum && !columnDef.enum.includes(newValue)) {
                  reject();
                }
                const { _id, status } = rowData;
                const userUpdate = {
                  _id,
                  status,
                  [columnDef.field]: newValue
                };
                updateUser({ ...userUpdate });
                resolve();
              });
            }
          }}
        />
      )}
    </div>
  );
};

Users.propTypes = {
  authReducer: authReducerType.isRequired,
  userReducer: userReducerType.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    userReducer: state.userReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchUsers: () => dispatch({ type: "FETCH_USERS" }),
    updateUser: user =>
      dispatch({
        type: "ASK_USER_UPDATE",
        payload: { user }
      })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(Users));
