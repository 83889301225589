import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";

import { documentReducerType, folderType } from "../../_helpers/appProptypes";

import "./GedFolderSelector.scss";
import { FETCH_DOCUMENTS } from "../../actions/document-actions";

const FolderSelector = ({
  folder,
  documentReducer,
  history,
  fetchDocuments
}) => {
  let selectedMainFolder;

  if (documentReducer && documentReducer.folder) {
    const { parents } = documentReducer.folder;
    if (parents) {
      selectedMainFolder = (parents[parents.length - 2] || {})._id;
    }
  }

  return (
    <Typography
      component="a"
      className={
        selectedMainFolder === folder._id
          ? "FolderSelector active "
          : "FolderSelector"
      }
      onClick={() => {
        fetchDocuments(folder._id);
        history.push(`/ged/${folder._id}`);
      }}
    >
      {folder.label}
      {folder.nbNewDocs > 0 && (
        <Badge color="primary" badgeContent={folder.nbNewDocs} />
      )}
    </Typography>
  );
};

FolderSelector.propTypes = {
  folder: folderType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  documentReducer: documentReducerType.isRequired,
  fetchDocuments: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchDocuments: folderId =>
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: { selectedFolderId: folderId }
      })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(FolderSelector));
