import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import PageTitle from "../PageTitle/PageTitle";
import HistoryListing from "./HistoryListing";
import TicketsToolbar from "../Collaborator/TicketsToolbar";

import "../Collaborator/Portfolio.scss";

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: isMobile ? "100%" : 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

class TicketsHistory extends Component {
  componentDidMount() {
    this.props.fetchHistory();
  }

  render() {
    const { classes, searchTerm } = this.props;

    return (
      <div className="Assistance">
        <PageTitle title="Historique">
          <Paper className={classes.root}>
            <IconButton aria-label="menu" className={classes.icon} disabled>
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Rechercher"
              inputProps={{ "aria-label": "Rechercher" }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  searchTerm(e.target.value);
                  e.target.value = "";
                }
              }}
            />
          </Paper>
        </PageTitle>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Paper>
              <div className="filesContainer">
                <TicketsToolbar historyRoute />
                <HistoryListing />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TicketsHistory.propTypes = {
  searchTerm: PropTypes.func.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.shape({}),
    icon: PropTypes.shape({}),
    input: PropTypes.shape({})
  }).isRequired
};

const mapStateToProps = () => ({});

const dispatchStateToProps = dispatch => {
  return {
    fetchHistory: () => dispatch({ type: "FETCH_TICKET_HISTORY" }),
    searchTerm: searchTerm =>
      dispatch({ type: "SEARCH_DOCUMENTS", payload: searchTerm })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withStyles(theme)(TicketsHistory));
