import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Version = ({ user }) => {
  const version = process.env.REACT_APP_VERSION;
  return (
    <div
      style={{
        position: "fixed",
        bottom: "0",
        right: "0",
        fontSize: "0.9em",
        color: user ? "black" : "white",
        backgroundColor: user ? "white" : "transparent",
        padding: "4px 8px"
      }}
    >
      <span>{`version : ${version}`}</span>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.authReducer.user
  };
};

Version.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(Version);
