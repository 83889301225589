import React from "react";

const DocumentsListingEmptyTable = () => {
  return (
    <div className="emptyTable opacity-60" style={{ paddingTop: "20px" }}>
      {"Pas de document"}
    </div>
  );
};

export default DocumentsListingEmptyTable;
