import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { handleError } from "./sagaCommon";

function* onFetchMoveFoldersSync({ payload }) {
  const { selectedFolderId } = yield select(state => state.documentReducer);
  const folderId = payload || selectedFolderId || "private";
  const { tenantId } = yield select(state => state.authReducer.user);

  try {
    const response = yield call(ApiManager.callApi, {
      ressource: `/folders/${folderId}?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_MOVE_FOLDERS_SYNC",
      payload: {
        folders: response.data.documents.filter(
          doc => doc.documentType === "folder"
        ),
        parents: response.data.parents,
        folderId
      }
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du chargement des dossiers");
  }
}

export function* onFetchMoveFolders() {
  yield takeLatest("FETCH_MOVE_FOLDERS", onFetchMoveFoldersSync);
}
