import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import { authReducerType } from "../../_helpers/appProptypes";

import "./Login.scss";
import HelpModal from "../modals/HelpModal";
import { useState } from "react";
import { USER_LOGIN } from "../../actions/login-actions";

const useStyles = makeStyles(() => ({
  paper: {
    textAlign: "center",
    padding: isMobile ? "15px" : "62px 97px",
    backgroundColor: "#E5E8F3"
  },
  loginBlock: {
    margin: "auto"
  }
}));

const Login = ({ tryLogin, authReducer, history }) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const classes = useStyles();
  const { isLoading, display2FA } = authReducer;
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  return (
    <div className="Login">
      <Grid container spacing={3}>
        <Grid item md={6} className={classes.loginBlock}>
          <Paper className={classes.paper}>
            {!isMobile && (
              <Typography component="h2">{"Mon espace privé"}</Typography>
            )}
            <form className={classes.container} noValidate autoComplete="off">
              <Typography component="label">{"Email"}</Typography>
              <TextField
                fullWidth
                id="outlined-name"
                placeholder="Votre email"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                type="email"
                value={login}
                onChange={e => setLogin(e.target.value)}
              />
              <Typography component="label">{"Mot de passe"}</Typography>
              <TextField
                fullWidth
                id="outlined-pass"
                placeholder="Votre mot de passe"
                type="password"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    tryLogin({ login, password });
                  }
                }}
              />

              {display2FA && (
                <>
                  <Typography component="label">{"Jeton 2FA :"}</Typography>
                  <TextField
                    fullWidth
                    id="outlined-pass"
                    placeholder="Votre jeton 2FA"
                    type="text"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={token}
                    onChange={e => setToken(e.target.value)}
                    onKeyDown={e => {
                      if (e.key !== "Enter") return;
                      tryLogin({ login, password, token });
                    }}
                  />
                </>
              )}
            </form>
            <div style={{ textAlign: "left" }}>
              <Typography
                component="a"
                onClick={() => history.push("/forgotPassword")}
              >
                {"Mot de passe oublié ?"}
              </Typography>
            </div>
            <div style={{ textAlign: "left" }}>
              <Typography component="a" onClick={() => setHelpModalOpen(true)}>
                {"Problème de connexion ?"}
              </Typography>
            </div>
            <Button
              fullWidth
              color="secondary"
              type="submit"
              onClick={e => {
                e.preventDefault();
                if (display2FA) {
                  tryLogin({ login, password, token });
                  return;
                }
                tryLogin({ login, password });
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : "Se connecter"}
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <HelpModal
        isOpen={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />
    </div>
  );
};

Login.propTypes = {
  tryLogin: PropTypes.func.isRequired,
  authReducer: authReducerType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    tryLogin: value => dispatch({ type: USER_LOGIN, payload: value })
  };
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
