/* eslint-disable react/forbid-prop-types */
import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "react-redux";

import "./LinkCategory.scss";

const LinkCategory = props => {
  const { category, linkReducer, openLink } = props;
  const categoryLinks = _.filter(linkReducer.links, { category });
  return (
    <Paper className="LinkCategory">
      <div className="titleContainer">
        <Typography component="h4">{category}</Typography>
      </div>
      <div>
        {categoryLinks.map(link => (
          <Typography
            className="LinkCategoryLink"
            component="div"
            key={link._id}
          >
            <a
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => openLink(link._id)}
            >
              {link.label}
            </a>
            {link.description ? <p>{link.description}</p> : null}
          </Typography>
        ))}
      </div>
    </Paper>
  );
};

LinkCategory.propTypes = {
  category: PropTypes.string.isRequired,
  linkReducer: PropTypes.object.isRequired,
  openLink: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    linkReducer: state.linkReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openLink: linkId => {
      dispatch({ type: "OPEN_HELP_LINK", payload: { linkId } });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkCategory);
