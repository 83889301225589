/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { isMobile } from "react-device-detect";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./Settings.scss";

import PageTitle from "../PageTitle/PageTitle";
import Loader from "../Loader/Loader";
import UsersSettings from "./UsersSettings";
import utils from "../../_helpers/utils";
import Auth2FA from "./Auth2FA";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPasswordDialog: false,
      selectedPanel: "applications",
      passwordRequestPending: false
    };

    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
    this.closePasswordDialog = this.closePasswordDialog.bind(this);
    this.setSelectedPanel = this.setSelectedPanel.bind(this);
  }

  componentDidMount() {
    this.props.fetchApplications();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.tenantId !== this.props.tenantId) {
      this.props.fetchApplications();
    }
    return true;
  }

  openChangePasswordModal() {
    this.setState({ openPasswordDialog: true });
  }

  async closePasswordDialog(event) {
    const { authReducer } = this.props;
    if (event === "sendLink") {
      this.props.requestResetPassword(authReducer.user.email);
      this.setState({
        openPasswordDialog: false,
        passwordRequestPending: true
      });
    }
    this.setState({ openPasswordDialog: false });
  }

  setSelectedPanel(panelTitle) {
    this.setState({ selectedPanel: panelTitle });
  }

  render() {
    const { authReducer, applicationReducer } = this.props;
    const { passwordRequestPending } = this.state;
    if (!authReducer.user || passwordRequestPending) return <Loader />;
    const { user } = authReducer;
    let { tenantId } = user;

    if (!tenantId) {
      tenantId = user.tenants[0]._id;
    }
    let userApplications = [];
    if (user.applications && user.applications.length > 0) {
      ({ applications: userApplications } =
        user.applications.find(app => app.tenantId === tenantId) || {});
    }

    const role = utils.getRole(authReducer.user.role);

    return (
      <div className="Settings">
        <PageTitle title="Paramètres" />
        {!authReducer.isCollabSession && (
          <div style={{ marginBottom: "24px" }}>
            <div
              style={{
                marginBottom: "24px",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "flex-start" : "space-between"
              }}
            >
              <div>
                <Typography component="div">
                  <Typography component="strong">
                    {`${authReducer.user.firstname} ${authReducer.user.lastname}`}
                  </Typography>
                  {` (${role})`}
                </Typography>
                <div>{authReducer.user.email}</div>
              </div>
              <div className="changePasswordContainer">
                <Button onClick={this.openChangePasswordModal}>
                  {"Changer le mot de passe"}
                </Button>
              </div>
            </div>
            <Dialog
              open={this.state.openPasswordDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Changer le mot de passe"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ marginBottom: "15px" }}>
                  <span className="text-warning">
                    {"Cette action va déconnecter votre session."}
                  </span>
                </DialogContentText>
                <DialogContentText>
                  {
                    "Cliquez sur le bouton pour recevoir un email de réinitialisation de mot de passe."
                  }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="secondary-button cancel"
                  onClick={() => this.closePasswordDialog("cancel")}
                  color="primary"
                >
                  {"Annuler"}
                </Button>
                <Button
                  className="primary-button"
                  onClick={() => this.closePasswordDialog("sendLink")}
                  color="primary"
                  autoFocus
                >
                  {"Envoyez-moi le lien"}
                </Button>
              </DialogActions>
            </Dialog>

            <Auth2FA />
          </div>
        )}

        {(["admin", "tenantAdmin"].includes(authReducer.user.role) ||
          authReducer.isCollabSession) && (
          <>
            {authReducer.isLoading ? (
              <CircularProgress />
            ) : (
              <Paper style={{ padding: "0", height: "73%" }}>
                <Tabs
                  value={this.state.selectedPanel}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, newValue) => this.setSelectedPanel(newValue)}
                >
                  <Tab value="applications" label="Applications" />
                  {!isMobile && (
                    <Tab value="users" label="Gestion des utilisateurs" />
                  )}
                </Tabs>
                <Typography
                  component="div"
                  role="tabpanel"
                  hidden={this.state.selectedPanel !== "applications"}
                  className="applicationsTable"
                >
                  <Table>
                    <TableBody>
                      {applicationReducer.applications.map(application => (
                        <TableRow
                          key={application._id}
                          style={{
                            display: isMobile ? "flex" : "",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: isMobile
                              ? "flex-start"
                              : "space-between"
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {application.name}
                          </TableCell>
                          <TableCell align="left">
                            {application.description}
                          </TableCell>
                          <TableCell
                            align="left"
                            title="contacter votre cabinet HDM pour gérer vos applications"
                          >
                            {userApplications &&
                            userApplications.findIndex(
                              a => a._id === application._id
                            ) !== -1 ? (
                              <div className="app-status enable">
                                {"Activée"}
                              </div>
                            ) : (
                              <div className="app-status disable">
                                {"Non activée"}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Typography>
                <Typography
                  component="div"
                  role="tabpanel"
                  hidden={this.state.selectedPanel !== "users"}
                  style={{ height: "89%" }}
                >
                  <UsersSettings
                    user={authReducer.user}
                    tenantId={authReducer.user.tenantId}
                  />
                </Typography>
              </Paper>
            )}
          </>
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  authReducer: PropTypes.object.isRequired,
  tenantId: PropTypes.string.isRequired,
  applicationReducer: PropTypes.object.isRequired,
  requestResetPassword: PropTypes.func.isRequired,
  fetchApplications: PropTypes.func.isRequired
};

const dispatchStateToProps = dispatch => {
  return {
    requestResetPassword: login =>
      dispatch({ type: "RESET_PASSWORD", payload: { login } }),
    fetchApplications: () => dispatch({ type: "FETCH_APPLICATIONS" })
  };
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    applicationReducer: state.applicationReducer
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(Settings);
