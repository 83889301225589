import { put, delay } from "redux-saga/effects";
import { USER_LOGOUT } from "../actions/login-actions";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";

export function* handleError(error, errorMessage) {
  // CURRENT TENANT CHECK
  if (
    error.response &&
    error.response.data &&
    error.response.data.code === "NOTCURRENTTENANT"
  ) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: {
        message:
          "Erreur : le dossier traité n'est pas votre dossier courant. Vous allez être redirigé vers votre dossier courant.",
        disableAutoClose: true
      }
    });
    yield delay(5000);
    // reload app with current tenant
    yield put({
      type: "GET_USER_INFO"
    });
  } else {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: errorMessage || error.message
    });
  }
  if (error.response.status === 403) {
    yield put({
      type: USER_LOGOUT
    });
  }
}

export { handleError as default };
