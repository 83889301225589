const initialState = {
  favorites: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FAVORITES_FETCH_SUCCESS": {
      const favorites = action.payload || [];

      return {
        ...state,
        favorites
      };
    }

    case "UPDATE_FAVORITES_SYNC": {
      const favorites = action.payload;
      return {
        ...state,
        favorites
      };
    }

    case "REMOVE_FROM_FAVORITES_SYNC": {
      const favorite = action.payload;
      return {
        ...state,
        favorites: state.favorites.filter(
          oldFavorite => favorite.docId !== oldFavorite.docId
        )
      };
    }

    default:
      return state;
  }
};

export default reducer;
