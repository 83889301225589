import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import { actionType, authReducerType } from "../../_helpers/appProptypes";

const theme = {
  updateRoot: {
    alignSelf: "center",
    padding: "10px 0px",
    fontSize: "x-small",
    color: "#a09a9a"
  },
  ticketStatus: {
    display: "inline-block"
  },
  closed: {
    color: "green"
  },
  opened: {
    color: "red"
  },
  inProgress: {
    color: "orange"
  }
};

const statusString = {
  closed: "Clôturé",
  opened: "A traiter",
  inProgress: "En cours"
};

const TicketAction = ({ action, companyName, authReducer, classes, role }) => {
  switch (action.actionType) {
    case "reply": {
      const selfWritten =
        action.author ===
        `${authReducer.user.firstname} ${authReducer.user.lastname}`;

      return (
        <div
          className="TicketAction"
          style={{
            width: "100%",
            display: "flex",
            alignItems: selfWritten ? "flex-end" : "flex-start",
            flexDirection: "column"
          }}
        >
          <div style={{ fontSize: "12px" }}>
            <b>{action.author}, </b>
            le {moment(action.date).format("DD/MM/YYYY HH:mm")}
            <br />
            <div style={{ color: "#999" }}>
              {!selfWritten && role === "collab" ? <b> {companyName}</b> : null}
            </div>
          </div>
          <div
            style={{
              borderRadius: "5px",
              margin: "8px 0",
              minWidth: "40%",
              maxWidth: "70%",
              backgroundColor: selfWritten ? "#f8f8f8" : "rgb(228, 232, 253)",
              padding: "10px",
              fontSize: "0.9rem"
            }}
          >
            {action.value}
          </div>
        </div>
      );
    }

    case "updateStatus": {
      return (
        <div className={`TicketAction ${classes.updateRoot}`}>
          {action.author} a passé le statut à &quot;
          <div className={`${classes.ticketStatus} ${classes[action.value]}`}>
            <b>{statusString[action.value] || "NON DEFINI"}</b>
          </div>
          &quot;, le {moment(action.date).format("DD/MM/YYYY HH:mm")}
        </div>
      );
    }

    case "updateAddressee": {
      return (
        <div className={`TicketAction ${classes.updateRoot}`}>
          {action.author} a mis à jour les destinataires de cette demande, le{" "}
          {moment(action.date).format("DD/MM/YYYY HH:mm")}
        </div>
      );
    }

    case "updateMission": {
      return (
        <div className={`TicketAction ${classes.updateRoot}`}>
          {action.author} a passé le domaine de cette demande à &quot;
          {action.value}
          &quot;, le {moment(action.date).format("DD/MM/YYYY HH:mm")}
        </div>
      );
    }

    case "updateCategory": {
      return (
        <div className={`TicketAction ${classes.updateRoot}`}>
          {action.author} a passé la catégorie de cette demande à &quot;
          {action.value}
          &quot;, le
          {moment(action.date).format("DD/MM/YYYY HH:mm")}
        </div>
      );
    }

    default:
      return <div className="TicketAction">Action non supportée</div>;
  }
};

TicketAction.defaultProps = {
  action: {},
  companyName: ""
};

TicketAction.propTypes = {
  authReducer: authReducerType.isRequired,
  action: actionType,
  role: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.shape({}),
    icon: PropTypes.shape({}),
    input: PropTypes.shape({}),
    updateRoot: PropTypes.shape({}),
    ticketStatus: PropTypes.shape({})
  }).isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps)(withStyles(theme)(TicketAction));
