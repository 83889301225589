import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

import { isMobile } from "react-device-detect";

import Loader from "../Loader/Loader";
import TicketLine from "./TicketLine";
import TicketLineMobile from "./TicketLineMobile";

import {
  authReducerType,
  supportReducerType
} from "../../_helpers/appProptypes";

import "./Ticket.scss";
import { isCollab, isCollabOrAdmin } from "../../_helpers/utils";

const TicketsListing = ({
  supportReducer,
  authReducer,
  markAllAsRead,
  myTickets = [],
  lateTickets = []
}) => {
  const { isLoading } = supportReducer;

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            align="center"
            style={{ color: "#134594", width: "5px", padding: "0px" }}
          />
          {!isMobile && isCollabOrAdmin(authReducer.user.role) && (
            <TableCell align="left" style={{ color: "#134594" }}>
              {"Société"}
            </TableCell>
          )}
          {!isMobile && (
            <TableCell align="center" style={{ color: "#134594" }}>
              {"Domaine"}
            </TableCell>
          )}
          {!isMobile && (
            <TableCell align="left" style={{ color: "#134594" }}>
              {"Catégorie"}
            </TableCell>
          )}
          <TableCell align="left" style={{ color: "#134594" }}>
            {isMobile ? "" : "Objet"}
          </TableCell>
          {!isMobile && (
            <TableCell align="center" style={{ color: "#134594" }}>
              {"Statut"}
            </TableCell>
          )}
          {!isMobile && (
            <TableCell align="center" style={{ color: "#134594" }}>
              {"Date de création"}
            </TableCell>
          )}
          {!isMobile && <TableCell width="3%" />}
        </TableRow>
      </TableHead>
    );
  };

  const renderLateTable = () => {
    if (!lateTickets.length) {
      return <div />;
    }

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#134594"
          }}
        >
          <Typography
            component="h2"
            style={{
              color: "#e6223f",
              fontSize: "24px",
              paddingTop: "24px"
            }}
          >
            {"Demandes en retard"}
          </Typography>

          {isCollabOrAdmin(authReducer.user.role) && (
            <ButtonBase
              className="markAllAsRead"
              onClick={() => markAllAsRead()}
              disabled={
                !authReducer.user.ticketNotifications ||
                !authReducer.user.ticketNotifications.length
              }
            >
              {"Tout marquer comme lu"}
            </ButtonBase>
          )}
        </div>
        <Table style={{ tableLayout: "fixed", width: "100%" }}>
          {renderTableHead()}
          <TableBody>
            {lateTickets.map(ticket =>
              isMobile ? (
                <TicketLineMobile
                  key={ticket._id}
                  ticket={ticket}
                  role={authReducer.user.role}
                />
              ) : (
                <TicketLine
                  key={ticket._id}
                  ticket={ticket}
                  role={authReducer.user.role}
                />
              )
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="TicketsListing" style={{ paddingTop: "18px" }}>
        {isCollab(authReducer.user.role) && (
          <div>
            <Typography
              component="h2"
              style={{ color: "#e6223f", fontSize: "18px", paddingTop: "18px" }}
            >
              {"Demandes en retard"}
            </Typography>
            <Table>{renderTableHead()}</Table>
            <Loader />
          </div>
        )}
        <div>
          <Typography
            component="h2"
            style={{ color: "#e6223f", fontSize: "18px", paddingTop: "18px" }}
          >
            {"Mes demandes"}
          </Typography>
          <Table>{renderTableHead()}</Table>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="TicketsListing">
      {isCollab(authReducer.user.role) && renderLateTable()}
      {!myTickets.length ? (
        <div>
          <Typography
            component="h2"
            style={{ color: "#e6223f", fontSize: "24px", paddingTop: "24px" }}
          >
            {"Mes demandes"}
          </Typography>
          <Table>{renderTableHead()}</Table>
          <div className="emptyTable" style={{ paddingTop: "15px" }}>
            {"Pas de ticket"}
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#134594"
            }}
          >
            <Typography
              component="h2"
              style={{ color: "#e6223f", fontSize: "24px", paddingTop: "24px" }}
            >
              {"Mes demandes"}
            </Typography>

            {!isMobile && !isCollabOrAdmin(authReducer.user.role) && (
              <ButtonBase
                className="markAllAsRead"
                onClick={() => markAllAsRead()}
                disabled={
                  !authReducer.user.ticketNotifications ||
                  !authReducer.user.ticketNotifications.length
                }
              >
                {"Tout marquer comme lu"}
              </ButtonBase>
            )}
          </div>
          <Table style={{ tableLayout: "fixed", width: "100%" }}>
            {renderTableHead()}
            <TableBody>
              {myTickets
                .sort((a, b) => moment(a.creationDate).diff(b.creationDate))
                .map(ticket =>
                  isMobile ? (
                    <TicketLineMobile
                      key={ticket._id}
                      ticket={ticket}
                      role={authReducer.user.role}
                    />
                  ) : (
                    <TicketLine
                      key={ticket._id}
                      ticket={ticket}
                      role={authReducer.user.role}
                    />
                  )
                )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

TicketsListing.propTypes = {
  supportReducer: supportReducerType.isRequired,
  authReducer: authReducerType.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  myTickets: PropTypes.arrayOf(Object).isRequired,
  lateTickets: PropTypes.arrayOf(Object).isRequired
};

const mapStateToProps = state => {
  return {
    supportReducer: state.supportReducer,
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markAllAsRead: () =>
      dispatch({
        type: "MARK_ALL_TICKETS_AS_READ"
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsListing);
