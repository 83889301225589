import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Save from "@material-ui/icons/Save";
import Share from "@material-ui/icons/Share";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteForever from "@material-ui/icons/DeleteForeverOutlined";

import Loader from "../Loader/Loader";

import { documentReducerType } from "../../_helpers/appProptypes";
import utils from "../../_helpers/utils";
import ApiManager from "../../lib/apiManager";
import LinkDeleteModal from "../Share/LinkDeleteModal";

import "./DocumentPreview.scss";
import { DOWNLOAD_FILE } from "../../actions/document-actions";
import SpecialFolder from "../../enums/special-folder.enum";

const officeExtensions = [
  "txt",
  "rtf",
  "fodt",
  "doc",
  "docx",
  "odt",
  "xls",
  "xlsx",
  "ods",
  "ppt",
  "pptx",
  "odp"
];

const PREVIEW_TYPES = {
  IMAGE: "IMAGE",
  PDF: "PDF"
};

const getDocumentExtraInfos = document => {
  if (!document)
    return {
      isCegidGed: null,
      isSizeAboveThreshold1: null,
      isSizeAboveThreshold2: null,
      isDownloadable: null
    };
  const sizeThreshold1 = process.env.REACT_APP_CEGID_GED_SIZE_THRESHOLD_1 || 1;
  const sizeThreshold2 = process.env.REACT_APP_CEGID_GED_SIZE_THRESHOLD_2 || 10;

  const isCegidGed = document.owner === "cegid cegid";
  const isSizeAboveThreshold1 = document.size >= sizeThreshold1 * 1024;
  const isSizeAboveThreshold2 = document.size >= sizeThreshold2 * 1024;
  const isDownloadable = !isCegidGed || (isCegidGed && !isSizeAboveThreshold2);

  return {
    isCegidGed,
    isSizeAboveThreshold1,
    isSizeAboveThreshold2,
    isDownloadable
  };
};

class DocumentPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      createOrUpdateLink: false,
      password: null,
      passwordChanged: false,
      usePassword: false,
      maxDownloads: "",
      errorText: "",
      previewData: null,
      isPreviewLoading: false
    };
    // this.fetchImage = this.fetchImage.bind(this);
    this.passwordInput = React.createRef();
    this.fetchPreview = this.fetchPreview.bind(this);
  }

  componentDidMount() {
    const { docId } = this.props.match.params;
    this.props.fetchDocument(docId);
  }

  componentDidUpdate(prevProps) {
    const { document, isLoading } = this.props.documentReducer;
    const prevDoc = prevProps.documentReducer.document;
    const { isDownloadable } = getDocumentExtraInfos(document);

    if (
      !isLoading &&
      !this.state.isPreviewLoading &&
      !this.state.previewData &&
      document &&
      isDownloadable
    )
      this.fetchPreview();

    if (
      document &&
      document.downloadLink &&
      (!prevDoc || document._id !== prevDoc._id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        usePassword: !!document.downloadLink.password,
        maxDownloads: document.downloadLink.maxDownloads
      });
    } else if (prevDoc && prevDoc.downloadLink && !document.downloadLink) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        usePassword: false,
        password: "",
        maxDownloads: ""
      });
    }
  }

  getPreviewType = document => {
    if (document) {
      const fileExtension = (
        document.extension || (document.name || "").split(".").pop()
      ).toLowerCase();

      if (
        document.mimeType &&
        document.mimeType.split("/")[0].toLowerCase() === "image"
      )
        return PREVIEW_TYPES.IMAGE;
      if (
        fileExtension === "pdf" ||
        officeExtensions.includes(fileExtension.toLowerCase())
      )
        return PREVIEW_TYPES.PDF;
    }
    return null;
  };

  async fetchPreview() {
    const { docId } = this.props.match.params;
    const { document } = this.props.documentReducer;
    const previewType = this.getPreviewType(document);
    const { tenantId } = this.props;

    if (previewType) {
      this.setState({ isPreviewLoading: true });
      const commonCallParams = {
        method: "GET",
        isAuthenticated: true,
        blob: true
      };

      let response;
      let previewData = null;

      switch (previewType) {
        case PREVIEW_TYPES.IMAGE: {
          try {
            response = await ApiManager.callApi({
              ...commonCallParams,
              ressource: `/documents/${docId}/download?tenantId=${tenantId}`
            });
            previewData = {
              type: PREVIEW_TYPES.IMAGE,
              src: URL.createObjectURL(response.data)
            };
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error("FETCH PREVIEW error", error);
          }
          break;
        }
        case PREVIEW_TYPES.PDF: {
          try {
            response = await ApiManager.callApi({
              ...commonCallParams,
              ressource: `/documents/${docId}/pdfPreview`
            });
            const pdfFile = new Blob([response.data], {
              type: "application/pdf"
            });
            previewData = {
              type: PREVIEW_TYPES.PDF,
              src: URL.createObjectURL(pdfFile)
            };
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error("PREVIEW error", error);
          }
          break;
        }
        default:
          break;
      }
      if (previewData) {
        this.setState({
          previewData,
          isPreviewLoading: false
        });
      }
    }
  }

  render() {
    const {
      documentReducer,
      history,
      downloadFile,
      shareDocument,
      updateLink,
      deleteLink,
      fetchDocument,
      isCollabSession
    } = this.props;
    const { document } = documentReducer;
    const {
      open,
      createOrUpdateLink,
      passwordChanged,
      usePassword,
      maxDownloads
    } = this.state;

    let { password } = this.state;
    const { downloadLink } = document || {};

    // on first display, display x chars in password field
    if (downloadLink && downloadLink.passwordLength && password === null) {
      password = Array(downloadLink.passwordLength + 1).join("x");
    }

    const { isPreviewLoading, previewData } = this.state;

    if (documentReducer.isLoading || !document) return <Loader />;

    // document is in CEGID GED
    const {
      isCegidGed,
      isSizeAboveThreshold1,
      isSizeAboveThreshold2,
      isDownloadable
    } = getDocumentExtraInfos(document);

    return (
      <div
        className="document__preview"
        style={{
          background: "white",
          padding: "5px 15px 30px 15px"
        }}
      >
        <div
          className="flex"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "100px"
          }}
        >
          <h1 style={{ color: "#134594" }}>{"Informations du document"}</h1>
        </div>

        <ButtonBase onClick={() => history.goBack()}>
          <ArrowLeft />
          {"Revenir au dossier"}
        </ButtonBase>

        <Grid
          container
          direction="row"
          spacing={5}
          style={{ flexGrow: 1, margin: "0px", marginLeft: "-20px" }}
        >
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={10}>
            <TextField
              id="name"
              name="name"
              label="Nom"
              fullWidth
              value={document.name}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={5}>
            <TextField
              id="owner"
              name="owner"
              label="Auteur"
              fullWidth
              value={document.owner}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="size"
              name="size"
              label="Taille du fichier"
              fullWidth
              value={utils.readableBytes(document.size)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={5}>
            <TextField
              id="creationDate"
              name="creationDate"
              label="Date de création"
              fullWidth
              value={moment(document.creationDate).format("DD/MM/YYYY HH:mm")}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="lastUpdate"
              name="lastUpdate"
              label="Dernière modification"
              fullWidth
              value={moment(document.lastUpdate).format("DD/MM/YYYY HH:mm")}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1} />

          {document.parentId !== SpecialFolder.PUBLIC_FOLDER && (
            <>
              {!isCollabSession && isDownloadable && (
                <>
                  <Grid item xs={12} sm={1} />
                  <Grid
                    item
                    xs={12}
                    sm={11}
                    style={{ color: "#134594", textAlign: "left" }}
                  >
                    <h3>{"Partage de document"}</h3>
                  </Grid>
                  {downloadLink && (
                    <>
                      <Grid item xs={12} sm={1} />
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        className={downloadLink ? "" : "greyed"}
                      >
                        <TextField
                          id="link"
                          name="link"
                          label="Lien de téléchargement"
                          fullWidth
                          value={
                            downloadLink
                              ? `${process.env.REACT_APP_PUBLIC_URL}/document/partage/${downloadLink._id}`
                              : "-"
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} />
                    </>
                  )}
                  {downloadLink && (
                    <>
                      <Grid item xs={12} sm={1} />
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={downloadLink ? "" : "greyed"}
                      >
                        <TextField
                          id="maxDownloadsField"
                          label="Limiter les téléchargements à (0 pour illimité)"
                          disabled={false}
                          type="number"
                          fullWidth
                          value={maxDownloads || 0}
                          onChange={e =>
                            this.setState({
                              maxDownloads: e.target.value,
                              createOrUpdateLink: true
                            })
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className={downloadLink ? "" : "greyed"}
                      >
                        <TextField
                          id="downloadsCount"
                          name="downloadsCount"
                          label="Téléchargements réalisés"
                          fullWidth
                          defaultValue={0}
                          value={
                            downloadLink ? downloadLink.downloadsCount : "-"
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} />
                    </>
                  )}
                  {downloadLink && (
                    <>
                      <Grid item xs={12} sm={1} />
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        className={downloadLink ? "" : "greyed"}
                      >
                        <FormControlLabel
                          label={usePassword ? "Protégé" : "Non-protégé"}
                          labelPlacement="end"
                          className="passwordProtection"
                          control={
                            <Switch
                              checked={usePassword}
                              onChange={() => {
                                const newState = {
                                  usePassword: !usePassword,
                                  createOrUpdateLink: true,
                                  errorText: ""
                                };
                                if (passwordChanged) {
                                  newState.password = "";
                                }
                                this.setState(newState);
                                /* if (usePassword) {
                          this.passwordInput.current.focus();
                        } */
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className={downloadLink ? "" : "greyed"}
                      >
                        <TextField
                          id="passwordField"
                          ref={this.passwordInput}
                          label={
                            usePassword
                              ? "Mot de passe"
                              : "(pas de mot de passe)"
                          }
                          style={{ paddingBottom: "5px" }}
                          fullWidth
                          type="password"
                          value={usePassword ? password : ""}
                          disabled={!usePassword}
                          error={!!this.state.errorText}
                          helperText={this.state.errorText}
                          onFocus={e => {
                            if (!passwordChanged) {
                              this.setState({
                                password: ""
                              });
                            }
                            e.stopPropagation();
                          }}
                          onClick={e => {
                            if (!passwordChanged) {
                              this.setState({
                                password: ""
                              });
                            }
                            e.stopPropagation();
                          }}
                          onChange={e =>
                            this.setState({
                              password: e.target.value,
                              createOrUpdateLink: true,
                              passwordChanged: true,
                              errorText: ""
                            })
                          }
                        />
                      </Grid>
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={downloadLink ? "" : "greyed"}
                        >
                          <TextField
                            id="linkCreationDate"
                            name="linkCreationDate"
                            label="Date de création du lien"
                            fullWidth
                            value={
                              downloadLink
                                ? moment(downloadLink.creationDate).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "-"
                            }
                            disabled
                          />
                        </Grid>
                      </>
                      <Grid item xs={12} sm={1} />
                    </>
                  )}
                  <>
                    <Grid item xs={12} sm={1} />
                    <Grid item xs={12} sm={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={!createOrUpdateLink && downloadLink}
                        startIcon={downloadLink ? <Save /> : <Share />}
                        onClick={() => {
                          if (!downloadLink) {
                            shareDocument(document._id, password, maxDownloads);
                            this.setState({ createOrUpdateLink: false });
                          } else if (
                            usePassword &&
                            (!password || password.length === 0)
                          ) {
                            this.setState({
                              errorText:
                                "Le mot de passe doit contenir au moins 1 caractère."
                            });
                          } else {
                            updateLink(
                              downloadLink._id,
                              password,
                              usePassword,
                              maxDownloads,
                              passwordChanged
                            );
                            this.setState({ createOrUpdateLink: false });
                          }
                        }}
                        style={{
                          textTransform: "none",
                          backgroundColor:
                            createOrUpdateLink || !downloadLink
                              ? "#134594"
                              : "#cccccc",
                          color: "white"
                        }}
                      >
                        {document && document.downloadLink
                          ? "Mettre à jour le lien"
                          : "Partager"}
                      </Button>
                    </Grid>
                    {downloadLink && (
                      <Grid item xs={12} sm={3}>
                        <Tooltip title="Supprimer le lien de partage">
                          <Button
                            fullWidth
                            variant="contained"
                            disabled={!downloadLink}
                            startIcon={<DeleteForever />}
                            onClick={() => {
                              this.setState({ open: true });
                            }}
                            style={{
                              textTransform: "none"
                              // backgroundColor: createOrUpdateLink ? "#134594" : "#cccccc",
                              // color: "white",
                            }}
                          >
                            Supprimer le lien
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={5} />
                  </>
                  <Grid item xs={12} sm={12} />
                </>
              )}
              <Grid item xs={12} sm={1} />
              {!isDownloadable && (
                <Grid item xs={12} sm={10}>
                  <Typography
                    align="center"
                    style={{ paddingTop: "30px", paddingBottom: "30px" }}
                  >
                    La prévisualisation et le téléchargement du fichier sont
                    impossibles car le fichier est trop voluminueux. <br />{" "}
                    Veuillez-vous rapprocher de votre gestionnaire.
                  </Typography>
                </Grid>
              )}
              {isDownloadable && isPreviewLoading && (
                <Grid item xs={12} sm={10}>
                  <Loader />
                  <Typography align="center">
                    Chargement de la prévisualisation
                    {isCegidGed &&
                      isSizeAboveThreshold1 &&
                      !isSizeAboveThreshold2 && (
                        <div>
                          La prévisualisation et le téléchargement pourront
                          prendre jusqu&apos;à 60 secondes.
                        </div>
                      )}
                  </Typography>
                </Grid>
              )}
              {previewData && previewData.type === PREVIEW_TYPES.IMAGE && (
                <Grid item xs={12} sm={10}>
                  <img src={previewData.src} alt="" />
                </Grid>
              )}
              {previewData && previewData.type === PREVIEW_TYPES.PDF && (
                <Grid item xs={12} sm={10}>
                  <iframe
                    title="pdfPreview"
                    src={previewData.src}
                    width="100%"
                    height="700px"
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} sm={1} />
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            {isDownloadable && (
              <Button
                variant="contained"
                onClick={() => {
                  downloadFile(document);
                }}
                style={{
                  textTransform: "none",
                  backgroundColor: "#134594",
                  color: "white"
                }}
              >
                {"Télécharger"}
              </Button>
            )}
          </Grid>
        </Grid>

        <LinkDeleteModal
          isOpen={open}
          deleteLink={() => {
            deleteLink(downloadLink._id);
            fetchDocument(document._id);
          }}
          onClose={() => this.setState({ open: false })}
        />
        <div
          id="back"
          style={{
            position: "relative",
            bottom: "0px",
            color: "rgb(30, 42, 126)",
            zIndex: "200",
            paddingRight: "5px",
            maxWidth: "200px"
          }}
        >
          <ButtonBase onClick={() => history.goBack()}>
            <ArrowLeft />
            {"Revenir au dossier"}
          </ButtonBase>
        </div>
      </div>
    );
  }
}

DocumentPreview.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func })
    .isRequired,
  documentReducer: documentReducerType.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ docId: PropTypes.string })
  }).isRequired,
  fetchDocument: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  updateLink: PropTypes.func.isRequired,
  deleteLink: PropTypes.func.isRequired,
  shareDocument: PropTypes.func.isRequired,
  isCollabSession: PropTypes.bool.isRequired,
  tenantId: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer,
    isCollabSession: state.authReducer.isCollabSession
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDocument: docId =>
      dispatch({ type: "FETCH_DOC_INFO", payload: docId }),
    shareDocument: (docId, password, maxDownloads) => {
      dispatch({
        type: "SHARE_DOCUMENT",
        payload: {
          docId,
          password,
          maxDownloads
        }
      });
    },
    updateLink: (
      linkId,
      password,
      enablePassword,
      maxDownloads,
      passwordChanged
    ) => {
      dispatch({
        type: "UPDATE_DOWNLOAD_LINK",
        payload: {
          linkId,
          password,
          enablePassword,
          maxDownloads,
          passwordChanged
        }
      });
    },
    downloadFile: doc => {
      dispatch({ type: DOWNLOAD_FILE, payload: doc });
    },
    deleteLink: linkId =>
      dispatch({ type: "DELETE_DOWNLOAD_LINK", payload: linkId })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocumentPreview));
