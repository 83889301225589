import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import "./SidebarClientInfo.scss";
import { connect } from "react-redux";

import { authReducerType } from "../../_helpers/appProptypes";

import Loader from "../Loader/Loader";
import { isCollab } from "../../_helpers/utils";

const ArrowDropDownIcon = withStyles(() => ({
  root: {
    color: "white"
  }
}))(ArrowDropDown);

const impositionCodeLabel = {
  IS: "Impôt sur les sociétés",
  IR: "Impôt sur le revenu",
  TF: "TF",
  NI: "MI"
};

const VATImpositionRegimeLabel = {
  RN: "Régime normal",
  RS: "Régime simplifié",
  MIC: "Mic"
};

const SidebarClientInfo = props => {
  const { authReducer, isDrawerOpen, updateTenant, history } = props;
  const { user = {}, isCollabSession } = authReducer;
  const [tenant, setTenant] = React.useState(
    user.tenantId || user.tenants[0]._id
  );

  React.useEffect(() => {
    setTenant(user.tenantId);
  }, [user]);

  if (!user || !user.tenants) {
    return <Loader />;
  }

  let tenantIndex = 0;
  if (authReducer && user.role !== "collab") {
    tenantIndex = user.tenants.findIndex(t => t._id === tenant);
  }

  const {
    companyName,
    address1,
    address2,
    address3,
    zipcode,
    city,
    VATImpositionRegime,
    impositionCode,
    siren,
    siretKey,
    noDossier
  } = user.tenants[tenantIndex];

  const Address1Component = React.forwardRef((propsRef, ref) => (
    <div
      {...propsRef}
      ref={ref}
      className="value"
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {address1}
    </div>
  ));

  const onUpdateTenant = value => {
    setTenant(value);
    updateTenant(value);
  };

  const renderCompanyName = () => {
    if (user.tenants.length > 1) {
      return (
        <Select
          variant="outlined"
          value={tenant}
          style={{ color: "white" }}
          onChange={event => onUpdateTenant(event.target.value)}
          IconComponent={ArrowDropDownIcon}
        >
          {user.tenants.map(t => (
            <MenuItem key={t._id} value={t._id} title={t.companyName}>
              {t.companyName.length > 16
                ? `${t.companyName.substring(0, 13)}...`
                : t.companyName}
            </MenuItem>
          ))}
        </Select>
      );
    }

    return companyName.length > 20
      ? `${companyName.substring(0, 17)}...`
      : companyName;
  };

  return (
    <div className="SidebarClientInfo">
      <div className="identity">
        <Avatar>
          <div
            className={!isCollab(authReducer.user.role) ? "initials" : ""}
            title={`${user.firstname} ${user.lastname} (${user.email})`}
            role="button"
            onClick={() => {
              if (!isCollab(authReducer.user.role)) {
                history.push("/parametres");
              }
            }}
            onKeyPress={() => {
              if (!isCollab(authReducer.user.role)) {
                history.push("/parametres");
              }
            }}
            tabIndex={0}
          >
            {(user.firstname || user.email).substring(0, 1)}
            {(user.lastname || "").substring(0, 1)}
          </div>
        </Avatar>
        {isDrawerOpen && !isCollab(authReducer.user.role) ? (
          <span>{renderCompanyName()}</span>
        ) : (
          ""
        )}
        {isDrawerOpen && authReducer.user.role === "collab" && (
          <span>
            {`${user.firstname} ${user.lastname}`}
            <br />
            {`${user.email}`}
            <br />
            {isCollabSession ? companyName : ""}
          </span>
        )}
      </div>

      {isDrawerOpen && (!isCollab(authReducer.user.role) || isCollabSession) ? (
        <div className="infoContainer">
          <span className="label">Adresse</span>
          <div style={{ width: "125px" }}>
            <Tooltip title={address1}>
              <Address1Component />
            </Tooltip>
            <div className="value">{address2}</div>
            <div className="value">{address3}</div>
            <div className="value">
              {/* eslint-disable-next-line */}
              {zipcode} {city}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {isDrawerOpen && (!isCollab(authReducer.user.role) || isCollabSession) ? (
        <div className="infoContainer">
          <span className="label">SIRET</span>
          <span className="value">{`${siren} ${siretKey}`}</span>
        </div>
      ) : (
        ""
      )}

      {isDrawerOpen && (!isCollab(authReducer.user.role) || isCollabSession) ? (
        <div className="infoContainer">
          <span className="label">Régime TVA</span>
          <span className="value">
            {VATImpositionRegime}
            {" - "}
            {VATImpositionRegimeLabel[
              (VATImpositionRegime || "NOVATIMPOSITIONREGIME")
                .toUpperCase()
                .trim()
            ] || "Autres"}
          </span>
        </div>
      ) : (
        ""
      )}

      {isDrawerOpen && (!isCollab(authReducer.user.role) || isCollabSession) ? (
        <div className="infoContainer">
          <span className="label">Régime fiscal</span>
          <span className="value">
            {impositionCode}
            {" - "}
            {impositionCodeLabel[
              (impositionCode || "NOVATIMPOSITIONCODE").toUpperCase().trim()
            ] || "Autre"}
          </span>
        </div>
      ) : (
        ""
      )}
      {isDrawerOpen && (!isCollab(authReducer.user.role) || isCollabSession) ? (
        <div className="infoContainer">
          <span className="label">N° client</span>
          <span className="value">{noDossier}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    updateTenant: tenantId =>
      dispatch({ type: "UPDATE_CURRENT_TENANT", payload: tenantId })
  };
};

SidebarClientInfo.propTypes = {
  authReducer: authReducerType.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  updateTenant: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(SidebarClientInfo));
