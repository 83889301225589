import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

import "./Login.scss";
import { isMobile } from "react-device-detect";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      requestPending: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendForm = this.handleSendForm.bind(this);
  }

  handleInputChange(target, value) {
    this.setState({ [target]: value }, () => {
      this.validateField(target, value);
    });
  }

  validateField(fieldName, value) {
    let { usernameError } = this.state;
    let emailValid = false;
    switch (fieldName) {
      case "username":
        emailValid =
          value.length > 0 &&
          value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        usernameError = emailValid ? "" : "Email invalide";
        break;
      default:
        break;
    }
    this.setState({
      usernameError
    });
  }

  handleSendForm() {
    this.validateField("username", this.state.username);
    if (this.state.username.length > 0) {
      this.setState({ requestPending: true });
      this.props.forgotPassword(this.state.username);
    }
  }

  render() {
    const { requestPending } = this.state;
    const buttonForm = (
      <Button
        fullWidth
        onClick={this.handleSendForm}
        disabled={
          this.state.usernameError ||
          this.state.username.length === 0 ||
          requestPending
        }
        color="secondary"
      >
        {requestPending ? (
          <div className="lds-dual-ring" />
        ) : (
          "Réinitialiser mon mot de passe"
        )}
      </Button>
    );

    return (
      <div className="Login">
        <Grid container spacing={3}>
          <Grid item sm={12} md={6} style={{ margin: "auto" }}>
            <Paper
              style={{
                textAlign: "center",
                padding: isMobile ? "10px" : "62px 97px",
                backgroundColor: "#E5E8F3"
              }}
            >
              {!isMobile && (
                <Typography component="h2">
                  {"Réinitialisation du mot de passe"}
                </Typography>
              )}
              <Typography component="h3">
                {"Renseignez votre adresse e-mail :"}
              </Typography>
              <form noValidate autoComplete="off">
                <Typography component="label">{"Adresse e-mail"}</Typography>
                <TextField
                  id="input-username"
                  type="username"
                  placeholder="Votre adresse e-mail"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={this.state.username}
                  onChange={e =>
                    this.handleInputChange("username", e.target.value)
                  }
                />
                <FormHelperText
                  id="name-error-text"
                  style={{
                    color: "#c51829",
                    fontWeight: "bold",
                    fontSize: "16px"
                  }}
                >
                  {this.state.usernameError}
                </FormHelperText>
              </form>
              {buttonForm}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  authReducer: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  }).isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => ({
  forgotPassword: login =>
    dispatch({ type: "RESET_PASSWORD", payload: { login } })
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
