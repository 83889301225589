/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";
import PageTitle from "../PageTitle/PageTitle";

import { authReducerType } from "../../_helpers/appProptypes";

import Indicators from "./Indicators";
import Users from "./Users";

import "./Admin.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const Admin = props => {
  const { authReducer, history } = props;

  const pathTabId =
    ((history.location || {}).pathname || "/").split("/")[2] || "indicateurs";
  const [tabId, setTabId] = React.useState(pathTabId);

  if (
    authReducer &&
    authReducer.user &&
    !["admin"].includes(authReducer.user.role)
  ) {
    return "Vous ne disposez pas des droits nécessaires pour afficher cette page.";
  }

  function tabChange(event, newValue) {
    const newTabId = newValue;
    setTabId(newTabId);
    switch (newTabId) {
      case "indicateurs":
        history.push("/admin/indicateurs");
        break;
      case "utilisateurs":
        history.push("/admin/utilisateurs");
        break;
      default:
        history.push("/admin/indicateurs");
    }
  }

  return (
    <div className="Admin">
      <PageTitle title="Administration" />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Paper className="adminBody">
            <AppBar position="static">
              <Tabs value={tabId} onChange={tabChange} aria-label="tab change">
                <Tab value="indicateurs" label="Indicateurs" wrapped />
                <Tab value="utilisateurs" label="Utilisateurs" wrapped />
              </Tabs>
            </AppBar>

            <TabPanel value={tabId} index="indicateurs">
              {tabId === "indicateurs" && <Indicators />}
            </TabPanel>
            <TabPanel value={tabId} index="utilisateurs">
              {tabId === "utilisateurs" && <Users />}
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Admin.propTypes = {
  authReducer: authReducerType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const dispatchStateToProps = () => {};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(Admin));
