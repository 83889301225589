import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import eventReducer from "./eventReducer";
import missionReducer from "./missionReducer";
import applicationReducer from "./applicationReducer";
import supportReducer from "./supportReducer";
import documentReducer from "./documentReducer";
import linkReducer from "./linkReducer";
import toastReducer from "./toastReducer";
import favoriteReducer from "./favoriteReducer";
import dashboardReducer from "./dashboardReducer";
import multiSelectionReducer from "./multiSelectionReducer";
import moveDocumentReducer from "./moveDocumentReducer";
import searchReducer from "./searchReducer";
import clientReducer from "./clientReducer";
import downloadLinkReducer from "./downloadLinkReducer";
import indicatorReducer from "./indicatorReducer";
import userReducer from "./userReducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    eventReducer,
    missionReducer,
    applicationReducer,
    supportReducer,
    documentReducer,
    linkReducer,
    toastReducer,
    favoriteReducer,
    dashboardReducer,
    multiSelectionReducer,
    moveDocumentReducer,
    searchReducer,
    clientReducer,
    downloadLinkReducer,
    indicatorReducer,
    userReducer
  });
