import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Done from "@material-ui/icons/Done";
import PropTypes from "prop-types";

const DocumentListingTable = ({ mine = false, withoutAction = false }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" style={{ color: "#134594" }}>
          <Done />
        </TableCell>
        <TableCell align="center" style={{ color: "#134594" }} />
        <TableCell align="center" style={{ color: "#134594" }}>
          {"Domaine"}
        </TableCell>
        <TableCell align="center" style={{ color: "#134594" }}>
          {"Type"}
        </TableCell>
        <TableCell align="left" style={{ color: "#134594" }}>
          {"Nom"}
        </TableCell>
        <TableCell align="left" style={{ color: "#134594" }}>
          {"Nom de la société"}
        </TableCell>
        <TableCell align="center" style={{ color: "#134594" }}>
          {"Déposé le"}
        </TableCell>
        {!mine && (
          <TableCell align="left" style={{ color: "#134594" }}>
            {"Responsable"}
          </TableCell>
        )}
        <TableCell align="center" style={{ color: "#134594" }}>
          {"Taille du fichier"}
        </TableCell>
        <TableCell align="center" style={{ color: "#134594" }}>
          {"Télécharger"}
        </TableCell>
        {!withoutAction && (
          <TableCell align="center" style={{ color: "#134594" }}>
            {"Traiter"}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

DocumentListingTable.propTypes = {
  mine: PropTypes.bool,
  withoutAction: PropTypes.bool
};

export default DocumentListingTable;
