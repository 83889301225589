import { takeLatest, put, call } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchMissionsSync() {
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/missions",
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_MISSIONS_SYNC",
      payload: response.data.missions
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchMissions() {
  yield takeLatest("FETCH_MISSIONS", onFetchMissionsSync);
}
