import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./DeleteModal.scss";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
}));

const DeleteModal = props => {
  const classes = useStyles();
  const { isOpen, onClose, deleteFunction } = props;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="DeleteModal"
      open={isOpen}
    >
      <div className={clsx(classes.paper, "DeleteModalContent")}>
        <h2 id="simple-modal-title">Suppression</h2>
        <div className="confirmationBody">
          <div className="confirmationText">
            La suppression de cet utilisateur sera irréversible.
            <br />
            Êtes-vous sûr(e) de vouloir le supprimer ?
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "48px" }}>
          <Button
            style={{ marginRight: "6px" }}
            onClick={onClose}
            className="cancel"
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              deleteFunction();
              onClose();
            }}
            color="primary"
          >
            Supprimer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired
};

export default DeleteModal;
