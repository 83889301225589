import React, { useRef, useState, forwardRef, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { clientReducerType } from "../../_helpers/appProptypes";
import ClientLine from "./ClientLine";
import clsx from "clsx";
import { FETCH_CLIENTS } from "../../actions/client-actions";

const FETCH_CLIENTS_LIMIT = 25;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const localization = {
  body: {
    emptyDataSourceMessage: "Pas de client correspondant",
    filterRow: {
      filterTooltip: "Filtrer"
    }
  },
  pagination: {
    labelDisplayedRows: "Dossiers {from}-{to} sur {count}"
  },
  toolbar: {
    exportTitle: "Exporter",
    exportAriaLabel: "Exporter",
    exportName: "Exporter en CSV",
    searchTooltip: "Recherche",
    searchPlaceholder: "Recherche"
  }
};

function getValue(indicatorId, perimeter) {
  const v = _.find(perimeter, e => e.indicatorId === indicatorId) || {};
  return Math.round(v.indicatorValue);
}

function getIndicatorValue(indicators, indicatorId) {
  const exercices = _.uniq(indicators.map(s => s.exerciceEndMonth)).sort();
  const currentExerciceCode = exercices[exercices.length - 1];
  const currentValues = _.find(
    indicators,
    s => s.exerciceEndMonth === currentExerciceCode
  )
    ? _.find(indicators, s => s.exerciceEndMonth === currentExerciceCode)
        .indicatorValues
    : null;
  if (!currentValues) return null;
  return getValue(indicatorId, currentValues);
}

const ClientsListing = ({
  clientReducer: { isLoading, clients, previous, next, totalCount = 0 },
  fetchClientsPortfolio,
  className = ""
}) => {
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const tableRef = React.createRef();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevClients = usePrevious(clients);
  const prevPage = usePrevious(page);
  const prevSearchTerm = usePrevious(searchTerm);

  useEffect(() => {
    // on first load or search change
    if (isLoading || prevSearchTerm !== searchTerm) {
      fetchClientsPortfolio({ search: searchTerm }, null, null);
    }
    // if clients list changed
    else if (
      !_.isEqual(
        (clients || []).map(c => c._id),
        (prevClients || []).map(c => c._id)
      )
    ) {
      tableRef.current.onQueryChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, searchTerm]);

  const clientsColumns = [
    {
      title: "Société",
      field: "name",
      searchable: true,
      type: "string"
    },
    {
      title: "A traiter",
      field: "",
      searchable: false,
      type: "string",
      sorting: false
    },
    {
      title: "Dernières mises à jour",
      field: "",
      searchable: false,
      type: "string",
      sorting: false
    },
    {
      title: "Chiffre d'affaire",
      field: "",
      searchable: true,
      type: "string",
      customSort: (a, b) => {
        const aVal = getIndicatorValue(a.indicators, "bdo_chiffredaffaire");
        const bVal = getIndicatorValue(b.indicators, "bdo_chiffredaffaire");
        return aVal - bVal;
      }
    },
    {
      title: "Dépenses",
      field: "SC",
      searchable: true,
      type: "string",
      customSort: (a, b) => {
        const aVal = getIndicatorValue(
          a.indicators,
          "bdo_chargesfonctionnement"
        );
        const bVal = getIndicatorValue(
          b.indicators,
          "bdo_chargesfonctionnement"
        );
        return aVal - bVal;
      }
    },
    {
      title: "Trésorerie",
      field: "SD",
      searchable: true,
      type: "string",
      customSort: (a, b) => {
        const aVal = getIndicatorValue(a.indicators, "bdo_tresorerie");
        const bVal = getIndicatorValue(b.indicators, "bdo_tresorerie");
        return aVal - bVal;
      }
    },
    {
      title: "Encours client",
      field: "SM",
      searchable: true,
      type: "string",
      customSort: (a, b) => {
        const aVal = getIndicatorValue(a.indicators, "bdo_creanceclient");
        const bVal = getIndicatorValue(b.indicators, "bdo_creanceclient");
        return aVal - bVal;
      }
    },
    {
      title: "Accès au portail du client",
      field: "comptesExclus",
      searchable: false,
      type: "string",
      sorting: false,
      headerStyle: {
        color: "red"
      }
    }
  ];

  const clientsDataSorted = _.sortBy(clients, c => c.name.toUpperCase());

  return (
    <div className={clsx("ClientsListing", className)}>
      <MaterialTable
        title={
          <div>
            {
              "Affichage des 25 premiers résultats de votre recherche. Affinez vos critères si besoin."
            }
          </div>
        }
        isLoading={isLoading}
        tableRef={tableRef}
        icons={tableIcons}
        options={{
          exportButton: true,
          sorting: false,
          paging: false,
          pageSize: parseInt(
            process.env.REACT_APP_COLLAB_PORTFOLIO_PAGE_SIZE || 5,
            10
          ),
          pageSizeOptions: [],
          paginationType: "normal",
          showFirstLastPageButtons: false,
          headerStyle: {
            color: "#134594",
            position: "sticky",
            top: 0,
            textAlign: "center",
            maxHeight: "30px",
            borderBottom: "2px solid grey"
          },
          maxBodyHeight: 560,
          debounceInterval: 400,
          searchText: searchTerm
        }}
        components={{
          Row: rowProps => {
            return (
              <ClientLine
                key={rowProps.data._id}
                client={_.find(clients, c => c._id === rowProps.data._id)}
              />
            );
          }
        }}
        columns={clientsColumns}
        data={query =>
          new Promise(resolve => {
            if (prevSearchTerm !== query.search) {
              setPage(0);
              setSearchTerm(query.search);
            }
            resolve({
              data: clientsDataSorted,
              page,
              totalCount
            });
          })
        }
        localization={localization}
        onChangePage={e => {
          if (prevPage < e) {
            fetchClientsPortfolio({ search: searchTerm }, null, next);
          } else {
            fetchClientsPortfolio({ search: searchTerm }, previous, null);
          }
          setPage(e);
        }}
      />
    </div>
  );
};

ClientsListing.propTypes = {
  clientReducer: clientReducerType.isRequired,
  fetchClientsPortfolio: PropTypes.func.isRequired,
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    clientReducer: state.clientReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchClientsPortfolio: (query, previous, next) =>
      dispatch({
        type: FETCH_CLIENTS,
        payload: {
          previous,
          next,
          search: query.search,
          limit: FETCH_CLIENTS_LIMIT
        }
      }),
    searchTerm: searchTerm =>
      dispatch({ type: "SEARCH_CLIENTS", payload: searchTerm })
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(ClientsListing);
