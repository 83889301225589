/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isMobile } from "react-device-detect";
import Folder from "@material-ui/icons/Folder";
import "./GedBreadCrumb.scss";
import Typography from "@material-ui/core/Typography";
import GedBreadcrumbParentFolder from "./GedBreadcrumbParentFolder";

const GedBreadCrumb = ({ documentReducer }) => {
  if (!documentReducer.folder) return null;

  const { parents } = documentReducer.folder;

  if (!parents) return null;

  const racineFolder = parents[parents.length - 1];

  let visibleParents = [];
  if (parents.length === 2) {
    visibleParents.push(parents[0]);
  } else {
    visibleParents = parents.slice(0, 2).reverse();
  }

  return (
    <div className="GedBreadCrumb">
      {isMobile && (
        <div className="folderContainer">
          <GedBreadcrumbParentFolder
            folder={{ name: <Folder color="primary" />, _id: "" }}
          />
          <div className="mr-4">{">"}</div>
        </div>
      )}
      {parents.length > 1 ? (
        <div className="folderContainer">
          <GedBreadcrumbParentFolder folder={racineFolder} />
          <div className="mr-4">{">"}</div>
        </div>
      ) : null}
      {parents.length > 3 ? (
        <>
          <Typography className="GedBreadcrumbOtherParents" component="span">
            {"..."}
          </Typography>
          <div className="mr-4">{">"}</div>
        </>
      ) : null}
      {visibleParents.map((pFolder, index) => (
        <div key={pFolder._id} className="folderContainer">
          {index !== visibleParents.length - 1 ? (
            <>
              <GedBreadcrumbParentFolder folder={pFolder} />
              <div className="mr-4">{">"}</div>
            </>
          ) : (
            <GedBreadcrumbParentFolder folder={pFolder} isActive />
          )}
        </div>
      ))}
    </div>
  );
};

GedBreadCrumb.propTypes = {
  documentReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

export default connect(mapStateToProps)(GedBreadCrumb);
