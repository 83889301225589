import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Auth2FADialog from "./Auth2FADialog";

import "./Settings.scss";
import ApiManager from "../../lib/apiManager";
import Loader from "../Loader/Loader";
import { useQuery } from "react-query";

const Auth2FA = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const get2FAActiveQuery = useQuery("get2FAActive", ApiManager.get2FAActive);

  if (get2FAActiveQuery.isLoading) return <Loader />;

  return (
    <div>
      {get2FAActiveQuery.data.data.active ? (
        <div>{"Vous avez activé l'authentification 2FA"}</div>
      ) : (
        <div className="">
          <div className="" style={{ marginBottom: "6px" }}>
            {"Pour plus de sécurité, veuillez activer l'authentification 2FA"}
          </div>
          <Button onClick={() => setModalOpen(true)}>
            {"Configurer le 2FA"}
          </Button>

          {modalOpen && (
            <Auth2FADialog
              onClose={() => setModalOpen(false)}
              onSuccess={() => get2FAActiveQuery.refetch()}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Auth2FA;
