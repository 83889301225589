import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CloudDownload from "@material-ui/icons/CloudDownload";

import "./DocumentsToolbar.scss";

import { multiSelectionReducerType } from "../../_helpers/appProptypes";

const DocumentsToolbar = props => {
  const { downloadSelection, processSelection, multiSelectionReducer } = props;
  const { selectionList } = multiSelectionReducer;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    processSelection();
  };

  const selectionNotEmpty = selectionList.length > 0;

  const zeroDownloads = selectionList.find(d => d.downloadsCount === 0);
  const selectionContainsZeroDownloadsDocuments = !!zeroDownloads;

  return (
    <div className="DocumentsToolbar">
      <Typography
        component="a"
        onClick={() => (selectionNotEmpty ? downloadSelection() : null)}
        className={selectionNotEmpty ? "tool" : "tool--disabled"}
      >
        <CloudDownload />
        Télécharger
      </Typography>{" "}
      <Typography
        component="a"
        onClick={() => (selectionNotEmpty ? handleClickOpen() : null)}
        className={selectionNotEmpty ? "tool" : "tool--disabled"}
      >
        <CheckCircle />
        Traiter
      </Typography>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Considérer ce(s) document(s) comme traîté(s) ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Ce(s) document(s) sera(ont) considéré(s) comme traité(s) et ne
              figuera(ront) plus dans cette liste, de façon irréversible.
              <br />
              {selectionContainsZeroDownloadsDocuments && (
                <b>CERTAINS DOCUMENTS N&apos;ONT JAMAIS ÉTÉ TÉLÉCHARGÉS.</b>
              )}
            </p>
            <p>Confirmez-vous avoir traité ce(s) document(s) ?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className="cancel">
            Annuler
          </Button>
          <Button onClick={handleClose}>Confirmer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DocumentsToolbar.propTypes = {
  downloadSelection: PropTypes.func.isRequired,
  processSelection: PropTypes.func.isRequired,
  multiSelectionReducer: multiSelectionReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    multiSelectionReducer: state.multiSelectionReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    downloadSelection: () =>
      dispatch({ type: "DOWNLOAD_SELECTION", payload: true }),
    processSelection: () => dispatch({ type: "PROCESS_SELECTION" })
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(DocumentsToolbar);
