import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      missions: [],
      applications: []
    };

    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitchMission = this.handleSwitchMission.bind(this);
    this.handleSwitchApp = this.handleSwitchApp.bind(this);
  }

  validateField(fieldName, value) {
    let { newEmailError } = this.state;

    let { emailValid } = false;

    switch (fieldName) {
      case "email":
        emailValid =
          value.length > 4 &&
          value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        newEmailError = emailValid ? "" : "Email invalide";
        break;

      default:
        break;
    }
    this.setState({
      newEmailError
    });
  }

  handleChange(accessor, value) {
    const nextState = {
      [accessor]: value
    };

    this.setState(nextState, () => {
      this.validateField(accessor, value);
    });
  }

  handleSwitchMission(missionId) {
    const { missions } = this.state;
    let newMissions;
    if (missions.indexOf(missionId) === -1) {
      newMissions = [...missions, missionId];
    } else {
      newMissions = missions.filter(m => m !== missionId);
    }
    this.setState({ missions: newMissions });
  }

  handleSwitchApp(appId) {
    const { applications } = this.state;
    let newApps;
    if (applications.indexOf(appId) === -1) {
      newApps = [...applications, appId];
    } else {
      newApps = applications.filter(a => a !== appId);
    }
    this.setState({ applications: newApps });
  }

  render() {
    const { loading, allMissions, allApps } = this.props;
    const { missions, applications } = this.state;

    const createButtonDisabled = !!(
      this.state.email === "" ||
      this.state.newEmailError ||
      this.state.missions.length === 0
    );
    return (
      <div className="settings__users__new">
        <div style={{ maxWidth: "800px", padding: "0 15px" }}>
          <div className="flex">
            <h1 className="capitalize">Nouveau compte</h1>
          </div>
          {loading && <CircularProgress color="secondary" />}
          {!loading && (
            <div>
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ flexGrow: 1 }}
              >
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="firstname"
                    name="firstname"
                    label="Prénom"
                    fullWidth
                    value={this.state.firstname}
                    onChange={event =>
                      this.handleChange("firstname", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="lastname"
                    name="lastname"
                    label="Nom"
                    fullWidth
                    value={this.state.lastname}
                    onChange={event =>
                      this.handleChange("lastname", event.target.value)
                    }
                  />
                </Grid>
                <Grid itm xs={12} sm={1} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Adresse e-mail"
                    fullWidth
                    autoComplete=""
                    value={this.state.email}
                    onChange={event =>
                      this.handleChange("email", event.target.value)
                    }
                  />
                </Grid>
                <Grid itm xs={12} sm={1} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="role"
                    name="role"
                    label="Rôle"
                    disabled
                    fullWidth
                    autoComplete=""
                    value="utilisateur secondaire"
                  />
                </Grid>
                <Grid item xs={12} sm={1} />
                {/* <Grid item xs={12} sm={5}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label="Mot de passe"
                    fullWidth
                    type="password"
                    value={this.state.password}
                    onChange={event =>
                      this.handleChange("password", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    required
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    label="Confirmation du mot de passe"
                    fullWidth
                    type="password"
                    value={this.state.passwordConfirmation}
                    onChange={event =>
                      this.handleChange(
                        "passwordConfirmation",
                        event.target.value
                      )
                    }
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} style={{ margin: "10px 0" }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Missions</FormLabel>
                    <FormGroup row>
                      {allMissions.map((mission, missionIndex) => (
                        <FormControlLabel
                          key={missionIndex}
                          control={
                            <Checkbox
                              checked={
                                missions.findIndex(m => m === mission._id) !==
                                -1
                              }
                              onChange={() => {
                                this.handleSwitchMission(mission._id);
                              }}
                              value={mission._id}
                            />
                          }
                          label={
                            <div
                              className="missionChip"
                              style={{
                                color: mission.color,
                                border: `1px solid ${mission.color}`
                              }}
                            >
                              {mission.label}
                            </div>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
                {allApps.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{"Applications"}</FormLabel>
                      <FormGroup row>
                        {allApps.map((app, appIndex) => (
                          <FormControlLabel
                            key={appIndex}
                            control={
                              <Checkbox
                                checked={
                                  applications.findIndex(a => a === app._id) !==
                                  -1
                                }
                                onChange={() => {
                                  this.handleSwitchApp(app._id);
                                }}
                                value={app._id}
                              />
                            }
                            label={<div className="appChip">{app.name}</div>}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <div
                style={{
                  display: "flex",
                  width: "95%",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  className="settings__users__new-create"
                  onClick={() => {
                    this.props.createAccountRequest(
                      this.state.firstname,
                      this.state.lastname,
                      this.state.email,
                      "password", // this.state.password,
                      this.state.missions,
                      this.state.applications
                    );
                    this.setState({
                      firstname: "",
                      lastname: "",
                      email: "",
                      // password: "",
                      // passwordConfirmation: "",
                      missions: [],
                      applications: []
                    });
                  }}
                  disabled={
                    createButtonDisabled
                    // ||
                    // this.state.password === "" ||
                    // this.state.password !== this.state.passwordConfirmation
                  }
                  color="primary"
                >
                  Créer
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NewUser.defaultProps = {
  loading: false
};

NewUser.propTypes = {
  createAccountRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  allMissions: PropTypes.arrayOf().isRequired,
  allApps: PropTypes.arrayOf().isRequired
};

export default NewUser;
