/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PageTitle from "../PageTitle/PageTitle";
import { authReducerType } from "../../_helpers/appProptypes";
import GedBreadCrumb from "../Ged/GedBreadCrumb";
import { isMobile } from "react-device-detect";
import PublicDocsFilesListing from "./PublicDocsFilesListing";
import PublicDocsToolbar from "./PublicDocsToolbar";
import Loader from "../Loader/Loader";
import { useQuery } from "react-query";

import "./PublicDocs.scss";
import ApiManager from "../../lib/apiManager";

const ONE_SECOND = 1000;

const PublicDocs = ({ authReducer }) => {
  const getPublicDocsQuery = useQuery("publicDocs", ApiManager.getPublicDocs, {
    refetchInterval: ONE_SECOND
  });

  if (
    authReducer &&
    authReducer.user &&
    !["admin"].includes(authReducer.user.role)
  ) {
    return "Vous ne disposez pas des droits nécessaires pour afficher cette page.";
  }

  return (
    <div className="PublicDocs">
      <PageTitle title="Documents publics" />
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item md={12} style={{ width: "100%" }}>
          <Paper className="PublicDocsBody">
            {getPublicDocsQuery.isLoading ? (
              <Loader />
            ) : (
              <div className="filesContainer" style={{ width: "100%" }}>
                <GedBreadCrumb />
                {!isMobile && (
                  <PublicDocsToolbar
                    onUploadSucceeded={getPublicDocsQuery.refetch}
                  />
                )}
                <PublicDocsFilesListing
                  documents={getPublicDocsQuery.data.data.documents}
                />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

PublicDocs.propTypes = {
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps)(PublicDocs);
