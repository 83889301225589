import { FETCH_DOCUMENTS, GET_NEW_DOCS } from "../actions/document-actions";

const initialState = {
  isSearching: false,
  isActive: false,
  searchTerm: "",
  resultsCount: 0,
  results: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS: {
      return {
        ...state,
        isActive: false
      };
    }

    case "SEARCH_DOCUMENT_ASYNC": {
      const { results, resultsCount } = action.payload;
      return {
        ...state,
        results,
        resultsCount,
        isSearching: false
      };
    }

    case "SEARCH_DOCUMENTS": {
      const searchTerm = action.payload;
      if (!searchTerm)
        return {
          ...state,
          isActive: false,
          isSearching: false
        };

      return {
        ...state,
        searchTerm,
        isActive: true,
        isSearching: true
      };
    }

    case GET_NEW_DOCS: {
      return {
        ...state,
        searchTerm: "",
        isActive: true,
        isSearching: true
      };
    }

    case "RENAME_DOCUMENT_SYNC": {
      const { document, newName } = action.payload;

      return {
        ...state,
        results: state.results.map(result => {
          if (result._id === document._id) {
            return {
              ...result,
              name: newName
            };
          }

          return result;
        })
      };
    }

    case "REMOVE_DOCUMENT_SYNC": {
      const document = action.payload;
      return {
        ...state,
        results: state.results.filter(result => result._id !== document._id)
      };
    }

    default:
      return state;
  }
};

export default reducer;
