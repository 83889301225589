/* eslint no-underscore-dangle: "off" */

import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import reducers from "./store/reducers";

import {
  onLogin,
  onValidateAccount,
  onRequestResetPasswordToken,
  onChangePassword,
  onGetUserInfo,
  onAccessClientPortal,
  onExitClientPortal,
  onUpdateCurrentTenant
} from "./sagas/authSaga";
import { onFetchEvents } from "./sagas/eventSaga";
import { onFetchMissions } from "./sagas/missionSaga";
import {
  onFetchApplications,
  onOpenApplication
} from "./sagas/applicationSaga";
import {
  onFetchSupports,
  onSendSupport,
  onFetchTickets,
  onUpdateTicketFilters,
  onFetchTicketHistory,
  onFetchTicket,
  onUpdateTicket,
  onReadAllTickets,
  onReadTicket
} from "./sagas/supportSaga";
import { onFetchLinks, onOpenLink } from "./sagas/linkSaga";
import { onFetchDashboards, onOpenDashboard } from "./sagas/dashboardSaga";
import {
  onFetchDocuments,
  onFetchNewPage,
  onFileUpload,
  onRemoveDocument,
  onCreateFolder,
  onRenameDocument,
  onDownloadFile,
  onFetchPrivateRootFolder,
  onFetchDocInfo,
  onFetchCollabDocs,
  onProcessDocument,
  onShareDocument,
  onFetchLinkInfo,
  onDownloadFileByLink,
  onReadAllDocuments
} from "./sagas/documentSaga";
import {
  onRemoveSelection,
  onMoveSelection,
  onDownloadSelection,
  onProcessSelection,
  onMarkSelectionAsRead
} from "./sagas/multiSelectionSaga";
import { onUpdateFavorites } from "./sagas/favoriteSaga";
import { onFetchMoveFolders } from "./sagas/moveDocumentSaga";
import { onSearchDocuments, onGetNewDocs } from "./sagas/searchSaga";
import {
  onFetchClients,
  onAskDataUpdate,
  onCancelUpdate
} from "./sagas/clientSaga";
import {
  onFetchDownloadLinks,
  onFetchDownloadLink,
  onUpdateDownloadLink,
  onDeleteDownloadLink
} from "./sagas/downloadLinkSaga";

import { onFetchIndicators, onAskIndicatorUpdate } from "./sagas/indicatorSaga";

import { onFetchUsers, onAskUserUpdate } from "./sagas/userSaga";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false
      })
    : compose;

const routerMiddlewaree = routerMiddleware(history);

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware, routerMiddlewaree)
);

export default function configureStore() {
  const store = createStore(reducers(history), enhancer);
  sagaMiddleware.run(onLogin);
  sagaMiddleware.run(onFetchEvents);
  sagaMiddleware.run(onFetchMissions);
  sagaMiddleware.run(onFetchApplications);
  sagaMiddleware.run(onOpenApplication);
  sagaMiddleware.run(onFetchDocuments);
  sagaMiddleware.run(onFetchNewPage);
  sagaMiddleware.run(onFetchSupports);
  sagaMiddleware.run(onFetchLinks);
  sagaMiddleware.run(onOpenLink);
  sagaMiddleware.run(onValidateAccount);
  sagaMiddleware.run(onRequestResetPasswordToken);
  sagaMiddleware.run(onChangePassword);
  sagaMiddleware.run(onUpdateFavorites);
  sagaMiddleware.run(onFileUpload);
  sagaMiddleware.run(onGetUserInfo);
  sagaMiddleware.run(onFetchDashboards);
  sagaMiddleware.run(onOpenDashboard);
  sagaMiddleware.run(onRemoveDocument);
  sagaMiddleware.run(onCreateFolder);
  sagaMiddleware.run(onRenameDocument);
  sagaMiddleware.run(onDownloadSelection);
  sagaMiddleware.run(onDownloadFile);
  sagaMiddleware.run(onRemoveSelection);
  sagaMiddleware.run(onSendSupport);
  sagaMiddleware.run(onFetchMoveFolders);
  sagaMiddleware.run(onMoveSelection);
  sagaMiddleware.run(onFetchPrivateRootFolder);
  sagaMiddleware.run(onSearchDocuments);
  sagaMiddleware.run(onGetNewDocs);
  sagaMiddleware.run(onFetchDocInfo);
  sagaMiddleware.run(onFetchClients);
  sagaMiddleware.run(onFetchCollabDocs);
  sagaMiddleware.run(onProcessSelection);
  sagaMiddleware.run(onProcessDocument);
  sagaMiddleware.run(onFetchTickets);
  sagaMiddleware.run(onAccessClientPortal);
  sagaMiddleware.run(onExitClientPortal);
  sagaMiddleware.run(onUpdateTicketFilters);
  sagaMiddleware.run(onAskDataUpdate);
  sagaMiddleware.run(onFetchTicketHistory);
  sagaMiddleware.run(onFetchTicket);
  sagaMiddleware.run(onUpdateTicket);
  sagaMiddleware.run(onReadAllTickets);
  sagaMiddleware.run(onReadTicket);
  sagaMiddleware.run(onUpdateCurrentTenant);
  sagaMiddleware.run(onCancelUpdate);
  sagaMiddleware.run(onShareDocument);
  sagaMiddleware.run(onFetchLinkInfo);
  sagaMiddleware.run(onDownloadFileByLink);
  sagaMiddleware.run(onReadAllDocuments);
  sagaMiddleware.run(onFetchDownloadLinks);
  sagaMiddleware.run(onFetchDownloadLink);
  sagaMiddleware.run(onUpdateDownloadLink);
  sagaMiddleware.run(onDeleteDownloadLink);
  sagaMiddleware.run(onMarkSelectionAsRead);
  sagaMiddleware.run(onFetchIndicators);
  sagaMiddleware.run(onAskIndicatorUpdate);
  sagaMiddleware.run(onFetchUsers);
  sagaMiddleware.run(onAskUserUpdate);
  return store;
}
