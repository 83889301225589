/* eslint-disable react/forbid-prop-types */

import React, { Component, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeftSharp from "@material-ui/icons/KeyboardArrowLeftSharp";
import Folder from "@material-ui/icons/Folder";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import Loader from "../Loader/Loader";

import "./GedMoveModal.scss";

const styles = theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
});

class GedNewFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectRootFolder: false
    };
  }

  componentDidMount() {
    this.props.fetchMoveFolders();
    this.props.fetchRootFolders();
  }

  render() {
    const {
      isOpen,
      onClose,
      moveSelectionTo,
      moveDocumentReducer,
      classes,
      fetchMoveFolders
    } = this.props;
    const parents = moveDocumentReducer.isLoading
      ? null
      : moveDocumentReducer.parents.reverse();
    return (
      <Modal
        aria-labelledby="simple-modal-moveto"
        aria-describedby="simple-modal-description"
        className="GedMoveModal"
        open={isOpen}
      >
        <div className={clsx(classes.paper, "GedMoveModalContent")}>
          <h2 id="simple-modal-moveto">Déplacer vers</h2>

          {this.state.selectRootFolder ||
          moveDocumentReducer.isLoading ? null : (
            <div>
              <span onClick={() => this.setState({ selectRootFolder: true })}>
                <KeyboardArrowLeftSharp
                  style={{ position: "relative", top: "6px" }}
                  color="primary"
                />
              </span>
              {parents.map((folder, index) => (
                <Fragment key={folder._id}>
                  <span
                    onClick={() => {
                      if (index !== parents.length - 1)
                        fetchMoveFolders(folder._id);
                    }}
                    className={
                      index === parents.length - 1
                        ? "currentFolder"
                        : "folderName"
                    }
                  >
                    {folder.name}
                  </span>
                  {index === parents.length - 1 ? null : (
                    <span color="primary">{" > "}</span>
                  )}
                </Fragment>
              ))}
            </div>
          )}
          <div className="moveModalContainer">
            {moveDocumentReducer.isLoading ? (
              <Loader />
            ) : (
              <>
                {this.state.selectRootFolder ? (
                  <div>
                    <div
                      className="rootFolderSelection"
                      onClick={() => {
                        fetchMoveFolders("private");
                        this.setState({ selectRootFolder: false });
                      }}
                    >
                      {" Mon espace privé"}
                    </div>
                    <div
                      className="rootFolderSelection"
                      onClick={() => {
                        fetchMoveFolders("depot");
                        this.setState({ selectRootFolder: false });
                      }}
                    >
                      {"Échange avec HDM"}
                    </div>
                  </div>
                ) : (
                  <>
                    {moveDocumentReducer.folders.length ? (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">{"Type"}</TableCell>
                            <TableCell align="right">{"Nom"}</TableCell>
                            <TableCell align="right">{"Déposé le"}</TableCell>
                            <TableCell align="right">
                              {"Propriétaire"}
                            </TableCell>
                            <TableCell align="right">{"Taille"}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {moveDocumentReducer.folders.map(folder => (
                            <TableRow key={folder._id}>
                              <TableCell
                                component="th"
                                scope="row"
                                onClick={() => fetchMoveFolders(folder._id)}
                                className="folderName"
                              >
                                <Folder />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                onClick={() => fetchMoveFolders(folder._id)}
                                className="folderName"
                              >
                                {folder.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {folder.createdAt}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {folder.owner}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                -
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Type</TableCell>
                              <TableCell align="right">Nom</TableCell>
                              <TableCell align="right">Déposé le</TableCell>
                              <TableCell align="right">Propriétaire</TableCell>
                              <TableCell align="right">Taille</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody />
                        </Table>
                        <div className="emptyFolder">Dossier vide...</div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div style={{ textAlign: "right", marginTop: "48px" }}>
            <Button
              style={{ marginRight: "6px" }}
              onClick={onClose}
              className="cancel"
            >
              Annuler
            </Button>
            <Button
              onClick={() => {
                moveSelectionTo();
                onClose();
              }}
            >
              Déplacer
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

GedNewFolder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  moveSelectionTo: PropTypes.func.isRequired,
  moveDocumentReducer: PropTypes.object.isRequired,
  fetchMoveFolders: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  fetchRootFolders: PropTypes.func.isRequired
};

const dispatchStateToProps = dispatch => {
  return {
    moveSelectionTo: () => dispatch({ type: "MOVE_SELECTION" }),
    fetchMoveFolders: folderId =>
      dispatch({ type: "FETCH_MOVE_FOLDERS", payload: folderId }),
    fetchRootFolders: () => dispatch({ type: "FETCH_ROOT_FOLDERS" })
  };
};

const mapStateToProps = state => {
  return {
    moveDocumentReducer: state.moveDocumentReducer,
    authReducer: state.authReducer
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withStyles(styles)(GedNewFolder));
