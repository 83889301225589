import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  }
}));

const LinkDeleteModal = props => {
  const classes = useStyles();
  const { isOpen, onClose, deleteLink } = props;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="LinkDeleteModal"
      open={isOpen}
    >
      <div className={clsx(classes.paper, "LinkDeleteModalContent")}>
        <h2 id="simple-modal-title">Suppression</h2>
        <div className="confirmationBody">
          <div className="confirmationText">
            <p>
              La suppression de ce lien de téléchargement sera irréversible.
              <br />
              Êtes-vous sûr(e) de vouloir le supprimer ?
            </p>
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "48px" }}>
          <Button style={{ marginRight: "6px" }} onClick={onClose}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              deleteLink();
              onClose();
            }}
            color="primary"
          >
            Supprimer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

LinkDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteLink: PropTypes.func.isRequired
};

export default LinkDeleteModal;
