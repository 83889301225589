import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import Loader from "../Loader/Loader";
import GedFileLine from "./GedFileLine";
import GedRenameModal from "./GedRenameModal";
import GedDeleteModal from "./GedDeleteModal";
import GedFileLineMobile from "./GedFileLineMobile";
import { useState } from "react";
import SortIcon from "./SortIcon";
import moment from "moment";

const getBreadCrumb = result => {
  let res = "";
  [...result.parents].reverse().forEach((parent, index) => {
    res += parent.name;
    if (index !== result.parents.length - 1) res += " > ";
  });
  return res;
};

const GedSearchResults = ({ searchReducer, isCollabSession }) => {
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortOrderAsc, setSortOrderAsc] = useState(true);

  let displayedSearchTerm = (searchReducer.searchTerm || "").replace(
    /\.\*/g,
    "*"
  );

  displayedSearchTerm = displayedSearchTerm
    .replace("$status:processed", "status:traité")
    .replace("$status:toProcess", "status:non-traité");

  if (searchReducer.isSearching) return <Loader />;

  const onRename = doc => {
    setSelectedDocument(doc);
    setRenameModalOpen(true);
  };

  const onRemove = doc => {
    setSelectedDocument(doc);
    setDeleteModalOpen(true);
  };

  const lowerCase = (str = "") => str.toLowerCase();

  const sortResults = (a, b) => {
    const fileACreationDateMoment = moment(a.creationDate ?? new Date());
    const fileBCreationDateMoment = moment(b.creationDate ?? new Date());

    switch (sortColumn) {
      case "size":
        if (sortOrderAsc) return (a.size ?? 0) > (b.size ?? 0) ? 1 : -1;
        return (a.size ?? 0) < (b.size ?? 0) ? 1 : -1;

      case "creationDate":
        if (sortOrderAsc)
          return fileACreationDateMoment.isAfter(fileBCreationDateMoment)
            ? 1
            : -1;
        return fileACreationDateMoment.isBefore(fileBCreationDateMoment)
          ? 1
          : -1;

      case "mimeType":
        if (sortOrderAsc)
          return lowerCase(a.mimeType) > lowerCase(b.mimeType) ? 1 : -1;
        return lowerCase(a.mimeType) < lowerCase(b.mimeType) ? 1 : -1;

      case "owner":
        if (sortOrderAsc)
          return lowerCase(a.owner) > lowerCase(b.owner) ? 1 : -1;
        return lowerCase(a.owner) < lowerCase(b.owner) ? 1 : -1;

      default:
        if (sortOrderAsc) return lowerCase(a.name) > lowerCase(b.name) ? 1 : -1;
        return lowerCase(a.name) < lowerCase(b.name) ? 1 : -1;
    }
  };

  const changeSortOrder = column => {
    if (column === sortColumn) setSortOrderAsc(!sortOrderAsc);
    setSortColumn(column);
  };

  return (
    <div
      style={{
        padding: "10px",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography component="h2" color="primary">
        {searchReducer.resultsCount > 1 ? (
          <span>
            {`${searchReducer.resultsCount} réponses correspondent à la recherche « ${displayedSearchTerm} »`}
          </span>
        ) : (
          <span>
            {`${searchReducer.resultsCount} réponse correspond à la recherche « ${displayedSearchTerm} »`}
          </span>
        )}
      </Typography>

      <div className="GedFilesListing">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell align="left" />}
              {!isMobile && (
                <TableCell
                  align="left"
                  onClick={() => changeSortOrder("mimeType")}
                >
                  <div className="flex gap-1">
                    <span>{"Type"}</span>

                    {sortColumn === "mimeType" && (
                      <SortIcon orderAsc={sortOrderAsc} />
                    )}
                  </div>
                </TableCell>
              )}
              <TableCell onClick={() => changeSortOrder("name")}>
                <div className="flex gap-1">
                  <span>{"Nom"}</span>

                  {sortColumn === "name" && (
                    <SortIcon orderAsc={sortOrderAsc} />
                  )}
                </div>
              </TableCell>

              {!isMobile && (
                <>
                  {!isCollabSession && (
                    <TableCell align="left">{"Favoris"}</TableCell>
                  )}
                  <TableCell
                    align="left"
                    onClick={() => changeSortOrder("creationDate")}
                  >
                    <div className="flex gap-1">
                      <span>{"Déposé le"}</span>

                      {sortColumn === "creationDate" && (
                        <SortIcon orderAsc={sortOrderAsc} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <span>{"Date CEGID"}</span>
                  </TableCell>

                  <TableCell
                    align="left"
                    onClick={() => changeSortOrder("owner")}
                  >
                    <div className="flex gap-1">
                      <span>{"Propriétaire"}</span>

                      {sortColumn === "owner" && (
                        <SortIcon orderAsc={sortOrderAsc} />
                      )}
                    </div>
                  </TableCell>

                  <TableCell
                    align="left"
                    onClick={() => changeSortOrder("size")}
                  >
                    <div className="flex gap-1">
                      <span>{"Taille"}</span>

                      {sortColumn === "size" && (
                        <SortIcon orderAsc={sortOrderAsc} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="left" />
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {searchReducer.results.sort(sortResults).map((result, index) => {
              const ComplementaryNameInfo = (
                <div style={{ color: "#B1B1B1", fontSize: "10px" }}>
                  {getBreadCrumb(result)}
                </div>
              );

              const allProps = {
                document: result,
                key: result._id,
                onRename: onRename,
                onRemove: onRemove,
                complementaryNameInfo: ComplementaryNameInfo
              };

              return isMobile ? (
                <GedFileLineMobile {...allProps} key={index} />
              ) : (
                <GedFileLine {...allProps} key={index} />
              );
            })}
          </TableBody>
        </Table>
      </div>

      {isRenameModalOpen && (
        <GedRenameModal
          isOpen={isRenameModalOpen}
          onClose={() => setRenameModalOpen(false)}
          document={selectedDocument}
        />
      )}

      {isDeleteModalOpen && (
        <GedDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          selection={selectedDocument}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ searchReducer }) => {
  return {
    searchReducer
  };
};

GedSearchResults.propTypes = {
  searchReducer: PropTypes.object.isRequired,
  isCollabSession: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(GedSearchResults);
