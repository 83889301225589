import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import Info from "@material-ui/icons/Info";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import TextFormat from "@material-ui/icons/TextFormat";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Share from "@material-ui/icons/Share";
import Drafts from "@material-ui/icons/DraftsSharp";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import _ from "underscore";
import { connect } from "react-redux";
import "./GedFileLine.scss";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import DocumentIcon from "../DocumentIcon/DocumentIcon";

import {
  documentType,
  favoriteReducerType,
  multiSelectionReducerType,
  documentReducerType,
  userType
} from "../../_helpers/appProptypes";

import utils from "../../_helpers/utils";
import { DOWNLOAD_FILE, FETCH_DOCUMENTS } from "../../actions/document-actions";
import SpecialFolder from "../../enums/special-folder.enum";

const sizeThreshold1 = process.env.REACT_APP_CEGID_GED_SIZE_THRESHOLD_1 || 1;
const sizeThreshold2 = process.env.REACT_APP_CEGID_GED_SIZE_THRESHOLD_2 || 10;

const GedFileLine = ({
  favoriteReducer,
  document,
  removeFromFavorites,
  addToFavorites,
  onRemove,
  toggleSelectDocument,
  onRename,
  downloadFile,
  multiSelectionReducer,
  documentReducer,
  selectable,
  complementaryNameInfo,
  history,
  isCollabSession,
  user,
  markSelectionAsRead,
  selectFolder
}) => {
  const { folder } = documentReducer;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);

  const rootFolder =
    folder && folder.parents ? folder.parents[folder.parents.length - 1] : null;
  const isCegidPublication = rootFolder && rootFolder._id === "publication";

  const handleDownload = doc => {
    setConfirmationOpen(false);
    downloadFile(doc);
  };

  const isFavorite = doc => {
    return _.some(
      favoriteReducer.favorites,
      favorite => favorite.docId === doc._id
    );
  };

  let newDocuments = [];
  if (user.gedNotifications) newDocuments = [...user.gedNotifications];
  const isFolderInDepot =
    documentReducer.folder &&
    !!(
      documentReducer.folder.parents &&
      documentReducer.folder.parents[documentReducer.folder.parents.length - 1]
        ._id === "depot"
    );

  const isNewDoc =
    newDocuments.indexOf(document._id) !== -1 || document.nbNewDocuments > 0;

  const processingStatus = status => {
    switch (status) {
      case "processed":
        return "Traité";
      case "toProcess":
        return "Non-traité";
      default:
        return "-";
    }
  };

  // document is in CEGID GED
  const isCegidGed = document.owner === "" || document.owner === "cegid";

  const documentDownloading =
    documentReducer && documentReducer.downloading
      ? documentReducer.downloading[document._id]
      : false;

  const isSizeAboveThreshold1 = document.size >= sizeThreshold1 * 1024;
  const isSizeAboveThreshold2 = document.size >= sizeThreshold2 * 1024;
  const isFolder = document.documentType === "folder";

  return (
    <>
      {isCegidGed && (
        <>
          <Dialog
            id="confirmation"
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            aria-labelledby="dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="dialog-title"
              disableTypography
              sx={{
                color: () => "black"
              }}
            >
              {"Confirmation de téléchargement"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Le téléchargement du fichier <b>{document.name}</b> pourra
                prendre jusqu&apos;à 60 secondes, voulez-vous le télécharger ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmationOpen(false)}
                className="cancel"
              >
                {"Annuler"}
              </Button>
              <Button onClick={() => handleDownload(document)}>
                {"Télécharger"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            id="warning"
            open={warningOpen}
            onClose={() => setWarningOpen(false)}
            aria-labelledby="dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="dialog-title"
              disableTypography
              sx={{
                color: () => "black"
              }}
            >
              {"Fichier trop volumineux"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Le téléchargement du fichier <b>{document.name}</b> est
                impossible en raison de sa taille trop importante. Veuillez-vous
                rapprocher de votre gestionnaire.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setWarningOpen(false)}>{"Ok"}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <TableRow
        key={document._id}
        className="GedFileLine"
        style={{
          cursor: "pointer",
          fontWeight: isNewDoc ? "bold" : "normal",
          background: isNewDoc ? "#fafaff" : ""
        }}
      >
        {selectable && (
          <TableCell component="th" scope="row">
            <Checkbox
              checked={
                (multiSelectionReducer.allSelect &&
                  !_.some(
                    multiSelectionReducer.excludeList,
                    doc => doc._id === document._id
                  )) ||
                _.some(
                  multiSelectionReducer.selectionList,
                  doc => doc._id === document._id
                )
              }
              onClick={() => toggleSelectDocument(document)}
            />
          </TableCell>
        )}
        <TableCell
          align="left"
          component="th"
          scope="row"
          style={{ fontWeight: "inherit", width: "5px", padding: "0px" }}
        >
          {isNewDoc && (
            <b>
              <Badge
                variant="dot"
                color="primary"
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left"
                }}
              />
            </b>
          )}
        </TableCell>
        <TableCell
          align="left"
          className="TypeCell"
          onClick={() => {
            if (isFolder) {
              history.push(`/ged/${document._id}`);
              selectFolder(document._id);
            } else if (!isCegidGed || (isCegidGed && !isSizeAboveThreshold1)) {
              downloadFile(document);
            } else if (isSizeAboveThreshold1 && !isSizeAboveThreshold2) {
              setConfirmationOpen(true);
            } else if (isSizeAboveThreshold2) {
              setWarningOpen(true);
            }
          }}
        >
          <DocumentIcon document={document} />
        </TableCell>
        <TableCell style={{ align: "left", fontWeight: "inherit" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "30px" }}>
              {document.isShared ? <Share /> : ""}
            </div>
            <div>
              <Typography
                component="a"
                className="filename"
                onClick={() => {
                  if (isFolder) {
                    history.push(`/ged/${document._id}`);
                    selectFolder(document._id);
                  } else if (
                    !isCegidGed ||
                    (isCegidGed && !isSizeAboveThreshold1)
                  ) {
                    downloadFile(document);
                  } else if (isSizeAboveThreshold1 && !isSizeAboveThreshold2) {
                    setConfirmationOpen(true);
                  } else if (isSizeAboveThreshold2) {
                    setWarningOpen(true);
                  }
                }}
              >
                {document.name}
                {isFolder && (
                  <span className="opacity-40">{` (${document.totalDocs ||
                    0})`}</span>
                )}
              </Typography>
              {complementaryNameInfo}
              {documentDownloading && (
                <CircularProgress
                  size="1.3rem"
                  style={{ marginLeft: "12px", verticalAlign: "middle" }}
                />
              )}
            </div>
          </div>
        </TableCell>
        {!isCollabSession && (
          <TableCell align="left" className="StarCell">
            {isFavorite(document) ? (
              <Star onClick={() => removeFromFavorites(document)} />
            ) : (
              <StarBorder onClick={() => addToFavorites(document)} />
            )}
          </TableCell>
        )}
        <TableCell
          align="left"
          className="DocumentDate"
          style={{ fontWeight: "inherit" }}
        >
          {isFolder
            ? "-"
            : moment(document.lastUpdate || document.creationDate).format(
                "DD/MM/YYYY"
              )}
        </TableCell>
        <TableCell
          align="left"
          className="DocumentDate"
          style={{ fontWeight: "inherit" }}
        >
          {isFolder ||
          !isCegidPublication ||
          !document.cegidYear ||
          !document.cegidMonth
            ? "-"
            : moment(`${document.cegidYear}/${document.cegidMonth}/01`).format(
                "MMM YYYY"
              )}
        </TableCell>
        <TableCell
          align="left"
          className="DocumentOwner"
          style={{ fontWeight: "inherit" }}
        >
          {document.owner === "public" ? "" : document.owner}
        </TableCell>
        {isFolderInDepot && (
          <TableCell className="DocumentStatus" align="left">
            {document.documentType === "file" &&
              processingStatus(document.processingStatus)}
            {document.documentType !== "file" && "-"}
          </TableCell>
        )}
        <TableCell
          className="DocumentSize"
          align="left"
          style={{ fontWeight: "inherit" }}
        >
          {document.size ? utils.readableBytes(document.size) : null}
        </TableCell>
        <TableCell className="DropdownCell">
          <div>
            <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
              <div>
                <Typography
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                  className={isDropdownOpen ? "DropdownActive" : null}
                >
                  ...
                </Typography>
                <div className="DropdownContainer">
                  {isDropdownOpen ? (
                    <Paper className="DropdownMenu">
                      {isFolder ? (
                        <div
                          className="DropdownOption"
                          onClick={() => {
                            history.push(`/ged/${document._id}`);
                            selectFolder(document._id);
                          }}
                        >
                          <OpenInBrowser />
                          {" Ouvrir"}
                        </div>
                      ) : (
                        <div
                          className="DropdownOption"
                          onClick={() => {
                            history.push(`/document/${document._id}`);
                            setDropdownOpen(false);
                          }}
                        >
                          <Info />
                          {"Informations"}
                        </div>
                      )}
                      {!isCollabSession &&
                        document.documentType !== "folder" &&
                        !(documentReducer.folder || {}).readOnly && (
                          <div
                            className="DropdownOption"
                            onClick={() => {
                              toggleSelectDocument(document);
                              markSelectionAsRead();
                              setDropdownOpen(false);
                            }}
                          >
                            <Drafts />
                            {" Marquer comme lu"}
                          </div>
                        )}

                      {// is collab session and user (collab) is owner
                      // or client session
                      // and folder is not readonly
                      ((isCollabSession && document.isDocumentOwner) ||
                        !isCollabSession) &&
                        !(documentReducer.folder || {}).readOnly && (
                          <>
                            <div
                              className="DropdownOption"
                              onClick={() => {
                                onRename(document);
                                setDropdownOpen(false);
                              }}
                            >
                              <TextFormat />
                              {" Renommer"}
                            </div>
                            <div
                              className="DropdownOption"
                              onClick={() => {
                                onRemove(document);
                                setDropdownOpen(false);
                              }}
                            >
                              <RemoveCircle />
                              {" Supprimer"}
                            </div>
                          </>
                        )}
                      {!isCollabSession &&
                        document.documentType !== "folder" &&
                        document.parentId !== SpecialFolder.PUBLIC_FOLDER && (
                          <div
                            className="DropdownOption"
                            onClick={() => {
                              history.push(
                                `/document/${document._id}?action=share`
                              );
                            }}
                          >
                            <Share />
                            {" Partager"}
                          </div>
                        )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

GedFileLine.propTypes = {
  favoriteReducer: favoriteReducerType.isRequired,
  document: documentType.isRequired,
  multiSelectionReducer: multiSelectionReducerType.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  // selectFolder: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  toggleSelectDocument: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  documentReducer: documentReducerType.isRequired,
  complementaryNameInfo: PropTypes.element,
  selectable: PropTypes.bool,
  user: userType.isRequired,
  selectFolder: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isCollabSession: PropTypes.bool.isRequired,
  markSelectionAsRead: PropTypes.func.isRequired
};

GedFileLine.defaultProps = {
  selectable: false,
  complementaryNameInfo: null
};

const dispatchStateToProps = dispatch => {
  return {
    addToFavorites: doc => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "add",
          favorite: {
            type: doc.documentType === "folder" ? "folder" : "file",
            name: doc.name,
            docId: doc._id
          }
        }
      });
    },
    removeFromFavorites: doc => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "remove",
          favorite: {
            type: "file",
            name: doc.name,
            docId: doc._id
          }
        }
      });
    },
    toggleSelectDocument: doc => {
      dispatch({ type: "TOGGLE_SELECT_DOCUMENT", payload: doc });
    },
    downloadFile: doc => {
      dispatch({ type: DOWNLOAD_FILE, payload: doc });
    },
    markSelectionAsRead: () => dispatch({ type: "MARK_SELECTION_AS_READ" }),
    selectFolder: folderId =>
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: { selectedFolderId: folderId }
      })
  };
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer,
    multiSelectionReducer: state.multiSelectionReducer,
    documentReducer: state.documentReducer,
    isCollabSession: state.authReducer.isCollabSession,
    user: state.authReducer.user
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(GedFileLine));
