import { takeLatest, put, call, select } from "redux-saga/effects";
import { USER_LOGOUT } from "../actions/login-actions";
import ApiManager from "../lib/apiManager";
import { handleError } from "./sagaCommon";

function* onFetchApplicationsSync() {
  try {
    const { tenantId } = yield select(state => state.authReducer.user);
    const response = yield call(ApiManager.callApi, {
      ressource: `/applications?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_APPLICATIONS_SYNC",
      payload: response.data.applications
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du chargement des applications");
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchApplications() {
  yield takeLatest("FETCH_APPLICATIONS", onFetchApplicationsSync);
}

function* onOpenApplicationSync({ payload }) {
  try {
    const { appId } = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    yield call(ApiManager.callApi, {
      ressource: `/applications/${appId}?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
  } catch (error) {
    yield handleError(error, "Erreur lors de l'ouverture de votre application");
  }
}

export function* onOpenApplication() {
  yield takeLatest("OPEN_APP", onOpenApplicationSync);
}
