import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckboxIcon from "@material-ui/icons/CheckBoxOutlined";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { isMobile } from "react-device-detect";
import {
  authReducerType,
  supportReducerType
} from "../../_helpers/appProptypes";
import { isCollab, isCollabOrAdmin } from "../../_helpers/utils";
import { InputLabel, Select } from "@material-ui/core";
import { UPDATE_TICKET_FILTERS } from "../../actions/ticket-actions";

const TicketsToolbar = ({
  history,
  updateFilters,
  supportReducer,
  historyRoute,
  authReducer,
  showFilters = false,
  onChangeFilters,
  filters
}) => {
  const [status, setStatus] = useState({
    opened: supportReducer.status.indexOf("opened") !== -1,
    inProgress: supportReducer.status.indexOf("inProgress") !== -1,
    recentlyClosed: supportReducer.status.indexOf("recentlyClosed") !== -1,
    closed: supportReducer.status.indexOf("closed") !== -1
  });

  const handleChange = async key => {
    const newStatus = { ...status };
    newStatus[key] = !status[key];
    const statusArray = [];
    Object.keys(newStatus).forEach(k => {
      if (newStatus[k]) statusArray.push(k);
    });
    await setStatus(newStatus);
    updateFilters({ status: statusArray, historyRoute });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <div
      className="TicketsToolbar"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row",
        flexFlow: isMobile ? "wrap-reverse" : "initial"
      }}
    >
      <FormControl component="fieldset">
        <FormGroup row>
          <FormLabel
            component="label"
            style={{
              display: "inline-flex",
              alignItems: "center",
              width: isMobile ? "100%" : ""
            }}
          >
            <span
              style={{
                color: "#134594",
                marginRight: "10px"
              }}
            >
              {"Statut :"}
            </span>
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!status.opened}
                checkedIcon={<CheckboxIcon style={{ color: "#134594" }} />}
                onChange={() => handleChange("opened")}
                value="opened"
              />
            }
            label={
              <div style={{ color: "red" }}>
                <b>{"A traiter"}</b>
              </div>
            }
            style={{ width: isMobile ? "100%" : "" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!status.inProgress}
                checkedIcon={<CheckboxIcon style={{ color: "#134594" }} />}
                onChange={() => handleChange("inProgress")}
                value="inProgress"
              />
            }
            label={
              <div style={{ color: "orange" }}>
                <b>{"En cours"}</b>
              </div>
            }
            style={{ width: isMobile ? "100%" : "" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!status.recentlyClosed}
                checkedIcon={<CheckboxIcon style={{ color: "#134594" }} />}
                onChange={() => handleChange("recentlyClosed")}
                value="closed"
              />
            }
            label={
              <div style={{ color: "green" }}>
                <b>{"Clôturé récemment"}</b>
              </div>
            }
            style={{ width: isMobile ? "100%" : "" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={status.closed}
                checkedIcon={<CheckboxIcon style={{ color: "#134594" }} />}
                onChange={() => handleChange("closed")}
                value="closed"
              />
            }
            label={
              <div style={{ color: "green" }}>
                <b>{"Clôturé"}</b>
              </div>
            }
            style={{ width: isMobile ? "100%" : "" }}
          />

          {isCollab(authReducer.user.role) && showFilters && (
            <>
              <FormControl style={{ minWidth: "140px" }}>
                <InputLabel>{"Filtrer par catégorie"}</InputLabel>
                <Select
                  value={`${filters.domain}_${filters.category}`}
                  onChange={event => {
                    const [domain, category] = event.target.value.split("_");
                    onChangeFilters({ category, domain });
                  }}
                >
                  <MenuItem value={"all_all"} style={{ paddingLeft: "24px" }}>
                    {"Toutes les catégories"}
                  </MenuItem>

                  {supportReducer.supports.map(support => {
                    const items = support.categories.map(category => {
                      return (
                        <MenuItem
                          value={`${support._id}_${category}`}
                          key={category}
                          style={{ paddingLeft: "24px" }}
                        >
                          {category}
                        </MenuItem>
                      );
                    });

                    return [
                      <MenuItem
                        key={support.label}
                        value={`${support._id}_all`}
                        style={{
                          fontSize: "0.8rem",
                          opacity: 0.6
                        }}
                      >
                        {support.label.toUpperCase()}
                      </MenuItem>,
                      items
                    ];
                  })}
                </Select>
              </FormControl>
            </>
          )}
        </FormGroup>
      </FormControl>
      <div
        style={
          isMobile
            ? { width: "100%", textAlign: "right", marginBottom: "20px" }
            : {}
        }
      >
        {!isCollabOrAdmin(authReducer.user.role) && (
          <>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {"Créer une demande"}
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push("/assistance/contact/comptable/assistance");
                }}
              >
                {"Comptable"}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push("/assistance/contact/social/assistance");
                }}
              >
                {"Social"}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push("/assistance/contact/juridique/assistance");
                }}
              >
                {"Juridique"}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push("/assistance/contact/autres/assistance");
                }}
              >
                {"Autres"}
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

TicketsToolbar.defaultProps = {
  historyRoute: false,
  showFilters: false,
  onChangeFilters: () => {},
  filters: {
    domain: "all",
    category: "all"
  }
};

TicketsToolbar.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  supportReducer: supportReducerType.isRequired,
  updateFilters: PropTypes.func.isRequired,
  historyRoute: PropTypes.bool,
  authReducer: authReducerType.isRequired,
  showFilters: PropTypes.bool,
  onChangeFilters: PropTypes.func,
  filters: PropTypes.shape({
    domain: PropTypes.string,
    category: PropTypes.string
  })
};

const mapStateToProps = ({ supportReducer, authReducer }) => {
  return {
    supportReducer,
    authReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    updateFilters: payload => dispatch({ type: UPDATE_TICKET_FILTERS, payload })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(TicketsToolbar));
