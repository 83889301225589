import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import CryptoJS from "crypto-js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./Login.scss";

class ValidateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordError: "",
      passwordControlError: "",
      passwordControl: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendForm = this.handleSendForm.bind(this);
  }

  handleInputChange(target, value) {
    this.setState({ [target]: value }, () => {
      this.validateField(target, value);
    });
  }

  validateField(fieldName, value) {
    const { password, passwordControl } = this.state;
    let { passwordError, passwordControlError } = this.state;
    let { passwordValid, passwordControlValid } = false;
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{10,}$/;
    const passwordLengthRegex = /^.{10,}$/;
    const passwordNumberRegex = /\d+/;
    switch (fieldName) {
      case "password":
        passwordValid = passwordRegex.test(value);
        passwordError = passwordValid ? (
          ""
        ) : (
          <div>
            <span>Au moins : </span>
            <span
              style={{
                color: `${passwordLengthRegex.test(value) ? "green" : "red"}`
              }}
            >
              10 caractères{" "}
            </span>
            -
            <span
              style={{
                color: `${passwordNumberRegex.test(value) ? "green" : "red"}`
              }}
            >
              {" "}
              1 chiffre
            </span>
          </div>
        );
        break;
      case "passwordControl":
        passwordControlValid = password === passwordControl;
        passwordControlError = passwordControlValid ? (
          ""
        ) : (
          <span
            style={{
              color: "red"
            }}
          >
            Les mots de passe ne correspondent pas
          </span>
        );
        break;
      default:
        break;
    }
    this.setState({
      passwordError,
      passwordControlError
    });
  }

  handleSendForm() {
    const { password } = this.state;
    if (password.length > 0) {
      this.props.validateAccount(
        password, // CryptoJS.SHA3(password).toString(),
        this.props.match.params.token
      );
    }
  }

  render() {
    const buttonForm = (
      <Button
        fullWidth
        onClick={this.handleSendForm}
        color="secondary"
        disabled={
          !!(
            this.state.passwordError ||
            this.state.passwordControlError ||
            !this.state.password ||
            !this.state.passwordControl
          )
        }
      >
        Valider mon compte
      </Button>
    );

    const label = (
      <Typography component="h3">
        Renseignez les informations ci-dessous pour valider votre compte
      </Typography>
    );
    const formFields = (
      <form noValidate autoComplete="off">
        <Typography component="label">
          {_.capitalize("mot de passe")}
        </Typography>
        <TextField
          id="input-password"
          placeholder="Votre mot de passe"
          type="password"
          margin="normal"
          variant="outlined"
          value={this.state.password}
          fullWidth
          onChange={e => this.handleInputChange("password", e.target.value)}
        />
        <FormHelperText id="name-error-text">
          {this.state.passwordError}
        </FormHelperText>
        <Typography component="label">
          {_.capitalize("confirmation du mot de passe")}
        </Typography>
        <TextField
          id="input-password-control"
          placeholder="Votre mot de passe"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={this.state.passwordControl}
          onChange={e =>
            this.handleInputChange("passwordControl", e.target.value)
          }
        />
        <FormHelperText id="name-error-text">
          {this.state.passwordControlError}
        </FormHelperText>
      </form>
    );

    return (
      <div className="Login">
        <Grid container spacing={3}>
          <Grid item md={6} style={{ margin: "auto" }}>
            <Paper
              style={{
                textAlign: "center",
                padding: "62px 97px",
                backgroundColor: "#E5E8F3"
              }}
            >
              <Typography component="h2">On y est presque...</Typography>
              {label}
              {formFields}
              {buttonForm}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ValidateAccount.propTypes = {
  validateAccount: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

const mapStateToProps = () => {};

const mapDispatchToProps = dispatch => ({
  validateAccount: (password, token) =>
    dispatch({ type: "VALIDATE_ACCOUNT", payload: { password, token } })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ValidateAccount));
