import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Done from "@material-ui/icons/Done";
import { ticketType, authReducerType } from "../../_helpers/appProptypes";

const TicketLine = ({ role, ticket, history, markAsRead, authReducer }) => {
  let newTickets = [];
  if (authReducer.user.ticketNotifications) {
    newTickets = [...authReducer.user.ticketNotifications];
  }
  const SubjectComponent = React.forwardRef((propsRef, ref) => (
    <div
      {...propsRef}
      ref={ref}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {ticket.subject}
    </div>
  ));

  const isNewTicket = newTickets.indexOf(ticket._id) !== -1;

  return (
    <TableRow
      hover
      key={ticket._id}
      onClick={() => history.push(`/assistance/${ticket._id}`)}
      style={{
        cursor: "pointer",
        fontWeight: isNewTicket ? "bold" : "normal",
        background: isNewTicket ? "#fafaff" : ""
      }}
      className="TicketLine"
    >
      <TableCell
        align="left"
        component="th"
        scope="row"
        style={{ fontWeight: "inherit", width: "5px", padding: "0px" }}
      >
        {isNewTicket && (
          <b>
            <Badge
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "center",
                horizontal: "left"
              }}
            />
          </b>
        )}
      </TableCell>
      {["admin", "collab"].includes(role) && (
        <TableCell
          align="left"
          component="th"
          scope="row"
          style={{ fontWeight: "inherit" }}
        >
          {ticket.companyName}
        </TableCell>
      )}
      <TableCell align="center" style={{ fontWeight: "inherit" }}>
        {ticket.field}
      </TableCell>
      <TableCell align="left" style={{ fontWeight: "inherit" }}>
        {ticket.category}
      </TableCell>
      <TableCell align="left" style={{ fontWeight: "inherit" }}>
        <Tooltip title={`${ticket.subject}`}>
          <SubjectComponent />
        </Tooltip>
      </TableCell>
      <TableCell align="center" style={{ fontWeight: "inherit" }}>
        {ticket.status === "closed" && (
          <div style={{ color: "green" }}>
            <b>{"Clôturé"}</b>
          </div>
        )}
        {ticket.status === "opened" && (
          <div style={{ color: "red" }}>
            <b>{"À traiter"}</b>
          </div>
        )}
        {ticket.status === "inProgress" && (
          <div style={{ color: "orange" }}>
            <b>{"En cours"}</b>
          </div>
        )}
      </TableCell>
      <TableCell align="center" style={{ fontWeight: "inherit" }}>
        {moment(ticket.creationDate).format("DD/MM/YYYY HH:mm")}
      </TableCell>
      <TableCell width="3%" align="center">
        {newTickets.includes(ticket._id) ? (
          <Tooltip title="Marquer comme lu">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                markAsRead(ticket._id);
              }}
            >
              <Done />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </TableCell>
    </TableRow>
  );
};

TicketLine.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  role: PropTypes.string.isRequired,
  ticket: ticketType.isRequired,
  markAsRead: PropTypes.func.isRequired,
  authReducer: authReducerType.isRequired
};

TicketLine.defaultProps = {};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markAsRead: ticketId =>
      dispatch({
        type: "MARK_TICKET_AS_READ",
        payload: { ticketId }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketLine));
