import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import utils from "../../_helpers/utils";

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.user
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.debUpdateAccount = _.debounce(this.updateAccount, 500);
    this.handleSwitchMission = this.handleSwitchMission.bind(this);
    this.handleSwitchApp = this.handleSwitchApp.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (!state.account && props.user) ||
      (state.account &&
        ((props.user || {})._id !== (state || {}).account._id ||
          (props.user || {}).version !== (state || {}).account.version))
    ) {
      return { account: props.user };
    }
    return {};
  }

  updateAccount() {
    this.props.updateAccount({ ...this.state.account });
  }

  handleChange(accessor, value, updateAccount = true) {
    let nextState;

    if (updateAccount) {
      nextState = {
        account: {
          ...this.state.account,
          [accessor]: value
        }
      };
      // removed : debounce if accessor is not mission and no mission
      // if (!(accessor === "missions" && value.length === 0))
      this.debUpdateAccount();
    } else {
      nextState = {
        [accessor]: value
      };
    }

    this.setState(nextState);
  }

  handleSwitchMission(missionId) {
    let { missions } = this.state.account;
    let newMissions;
    if (!missions) {
      missions = [];
    }
    if (missions.indexOf(missionId) === -1) {
      newMissions = [...missions, missionId];
    } else {
      newMissions = missions.filter(m => m !== missionId);
    }
    this.handleChange("missions", newMissions);
  }

  handleSwitchApp(appId) {
    let { applications } = this.state.account;
    let newApps;
    if (!applications) {
      applications = [];
    }
    if (applications.indexOf(appId) === -1) {
      newApps = [...applications, appId];
    } else {
      newApps = applications.filter(a => a !== appId);
    }
    this.handleChange("applications", newApps);
  }

  render() {
    const { account } = this.state;

    const { allMissions, allApps, currentUserEmail } = this.props;
    const {
      firstname,
      email,
      lastname,
      missions,
      applications,
      role
    } = account;
    if (!account)
      return <div className="settings__users__details">no account</div>;

    const roleName = utils.getRole(role);

    const editDisabled = role === "tenantAdmin" || email === currentUserEmail;

    return (
      <div className="settings__users__details">
        <div style={{ maxWidth: "800px", padding: "0 15px" }}>
          <div className="flex">
            <h1 className="capitalize">Informations du compte</h1>
          </div>

          <div className="settings__users__details-form">
            <div className="settings__users__details-form-inputs">
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ flexGrow: 1 }}
              >
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="Prénom"
                    disabled={editDisabled}
                    fullWidth
                    autoComplete=""
                    value={firstname || ""}
                    onChange={event =>
                      this.handleChange("firstname", event.target.value)
                    }
                  />
                </Grid>
                <Grid itm xs={12} sm={1} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="lastname"
                    name="lastname"
                    label="Nom"
                    disabled={editDisabled}
                    fullWidth
                    autoComplete=""
                    value={lastname || ""}
                    onChange={event =>
                      this.handleChange("lastname", event.target.value)
                    }
                  />
                </Grid>
                <Grid itm xs={12} sm={1} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="username"
                    name="username"
                    label="Adresse e-mail"
                    disabled={editDisabled}
                    fullWidth
                    value={email}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid itm xs={12} sm={1} />
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="role"
                    name="role"
                    label="Rôle"
                    disabled
                    fullWidth
                    autoComplete=""
                    value={roleName || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={1} />
                <Grid item xs={12} sm={12}>
                  {" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Missions</FormLabel>
                    <FormGroup row>
                      {allMissions.map(mission => (
                        <FormControlLabel
                          key={mission._id}
                          control={
                            <Checkbox
                              disabled={editDisabled}
                              checked={
                                missions.findIndex(m => m === mission._id) !==
                                  -1 || role === "tenantAdmin"
                              }
                              onChange={() => {
                                this.handleSwitchMission(mission._id);
                              }}
                              value={mission._id}
                            />
                          }
                          label={
                            <div
                              className="missionChip"
                              style={{
                                color: mission.color,
                                border: `1px solid ${mission.color}`
                              }}
                            >
                              {mission.label}
                            </div>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
                {allApps.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Applications</FormLabel>
                      <FormGroup row>
                        {allApps.map(app => (
                          <FormControlLabel
                            key={app._id}
                            control={
                              <Checkbox
                                disabled={editDisabled}
                                checked={
                                  !applications
                                    ? false
                                    : applications.findIndex(
                                        a => a === app._id
                                      ) !== -1 || role === "tenantAdmin"
                                }
                                onChange={() => {
                                  this.handleSwitchApp(app._id);
                                }}
                                value={app._id}
                              />
                            }
                            label={<div className="appChip">{app.name}</div>}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  user: PropTypes.shape({}).isRequired,
  updateAccount: PropTypes.func.isRequired,
  allMissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allApps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentUserEmail: PropTypes.string.isRequired
};

export default UserSettings;
