import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { isMobile } from "react-device-detect";
import PageTitle from "../PageTitle/PageTitle";
import ApplicationItem from "./ApplicationItem";
import Loader from "../Loader/Loader";

import { authReducerType } from "../../_helpers/appProptypes";

const Applications = props => {
  const { authReducer, openApp } = props;
  const { user } = authReducer;
  let { tenantId } = user;

  if (!tenantId) tenantId = user.tenants[0]._id;

  const { applications } =
    user.role === "collab" && !(authReducer || {}).isCollabSession
      ? user
      : user.applications.find(app => app.tenantId === tenantId) || [];

  return (
    <div>
      <PageTitle title="Applications" />
      {authReducer.user && (!applications || applications.length === 0) ? (
        // eslint-disable-next-line react/jsx-indent
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh"
          }}
        >
          <Typography variant="h4" component="h4">
            {"Vous n'avez accès à aucune application pour le moment."}
          </Typography>
          <Typography component="p">
            {
              "Si vous souhaitez accéder à des applications du portail, veuillez contacter HDM."
            }
          </Typography>
        </Paper>
      ) : (
        ""
      )}
      {authReducer.user ? (
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "",
            alignItems: "center"
          }}
        >
          {(applications || []).map(
            application =>
              application.url &&
              application.url !== "" && (
                <Grid
                  item
                  key={application._id}
                  md={3}
                  onClick={() => {
                    openApp(application._id);
                    if (application.openingType === "blank")
                      window.open(application.url, "_blank");
                    else props.history.push(`/applications/${application._id}`);
                  }}
                  style={{ width: isMobile ? "80%" : "" }}
                >
                  <ApplicationItem application={application} />
                </Grid>
              )
          )}
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
};

Applications.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  authReducer: authReducerType.isRequired,
  openApp: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openApp: appId => {
      dispatch({ type: "OPEN_APP", payload: { appId } });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Applications));
