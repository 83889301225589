/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "underscore";
import { isMobile } from "react-device-detect";
import PageTitle from "../PageTitle/PageTitle";
import LinkCategory from "./LinkCategory";

import "./Links.scss";

class Links extends Component {
  componentDidMount() {
    this.props.fetchLinks();
  }

  render() {
    const { linkReducer } = this.props;

    const listCategories = linkReducer.links.map(link => link.category);
    const uniqListCategories = _.uniq(listCategories);
    return (
      <div
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        className="Links"
      >
        <PageTitle title="Liens utiles" />

        <Grid
          container
          spacing={2}
          style={{ flex: "1 1 auto", paddingBottom: "70px" }}
        >
          {uniqListCategories.map(category => (
            <Grid
              sm={12}
              md={4}
              item
              key={category}
              style={isMobile ? { width: "100%" } : {}}
            >
              <LinkCategory category={category} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

Links.propTypes = {
  linkReducer: PropTypes.object.isRequired,
  fetchLinks: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    linkReducer: state.linkReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLinks: () => dispatch({ type: "FETCH_HELP_LINKS" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Links);
