import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Edit from "@material-ui/icons/Edit";
import Save from "@material-ui/icons/Save";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import DeleteForever from "@material-ui/icons/DeleteForeverOutlined";
import Loader from "../Loader/Loader";
import LinkDeleteModal from "./LinkDeleteModal";
import { downloadLinkReducerType } from "../../_helpers/appProptypes";
import "./LinkDetails.scss";

const LinkDetails = props => {
  const {
    downloadLinkReducer,
    fetchLink,
    updateLink,
    deleteLink,
    history
  } = props;
  const { linkId } = props.match.params;
  const { downloadLink } = downloadLinkReducer;

  const [editing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [enablePassword, setEnablePassword] = useState(
    downloadLink ? downloadLink.hasPassword : false
  );
  const [password, setPassword] = useState(null);
  const [maxDownloads, setMaxDownloads] = useState(
    downloadLink ? downloadLink.maxDownloads : null
  );

  useEffect(() => {
    fetchLink(linkId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (downloadLinkReducer.isLoading || !downloadLink) {
    return <Loader />;
  }

  const url = `${process.env.REACT_APP_PUBLIC_URL}/document/partage/${downloadLink._id}`;
  return (
    <div
      className="link__details"
      style={{
        background: "white",
        padding: "5px 15px 30px 15px",
        height: "85vh"
      }}
    >
      <div
        className="flex"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "90px"
        }}
      >
        <h1 style={{ color: "#134594" }}>
          {"Informations du lien de téléchargement"}
        </h1>
        <div>
          <Tooltip
            title={
              editing ? "Sauvegarder les modifications" : "Modifier les options"
            }
          >
            <IconButton
              onClick={() => {
                if (!editing) {
                  setEditing(true);
                } else {
                  updateLink(linkId, password, enablePassword, maxDownloads);
                  setEditing(false);
                }
              }}
            >
              {editing ? <Save /> : <Edit />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer ce lien">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <DeleteForever />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <br />
      <div className="link__details-form">
        <Grid container direction="row" spacing={5} style={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={10}>
            <TextField
              id="name"
              name="name"
              label="Nom du document"
              fullWidth
              value={downloadLink.documentName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={5}>
            <TextField
              id="creationDate"
              name="creationDate"
              label="Date de création"
              fullWidth
              value={moment(downloadLink.creationDate).format(
                "DD/MM/YYYY HH:mm"
              )}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="downloadsCount"
              name="downloadsCount"
              label="Nombre de téléchargements"
              fullWidth
              value={downloadLink.downloadsCount}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={5}>
            <TextField
              id="owner"
              name="owner"
              label="Auteur"
              fullWidth
              value={downloadLink.author}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="maxDownloads"
              name="maxDownloads"
              label="Nombre de téléchargements maximum"
              fullWidth
              type="number"
              value={maxDownloads}
              onChange={e => setMaxDownloads(e.target.value)}
              disabled={!editing}
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={10}>
            <TextField
              id="link"
              name="link"
              label="Lien de téléchargement"
              fullWidth
              value={url}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={5}>
            <FormControlLabel
              label={
                (downloadLink.hasPassword && enablePassword) ||
                (!downloadLink.hasPassword && enablePassword)
                  ? "Protégé par un mot de passe"
                  : "Non protégé"
              }
              labelPlacement="end"
              control={
                <Checkbox
                  disabled={!editing}
                  checked={downloadLink.hasPassword && enablePassword}
                  onChange={() => setEnablePassword(!enablePassword)}
                />
              }
            />
          </Grid>
          {!downloadLink.hasPassword && enablePassword && (
            <Grid item xs={12} sm={5}>
              <TextField
                id="password"
                name="password"
                type="password"
                label="Mot de passe"
                fullWidth
                value={password}
                disabled={!editing}
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={1} />
        </Grid>
      </div>
      <div
        id="back"
        style={{
          position: "absolute",
          bottom: "10vh",
          color: "rgb(30, 42, 126)",
          zIndex: "200",
          paddingRight: "5px"
        }}
      >
        <ButtonBase onClick={() => history.push("/partage")}>
          <ArrowLeft />
          Revenir à la liste
        </ButtonBase>
      </div>
      <LinkDeleteModal
        isOpen={open}
        deleteLink={() => {
          deleteLink(linkId);
          history.push("/partage");
        }}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

LinkDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ linkId: PropTypes.string })
  }).isRequired,
  downloadLinkReducer: downloadLinkReducerType.isRequired,
  fetchLink: PropTypes.func.isRequired,
  updateLink: PropTypes.func.isRequired,
  deleteLink: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    downloadLinkReducer: state.downloadLinkReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLink: linkId =>
      dispatch({ type: "FETCH_DOWNLOAD_LINK", payload: linkId }),
    updateLink: (linkId, password, enablePassword, maxDownloads) => {
      dispatch({
        type: "UPDATE_DOWNLOAD_LINK",
        payload: { linkId, password, enablePassword, maxDownloads }
      });
    },
    deleteLink: linkId =>
      dispatch({ type: "DELETE_DOWNLOAD_LINK", payload: linkId })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LinkDetails));
