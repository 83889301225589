const initialState = {
  applications: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_APPLICATIONS_SYNC": {
      const applications = action.payload;
      return {
        ...state,
        isLoading: false,
        applications
      };
    }

    default:
      return state;
  }
};

export default reducer;
