/* eslint-disable react/forbid-prop-types */

import React from "react";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import HelpButton from "../HelpButton/HelpButton";
import HomePaper from "./HomePaper";
import { authReducerType } from "../../_helpers/appProptypes";
import "./Home.scss";
import { FETCH_SUPPORTS } from "../../actions/ticket-actions";

const NB_DISPLAYED_EVENTS = isMobile ? 3 : 6;
const eventsPerDay = isMobile ? 1 : 2;

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    minWidth: 150,
    maxWidth: 320,
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const Home = props => {
  const [tooltipOpen, setTooltipOpen] = React.useState({});
  const {
    fetchSupports,
    fetchEvents,
    authReducer,
    eventReducer,
    supportReducer,
    isCollabSession
  } = props;

  const { user = {} } = authReducer;

  React.useEffect(() => {
    fetchSupports();
    if (user) {
      fetchEvents(user.tenantId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducer]);

  const stepClick = date => {
    const newTooltipOpen = { ...tooltipOpen };
    newTooltipOpen[date] =
      newTooltipOpen[date] === undefined ? true : !newTooltipOpen[date];
    setTooltipOpen(newTooltipOpen);
  };

  if (!user) {
    return <></>;
  }

  const eventColors = [
    "#080066",
    "#2E1F91",
    "#443EAD",
    "#5A5CC8",
    "#808BF4",
    "#96BAFF"
  ];
  let eventIndex = 0;

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Grid container spacing={3} className="Home">
        <Grid
          container
          item
          sm={12}
          md={authReducer.user.role === "admin" ? 12 : 9}
          direction="column"
        >
          <HomePaper
            title="Échéances"
            className="flex-grow-0"
            style={{ height: "100%" }}
          >
            <Stepper
              alternativeLabel
              nonLinear={false}
              activeStep={eventReducer.events.length}
            >
              {eventReducer.events
                .slice(0, NB_DISPLAYED_EVENTS)
                .map(({ date, events }, index) => {
                  const eventColor = eventColors[eventIndex];
                  eventIndex += 1;
                  return (
                    // es-lint doesn't handle well the following part
                    // it keeps reformating the code on each save
                    // preventing commit as well
                    /* eslint-disable */

                    <HtmlTooltip
                      key={index}
                      title={
                        <div>
                          <div>
                            <b>{moment(date).format("DD/MM/YYYY")} : </b>
                          </div>
                          <ul style={{ paddingLeft: "10px" }}>
                            {events.map((e, indexEvent) => (
                              <li key={indexEvent}>{e.description}</li>
                            ))}
                          </ul>
                        </div>
                      }
                      open={tooltipOpen[date] || false}
                    >
                      <Step key={date}>
                        <StepLabel
                          icon={moment(date).format("DD/MM/YYYY")}
                          onClick={(e) => {
                            stepClick(date);
                          }}
                          StepIconComponent={(props) => (
                            <div
                              className="step"
                              style={{ backgroundColor: eventColor }}
                            >
                              {props.icon}
                            </div>
                          )}
                        >
                          {events
                            .slice(0, eventsPerDay)
                            .map((e, indexEvent) => (
                              <div key={indexEvent}>{e.label}</div>
                            ))}
                          {events.length > eventsPerDay ? (
                            <div className="step-more">
                              {`et ${events.length - eventsPerDay} autre(s)`}
                            </div>
                          ) : (
                            ""
                          )}
                        </StepLabel>
                      </Step>
                    </HtmlTooltip>
                  );
                })}
            </Stepper>
          </HomePaper>
        </Grid>

        <Grid
          style={{ display: "flex" }}
          item
          md={3}
          sm={12}
          container
          direction="column"
        >
          {authReducer.user.role !== "admin" && (
            <HomePaper title="Assistance">
              <div className="assistance-wrapper">
                <div className="assistance-container">
                  {supportReducer.supports.map(({ _id, label }) => (
                    <HelpButton
                      key={_id}
                      label={label}
                      supportId={_id}
                      disabled={isCollabSession}
                    />
                  ))}
                </div>
              </div>
            </HomePaper>
          )}
          {/* <News /> */}
        </Grid>
      </Grid>

      <div style={{ marginTop: "48px", flexGrow: "1" }}>
        <Grid container item style={{ height: "100%" }}>
          <iframe
            scrolling="no"
            src=" https://hdm.businesscomm.fr/"
            frameBorder="0"
            style={{ width: "100%" }}
            className="clipped-iframe"
          ></iframe>
        </Grid>
      </div>
    </div>
  );
};

Home.propTypes = {
  authReducer: authReducerType.isRequired,
  eventReducer: PropTypes.object.isRequired,
  supportReducer: PropTypes.object.isRequired,
  fetchSupports: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  isCollabSession: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    eventReducer: state.eventReducer,
    supportReducer: state.supportReducer,
    isCollabSession: state.authReducer.isCollabSession,
  };
};

const dispatchStateToProps = (dispatch) => {
  return {
    fetchSupports: () => dispatch({ type: FETCH_SUPPORTS }),
    fetchEvents: () => dispatch({ type: "FETCH_EVENTS" }),
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(Home);
