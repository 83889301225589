import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Info from "@material-ui/icons/Info";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import TextFormat from "@material-ui/icons/TextFormat";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Share from "@material-ui/icons/Share";
import Drafts from "@material-ui/icons/DraftsSharp";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import _ from "underscore";
import { connect } from "react-redux";
import "./GedFileLine.scss";
import moment from "moment";
import DocumentIcon from "../DocumentIcon/DocumentIcon";
import {
  documentType,
  multiSelectionReducerType,
  documentReducerType,
  userType
} from "../../_helpers/appProptypes";
import utils from "../../_helpers/utils";
import { DOWNLOAD_FILE, FETCH_DOCUMENTS } from "../../actions/document-actions";

const GedFileLineMobile = ({
  document,
  selectFolder,
  onRemove,
  toggleSelectDocument,
  onRename,
  downloadFile,
  multiSelectionReducer,
  documentReducer,
  selectable,
  complementaryNameInfo,
  history,
  isCollabSession,
  user,
  markSelectionAsRead
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  let newDocuments = [];
  if (user.gedNotifications) newDocuments = user.gedNotifications;
  const isNewDoc = newDocuments.indexOf(document._id) !== -1;

  const isFolder = document.documentType === "folder";

  return (
    <TableRow
      key={document._id}
      className="GedFileLine"
      style={{
        cursor: "pointer",
        fontWeight: isNewDoc ? "bold" : "normal",
        background: isNewDoc ? "#fafaff" : ""
      }}
    >
      {selectable && (
        <TableCell component="th" scope="row">
          <Checkbox
            checked={
              (multiSelectionReducer.allSelect &&
                !_.some(
                  multiSelectionReducer.excludeList,
                  doc => doc._id === document._id
                )) ||
              _.some(
                multiSelectionReducer.selectionList,
                doc => doc._id === document._id
              )
            }
            onClick={() => toggleSelectDocument(document)}
          />
        </TableCell>
      )}

      <TableCell style={{ align: "left", fontWeight: "inherit" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            {isNewDoc && (
              <b>
                <Badge
                  variant="dot"
                  color="primary"
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left"
                  }}
                />
              </b>
            )}
            <DocumentIcon document={document} />{" "}
            <Typography
              component="a"
              className="filename"
              onClick={() =>
                isFolder
                  ? history.push(`/ged/${document._id}`)
                  : downloadFile(document)
              }
            >
              {document.name}
            </Typography>
          </div>
          <div>{complementaryNameInfo}</div>
          <div>
            {isFolder
              ? "-"
              : moment(document.lastUpdate || document.creationDate).format(
                  "DD/MM/YYYY"
                )}
          </div>
          <div>{document.size ? utils.readableBytes(document.size) : null}</div>
        </div>
      </TableCell>

      <TableCell className="DropdownCell">
        <div>
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <div>
              <Typography
                onClick={() => setDropdownOpen(!isDropdownOpen)}
                className={isDropdownOpen ? "DropdownActive" : null}
              >
                {"..."}
              </Typography>
              <div className="DropdownContainer">
                {isDropdownOpen && (
                  <Paper className="DropdownMenu">
                    {isFolder ? (
                      <div
                        className="DropdownOption"
                        onClick={() => {
                          selectFolder(document);
                          setDropdownOpen(false);
                        }}
                      >
                        <OpenInBrowser />
                        {" Ouvrir"}
                      </div>
                    ) : (
                      <div
                        className="DropdownOption"
                        onClick={() => {
                          history.push(`/document/${document._id}`);
                          setDropdownOpen(false);
                        }}
                      >
                        <Info />
                        {"Informations"}
                      </div>
                    )}
                    {!isCollabSession &&
                    (documentReducer.folder || {}).readOnly ? (
                      <div
                        className="DropdownOption"
                        onClick={() => {
                          toggleSelectDocument(document);
                          markSelectionAsRead();
                          setDropdownOpen(false);
                        }}
                      >
                        <Drafts />
                        {" Marquer comme lu"}
                      </div>
                    ) : null}
                    {!(
                      (!isCollabSession &&
                        !(documentReducer.folder || {}).readOnly) ||
                      (isCollabSession &&
                        (documentReducer.folder || {}).readOnly)
                    ) ? null : (
                      <>
                        <div
                          className="DropdownOption"
                          onClick={() => {
                            onRename(document);
                            setDropdownOpen(false);
                          }}
                        >
                          <TextFormat />
                          {" Renommer"}
                        </div>
                        <div
                          className="DropdownOption"
                          onClick={() => {
                            onRemove(document);
                            setDropdownOpen(false);
                          }}
                        >
                          <RemoveCircle />
                          {" Supprimer"}
                        </div>
                      </>
                    )}
                    {!isCollabSession && !isFolder && (
                      <div
                        className="DropdownOption"
                        onClick={() => {
                          history.push(
                            `/document/${document._id}?action=share`
                          );
                        }}
                      >
                        <Share />
                        {" Partager"}
                      </div>
                    )}
                  </Paper>
                )}
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </TableCell>
    </TableRow>
  );
};

GedFileLineMobile.propTypes = {
  document: documentType.isRequired,
  multiSelectionReducer: multiSelectionReducerType.isRequired,
  selectFolder: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  toggleSelectDocument: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  documentReducer: documentReducerType.isRequired,
  complementaryNameInfo: PropTypes.element,
  selectable: PropTypes.bool,
  user: userType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isCollabSession: PropTypes.bool.isRequired,
  markSelectionAsRead: PropTypes.func.isRequired
};

GedFileLineMobile.defaultProps = {
  selectable: false,
  complementaryNameInfo: null
};

const dispatchStateToProps = dispatch => {
  return {
    addToFavorites: (doc, tenantId) =>
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "add",
          favorite: {
            type: doc.documentType === "folder" ? "folder" : "file",
            name: doc.name,
            docId: doc._id
          },
          tenantId
        }
      }),
    removeFromFavorites: (doc, tenantId) =>
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "remove",
          favorite: {
            type: "file",
            name: doc.name,
            docId: doc._id
          },
          tenantId
        }
      }),
    selectFolder: doc =>
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: { selectedFolderId: doc._id }
      }),
    toggleSelectDocument: doc =>
      dispatch({ type: "TOGGLE_SELECT_DOCUMENT", payload: doc }),
    downloadFile: doc => dispatch({ type: DOWNLOAD_FILE, payload: doc }),
    markSelectionAsRead: () => dispatch({ type: "MARK_SELECTION_AS_READ" })
  };
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer,
    multiSelectionReducer: state.multiSelectionReducer,
    documentReducer: state.documentReducer,
    isCollabSession: state.authReducer.isCollabSession,
    user: state.authReducer.user
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(GedFileLineMobile));
