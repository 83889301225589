import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConnectedRouter } from "connected-react-router";
import MomentUtils from "@date-io/moment";
import { Provider } from "react-redux";
import moment from "moment";
import configureStore, { history } from "./configureStore";
import Routes from "./Routes";
import { serviceWorkerUnregister } from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "react-query";

import "moment/locale/fr";

moment.locale("fr");

const store = configureStore();

const theme = createTheme({
  palette: {
    primary: { main: "#134594" },
    secondary: { main: "#586EE5" }
  },
  status: {
    danger: "orange"
  },
  typography: {
    fontFamily: [
      '"Helvetica"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    h2: {
      fontSize: "35px",
      color: "#134594",
      fontWeight: "inherit"
    },
    h3: {
      color: "#134594",
      fontSize: "32px"
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        backgroundColor: "#134594 !important",
        color: "white !important",
        borderRadius: "29px !important",
        border: "56px",
        padding: "8px 15px",
        boxShadow: "3px 6px 9px #00000029",
        textTransform: "inherit"
      }
    },

    MuiButtonLabel: {
      text: {
        color: "white"
      }
    }
  }
});

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
            <Routes />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// remove serviceWorker call as we don't use serviceWorker at all
// serviceWorker.unregister();
serviceWorkerUnregister();
