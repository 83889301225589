/*  eslint react/forbid-prop-types: "off" */

import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageTitle from "../PageTitle/PageTitle";
import DashboardItem from "./DashboardItem";
import Loader from "../Loader/Loader";

import { dashboardReducerType } from "../../_helpers/appProptypes";

const Dashboard = ({
  dashboardReducer,
  updateDashboards,
  openDashboard,
  history
}) => {
  useEffect(() => {
    updateDashboards();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dashboardReducer || !dashboardReducer.dashboards) return <Loader />;

  return (
    <>
      <PageTitle title="Tableaux de bord" />
      <Grid container spacing={4}>
        {dashboardReducer.dashboards
          .sort((a, b) => {
            return a.position - b.position;
          })
          .map(dashboard => (
            <Grid
              key={dashboard._id}
              item
              xs={3}
              onClick={() => {
                openDashboard(dashboard._id);
                history.push(`/tableau-de-bord/${dashboard._id}`);
              }}
            >
              <DashboardItem dashboard={dashboard} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    dashboardReducer: state.dashboardReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    updateDashboards: () => dispatch({ type: "FETCH_DASHBOARDS" }),
    openDashboard: dashboardId => {
      dispatch({ type: "OPEN_DASHBOARD", payload: { dashboardId } });
    }
  };
};

Dashboard.propTypes = {
  history: PropTypes.object.isRequired,
  dashboardReducer: dashboardReducerType.isRequired,
  updateDashboards: PropTypes.func.isRequired,
  openDashboard: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(Dashboard));
