import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import Loader from "../Loader/Loader";
import PageTitle from "../PageTitle/PageTitle";
import DocumentsToolbar from "./DocumentsToolbar";
import DocumentsListing from "./DocumentsListing";

import { authReducerType } from "../../_helpers/appProptypes";

import "./Portfolio.scss";
import { useEffect } from "react";
import { FETCH_COLLAB_DOCS_ACTION } from "../../actions/document-actions";

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

const Documents = ({
  authReducer,
  classes,
  searchTerm,
  searchReducer,
  fetchCollabDocuments
}) => {
  useEffect(() => {
    if (!searchReducer.isActive) fetchCollabDocuments();
  }, [searchReducer, fetchCollabDocuments]);

  const defaultValue = "";

  if (!authReducer.user) return <Loader />;
  return (
    <div className="Documents">
      <PageTitle title="Documents à traiter">
        <Paper className={classes.root} style={{ marginLeft: "16px" }}>
          <IconButton aria-label="menu" className={classes.icon} disabled>
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Rechercher"
            defaultValue={defaultValue}
            inputProps={{ "aria-label": "Rechercher" }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                const term = (e.target.value || "").replace(/\*/g, ".*");
                searchTerm(term);
              }
            }}
          />
        </Paper>
      </PageTitle>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Paper>
            <div className="filesContainer" style={{ overflow: "auto" }}>
              <DocumentsToolbar />
              <DocumentsListing />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Documents.propTypes = {
  searchTerm: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.shape({}),
    icon: PropTypes.shape({}),
    input: PropTypes.shape({})
  }).isRequired,
  authReducer: authReducerType.isRequired,
  searchReducer: PropTypes.shape({ isActive: PropTypes.bool }).isRequired,
  fetchCollabDocuments: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    searchReducer: state.searchReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchCollabDocuments: () => dispatch({ type: FETCH_COLLAB_DOCS_ACTION }),
    searchTerm: searchTerm =>
      dispatch({ type: "SEARCH_DOCUMENTS", payload: searchTerm })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withStyles(theme)(Documents));
