/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";

import "./GedBreadCrumb.scss";

const GedPagination = props => {
  const { documentsCount, page, pageSize, sort, order } = props.documentReducer;
  return (
    <TableFooter>
      <TableRow>
        {documentsCount > pageSize ? (
          <TablePagination
            onChangePage={(e, pageNumber) => {
              props.fetchNewPage(pageNumber, sort, order);
            }}
            page={page}
            rowsPerPage={pageSize}
            count={documentsCount}
            labelRowsPerPage="documents par page : "
            rowsPerPageOptions={[10]}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} sur ${count}`;
            }}
          />
        ) : null}
      </TableRow>
    </TableFooter>
  );
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

GedPagination.propTypes = {
  documentReducer: PropTypes.object.isRequired,
  fetchNewPage: PropTypes.func.isRequired
};

const dispatchStateToProps = dispatch => {
  return {
    fetchNewPage: (pageNumber, sort, order) => {
      dispatch({
        type: "FETCH_NEW_PAGE",
        payload: {
          page: pageNumber,
          sort,
          order
        }
      });
    }
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(GedPagination);
