import React from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { isMobile } from "react-device-detect";
import Loader from "../Loader/Loader";
import { useState } from "react";
import PublicDocsFileLine from "./PublicDocsFileLine";
import { TableHead } from "@material-ui/core";
import GedRenameModal from "../Ged/GedRenameModal";
import PropTypes from "prop-types";

import "./PublicDocsFilesListing.scss";
import { useQuery } from "react-query";
import ApiManager from "../../lib/apiManager";

const PublicDocsFilesListing = ({ documents }) => {
  const [loading] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const getPublicDocsQuery = useQuery("publicDocs", ApiManager.getPublicDocs);

  const onRenameDocument = async (document, newDocumentName) => {
    await ApiManager.callApi({
      ressource: `/documents/${document._id}/updateMeta`,
      method: "POST",
      isAuthenticated: true,
      data: {
        name: newDocumentName,
        tenantId: "public"
      }
    });

    getPublicDocsQuery.refetch();
  };

  const MyTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <span>{"Nom"}</span>
          </TableCell>
          {!isMobile && (
            <>
              <TableCell align="left">
                <span>{"Déposé le"}</span>
              </TableCell>
              <TableCell align="left">{"Taille"}</TableCell>
            </>
          )}
          <TableCell align="right" />
        </TableRow>
      </TableHead>
    );
  };

  if (loading) {
    return (
      <div className="PublicDocsFilesListing">
        <Table>
          <MyTableHead />
        </Table>
        <Loader />
      </div>
    );
  }

  return (
    <div className="PublicDocsFilesListing">
      {!documents.length ? (
        <>
          <Table>
            <MyTableHead />
          </Table>
          <div className="emptyFolder">{"Ce dossier est vide"}</div>
        </>
      ) : (
        <Table stickyHeader>
          <MyTableHead />
          <TableBody>
            {documents.map((document, index) => {
              return (
                <PublicDocsFileLine
                  key={index}
                  document={document}
                  onRename={doc => {
                    setSelectedDocument(doc);
                    setRenameModalOpen(true);
                  }}
                />
              );
            })}
          </TableBody>
        </Table>
      )}

      {isRenameModalOpen && (
        <GedRenameModal
          isOpen={isRenameModalOpen}
          onClose={() => setRenameModalOpen(false)}
          onRenameDocument={onRenameDocument}
          document={selectedDocument}
        />
      )}
    </div>
  );
};

PublicDocsFilesListing.propTypes = {
  documents: PropTypes.array
};

export default PublicDocsFilesListing;
