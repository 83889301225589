import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Share from "@material-ui/icons/Share";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
import PageTitle from "../PageTitle/PageTitle";
import "./Ged.scss";
import GedCategory from "./GedCategory";
import GedBreadCrumb from "./GedBreadCrumb";
import GedToolbar from "./GedToolbar";
import GedFilesListing from "./GedFileListing";
import Loader from "../Loader/Loader";
import GedSearchResults from "./GedSearchResults";
import {
  authReducerType,
  documentReducerType
} from "../../_helpers/appProptypes";
import clsx from "clsx";
import GedRetentionInfo from "./GedRetentionInfo";
import { FETCH_DOCUMENTS } from "../../actions/document-actions";
import { isAdmin, isCollab, isCollabOrAdmin } from "../../_helpers/utils";
import SpecialFolder from "../../enums/special-folder.enum";

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: isMobile ? "100%" : 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

class Ged extends Component {
  constructor(props) {
    super(props);

    this.search = term => props.searchTerm(term, 200);

    this.debSearch = debounce(term => {
      this.search(term);
    }, 500);
  }

  componentDidMount() {
    const { searchReducer, fetchRootFolders, fetchDocuments } = this.props;
    const { folderId } = this.props.match.params;
    if (folderId) this.props.fetchDocuments(folderId);
    else if (!searchReducer || !searchReducer.isActive) {
      fetchDocuments();
    }
    fetchRootFolders();
  }

  render() {
    const {
      authReducer,
      documentReducer,
      searchReducer,
      classes,
      history,
      markAllAsRead
    } = this.props;

    const { selectedFolderId } = documentReducer;

    if (!authReducer.user) {
      return <Loader />;
    }

    const privateFolders = documentReducer.rootFolders.map(doc => ({
      _id: doc.privateFolderId,
      label: doc.label,
      nbNewDocs: doc[`${doc.privateFolderId}_nbNewDocs`]
    }));

    const echangeFolders = documentReducer.rootFolders.map(doc => ({
      _id: doc.depotFolderId,
      label: doc.label,
      nbNewDocs: doc[`${doc.depotFolderId}_nbNewDocs`]
    }));

    const publicationFolders = documentReducer.rootFolders.map(doc => ({
      _id: doc.publicationFolderId,
      label: doc.label,
      nbNewDocs: doc[`${doc.publicationFolderId}_nbNewDocs`]
    }));

    const publicFolder = {
      _id: SpecialFolder.PUBLIC_FOLDER,
      label: "Public",
      nbNewDocs:
        documentReducer[`${SpecialFolder.PUBLIC_FOLDER}_nbNewDocs`] || 0
    };

    return (
      <div
        className="Ged"
        style={{
          height: isMobile ? "auto" : "calc(100vh - 70px)",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <PageTitle title="GED">
          <>
            <div className="flex justify-between items-center gap-4">
              <Paper className={classes.root}>
                <IconButton
                  aria-label="menu"
                  className={clsx(classes.icon, "py-0")}
                  disabled
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder="Rechercher"
                  inputProps={{ "aria-label": "Rechercher" }}
                  onChange={e => {
                    const term = (e.target.value || "").replace(/\*/g, ".*");
                    if (isMobile) this.debSearch(term);
                  }}
                  onKeyDown={e => {
                    e.persist();
                    const term = (e.target.value || "").replace(/\*/g, ".*");
                    if (e.key === "Enter") {
                      this.search(term);
                    }
                  }}
                />
              </Paper>

              {(authReducer.user.gedNotifications || []).length > 1 && (
                <div>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={markAllAsRead}
                  >
                    {"Tout marquer comme lu "}
                  </Button>
                </div>
              )}
            </div>

            {!isMobile && (
              <div
                className="mt-2"
                style={{
                  fontSize: "12px",
                  color: "#888",
                  flexDirection: isMobile ? "column" : "row"
                }}
              >
                <span>{"Entrez "}</span>
                <span>{"*"}</span>
                <span>{" pour remplacer n'importe quel caractère. "}</span>
                <span>{" Voir les documents "}</span>
                <span
                  onKeyDown={e => {
                    e.stopPropagation();
                  }}
                  onClick={e => {
                    e.persist();
                    this.search(null);
                  }}
                  role="link"
                  tabIndex="0"
                  className="processFilter"
                >
                  {"tout"}
                </span>

                <span>{" / "}</span>

                <span
                  onKeyDown={e => {
                    e.stopPropagation();
                  }}
                  onClick={e => {
                    e.persist();
                    this.search("$status:processed");
                  }}
                  role="link"
                  tabIndex="0"
                  className="processFilter"
                >
                  {"traités"}
                </span>

                <span>{" / "}</span>

                <span
                  onKeyDown={e => {
                    e.stopPropagation();
                  }}
                  onClick={e => {
                    e.persist();
                    this.search("$status:toProcess");
                  }}
                  role="link"
                  tabIndex="-1"
                  className="processFilter"
                >
                  {"non-traités"}
                </span>
              </div>
            )}
          </>
        </PageTitle>

        <Grid
          container
          spacing={2}
          style={{
            height: "calc(100% - 70px)",
            flex: "1 1 auto"
          }}
        >
          {" "}
          {(!isMobile || (!selectedFolderId && !searchReducer.isActive)) && (
            <Grid
              item
              sm={12}
              md={3}
              style={{ overflow: "auto", width: isMobile ? "100%" : "auto" }}
            >
              <div>
                <Paper className="GedCategoryContainer">
                  {!isCollabOrAdmin(authReducer.user.role) &&
                    !authReducer.isCollabSession && (
                      <GedCategory
                        title="Mon espace privé"
                        folderId="private"
                        folders={privateFolders}
                      />
                    )}

                  <GedCategory
                    title="Échange avec HDM"
                    folderId="depot"
                    folders={echangeFolders}
                  />

                  {(!isAdmin(authReducer.user.role) ||
                    (isCollab(authReducer.user.role) &&
                      authReducer.isCollabSession)) && (
                    <GedCategory
                      title="Publication HDM"
                      folderId="publication"
                      folders={[...publicationFolders, publicFolder]}
                    />
                  )}
                </Paper>
                {!isMobile && !authReducer.isCollabSession && (
                  <Paper style={{ marginTop: "10px" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<Share />}
                      onClick={() => history.push("/partage")}
                      style={{
                        textTransform: "none",
                        backgroundColor: "white",
                        color: "#134594"
                      }}
                    >
                      {"Partage de documents"}
                    </Button>
                  </Paper>
                )}
              </div>
            </Grid>
          )}
          {(!isMobile || selectedFolderId || searchReducer.isActive) && (
            <Grid
              sm={12}
              md={9}
              item
              style={{ width: isMobile ? "100%" : "auto" }}
            >
              <Paper>
                {searchReducer.isActive ? (
                  <div className="filesContainer">
                    <GedSearchResults
                      isCollabSession={authReducer.isCollabSession}
                    />
                  </div>
                ) : (
                  <div className="filesContainer">
                    <GedBreadCrumb />
                    <GedRetentionInfo />
                    {!isMobile && <GedToolbar />}
                    <GedFilesListing />
                  </div>
                )}
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const dispatchStateToProps = dispatch => {
  return {
    fetchDocuments: folderId =>
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: { selectedFolderId: folderId }
      }),
    fetchRootFolders: () => dispatch({ type: "FETCH_ROOT_FOLDERS" }),
    searchTerm: searchTerm =>
      dispatch({ type: "SEARCH_DOCUMENTS", payload: searchTerm }),
    markAllAsRead: () => dispatch({ type: "MARK_ALL_DOCUMENTS_AS_READ" })
  };
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer,
    authReducer: state.authReducer,
    searchReducer: state.searchReducer
  };
};

Ged.propTypes = {
  fetchDocuments: PropTypes.func.isRequired,
  fetchRootFolders: PropTypes.func.isRequired,
  searchTerm: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  authReducer: authReducerType.isRequired,
  searchReducer: PropTypes.shape({ isActive: PropTypes.bool }).isRequired,
  documentReducer: documentReducerType.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    icon: PropTypes.shape({}),
    input: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ folderId: PropTypes.string })
  }).isRequired
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(withStyles(theme)(Ged)));
