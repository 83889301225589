import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { handleError } from "./sagaCommon";

function* onFetchEventsSync() {
  try {
    const { tenantId } = yield select(state => state.authReducer.user);

    const ressource = tenantId ? `/events/${tenantId}` : "/events";

    const response = yield call(ApiManager.callApi, {
      ressource,
      method: "GET",
      useMock: false,
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_EVENTS_SYNC",
      payload: response.data
    });
  } catch (error) {
    yield handleError(error);
  }
}

export function* onFetchEvents() {
  yield takeLatest("FETCH_EVENTS", onFetchEventsSync);
}
