import {
  takeLatest,
  put,
  call,
  fork,
  take,
  takeEvery,
  select
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { push } from "react-router-redux";
import fileDownload from "js-file-download";
import uuidv4 from "uuid/v4";
import ApiManager from "../lib/apiManager";
import { handleError } from "./sagaCommon";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import {
  CREATE_FOLDER,
  CREATE_FOLDER_SYNC,
  DOWNLOAD_FILE,
  FETCH_COLLAB_DOCS_ACTION,
  FETCH_COLLAB_DOCS_SYNC_ACTION,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SYNC,
  PROCESS_DOCUMENT_ACTION,
  REMOVE_DOCUMENT,
  UPLOAD_FILE
} from "../actions/document-actions";
import { USER_LOGOUT } from "../actions/login-actions";

const DEFAULT_SORT = "name";
const DEFAULT_ORDER = "asc";

function createUploader({ file, folderId, tenantId }) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = ApiManager.uploadFile({
    file,
    folderId,
    uploadProgressCb,
    ressource: "/documents",
    tenantId
  });
  return [uploadPromise, chan];
}

function* onFetchDocumentsSync({ payload }) {
  const { pageSize, page } = yield select(state => state.documentReducer);
  const { isCollabSession } = yield select(state => state.authReducer);
  const { tenantId } = yield select(state => state.authReducer.user);

  yield put({
    type: "LOADING_FOLDER"
  });
  yield put({
    type: "RESET_SELECTION"
  });
  const { selectedFolderId, sort, order } = payload || {};
  const folderId = selectedFolderId;
  const ressource = folderId ? `/folders/${folderId}` : "/folders/private";
  const queryParams = `?page=${page}&pageSize=${pageSize}&tenantId=${tenantId}&sort=${sort ||
    DEFAULT_SORT}&order=${order || DEFAULT_ORDER} `;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource:
        isCollabSession && !folderId ? "/folders" : ressource + queryParams,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: FETCH_DOCUMENTS_SYNC,
      payload: response.data
    });
    yield put({
      type: "UPDATE_GED_NOTIFICATIONS",
      payload: { gedNotifications: response.data.gedNotifications }
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du chargement des documents");

    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchDocuments() {
  yield takeLatest(FETCH_DOCUMENTS, onFetchDocumentsSync);
}

function* uploadProgressWatcher(chan) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    yield put({ type: "FILE_UPLOAD_PROGRESS", payload: progress });
  }
}

function* onFileUploadSync({ payload }) {
  try {
    const { file } = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    const { selectedFolderId } = yield select(state => state.documentReducer);
    yield put({
      type: "UPLOAD_IN_PROGRESS",
      payload: { value: file }
    });
    const [uploadPromise, chan] = yield call(createUploader, {
      file,
      folderId: selectedFolderId,
      tenantId
    });
    yield fork(uploadProgressWatcher, chan);
    const response = yield call(() => uploadPromise);
    yield put({ type: "UPLOAD_COMPLETED" });
    yield put({ type: "UPDATE_DOCUMENTS_FOLDER", payload: response.data.data });
  } catch (error) {
    yield handleError(error, "Erreur lors de l'envoi du fichier");
  }
}

export function* onFileUpload() {
  yield takeLatest(UPLOAD_FILE, onFileUploadSync);
}

function* onRemoveDocumentSync({ payload }) {
  const { document } = payload;
  const { tenantId } = yield select(state => state.authReducer.user);
  const data = {
    parentId: document.parentId,
    allSelected: false,
    documentIds: [document._id],
    tenantId
  };
  try {
    yield put({
      type: "REMOVE_DOCUMENT_SYNC",
      payload: document
    });
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/delete `,
      method: "POST",
      isAuthenticated: true,
      data
    });
    if (response.data.favorites) {
      yield put({
        type: "UPDATE_FAVORITES_SYNC",
        payload: response.data.favorites
      });
    }
  } catch (error) {
    yield put({
      type: "ADD_DOCUMENT_SYNC",
      payload: document
    });
    yield handleError(error, "Erreur lors de la suppression de ce document");
  }
}

export function* onRemoveDocument() {
  yield takeLatest(REMOVE_DOCUMENT, onRemoveDocumentSync);
}

function* onCreateFolderSync({ payload }) {
  const { folderName } = payload;
  const { tenantId } = yield select(state => state.authReducer.user);
  const { selectedFolderId } = yield select(state => state.documentReducer);
  const randomId = uuidv4();
  try {
    yield put({
      type: "ADD_DOCUMENT_SYNC",
      payload: { name: folderName, documentType: "folder", _id: randomId }
    });
    const response = yield call(ApiManager.callApi, {
      ressource: `/folders`,
      method: "POST",
      isAuthenticated: true,
      data: {
        parentId: selectedFolderId || "private",
        folderName,
        tenantId
      }
    });
    yield put({
      type: CREATE_FOLDER_SYNC,
      payload: {
        folder: response.data.folder,
        oldId: randomId
      }
    });
  } catch (error) {
    yield put({
      type: "REMOVE_DOCUMENT_SYNC",
      payload: { name: folderName, documentType: "folder" }
    });
    yield handleError(error, "Erreur lors de la création du dossier");
  }
}

export function* onCreateFolder() {
  yield takeLatest(CREATE_FOLDER, onCreateFolderSync);
}

function* onRenameDocumentSync({ payload }) {
  const { document, newName } = payload;

  try {
    yield put({
      type: "RENAME_DOCUMENT_SYNC",
      payload
    });
    const { tenantId } = yield select(state => state.authReducer.user);

    const data = {
      name: newName,
      tenantId
    };

    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/${document._id}/updateMeta`,
      method: "POST",
      isAuthenticated: true,
      data
    });
    yield put({
      type: "RENAME_DOCUMENT_SYNC",
      payload: { newName: response.data.data.name, document }
    });
    if (response.data.favorites) {
      yield put({
        type: "UPDATE_FAVORITES_SYNC",
        payload: response.data.favorites
      });
    }
  } catch (error) {
    yield handleError(error, "Erreur lors du renommage du document");
  }
}

export function* onRenameDocument() {
  yield takeLatest("RENAME_DOCUMENT", onRenameDocumentSync);
}

async function fetchBlobData(blob) {
  return blob.text();
}

function* onDownloadFileSync({ payload }) {
  const document = payload;
  try {
    yield put({
      type: "FILE_DOWNLOAD_START",
      payload: document._id
    });

    const { tenantId } = yield select(state => state.authReducer.user);
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/${document._id}/download?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true,
      blob: true
    });

    fileDownload(response.data, document.name);

    yield put({
      type: "FILE_DOWNLOAD_END",
      payload: document._id
    });
    yield put({
      type: "UPDATE_GED_NOTIFICATIONS",
      payload: { documentsToRemove: [document._id] }
    });
    // update root folders (for notifications)
    yield put({
      type: "FETCH_ROOT_FOLDERS"
    });
  } catch (error) {
    const errorText = yield call(fetchBlobData, error.response.data);
    const errorOjb = { response: { data: JSON.parse(errorText) } };
    yield handleError(errorOjb, "Erreur lors du téléchargement du fichier");
  }
}

export function* onDownloadFile() {
  yield takeEvery(DOWNLOAD_FILE, onDownloadFileSync);
}

function* onFetchNewPageAsync({ payload }) {
  const { page, sort, order } = payload;
  const { pageSize, selectedFolderId } = yield select(
    state => state.documentReducer
  );
  const { tenantId } = yield select(state => state.authReducer.user);
  yield put({
    type: "LOADING_FOLDER"
  });
  const ressource = selectedFolderId
    ? `/folders/${selectedFolderId}`
    : "/folders/private";

  const queryParams = `?page=${page}&pageSize=${pageSize}&tenantId=${tenantId}&sort=${sort ||
    DEFAULT_SORT}&order=${order || DEFAULT_ORDER}`;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: ressource + queryParams,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: FETCH_DOCUMENTS_SYNC,
      payload: response.data
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du chargement de la page");
  }
}

export function* onFetchNewPage() {
  yield takeLatest("FETCH_NEW_PAGE", onFetchNewPageAsync);
}

function* onFetchRootFolderAsync() {
  const { tenantId } = yield select(state => state.authReducer.user);
  const ressource = `/fields/?tenantId=${tenantId}`;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_ROOT_FOLDERS_SYNC",
      payload: response.data.fields
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du chargement des dossiers racines");
  }
}

export function* onFetchPrivateRootFolder() {
  yield takeLatest("FETCH_ROOT_FOLDERS", onFetchRootFolderAsync);
}

function* onFetchDocInfoAsync({ payload }) {
  const docId = payload;
  const ressource = `/documents/${docId}`;
  try {
    yield put({ type: "LOADING_FOLDER" });
    const response = yield call(ApiManager.callApi, {
      ressource,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_DOC_INFO_SYNC",
      payload: response.data
    });
    // update root folders (for notifications)
    yield put({
      type: "FETCH_ROOT_FOLDERS"
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 404) {
      yield put(push("/404"));
    }
  }
}

export function* onFetchDocInfo() {
  yield takeLatest("FETCH_DOC_INFO", onFetchDocInfoAsync);
}

function* onShareDocumentAsync({ payload }) {
  try {
    const { docId, password, maxDownloads } = payload;

    const { tenantId } = yield select(state => state.authReducer.user);

    const data = {
      documentId: docId,
      tenantId
    };

    if (password && password.length > 0) {
      data.password = password;
    }

    if (maxDownloads) {
      data.maxDownloads = maxDownloads;
    }

    const response = yield call(ApiManager.callApi, {
      ressource: "/documents/share",
      method: "POST",
      isAuthenticated: true,
      data
    });

    yield put({
      type: "SHARE_DOCUMENT_SYNC",
      payload: response.data.downloadLink
    });
  } catch (error) {
    yield handleError(error, "Erreur lors du partage du fichier");
    /* if (error.response.status === 404) {
      yield put(push("/404"));
    } */
  }
}

export function* onShareDocument() {
  yield takeLatest("SHARE_DOCUMENT", onShareDocumentAsync);
}

function* onFetchLinkInfoAsync({ payload }) {
  try {
    const linkId = payload;

    const response = yield call(ApiManager.callApi, {
      ressource: `/links/info/${linkId}`,
      method: "GET",
      isAuthenticated: false
    });

    yield put({
      type: "FETCH_LINK_INFO_SYNC",
      payload: { ...response.data }
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 404) {
      yield put(push("/404"));
    }
  }
}

export function* onFetchLinkInfo() {
  yield takeLatest("FETCH_LINK_INFO", onFetchLinkInfoAsync);
}

function* onDownloadFileByLinkAsync({ payload }) {
  const { linkId, password, documentName } = payload;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: `/links/download/${linkId}`,
      method: "POST",
      isAuthenticated: true,
      data: { password },
      blob: true
    });

    fileDownload(response.data, documentName);
    yield put({
      type: "DOWNLOAD_FILE_LINK_SYNC",
      payload: { success: true }
    });
  } catch (error) {
    const errorPayload =
      error.response.status === 403
        ? "Mot de passe incorrect"
        : "Impossible de télécharger le fichier";

    yield put({
      type: TOAST_ERROR_ACTION,
      payload: errorPayload
    });
  }
}

export function* onDownloadFileByLink() {
  yield takeLatest("DOWNLOAD_FILE_LINK", onDownloadFileByLinkAsync);
}

function* onFetchCollabDocsAsync() {
  try {
    yield put({
      type: "LOADING_FOLDER"
    });
    const response = yield call(ApiManager.callApi, {
      ressource: "/documents/collab",
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: FETCH_COLLAB_DOCS_SYNC_ACTION,
      payload: response.data
    });
    if (response.data.gedNotifications) {
      yield put({
        type: "UPDATE_GED_NOTIFICATIONS",
        payload: { gedNotifications: response.data.gedNotifications }
      });
    }
    // update root folders (for notifications)
    yield put({
      type: "FETCH_ROOT_FOLDERS"
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchCollabDocs() {
  yield takeLatest(FETCH_COLLAB_DOCS_ACTION, onFetchCollabDocsAsync);
}

function* onProcessDocumentAsync({ payload }) {
  try {
    const documentId = payload;
    const data = { documentIds: [documentId] };

    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/process`,
      method: "PUT",
      isAuthenticated: true,
      data
    });

    if (response.data.updatedDocs !== data.documentIds.length) {
      yield put({
        type: TOAST_ERROR_ACTION,
        payload: "Impossible de traiter le document"
      });
    } else {
      yield put({
        type: FETCH_COLLAB_DOCS_ACTION
      });
      yield put({
        type: "UPDATE_GED_NOTIFICATIONS",
        payload: { gedNotifications: response.data.gedNotifications }
      });
      // update root folders (for notifications)
      yield put({
        type: "FETCH_ROOT_FOLDERS"
      });
    }
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de traiter le document"
    });
  }
}

export function* onProcessDocument() {
  yield takeLatest(PROCESS_DOCUMENT_ACTION, onProcessDocumentAsync);
}

function* onReadAllDocumentsSync() {
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: `/documents/read`,
      method: "PUT",
      isAuthenticated: true,
      data: {
        allSelected: true
      }
    });

    yield put({
      type: "UPDATE_GED_NOTIFICATIONS",
      payload: { gedNotifications: response.data.gedNotifications }
    });
    // update root folders (for notifications)
    yield put({
      type: "FETCH_ROOT_FOLDERS"
    });
  } catch (error) {
    yield put({ type: TOAST_ERROR_ACTION, payload: error.message });
  }
}

export function* onReadAllDocuments() {
  yield takeLatest("MARK_ALL_DOCUMENTS_AS_READ", onReadAllDocumentsSync);
}
