import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import "./ApplicationItem.scss";

import {
  applicationType,
  favoriteReducerType
} from "../../_helpers/appProptypes";

const ApplicationItem = props => {
  const {
    application,
    markAsFavorite,
    removeFromFavorite,
    favoriteReducer
  } = props;
  const isFavorite = favoriteReducer.favorites.find(
    favorite => favorite.docId === application._id
  );

  const thumbnailUrl = `../img/app/${application._id}.svg`;
  return (
    <Paper className="ApplicationItem">
      <div className="imageContainer">
        <img src={thumbnailUrl} alt={application.name} />
      </div>
      <div className="infoContainer">
        <div className="titleContainer">
          <Typography
            component="h4"
            color="primary"
            style={{ display: "inline-block" }}
          >
            {application.name}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}
        >
          {isFavorite ? (
            <Star
              onClick={e => {
                e.stopPropagation();
                removeFromFavorite(application);
              }}
            />
          ) : (
            <StarBorder
              onClick={e => {
                e.stopPropagation();
                markAsFavorite(application);
              }}
            />
          )}
        </div>
      </div>
    </Paper>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    markAsFavorite: application => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "add",
          favorite: {
            name: application.name,
            type: "application",
            docId: application._id
          }
        }
      });
    },
    removeFromFavorite: application => {
      dispatch({
        type: "UPDATE_FAVORITES",
        payload: {
          action: "remove",
          favorite: {
            name: application.name,
            type: "application",
            docId: application._id
          }
        }
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer
  };
};

ApplicationItem.propTypes = {
  application: applicationType.isRequired,
  markAsFavorite: PropTypes.func.isRequired,
  removeFromFavorite: PropTypes.func.isRequired,
  favoriteReducer: favoriteReducerType.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationItem);
