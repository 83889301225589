/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import CreateNewFolder from "@material-ui/icons/CreateNewFolder";
import FileCopy from "@material-ui/icons/FileCopy";
import MoveToInbox from "@material-ui/icons/MoveToInbox";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import GedNewFolderModal from "./GedNewFolderModal";
import GedMoveModal from "./GedMoveModal";
import GedDeleteModal from "./GedDeleteModal";
import "./GedToolbar.scss";
import { ECHANGE_FOLDERS } from "../../constants/folder.constant";
import { REMOVE_SELECTION, UPLOAD_FILE } from "../../actions/document-actions";

const GedToolbar = ({ uploadFile, multiSelectionReducer, documentReducer }) => {
  const [isNewFolderOpen, setNewFolderOpen] = useState(false);
  const [isMoveModalOpen, setMoveModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleUploadFile = () =>
    document.getElementById("fileUploadInput").click();
  const handleUploadFileChange = e => uploadFile(e.target.files[0]);

  const { selectedFolderId, folder, documentsCount } = documentReducer;

  const isEchangeFolder = ECHANGE_FOLDERS.indexOf(selectedFolderId) !== -1;

  if (!folder) return null;

  const { allSelect, selectionList, excludeList } = multiSelectionReducer;
  const isValidSelection = allSelect
    ? documentsCount - excludeList.length > 0
    : selectionList.length > 0;

  return (
    <>
      <div className="GedToolbar">
        <Input
          type="file"
          id="fileUploadInput"
          style={{ display: "none" }}
          onChange={handleUploadFileChange}
          // necessary to clear file selection, in order to
          // be able to upload same file twice
          onClick={event => {
            const e = event;
            e.target.value = null;
          }}
        />
        {!folder.readOnly && !isEchangeFolder && (
          <Typography
            component="a"
            className="tool"
            onClick={() => setNewFolderOpen(true)}
          >
            <CreateNewFolder />
            <span>{"Nouveau dossier"}</span>
          </Typography>
        )}
        {!folder.readOnly && (
          <Typography
            component="span"
            className="tool"
            onClick={handleUploadFile}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            <FileCopy />
            <span>{"Charger un fichier"}</span>
          </Typography>
        )}
        {!folder.readOnly && (
          <Typography
            component="a"
            className={isValidSelection ? "tool" : "tool tool--disabled"}
            disabled={!isValidSelection}
            onClick={() => {
              if (isValidSelection) {
                setMoveModalOpen(true);
              }
            }}
          >
            <MoveToInbox />
            {"Déplacer"}
          </Typography>
        )}
        {!folder.readOnly && (
          <Typography
            component="a"
            className={isValidSelection ? "tool" : "tool tool--disabled"}
            disabled={!isValidSelection}
            onClick={() => {
              if (isValidSelection) {
                setDeleteModalOpen(true);
              }
            }}
          >
            <RemoveCircle />
            {"Supprimer"}
          </Typography>
        )}
      </div>

      {isNewFolderOpen && (
        <GedNewFolderModal
          isOpen={isNewFolderOpen}
          onClose={() => setNewFolderOpen(false)}
        />
      )}
      {isMoveModalOpen && (
        <GedMoveModal
          isOpen={isMoveModalOpen}
          onClose={() => setMoveModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && (
        <GedDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          selection={
            allSelect
              ? documentsCount - excludeList.length
              : selectionList.length
          }
          selectionList={selectionList}
          excludeList={excludeList}
          multiple
        />
      )}
    </>
  );
};

const dispatchStateToProps = dispatch => {
  return {
    uploadFile: file => dispatch({ type: UPLOAD_FILE, payload: { file } }),
    removeSelection: () => dispatch({ type: REMOVE_SELECTION })
  };
};

const mapStateToProps = state => {
  return {
    multiSelectionReducer: state.multiSelectionReducer,
    documentReducer: state.documentReducer
  };
};

GedToolbar.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  multiSelectionReducer: PropTypes.object.isRequired,
  documentReducer: PropTypes.object.isRequired
};

export default connect(mapStateToProps, dispatchStateToProps)(GedToolbar);
