const initialState = {
  missions: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MISSIONS_SYNC": {
      const missions = action.payload;
      return {
        ...state,
        isLoading: false,
        missions
      };
    }

    default:
      return state;
  }
};

export default reducer;
