const initialState = {
  events: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EVENTS_SYNC": {
      const events = action.payload;
      return {
        ...state,
        isLoading: false,
        events
      };
    }

    case "EVENTS_CLEAR": {
      return {
        ...state,
        isAuthenticated: false,
        events: []
      };
    }

    default:
      return state;
  }
};

export default reducer;
