import { takeLatest, put, call } from "redux-saga/effects";

import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import {
  FETCH_CLIENTS,
  FETCH_CLIENTS_SYNC,
  LOADING_CLIENTS
} from "../actions/client-actions";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchClientsSync({ payload }) {
  try {
    yield put({ type: LOADING_CLIENTS });
    const { previous, next, search, limit } = payload;
    let ressource = `/users/tenants?search=${search}`;
    if (previous) ressource = `${ressource}&previous=${previous}`;
    if (next) ressource = `${ressource}&next=${next}`;
    if (limit) ressource = `${ressource}&limit=${limit}`;

    const response = yield call(ApiManager.callApi, {
      ressource,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: FETCH_CLIENTS_SYNC,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchClients() {
  yield takeLatest(FETCH_CLIENTS, onFetchClientsSync);
}

function* onAskDataUpdateSync({ payload }) {
  try {
    const { tenantId, publicationDate } = payload;
    const response = yield call(ApiManager.callApi, {
      ressource: `/tenants/${tenantId}`,
      method: "PUT",
      isAuthenticated: true,
      data: { publicationDate }
    });
    const reducerPayload = {
      tenantId,
      accountingPublicationStatus:
        response.data.tenant.accountingPublicationStatus,
      accountingPublicationLimitDate: publicationDate
    };
    yield put({ type: "ASK_DATA_UPDATE_SYNC", payload: reducerPayload });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
  }
}

export function* onAskDataUpdate() {
  yield takeLatest("ASK_DATA_UPDATE", onAskDataUpdateSync);
}

function* onCancelUpdateSync({ payload }) {
  try {
    const tenantId = payload;
    const response = yield call(ApiManager.callApi, {
      ressource: `/tenants/${tenantId}`,
      method: "PUT",
      isAuthenticated: true
    });
    const reducerPayload = {
      tenantId,
      accountingPublicationStatus:
        response.data.tenant.accountingPublicationStatus,
      accountingPublicationLimitDate:
        response.data.tenant.accountingPublicationLimitDate
    };
    yield put({ type: "ASK_DATA_UPDATE_SYNC", payload: reducerPayload });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
  }
}

export function* onCancelUpdate() {
  yield takeLatest("CANCEL_UPDATE", onCancelUpdateSync);
}
