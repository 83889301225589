import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import "./Login.scss";

const LinkSent = props => {
  const { history } = props;
  return (
    <div className="Login">
      <Grid container spacing={3}>
        <Grid item md={6} style={{ margin: "auto" }}>
          <Paper
            style={{
              textAlign: "center",
              padding: "62px 97px",
              backgroundColor: "#E5E8F3"
            }}
          >
            <Typography component="h3">
              {
                "Un mail de validation de réinitialisation de votre mot de passe vous a été envoyé"
              }
            </Typography>
            <Typography component="h4">
              {"Merci de vérifier votre messagerie et suivre la procédure."}
            </Typography>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography
                component="a"
                onClick={() => history.push("/connexion")}
              >
                {"Retourner à la page de connexion"}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

LinkSent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(LinkSent);
