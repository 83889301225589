/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Box, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { connect } from "react-redux";

import { authReducerType } from "../../_helpers/appProptypes";

import "./Layout.scss";

const MobileHeader = props => {
  const { authReducer } = props;
  const { user = {} } = authReducer;

  if (!user) return <div />;

  let tenantIndex = 0;

  if (authReducer && user.role !== "collab") {
    tenantIndex = user.tenants.findIndex(
      t => t._id === (user.tenantId || user.tenants[0]._id)
    );
  }

  const { companyName } = user.tenants[tenantIndex];

  const { onMenuClick } = props;

  return (
    <Paper
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        height: "60px",
        width: "100%",
        zIndex: "1000"
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="row"
        padding="0 5px"
        alignItems="center"
        justifyContent="space-between"
        color="primary"
      >
        <IconButton color="inherit" size="small" onClick={onMenuClick}>
          <MenuIcon />
        </IconButton>
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          padding="5px"
          textAlign="center"
        >
          {companyName}
        </Box>
      </Box>
    </Paper>
  );
};

MobileHeader.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer,
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps, {})(MobileHeader);
