import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import PageTitle from "../PageTitle/PageTitle";
import TicketsListing from "./TicketsListing";
import TicketsToolbar from "./TicketsToolbar";

import { supportReducerType } from "../../_helpers/appProptypes";

import "./Portfolio.scss";
import {
  FETCH_SUPPORTS,
  FETCH_TICKETS,
  UPDATE_TICKET_FILTERS
} from "../../actions/ticket-actions";

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: isMobile ? "100%" : 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

const Assistance = ({
  fetchTickets,
  fetchSupports,
  classes,
  searchTerm,
  supportReducer
}) => {
  const [filters, setFilters] = useState({
    domain: "all",
    category: "all"
  });

  useEffect(() => {
    fetchTickets();
    fetchSupports();
  }, [fetchTickets, fetchSupports]);

  const { myTickets = [], lateTickets = [] } = supportReducer;
  const defaultValue =
    supportReducer && supportReducer.searchTerm
      ? supportReducer.searchTerm.replace(".*", "")
      : "";

  const applyFilters = ticket => {
    if (filters.domain === "all" && filters.category === "all") return true;
    if (filters.domain === "all") return ticket.category === filters.category;
    if (filters.category === "all") return ticket.missionId === filters.domain;
    return (
      ticket.category === filters.category &&
      ticket.missionId === filters.domain
    );
  };

  const myTicketsFiltered = myTickets.filter(applyFilters);
  const lateTicketsFiltered = lateTickets.filter(applyFilters);

  return (
    <div className="Assistance" style={{ height: "100%" }}>
      <PageTitle title="Assistance">
        <Paper className={classes.root}>
          <IconButton aria-label="menu" className={classes.icon} disabled>
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Rechercher"
            defaultValue={defaultValue}
            inputProps={{ "aria-label": "Rechercher" }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                const term = (e.target.value || "").replace(/\*/g, ".*");
                searchTerm(term);
              }
            }}
          />
        </Paper>
      </PageTitle>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Paper>
            <div className="filesContainer">
              <TicketsToolbar
                showFilters
                filters={filters}
                onChangeFilters={setFilters}
              />
              <TicketsListing
                myTickets={myTicketsFiltered}
                lateTickets={lateTicketsFiltered}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Assistance.propTypes = {
  searchTerm: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.shape({}),
    icon: PropTypes.shape({}),
    input: PropTypes.shape({})
  }).isRequired,
  fetchTickets: PropTypes.func.isRequired,
  fetchSupports: PropTypes.func.isRequired,
  supportReducer: supportReducerType.isRequired
};

const mapStateToProps = state => ({
  supportReducer: state.supportReducer
});

const dispatchStateToProps = dispatch => {
  return {
    fetchTickets: () => dispatch({ type: FETCH_TICKETS }),
    fetchSupports: () => dispatch({ type: FETCH_SUPPORTS }),
    searchTerm: searchTerm => {
      dispatch({ type: UPDATE_TICKET_FILTERS, payload: { searchTerm } });
    }
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withStyles(theme)(Assistance));
