import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import PageTitle from "../PageTitle/PageTitle";
import DownloadLinks from "./DownloadLinks";
import Loader from "../Loader/Loader";
import { authReducerType } from "../../_helpers/appProptypes";

const Share = ({ authReducer, fetchLinks }) => {
  useEffect(() => {
    fetchLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authReducer.user) {
    return <Loader />;
  }

  return (
    <div className="Partage">
      <PageTitle title="Partage de documents" />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Paper>
            <div className="linksContainer">
              <DownloadLinks />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Share.propTypes = {
  authReducer: authReducerType.isRequired,
  fetchLinks: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    downloadLinkReducer: state.downloadLinkReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLinks: () => dispatch({ type: "FETCH_DOWNLOAD_LINKS" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
