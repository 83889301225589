import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import { handleError } from "./sagaCommon";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchLinksSync() {
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/helpLinks",
      method: "GET",
      isAuthenticated: true
      // useMock: false
    });
    yield put({
      type: "FETCH_HELP_LINKS_SYNC",
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchLinks() {
  yield takeLatest("FETCH_HELP_LINKS", onFetchLinksSync);
}

function* onOpenLinkSync({ payload }) {
  try {
    const { linkId } = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    yield call(ApiManager.callApi, {
      ressource: `/helpLinks/${linkId}?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
  } catch (error) {
    yield handleError(error, "Erreur lors de l'ouverture du lien");
  }
}

export function* onOpenLink() {
  yield takeLatest("OPEN_HELP_LINK", onOpenLinkSync);
}
