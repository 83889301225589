import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker } from "@material-ui/pickers";
import CallMade from "@material-ui/icons/CallMade";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import UpdateIcon from "@material-ui/icons/Update";
import CancelIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { clientType } from "../../_helpers/appProptypes";
import "./ClientLine.scss";
import { useState } from "react";

function getValue(indicatorId, perimeter) {
  const v = _.find(perimeter, e => e.indicatorId === indicatorId) || {};
  return Math.round(v.indicatorValue);
}

function formatCurrency(number) {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR"
  }).format(number);
}

const renderIndicatorVariation = (x, sign = 1) => {
  const n = Number.parseFloat(x);
  if (!n) return <div />;
  if (n === 0)
    return (
      <div style={{ fontSize: "0.8em" }}>
        <TrendingFlat />
      </div>
    );
  // default positive color scheme
  let positiveColor = "green";
  let negativeColor = "red";
  if (sign < 0) {
    positiveColor = "red";
    negativeColor = "green";
  }
  if (n > 0)
    return (
      <div style={{ color: positiveColor, fontSize: "0.8em" }}>
        {`+ ${x.toFixed(1)}%`}
        <CallMade style={{ verticalAlign: "middle" }} />
      </div>
    );
  return (
    <div
      style={{
        color: negativeColor,
        fontSize: "0.8em"
      }}
    >
      {`- ${Math.abs(x.toFixed(1))}%`}
      <CallMade
        style={{ verticalAlign: "middle", transform: "rotate(90deg)" }}
      />
    </div>
  );
};

function renderIndicator(indicatorMap, indicatorId, indicatorName) {
  return _.get(indicatorMap, `${indicatorId}.currentExercice`, "DEFAULT") !==
    "DEFAULT" ? (
    <HtmlTooltip
      title={
        <div>
          <div>
            <b>{indicatorName}</b>
          </div>
          <hr />
          <div>
            N &nbsp; &nbsp;: &nbsp;{" "}
            {formatCurrency(indicatorMap[indicatorId].currentExercice)}{" "}
          </div>
          <div>
            N-1 : &nbsp;{" "}
            {formatCurrency(indicatorMap[indicatorId].previousExercice)}{" "}
          </div>
        </div>
      }
    >
      <div>
        {`${formatCurrency(indicatorMap[indicatorId].currentExercice)} `}
        {renderIndicatorVariation(indicatorMap[indicatorId].variation)}
      </div>
    </HtmlTooltip>
  ) : (
    "-"
  );
}

const HtmlTooltip = withStyles(thm => ({
  tooltip: {
    backgroundColor: thm.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: thm.shadows[1],
    fontSize: 14,
    minWidth: 150,
    maxWidth: 320,
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const ClientLine = ({
  client,
  accessClientPortal,
  updateData,
  cancelUpdate
}) => {
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [selectedDate, handleDateChange] = useState(
    client && client.lastAccountabilityUpdate
      ? client.lastAccountabilityUpdate
      : moment()
  );
  if (!client) return null;
  const { indicators } = client;

  const handleClose = () => {
    setOpen(false);
    updateData(client._id, selectedDate);
  };

  const handleCloseCancel = () => {
    setOpenCancel(false);
    cancelUpdate(client._id);
  };

  let currentExerciceCode;
  let previousExerciceCode;
  let currentValues = {};
  let previousValues = {};
  const indicatorMap = {
    sales: {},
    treasury: {},
    clientOutstanding: {},
    expenses: {}
  };

  if (_.size(indicators) > 0) {
    const exercices = _.uniq(indicators.map(s => s.exerciceEndMonth)).sort();
    currentExerciceCode = exercices[exercices.length - 1];
    previousExerciceCode = exercices[exercices.length - 2];
    currentValues = _.find(
      indicators,
      s => s.exerciceEndMonth === currentExerciceCode
    ).indicatorValues;
    if (_.find(indicators, s => s.exerciceEndMonth === previousExerciceCode))
      previousValues = _.find(
        indicators,
        s => s.exerciceEndMonth === previousExerciceCode
      )
        ? _.find(indicators, s => s.exerciceEndMonth === previousExerciceCode)
            .indicatorValues
        : [];

    const currentSales = getValue("bdo_chiffredaffaire", currentValues);
    const previousSales = getValue("bdo_chiffredaffaire_iso", previousValues);
    indicatorMap.sales = {
      currentExercice: currentSales,
      previousExercice: previousSales,
      variation: (currentSales / previousSales - 1) * 100
    };
    const currentTreasury = getValue("bdo_tresorerie", currentValues);
    const previousTreasury = getValue("bdo_tresorerie_iso", previousValues);
    indicatorMap.treasury = {
      currentExercice: currentTreasury,
      previousExercice: previousTreasury,
      variation: (currentTreasury / previousTreasury - 1) * 100
    };
    const currentClientOutstanding = getValue(
      "bdo_creanceclient",
      currentValues
    );
    const previousClientOutstanding = getValue(
      "bdo_creanceclient_iso",
      previousValues
    );
    indicatorMap.clientOutstanding = {
      currentExercice: currentClientOutstanding,
      previousExercice: previousClientOutstanding,
      variation:
        (currentClientOutstanding / previousClientOutstanding - 1) * 100
    };
    const currentExpenses = getValue(
      "bdo_chargesfonctionnement",
      currentValues
    );
    const previousExpenses = getValue(
      "bdo_chargesfonctionnement_iso",
      previousValues
    );
    indicatorMap.expenses = {
      currentExercice: currentExpenses,
      previousExercice: previousExpenses,
      variation: (currentExpenses / previousExpenses - 1) * 100
    };
  }

  return (
    <TableRow className="ClientLine">
      <TableCell align="center">
        {client.name.toUpperCase()} ({client.noDossier})
      </TableCell>

      <TableCell align="center">
        <HtmlTooltip
          title={
            <div style={{ fontSize: "0.8rem", textAlign: "center" }}>
              <div>
                {" "}
                <b>{client.nonProcessedDocs}</b>
                {" documents non traités"}
              </div>
              <br />
              <div style={{ whiteSpace: "nowrap" }}>
                <b>{client.openedTickets}</b>
                {" demandes non traitées - "}
                <b>{client.inProgressTickets}</b>
                {" demandes en cours de traitement"}
              </div>
            </div>
          }
        >
          <div style={{ textAlign: "center" }}>
            {client.nonProcessedDocs}
            <br />
            <span
              style={{ whiteSpace: "nowrap" }}
            >{`${client.openedTickets} - ${client.inProgressTickets}`}</span>
          </div>
        </HtmlTooltip>
      </TableCell>

      <TableCell align="center">
        <HtmlTooltip
          title={
            <div style={{ textAlign: "center" }}>
              <div>{"Mise à jour comptabilité"}</div>
            </div>
          }
        >
          <div style={{ fontSize: "x-small" }}>
            <span>{"Comptabilité "}</span>
            <span>
              {client.lastAccountabilityUpdate
                ? `au : ${moment(client.lastAccountabilityUpdate).format(
                    "DD/MM/YYYY"
                  )}`
                : ": pas de données"}
            </span>
            <div style={{ display: "inline-block" }}>
              <Tooltip title="">
                <IconButton
                  onClick={() => setOpen(true)}
                  style={{ padding: "2px" }}
                >
                  <UpdateIcon />
                </IconButton>
              </Tooltip>

              {client.accountingPublicationStatus === "updatePending" && (
                <>
                  <ButtonBase
                    onClick={() => setOpen(true)}
                    style={{ maxWidth: "50%" }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: "xx-small",
                        color: "grey",
                        verticalAlign: "middle",
                        textAlign: "center"
                      }}
                    >
                      {`Mise à jour demandée jusqu'au : ${moment(
                        client.accountingPublicationLimitDate
                      ).format("DD/MM/YYYY")}`}
                    </div>
                  </ButtonBase>

                  <Tooltip title="Annuler la mise à jour">
                    <IconButton
                      onClick={() => setOpenCancel(true)}
                      style={{ padding: "2px" }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </HtmlTooltip>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">
            {"Demander la mise à jour des données"}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {"Prendre les écritures comptables jusqu'au :"}
            </DialogContentText>
            <DatePicker
              value={selectedDate}
              disableToolbar
              onChange={handleDateChange}
              maxDate={moment()}
              format="DD/MM/YYYY"
              disableFuture
              invalidDateMessage="Date invalide"
              minDateMessage="La date choisie ne peut pas être antérieure à la date de la dernière mise à jour"
              maxDateMessage="La date choisie ne peut pas être postérieure à la date d'aujourd'hui"
              cancelLabel="Annuler"
            />
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => setOpen(false)}
              className="cancel"
            >
              {"Annuler"}
            </Button>
            <Button size="small" onClick={handleClose}>
              {"Confirmer"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openCancel}
          onClose={handleCloseCancel}
          aria-labelledby="form-dialog-title"
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">
            {"Annuler la mise à jour des données"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {
                "Êtes-vous sûr(e) de vouloir annuler la mise à jour des données du client ?"
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => setOpenCancel(false)}
              className="cancel"
            >
              {"Annuler"}
            </Button>
            <Button size="small" onClick={handleCloseCancel}>
              {"Confirmer"}
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>

      <TableCell align="right">
        {renderIndicator(indicatorMap, "sales", "Chiffre d'affaire")}
      </TableCell>
      <TableCell align="right">
        {renderIndicator(indicatorMap, "expenses", "Dépenses")}
      </TableCell>
      <TableCell align="right">
        {renderIndicator(indicatorMap, "treasury", "Trésorerie")}
      </TableCell>
      <TableCell align="right">
        {renderIndicator(indicatorMap, "clientOutstanding", "Encours client")}
      </TableCell>
      <TableCell align="center">
        {client.collabAccessRight ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => accessClientPortal(client._id)}
          >
            {"Ouvrir"}
          </Button>
        ) : (
          <Button variant="contained" color="primary" disabled fullWidth>
            {"Pas d'options"}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

ClientLine.propTypes = {
  client: clientType.isRequired,
  accessClientPortal: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    accessClientPortal: tenantId => {
      dispatch({ type: "ACCESS_CLIENT_PORTAL", payload: { tenantId } });
    },
    updateData: (tenantId, publicationDate) => {
      dispatch({
        type: "ASK_DATA_UPDATE",
        payload: { tenantId, publicationDate }
      });
    },
    cancelUpdate: tenantId => {
      dispatch({ type: "CANCEL_UPDATE", payload: tenantId });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLine);
