import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../Loader/Loader";
import PageTitle from "../PageTitle/PageTitle";
import ClientsListing from "./ClientsListing";
import { authReducerType } from "../../_helpers/appProptypes";
import PortfolioIndicator from "./PortfolioIndicator";
import COLLABORATOR_INDICATORS from "../../constants/collaborator-indicator.constant";

import "./Portfolio.scss";

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

const Portfolio = ({ authReducer }) => {
  if (!authReducer.user) return <Loader />;

  return (
    <div className="Portfolio">
      <PageTitle title="Portefeuille" />

      <div className="flex flex-col">
        <Grid container spacing={2}>
          {COLLABORATOR_INDICATORS.map(collabIndicator => (
            <Grid item md={4} key={collabIndicator.id}>
              <PortfolioIndicator
                text={collabIndicator.text}
                pluralText={collabIndicator.pluralText}
                counter={(authReducer.user[collabIndicator.field] || []).length}
              />
            </Grid>
          ))}
        </Grid>

        <ClientsListing className="mt-6" />
      </div>
    </div>
  );
};

Portfolio.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.shape({}),
    input: PropTypes.string
  }).isRequired,
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps)(withStyles(theme)(Portfolio));
