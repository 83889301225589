import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { FETCH_CLIENTS } from "../../actions/client-actions";

const FETCH_WAIT_SECONDS = 800;
const FETCH_CLIENT_LIMIT = 25;

const FolderSearchInput = ({
  onSelect,
  fetchClientsPortfolio,
  clientReducer
}) => {
  const { isLoading, clients } = clientReducer;

  const options = _.sortBy(clients || [], c => c.companyName).map(client => ({
    label: `${client.noDossier} - ${client.name}`,
    value: client.noDossier
  }));

  if (clients.length > 0) {
    options.push({
      label: "Effectuez une recherche pour trouver d'autres dossiers",
      disabled: true
    });
  }

  const fetchClientsPortfolioDebounced = _.debounce(inputValue => {
    const cleanSearchValue = inputValue.replace(/\W/g, "");
    if (cleanSearchValue && cleanSearchValue.length >= 2)
      fetchClientsPortfolio({ search: cleanSearchValue });
    // setLoading(false)
  }, FETCH_WAIT_SECONDS);

  return (
    <ReactSelect
      loadingMessage={() => "Recherche en cours..."}
      options={options}
      onInputChange={fetchClientsPortfolioDebounced}
      onChange={onSelect}
      isSearchable
      placeholder={"Entrez un nom ou un numéro de dossier"}
      isLoading={isLoading}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue.length < 2) return "Entrez au moins 2 caractères";
        if (isLoading) return "Recherche en cours...";
        return `Pas de résultats pour « ${inputValue} »`;
      }}
      isMulti={false}
      isOptionDisabled={option => option.disabled}
    />
  );
};

const mapStateToProps = state => {
  return {
    clientReducer: state.clientReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchClientsPortfolio: query =>
      dispatch({
        type: FETCH_CLIENTS,
        payload: {
          previous: null,
          next: null,
          search: query.search,
          limit: FETCH_CLIENT_LIMIT
        }
      })
  };
};

FolderSearchInput.propTypes = {
  fetchClientsPortfolio: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  clientReducer: PropTypes.object.isRequired
  // {
  //   isLoading: PropTypes.bool.isRequired,
  //   clients: PropTypes.array.isRequired
  // }
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(FolderSearchInput);
