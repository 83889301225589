import _ from "underscore";

const initialState = {
  allSelect: false,
  selectionList: [],
  excludeList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_SELECTION": {
      return {
        ...initialState
      };
    }

    case "TOGGLE_SELECT_ALL": {
      return {
        ...state,
        allSelect: !state.allSelect,
        excludeList: [],
        selectionList: []
      };
    }

    case "TOGGLE_SELECT_DOCUMENT": {
      const document = action.payload;
      const isInSelection = _.some(
        state.selectionList,
        doc => doc._id === document._id
      );
      const isInDeselection = _.some(
        state.excludeList,
        doc => doc._id === document._id
      );

      if (state.allSelect) {
        if (isInSelection) {
          // Should not happend
          return state;
        }

        if (isInDeselection) {
          return {
            ...state,
            excludeList: state.excludeList.filter(
              doc => doc._id !== document._id
            )
          };
        }

        return {
          ...state,
          excludeList: [...state.excludeList, document]
        };
      }

      if (isInSelection) {
        return {
          ...state,
          selectionList: state.selectionList.filter(
            doc => doc._id !== document._id
          )
        };
      }

      return {
        ...state,
        selectionList: [...state.selectionList, document]
      };
    }

    default:
      return state;
  }
};

export default reducer;
