import PropTypes from "prop-types";

export const actionType = PropTypes.shape({
  date: PropTypes.date,
  author: PropTypes.string,
  value: PropTypes.string
});

export const applicationType = PropTypes.shape({
  docId: PropTypes.string,
  thumbnail: PropTypes.string,
  name: PropTypes.string
});

export const userType = PropTypes.shape({
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  tenantId: PropTypes.string,
  role: PropTypes.string,
  applications: PropTypes.arrayOf(PropTypes.shape({})),
  gedNotifications: PropTypes.arrayOf(PropTypes.string)
});

export const documentType = PropTypes.shape({
  _id: PropTypes.string,
  owner: PropTypes.string,
  size: PropTypes.number,
  field: PropTypes.string,
  title: PropTypes.string,
  companyName: PropTypes.string,
  creationDate: PropTypes.string,
  documentType: PropTypes.string
});

export const dashboardType = PropTypes.shape({
  _id: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  url: PropTypes.string,
  snapshot: PropTypes.string,
  date: PropTypes.string
});

export const clientType = PropTypes.shape({
  _id: PropTypes.string,
  indicators: PropTypes.arrayOf(PropTypes.shape({})),
  lastAccountabilityUpdate: PropTypes.string,
  collabAccessRight: PropTypes.bool
});

export const ticketType = PropTypes.shape({
  _id: PropTypes.string,
  companyName: PropTypes.string,
  field: PropTypes.string,
  category: PropTypes.string,
  subject: PropTypes.string,
  status: PropTypes.string,
  creationDate: PropTypes.string
});

export const folderType = PropTypes.shape({
  _id: PropTypes.string,
  label: PropTypes.string
});

export const authReducerType = PropTypes.shape({
  isAuthenticated: PropTypes.bool,
  isCollabSession: PropTypes.bool,
  isLoading: PropTypes.bool,
  user: userType
});

export const documentReducerType = PropTypes.shape({
  folder: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  selectedFolderId: PropTypes.string,
  parentFolders: PropTypes.arrayOf(PropTypes.string),
  rootFolders: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number,
  documentsCount: PropTypes.number,
  pageSize: PropTypes.number,
  sort: PropTypes.string,
  document: documentType,
  nonProcessedDocs: PropTypes.arrayOf(PropTypes.shape({})),
  recentlyProcessedDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  myDocs: PropTypes.arrayOf(PropTypes.shape({})),
  downloading: PropTypes.shape({})
});

export const dashboardReducerType = PropTypes.shape({
  dashboards: PropTypes.arrayOf(PropTypes.dashboardType)
});

export const favoriteReducerType = PropTypes.shape({
  favorites: PropTypes.arrayOf(PropTypes.shape({}))
});

export const applicationReducerType = PropTypes.shape({
  applications: PropTypes.arrayOf(applicationType),
  isLoading: PropTypes.bool
});

export const supportReducerType = PropTypes.shape({
  myTickets: PropTypes.arrayOf(ticketType),
  lateTickets: PropTypes.arrayOf(ticketType),
  isLoading: PropTypes.bool,
  status: PropTypes.arrayOf(PropTypes.string),
  supports: PropTypes.arrayOf(PropTypes.string)
});

export const missionReducerType = PropTypes.shape({
  missions: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool
});

export const clientReducerType = PropTypes.shape({
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  indicatorDictionnary: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool
});

export const indicatorReducerType = PropTypes.shape({
  indicators: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool
});

export const userReducerType = PropTypes.shape({
  users: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool
});

export const multiSelectionReducerType = PropTypes.shape({
  allSelect: PropTypes.bool,
  excludeList: PropTypes.arrayOf(PropTypes.shape({})),
  selectionList: PropTypes.arrayOf(PropTypes.shape({}))
});

export const downloadLinkType = PropTypes.shape({
  _id: PropTypes.string,
  documentName: PropTypes.string,
  hasPassword: PropTypes.bool,
  maxDownloads: PropTypes.number,
  downloadsCount: PropTypes.number,
  creationDate: PropTypes.string,
  author: PropTypes.string,
  url: PropTypes.string
});

export const downloadLinkReducerType = PropTypes.shape({
  isLoading: PropTypes.bool,
  downloadLink: downloadLinkType,
  downloadLinks: PropTypes.arrayOf(downloadLinkType)
});

export default {
  applicationType,
  userType,
  documentType,
  clientType,
  ticketType,
  folderType,
  authReducerType,
  documentReducerType,
  favoriteReducerType,
  clientReducerType,
  indicatorReducerType,
  applicationReducerType,
  supportReducerType,
  missionReducerType,
  multiSelectionReducerType,
  downloadLinkType,
  downloadLinkReducerType
};
