import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Loader from "../Loader/Loader";
import { authReducerType } from "../../_helpers/appProptypes";

class ApplicationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.hideSpinner = this.hideSpinner.bind(this);
  }

  componentDidMount() {
    const { authReducer } = this.props;
    if (!authReducer.user) {
      this.props.getUserInfo();
    }
  }

  hideSpinner = () => {
    this.setState({
      loading: false
    });
  };

  render() {
    const { authReducer } = this.props;
    const { appId } = this.props.match.params;
    if (authReducer.isLoading) return <Loader />;
    const app = authReducer.user.applications.filter(a => a._id === appId)[0];
    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <iframe
          title={app.name}
          width="100%"
          height="102%"
          frameBorder="0"
          allowFullScreen
          src={app.url}
          onLoad={this.hideSpinner}
        />
        <div
          id="back"
          style={{
            position: "absolute",
            bottom: "20px",
            color: "#263AD1",
            zIndex: "200",
            backgroundColor: "white",
            paddingRight: "5px"
          }}
        >
          <ButtonBase onClick={() => this.props.history.push("/applications")}>
            <ArrowLeft />
            Revenir aux applications
          </ButtonBase>
        </div>
      </>
    );
  }
}

ApplicationDetail.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  authReducer: authReducerType.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ appId: PropTypes.string })
  }).isRequired,
  getUserInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserInfo: () => dispatch({ type: "GET_USER_INFO" })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApplicationDetail));
