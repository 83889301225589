import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import utils from "../../_helpers/utils";
import Loader from "../Loader/Loader";

import "./DownloadDocument.scss";

const DownloadDocument = props => {
  const { linkId } = props.match.params;
  const { fetchInfo, downloadFile, documentReducer } = props;
  const [password, setPassword] = React.useState("");

  const [downloadStarted, setDownloadStarted] = React.useState(false);

  useEffect(() => {
    fetchInfo(linkId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { downloadSuccess } = documentReducer;
    setDownloadStarted(downloadSuccess);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentReducer.downloadSuccess]);

  if (!documentReducer.downloadLink) {
    return (
      <div className="DownloadDocument">
        <Grid container spacing={3}>
          <Grid item md={6} style={{ margin: "auto" }}>
            <Paper
              style={{
                textAlign: "center",
                padding: "62px 97px",
                backgroundColor: "white"
              }}
            >
              <Loader />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="DownloadDocument">
      <Grid container spacing={3}>
        <Grid item md={6} style={{ margin: "auto" }}>
          <Paper
            style={{
              textAlign: "center",
              padding: "62px 97px",
              backgroundColor: "white"
            }}
          >
            {documentReducer.downloadLink.maxDownloadsReached && (
              <>
                <Typography component="h3">
                  Nombre maximum de téléchargements atteint
                </Typography>
                <Typography component="h4">
                  Veuillez informer la personne ayant créé ce lien que le quota
                  d&apos;utilisation de ce lien a été atteint.
                </Typography>
              </>
            )}
            {!documentReducer.downloadLink.maxDownloadsReached &&
              !downloadStarted && (
                <Typography component="h3">Télécharger le document</Typography>
              )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div className="documentName">
                {documentReducer.downloadLink.documentName}
              </div>
              <div className="documentSize">
                {utils.readableBytes(documentReducer.downloadLink.documentSize)}
              </div>

              {downloadStarted && (
                <>
                  <div>
                    <CheckCircleIcon />
                  </div>
                  <div>Votre téléchargement a commencé</div>
                </>
              )}
              {!downloadStarted &&
                !documentReducer.downloadLink.maxDownloadsReached &&
                documentReducer.downloadLink.hasPassword && (
                  <form noValidate autoComplete="off">
                    <Typography component="label">Mot de passe</Typography>
                    <TextField
                      fullWidth
                      id="input-password"
                      placeholder="Entrez le mot de passe"
                      type="password"
                      margin="normal"
                      variant="outlined"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </form>
                )}
            </div>

            {!documentReducer.downloadLink.maxDownloadsReached &&
              !downloadStarted && (
                <Button
                  onClick={() => {
                    downloadFile(
                      linkId,
                      documentReducer.downloadLink.documentName,
                      password
                    );
                  }}
                  disabled={
                    documentReducer.downloadLink.hasPassword && !password
                  }
                  color="secondary"
                >
                  Télécharger
                </Button>
              )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

DownloadDocument.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      linkId: PropTypes.string
    })
  }).isRequired,
  fetchInfo: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  documentReducer: PropTypes.shape({
    downloadLink: PropTypes.shape({
      hasPassword: PropTypes.bool,
      maxDownloadsReached: PropTypes.bool,
      documentName: PropTypes.string,
      documentSize: PropTypes.number
    }),
    downloadSuccess: PropTypes.bool
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer
  };
};

const mapDispatchToProps = dispatch => ({
  fetchInfo: linkId => dispatch({ type: "FETCH_LINK_INFO", payload: linkId }),
  downloadFile: (linkId, documentName, password) =>
    dispatch({
      type: "DOWNLOAD_FILE_LINK",
      payload: { linkId, documentName, password }
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DownloadDocument));
