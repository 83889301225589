import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AuthenticatedRoute = ({
  authReducer: { isAuthenticated },
  path,
  component,
  exact,
  ...rest
}) => {
  if (isAuthenticated) {
    return (
      <Route
        exact={exact}
        path={path}
        render={props =>
          React.createElement(component, {
            ...props,
            ...rest
          })
        }
      />
    );
  }

  return <Route path={path} render={() => <Redirect to="/connexion" />} />;
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

AuthenticatedRoute.propTypes = {
  authReducer: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AuthenticatedRoute);
