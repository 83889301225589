import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ApiManager from "../../lib/apiManager";
import UsersList from "./UsersList";
import UserSettings from "./UserSettings";
import NewUser from "./NewUser";

import {
  userType,
  authReducerType,
  missionReducerType
} from "../../_helpers/appProptypes";
import { TOAST_ERROR_ACTION } from "../../actions/toast-actions";

class UsersSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      fetching: true,
      user: null,
      newUser: true
    };
    this.loadData = this.loadData.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.createUser = this.createUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  async componentDidMount() {
    if (this.props.authReducer.user)
      this.loadData(this.props.authReducer.user.tenantId);
    const { fetchMissions } = this.props;
    fetchMissions();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.tenantId !== this.props.tenantId) {
      this.loadData(nextProps.tenantId);
    }
    return true;
  }

  async loadData(tenantId) {
    let users = await ApiManager.callApi({
      ressource: `/users?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
    users = users.data.users.filter(a => this.props.user._id !== a._id);
    const currentUserId = (this.state.user || {})._id;

    this.setState({
      user: users.filter(u => u._id === currentUserId)[0],
      users,
      fetching: false
    });
  }

  async updateUser(user, tenantId) {
    try {
      await ApiManager.callApi({
        ressource: `/users/${user._id}`,
        method: "PUT",
        isAuthenticated: true,
        data: { user, tenantId }
      });
      this.loadData(tenantId);
    } catch (error) {
      let errorMessage =
        error.response.data.message ||
        "Erreur lors de la mise à jour de l'utilisateur";
      // CURRENT TENANT CHECK
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "NOTCURRENTTENANT"
      ) {
        errorMessage =
          "Erreur : le dossier traité n'est pas votre dossier courant. Veuillez recharger la page";
      }

      this.props.displayError(errorMessage);
      this.setState({ loadingCreateNewUser: false });
    }
  }

  async createUser(
    firstname,
    lastname,
    email,
    password,
    missions,
    applications,
    tenantId
  ) {
    this.setState({ loadingCreateNewUser: true });
    const user = {
      firstname,
      lastname,
      email,
      password,
      missions: [...missions],
      applications: [...applications],
      tenants: [this.props.user.tenantId || this.props.user.tenants[0]._id],
      role: "tenantUser"
    };
    try {
      await ApiManager.callApi({
        ressource: "/users",
        method: "POST",
        isAuthenticated: true,
        data: { user, tenantId }
      });
      this.loadData(tenantId);
      this.setState({ loadingCreateNewUser: false });
    } catch (error) {
      let errorMessage =
        error.response.data.message ||
        "Erreur lors de la création de l'utilisateur";
      // CURRENT TENANT CHECK
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "NOTCURRENTTENANT"
      ) {
        errorMessage =
          "Erreur : le dossier traité n'est pas votre dossier courant. Veuillez recharger la page";
      }

      this.props.displayError(errorMessage);
      this.setState({ loadingCreateNewUser: false });
    }
  }

  async deleteUser(user, tenantId) {
    try {
      await this.setState({ fetching: true });
      await ApiManager.callApi({
        ressource: `/users/${user._id}?tenantId=${tenantId}`,
        method: "DELETE",
        isAuthenticated: true
      });
      this.loadData(tenantId);
    } catch (error) {
      let errorMessage =
        error.response.data.message ||
        "Erreur lors de la création de l'utilisateur";
      // CURRENT TENANT CHECK
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "NOTCURRENTTENANT"
      ) {
        errorMessage =
          "Erreur : le dossier traité n'est pas votre dossier courant. Veuillez recharger la page";
      }

      this.props.displayError(errorMessage);
      this.setState({ loadingCreateNewUser: false });
    }
  }

  render() {
    const { missionReducer, authReducer } = this.props;
    const { missions: allMissions } = missionReducer;
    const currentUserEmail = this.props.user.email;

    let filteredApps = [];
    let filteredMissions = [];
    let filteredUsers = [];

    if (authReducer) {
      const currentTenantId =
        authReducer.user.tenantId || authReducer.user.tenants[0]._id;
      const currentTenant = authReducer.user.tenants.find(
        t => t._id === currentTenantId
      );

      if (
        authReducer.user.applications &&
        authReducer.user.applications.length > 0
      ) {
        filteredApps = authReducer.user.applications
          .find(app => app.tenantId === currentTenantId)
          // filter applications without url, but keep bdo_tdb
          .applications.filter(
            a => (a.url && a.url !== "") || a._id === "bdo_tdb"
          );
      }

      filteredMissions = allMissions.filter(m =>
        currentTenant.missions.includes(m._id)
      );

      filteredUsers = this.state.users.filter(u =>
        u.tenants.includes(currentTenantId)
      );
    }

    return (
      <div className="settings__users flex flex__row flex__left ">
        <UsersList
          selectUser={user => this.setState({ user })}
          users={filteredUsers}
          fetching={this.state.fetching}
          clearUserSelection={() => this.setState({ user: null })}
          deleteUser={async user => {
            await this.deleteUser(user, authReducer.user.tenantId);
          }}
          missions={allMissions}
          currentUserEmail={currentUserEmail}
        />
        {this.state.user && (
          <UserSettings
            user={this.state.user}
            newUser={this.state.newUser}
            updateAccount={async account => {
              await this.updateUser(account, authReducer.user.tenantId);
            }}
            allMissions={filteredMissions}
            allApps={filteredApps}
            currentUserEmail={currentUserEmail}
          />
        )}
        {!this.state.user && (
          <NewUser
            loading={this.state.loadingCreateNewUser}
            createAccountRequest={async (
              firstname,
              lastname,
              email,
              password,
              missions,
              applications
            ) => {
              this.setState({
                loadingCreateNewUser: true
              });
              await this.createUser(
                firstname,
                lastname,
                email,
                password,
                missions,
                applications,
                authReducer.user.tenantId
              );
            }}
            allMissions={filteredMissions}
            allApps={filteredApps}
          />
        )}
      </div>
    );
  }
}

UsersSettings.propTypes = {
  user: userType.isRequired,
  tenantId: PropTypes.string.isRequired,
  fetchMissions: PropTypes.func.isRequired,
  authReducer: authReducerType.isRequired,
  missionReducer: missionReducerType.isRequired,
  displayError: PropTypes.func.isRequired
};

const dispatchStateToProps = dispatch => {
  return {
    fetchMissions: () => dispatch({ type: "FETCH_MISSIONS" }),
    displayError: value =>
      dispatch({ type: TOAST_ERROR_ACTION, payload: value })
  };
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    missionReducer: state.missionReducer
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(UsersSettings);
