import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import {
  TOAST_ERROR_ACTION,
  TOAST_SUCCESS_ACTION
} from "../actions/toast-actions";
import { handleError } from "./sagaCommon";
import {
  FETCH_SUPPORTS,
  FETCH_TICKETS,
  SEND_SUPPORT,
  UPDATE_TICKET_FILTERS
} from "../actions/ticket-actions";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchSupportsSync() {
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/tickets/supports",
      method: "GET",
      isAuthenticated: true
      // useMock: true
    });
    yield put({
      type: "FETCH_SUPPORTS_ASYNC",
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchSupports() {
  yield takeLatest(FETCH_SUPPORTS, onFetchSupportsSync);
}

function* onSendSupportSync({ payload }) {
  try {
    const { tenantId } = yield select(state => state.authReducer.user);
    const data = { ...payload, tenantId };
    yield call(ApiManager.callApi, {
      ressource: "/tickets",
      method: "POST",
      data,
      isAuthenticated: true
    });
    yield put({
      type: TOAST_SUCCESS_ACTION,
      payload: "Votre demande de support a été envoyée"
    });
  } catch (error) {
    yield handleError(error, "Erreur lors de l'envoi de votre demande");
  }
}

export function* onSendSupport() {
  yield takeLatest(SEND_SUPPORT, onSendSupportSync);
}

function* onFetchTicketHistorySync() {
  try {
    yield put({ type: "LOAD_TICKETS" });

    const response = yield call(ApiManager.callApi, {
      ressource: "/tickets/history",
      method: "GET",
      isAuthenticated: true
    });

    yield put({ type: "FETCH_TICKET_HISTORY_ASYNC", payload: response.data });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Une erreur s'est produite lors de la récupération des tickets"
    });
  }
}

export function* onFetchTicketHistory() {
  yield takeLatest("FETCH_TICKET_HISTORY", onFetchTicketHistorySync);
}

function* onFetchTicketsSync() {
  const {
    page,
    pageSize,
    sort,
    order,
    status,
    mission,
    searchTerm,
    unread
  } = yield select(state => state.supportReducer);

  const { tenantId } = yield select(state => state.authReducer.user);

  if (status.length === 0) {
    yield put({
      type: "FETCH_TICKETS_ASYNC",
      payload: []
    });
  } else {
    let queryParams = `?tenantId=${tenantId}&page=${page}&search=${searchTerm ||
      ""}&pageSize=${pageSize}&order=${order}&status=${status.join(
      ","
    )}&mission=${mission.join(",")}`;
    if (sort) {
      queryParams += `&sort=${sort}`;
    }
    if (unread) {
      queryParams += `&unread=${true}`;
    }
    try {
      const response = yield call(ApiManager.callApi, {
        ressource: `/tickets${queryParams}`,
        method: "GET",
        isAuthenticated: true
      });
      yield put({
        type: "FETCH_TICKETS_ASYNC",
        payload: response.data
      });
      if (response.data.ticketNotifications) {
        yield put({
          type: "UPDATE_TICKET_NOTIFICATIONS",
          payload: { ticketNotifications: response.data.ticketNotifications }
        });
      }
    } catch (error) {
      yield handleError(
        error,
        "Une erreur s'est produite lors de la récupération des demandes"
      );
    }
  }
}

export function* onFetchTickets() {
  yield takeLatest(FETCH_TICKETS, onFetchTicketsSync);
}

function* onFetchTicketSync({ payload }) {
  try {
    yield put({ type: "LOAD_TICKETS" });
    const { ticketId } = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    const response = yield call(ApiManager.callApi, {
      ressource: `/tickets/${ticketId}?tenantId=${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });
    yield put({
      type: "FETCH_TICKET_ASYNC",
      payload: response.data
    });
    if (response.data.ticketNotifications) {
      yield put({
        type: "UPDATE_TICKET_NOTIFICATIONS",
        payload: { ticketNotifications: response.data.ticketNotifications }
      });
    }
  } catch (error) {
    yield handleError(
      error,
      "Une erreur s'est produite lors de la récupération de la demande"
    );
  }
}

export function* onFetchTicket() {
  yield takeLatest("FETCH_TICKET", onFetchTicketSync);
}

function* onUpdateTicketFiltersSync({ payload }) {
  try {
    const {
      page,
      pageSize,
      sort,
      status,
      mission,
      searchTerm = ""
    } = yield select(state => state.supportReducer);
    const newPage = payload.page;
    const newPageSize = payload.pageSize;
    const newSort = payload.sort;
    const newStatus = payload.status;
    const newMission = payload.mission;
    const newSearchTerm = payload.searchTerm || ".*";
    const { historyRoute } = payload;

    const newOptions = {
      page: newPage === undefined ? page : newPage,
      pageSize: newPageSize || pageSize,
      sort: newSort || sort,
      status: newStatus || status,
      mission: newMission || mission,
      searchTerm: newSearchTerm || searchTerm,
      unread: payload.unread || false
    };

    yield put({
      type: "UPDATE_TICKET_FILTERS_ASYNC",
      payload: newOptions
    });

    if (historyRoute) {
      const response = yield call(ApiManager.callApi, {
        ressource: `/tickets/history?status=${newStatus.join(",")}`,
        method: "GET",
        isAuthenticated: true
      });

      yield put({ type: "FETCH_TICKET_HISTORY_ASYNC", payload: response.data });
    } else {
      yield put({ type: FETCH_TICKETS });
    }
  } catch (error) {
    yield put({ type: TOAST_ERROR_ACTION, payload: error.message });
  }
}

export function* onUpdateTicketFilters() {
  yield takeLatest(UPDATE_TICKET_FILTERS, onUpdateTicketFiltersSync);
}

function* onUpdateTicketSync({ payload }) {
  try {
    const { action } = payload;
    const ticketId = yield select(state => state.supportReducer.ticket._id);
    const { tenantId } = yield select(state => state.authReducer.user);

    const response = yield call(ApiManager.callApi, {
      ressource: `/tickets/${ticketId}`,
      method: "PUT",
      isAuthenticated: true,
      data: { action, tenantId }
    });

    yield put({ type: "UPDATE_TICKET_ASYNC", payload: response.data });
  } catch (error) {
    yield handleError(
      error,
      "Une erreur s'est produite lors de la mise à jour de la demande"
    );
  }
}

export function* onUpdateTicket() {
  yield takeLatest("UPDATE_TICKET", onUpdateTicketSync);
}

function* onReadTicketSync({ payload }) {
  try {
    const { ticketId } = payload;
    const { tenantId } = yield select(state => state.authReducer.user);

    const response = yield call(ApiManager.callApi, {
      ressource: `/tickets/${ticketId}/read`,
      method: "PUT",
      isAuthenticated: true,
      data: { tenantId }
    });

    yield put({
      type: "UPDATE_TICKET_NOTIFICATIONS",
      payload: { ticketNotifications: response.data.ticketNotifications }
    });
  } catch (error) {
    yield handleError(
      error,
      "Une erreur s'est produite lors du marque de la demande comme lue"
    );
  }
}

export function* onReadTicket() {
  yield takeLatest("MARK_TICKET_AS_READ", onReadTicketSync);
}

function* onReadAllTicketsSync() {
  try {
    const { tenantId } = yield select(state => state.authReducer.user);
    const response = yield call(ApiManager.callApi, {
      ressource: `/tickets/read`,
      method: "PUT",
      isAuthenticated: true,
      data: { tenantId }
    });

    yield put({
      type: "UPDATE_TICKET_NOTIFICATIONS",
      payload: { ticketNotifications: response.data.ticketNotifications }
    });
  } catch (error) {
    yield handleError(error);
  }
}

export function* onReadAllTickets() {
  yield takeLatest("MARK_ALL_TICKETS_AS_READ", onReadAllTicketsSync);
}
