import { takeLatest, put, call } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { TOAST_ERROR_ACTION } from "../actions/toast-actions";
import { handleError } from "./sagaCommon";
import { USER_LOGOUT } from "../actions/login-actions";

function* onFetchUsersSync() {
  try {
    yield put({ type: "LOADING_USERS" });
    const response = yield call(ApiManager.callApi, {
      ressource: `/users/all`,
      method: "GET",
      isAuthenticated: true
    });
    yield put({ type: "FETCH_USERS_SYNC", payload: response.data });
  } catch (error) {
    yield handleError(
      error,
      "Une erreur s'est produite lors du chargement des utilisateurs"
    );
    if (error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
    }
  }
}

export function* onFetchUsers() {
  yield takeLatest("FETCH_USERS", onFetchUsersSync);
}

function* onAskUserUpdateSync({ payload }) {
  try {
    const { user } = payload;
    const { _id } = user;

    yield call(ApiManager.callApi, {
      ressource: `/users/${_id}`,
      method: "PUT",
      isAuthenticated: true,
      data: {
        user
      }
    });
    const reducerPayload = {
      user
    };
    yield put({ type: "ASK_USER_UPDATE_SYNC", payload: reducerPayload });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: error.message
    });
  }
}

export function* onAskUserUpdate() {
  yield takeLatest("ASK_USER_UPDATE", onAskUserUpdateSync);
}
