import { takeLatest, put, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import ApiManager from "../lib/apiManager";
import AuthManager from "../lib/authManager";
import {
  TOAST_ERROR_ACTION,
  TOAST_INFO_ACTION
} from "../actions/toast-actions";
import {
  TRY_LOGIN,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_MISSING_MFA,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "../actions/login-actions";

function* onLoginSync({ payload }) {
  const { login, password, token } = payload;
  yield put({
    type: TRY_LOGIN
  });

  const data = {
    login,
    password
  };

  if (token) {
    data.token = token;
  }

  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/login",
      method: "POST",
      data
    });
    if (response.data.error && response.data.error === "missing_token") {
      yield put({
        type: USER_LOGIN_MISSING_MFA
      });

      return;
    }
    AuthManager.login(response.data);
    const user = response.data;

    let newDocsCount = 0;
    if (user.gedNotifications && user.gedNotifications.length > 0) {
      newDocsCount = user.gedNotifications.length;
    }
    let newTicketsCount = 0;
    if (user.ticketNotifications && user.ticketNotifications.length > 0) {
      newTicketsCount = user.ticketNotifications.length;
    }

    yield put({
      type: USER_LOGIN_SUCCESS,
      payload: user
    });
    const message = `Bonjour ${user.firstname} ${user.lastname} !`;

    let toastPayload = message;
    if (newDocsCount || newTicketsCount) {
      toastPayload = {
        message,
        displayNotifications: true,
        name: `${user.firstname} ${user.lastname}`,
        disableAutoClose: true,
        newDocsCount,
        newTicketsCount
      };
    }
    yield put({
      type: TOAST_INFO_ACTION,
      payload: toastPayload
    });
    yield put({
      type: "FAVORITES_FETCH_SUCCESS",
      payload: user.favorites
    });
  } catch (error) {
    yield put({
      type: USER_LOGIN_ERROR
    });
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Vos identifiants sont incorrects"
    });
  }
}

export function* onLogin() {
  yield takeLatest(USER_LOGIN, onLoginSync);
}

function* onValidateAccountSync({ payload }) {
  const { password, token } = payload;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: `/users/accountValidation/${token}`,
      method: "POST",
      data: {
        password
      }
    });

    yield put({
      type: USER_LOGIN,
      payload: { login: response.data.email, password }
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Compte déjà validé ou token incorrect"
    });
  }
}

export function* onValidateAccount() {
  yield takeLatest("VALIDATE_ACCOUNT", onValidateAccountSync);
}

function* onRequestResetPasswordTokenSync({ payload }) {
  const { login } = payload;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/users/password/reset",
      method: "POST",
      data: {
        email: login
      }
    });
    yield put({
      type: "RESET_PASSWORD_SUCCESS",
      payload: response.data
    });
    AuthManager.logout();
    yield put({ type: USER_LOGOUT });
    yield put(push("/linkSent"));
  } catch (error) {
    yield put({
      type: "ERROR",
      payload: { error }
    });
  }
}

export function* onRequestResetPasswordToken() {
  yield takeLatest("RESET_PASSWORD", onRequestResetPasswordTokenSync);
}

function* onChangePasswordAsync({ payload }) {
  const { password, token } = payload;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: `/users/passwordReset/${token}`,
      method: "POST",
      data: {
        password
      }
    });
    yield put({
      type: "CHANGE_PASSWORD_SUCCESS",
      payload: response.data
    });
    yield put(push("/connexion"));
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Le changement de mot de passe a échoué"
    });
  }
}

export function* onChangePassword() {
  yield takeLatest("CHANGE_PASSWORD", onChangePasswordAsync);
}

function* onGetUserInfoAsync() {
  try {
    yield put({
      type: TRY_LOGIN
    });
    const response = yield call(ApiManager.callApi, {
      ressource: "/users/info",
      method: "GET",
      isAuthenticated: true
    });

    yield put({
      type: USER_LOGIN_SUCCESS,
      payload: response.data
    });

    yield put({
      type: "UPDATE_FAVORITES_SYNC",
      payload: response.data.favorites
    });

    const user = response.data;
    const newDocsCount = (user.gedNotifications || []).length;
    const newTicketsCount = (user.ticketNotifications || []).length;

    if (newDocsCount || newTicketsCount) {
      const toastPayload = {
        message: " ",
        displayNotifications: true,
        name: `${user.firstname} ${user.lastname}`,
        disableAutoClose: true,
        newDocsCount,
        newTicketsCount
      };

      yield put({
        type: TOAST_INFO_ACTION,
        payload: toastPayload
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put({
        type: USER_LOGOUT
      });
      yield put({
        type: "EVENTS_CLEAR"
      });
    }
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de récupérer le document user"
    });
  }
}

export function* onGetUserInfo() {
  yield takeLatest("GET_USER_INFO", onGetUserInfoAsync);
}

function* onAccessClientPortalAsync({ payload }) {
  try {
    const { tenantId } = payload;
    const response = yield call(ApiManager.callApi, {
      ressource: `/users/collabSession/${tenantId}`,
      method: "GET",
      isAuthenticated: true
    });

    AuthManager.startCollabSession(response.data);

    const {
      powerslideToken,
      dashboardIds,
      applications,
      tenant
    } = response.data;
    yield put({
      type: "ACCESS_CLIENT_PORTAL_SUCCESS",
      payload: { tenantId, powerslideToken, dashboardIds, applications, tenant }
    });
    yield put(push("/"));
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible d'accéder au portail du client"
    });
  }
}

export function* onAccessClientPortal() {
  yield takeLatest("ACCESS_CLIENT_PORTAL", onAccessClientPortalAsync);
}

function* onExitClientPortalAsync() {
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/users/stopCollabSession",
      method: "GET",
      isAuthenticated: true
    });

    const { token, tenants, applications } = response.data;
    AuthManager.stopCollabSession(token);

    yield put({
      type: "EXIT_CLIENT_PORTAL_SUCCESS",
      payload: { tenants, applications }
    });
    yield put(push("/"));
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de quitter le portail du client"
    });
  }
}

export function* onExitClientPortal() {
  yield takeLatest("EXIT_CLIENT_PORTAL", onExitClientPortalAsync);
}

function* onUpdateCurrentTenantAsync({ payload }) {
  const tenantId = payload;
  try {
    const response = yield call(ApiManager.callApi, {
      ressource: "/users/tenant",
      method: "PUT",
      isAuthenticated: true,
      data: { tenantId }
    });

    const {
      token,
      missions,
      gedNotifications,
      ticketNotifications,
      favorites,
      role
    } = response.data;

    yield put({
      type: "UPDATE_CURRENT_TENANT_SYNC",
      payload: {
        tenantId,
        token,
        missions,
        gedNotifications,
        ticketNotifications,
        role
      }
    });
    yield put({
      type: "UPDATE_FAVORITES_SYNC",
      payload: favorites
    });
  } catch (error) {
    yield put({
      type: TOAST_ERROR_ACTION,
      payload: "Impossible de sélectionner cette entreprise"
    });
  }
}

export function* onUpdateCurrentTenant() {
  yield takeLatest("UPDATE_CURRENT_TENANT", onUpdateCurrentTenantAsync);
}
