import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const PortfolioIndicator = ({ counter, text, pluralText }) => {
  return (
    <Paper>
      <div className="px-4 py-6 text-center">
        <span className="bold text-2xl text-center">{`${counter} `}</span>
        <span className="text-center opacity-60">
          {counter > 1 ? pluralText : text}
        </span>
      </div>
    </Paper>
  );
};

PortfolioIndicator.propTypes = {
  counter: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  pluralText: PropTypes.string.isRequired
};

export default PortfolioIndicator;
