import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import PropTypes from "prop-types";
import React from "react";

const SortIcon = ({ orderAsc = false }) => {
  return (
    <div className="flex items-center justify-center">
      {orderAsc ? (
        <ArrowDownward className="sortIcon" />
      ) : (
        <ArrowUpward className="sortIcon" />
      )}
    </div>
  );
};

SortIcon.propTypes = {
  orderAsc: PropTypes.bool
};

export default SortIcon;
