import { takeLatest, put, call, select } from "redux-saga/effects";
import ApiManager from "../lib/apiManager";
import { handleError } from "./sagaCommon";

function* updateFavoritesSync({ payload }) {
  const { action, favorite } = payload;
  const { tenantId } = yield select(state => state.authReducer.user);

  const favoriteState = yield select(state => state.favoriteReducer);
  const oldFavorites = favoriteState.favorites;
  let favorites;
  if (action === "add") {
    favorites = [...oldFavorites, favorite];
  } else {
    favorites = oldFavorites.filter(fav => fav.docId !== favorite.docId);
  }
  try {
    yield put({
      type: "UPDATE_FAVORITES_SYNC",
      payload: favorites
    });
    yield call(ApiManager.callApi, {
      ressource: "/users/favorites",
      method: "PUT",
      // data: { favorites: [{ test: "", test2: "" }] },
      data: { favorites, tenantId },
      isAuthenticated: true
    });
  } catch (error) {
    yield put({
      type: "UPDATE_FAVORITES_SYNC",
      payload: oldFavorites
    });
    yield handleError(error);
  }
}

export function* onUpdateFavorites() {
  yield takeLatest("UPDATE_FAVORITES", updateFavoritesSync);
}
