import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { Box, Typography } from "@material-ui/core";
import { ticketType, authReducerType } from "../../_helpers/appProptypes";

const TicketLineMobile = props => {
  const { ticket, history, authReducer /* , selectable */ } = props;
  let newTickets = [];
  if (authReducer.user.ticketNotifications) {
    newTickets = [...authReducer.user.ticketNotifications];
  }
  const SubjectComponent = React.forwardRef((propsRef, ref) => (
    <div
      {...propsRef}
      ref={ref}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {ticket.subject}
    </div>
  ));

  const isNewTicket = newTickets.indexOf(ticket._id) !== -1;

  return (
    <TableRow
      hover
      key={ticket._id}
      onClick={() => history.push(`/assistance/${ticket._id}`)}
      style={{
        cursor: "pointer",
        fontWeight: isNewTicket ? "bold" : "normal",
        background: isNewTicket ? "#fafaff" : ""
      }}
      className="TicketLine"
    >
      <TableCell
        align="left"
        component="th"
        scope="row"
        style={{ fontWeight: "inherit", width: "5px", padding: "0px" }}
      >
        {isNewTicket && (
          <b>
            <Badge
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "center",
                horizontal: "left"
              }}
            />
          </b>
        )}
      </TableCell>

      <TableCell align="left" style={{ fontWeight: "inherit" }}>
        <Tooltip title={`${ticket.subject}`}>
          <>
            <Box display="flex" justifyContent="space-between">
              {ticket.status === "closed" && (
                <div style={{ color: "green" }}>
                  <b>Clôturé</b>
                </div>
              )}
              {ticket.status === "opened" && (
                <div style={{ color: "red" }}>
                  <b>A traiter</b>
                </div>
              )}
              {ticket.status === "inProgress" && (
                <div style={{ color: "orange" }}>
                  <b>En cours</b>
                </div>
              )}
              <Box>
                <Typography variant="caption">
                  {moment(ticket.creationDate).format("DD/MM/YYYY HH:mm")}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h6">
              <SubjectComponent />
            </Typography>
            <Box marginTop="10px">
              <Typography variant="caption">
                {ticket.field} / {ticket.category}
              </Typography>
            </Box>
          </>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

TicketLineMobile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  ticket: ticketType.isRequired,
  authReducer: authReducerType.isRequired // ,
  //   selectable: PropTypes.bool
};

TicketLineMobile.defaultProps = {
  //   selectable: false
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markAsRead: ticketId =>
      dispatch({
        type: "MARK_TICKET_AS_READ",
        payload: { ticketId }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketLineMobile));
