import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import BarChart from "@material-ui/icons/BarChart";
import Build from "@material-ui/icons/Build";
import Home from "@material-ui/icons/Home";
import ViewModule from "@material-ui/icons/ViewModule";
import Receipt from "@material-ui/icons/Receipt";
import StarRate from "@material-ui/icons/StarRate";
import LinkIcon from "@material-ui/icons/Link";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Eject from "@material-ui/icons/Eject";
import Folder from "@material-ui/icons/Folder";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SettingsIcon from "@material-ui/icons/Settings";
import LiveHelp from "@material-ui/icons/LiveHelp";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import {
  authReducerType,
  favoriteReducerType
} from "../../_helpers/appProptypes";
import "./Sidebar.scss";

import logoHDM from "../../images/LOGO_HDM_2.svg";
import SidebarClientInfo from "./SidebarClientInfo";
import AuthManager from "../../lib/authManager";
import Loader from "../Loader/Loader";
import { HIDE_TOAST_ACTION } from "../../actions/toast-actions";
import { isAdmin, isCollab, isCollabOrAdmin } from "../../_helpers/utils";
import { USER_LOGOUT } from "../../actions/login-actions";
import FileCopy from "@material-ui/icons/FileCopy";

const drawerWidth = isMobile ? 0 : 260;
const dockerdDrawerWidth = isMobile ? 0 : 74;

const useStyles = makeStyles(theme => {
  const background = props => props.background;
  return {
    drawerPaper: {
      [theme.breakpoints.down("sm")]: {
        position: "fixed"
      },
      whiteSpace: "nowrap",
      background,
      color: "white",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperOpen: {
      [theme.breakpoints.down("sm")]: {
        width: "260px"
      }
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      },
      [theme.breakpoints.down("sm")]: {
        width: 0
      }
    },
    menuListItem: {
      color: "white !important",
      paddingLeft: "5px",
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.4)"
      }
    },
    menuListItemHighlighted: {
      color: "white !important",
      paddingLeft: "5px",
      backgroundColor: "rgba(255,255,255,0.2)",
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.3)"
      }
    },
    menuListItemIcon: {
      color: "white !important",
      paddingLeft: "5px"
    },
    menuListItemText: {
      flex: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  };
});

const getFavoriteLink = (favorite, applications) => {
  if (favorite.type === "dashboard")
    return `/tableau-de-bord/${favorite.docId}`;
  if (favorite.type === "application") {
    const app = applications.find(a => a._id === favorite.docId);
    return `${app.url}`;
  }
  if (favorite.type === "folder") return `/ged/${favorite.docId}`;
  return `/document/${favorite.docId}`;
};

const renderFavoriteIcon = favorite => {
  if (favorite.type === "dashboard") {
    return <BarChart />;
  }
  if (favorite.type === "application") {
    return <ViewModule />;
  }

  if (favorite.type === "folder") {
    return <Folder />;
  }

  return <Receipt />;
};

const Sidebar = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(isMobile);
  const [isFavoritesMenuOpen, setIsFavoritesMenuOpen] = useState(isDrawerOpen);
  const [newDocsCount, setNewDocsCount] = useState(0);
  const [newTicketsCount, setNewTicketsCount] = useState(0);
  const [missions, setMissions] = useState([]);
  const {
    logout,
    favoriteReducer,
    history,
    changeMarginWidth,
    authReducer,
    exitClientPortal,
    location,
    toggleDrawerExternal
  } = props;

  const styleProps = {
    background:
      authReducer.user && isCollab(authReducer.user.role)
        ? "linear-gradient(#134594, #263AD1)"
        : "linear-gradient(#1E2A2E, #797979)"
  };

  const classes = useStyles(styleProps);

  const activePath = ((location || {}).pathname || "/").split("/")[1];

  let accessToDashboards = false;
  let applications = [];

  if (authReducer.user) {
    const { user } = authReducer;
    let { tenantId } = user;
    if (!tenantId) {
      tenantId = user.tenants[0]._id;
    }

    if (["tenantUser", "tenantAdmin", "admin"].includes(user.role)) {
      ({ applications } =
        (user.applications || []).find(app => app.tenantId === tenantId) || {});
    }

    accessToDashboards = !applications
      ? false
      : applications.filter(app => app._id === "bdo_tdb").length > 0;
  }

  const toggleDrawer = () => {
    if (isDrawerOpen) {
      setIsFavoritesMenuOpen(false);
      setIsDrawerOpen(!isDrawerOpen);
      changeMarginWidth(dockerdDrawerWidth);
    } else {
      setIsDrawerOpen(!isDrawerOpen);
      changeMarginWidth(drawerWidth);
    }
  };

  React.useEffect(() => {
    if (authReducer.user) {
      const gedNotifications = authReducer.user.gedNotifications || [];
      setNewDocsCount(gedNotifications.length);

      const ticketNotifications = authReducer.user.ticketNotifications || [];
      setNewTicketsCount(ticketNotifications.length);

      // get missions
      const userMissions = !authReducer.user.missions
        ? []
        : authReducer.user.missions
            .filter(m => m) // filter out nulls
            .map(m => (m._id ? m._id : m));
      setMissions(userMissions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducer.user]);

  React.useEffect(() => {
    toggleDrawer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDrawerExternal]);

  // if (authReducer.isLoading || !authReducer.user) return null;
  if (authReducer.isLoading || !authReducer.user) return <Loader />;

  return (
    <Drawer
      variant="permanent"
      open={isDrawerOpen}
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !isDrawerOpen && classes.drawerPaperClose,
          isDrawerOpen && classes.drawerPaperOpen,
          "Sidebar"
        )
      }}
    >
      <div
        className="container"
        style={{ display: "flex", flex: "1 1 auto", flexDirection: "column" }}
      >
        <div>
          <div style={{ display: "inline-block" }}>
            <ButtonBase onClick={() => history.push("/")}>
              <img
                src={logoHDM}
                alt="logo HDM"
                height={isDrawerOpen ? "90px" : "22px"}
              />
            </ButtonBase>
          </div>
          <div
            style={{ display: "inline-block", float: "right", color: "white" }}
          >
            <IconButton onClick={toggleDrawer} color="inherit">
              {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
        </div>
        <SidebarClientInfo isDrawerOpen={isDrawerOpen} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0",
            borderTop: isDrawerOpen ? "3px solid #e6223f" : ""
          }}
        >
          <List style={{ flex: "1 1 auto" }}>
            <ListItem
              className={
                ["", "portefeuille"].includes(activePath)
                  ? classes.menuListItemHighlighted
                  : classes.menuListItem
              }
              button
              onClick={() => {
                if (isMobile) {
                  toggleDrawer();
                }
                return !isCollab(authReducer.user.role) ||
                  authReducer.isCollabSession
                  ? history.push("/")
                  : history.push("/portefeuille");
              }}
            >
              <ListItemIcon className={classes.menuListItemIcon}>
                <Home />
              </ListItemIcon>
              <ListItemText
                className={classes.menuListItemText}
                primary={
                  !isCollab(authReducer.user.role) ||
                  authReducer.isCollabSession
                    ? "Accueil"
                    : "Portefeuille"
                }
              />
            </ListItem>
            {// user has tdbapplication
            !isMobile &&
              accessToDashboards &&
              (!isCollab(authReducer.user.role) ||
                authReducer.isCollabSession) && (
                <ListItem
                  className={
                    ["tableau-de-bord"].includes(activePath)
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    history.push("/tableau-de-bord");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <BarChart />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="Tableaux de bord"
                  />
                </ListItem>
              )}
            {isCollab(authReducer.user.role) && !authReducer.isCollabSession && (
              <ListItem
                className={
                  ["documents"].includes(activePath)
                    ? classes.menuListItemHighlighted
                    : classes.menuListItem
                }
                button
                onClick={() => {
                  if (isMobile) {
                    toggleDrawer();
                  }
                  history.push("/documents");
                }}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  {newDocsCount && !authReducer.isCollabSession ? (
                    <Badge badgeContent={newDocsCount} color="primary">
                      <Receipt />
                    </Badge>
                  ) : (
                    <Receipt />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.menuListItemText}
                  primary="Docs à traiter"
                />
              </ListItem>
            )}
            {missions.length > 0 &&
              (["tenantAdmin", "tenantUser"].includes(authReducer.user.role) ||
                authReducer.isCollabSession) && (
                <ListItem
                  className={
                    ["ged"].includes(activePath)
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    history.push("/ged");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    {newDocsCount ? (
                      <Badge badgeContent={newDocsCount} color="primary">
                        <Receipt />
                      </Badge>
                    ) : (
                      <Receipt />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="GED"
                  />
                </ListItem>
              )}

            {authReducer.user.role !== "admin" && (
              <ListItem
                className={
                  ["applications"].includes(activePath)
                    ? classes.menuListItemHighlighted
                    : classes.menuListItem
                }
                button
                onClick={() => {
                  if (isMobile) {
                    toggleDrawer();
                  }
                  history.push("/applications");
                }}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  <ViewModule />
                </ListItemIcon>
                <ListItemText
                  className={classes.menuListItemText}
                  primary="Applications"
                />
              </ListItem>
            )}

            {!["admin", "collab"].includes(authReducer.user.role) && (
              <ListItem
                className={
                  ["liens-utiles"].includes(activePath)
                    ? classes.menuListItemHighlighted
                    : classes.menuListItem
                }
                button
                onClick={() => {
                  if (isMobile) {
                    toggleDrawer();
                  }
                  history.push("/liens-utiles");
                }}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.menuListItemText}
                  primary="Liens utiles"
                />
              </ListItem>
            )}
            {!isMobile &&
            !["admin", "collab"].includes(authReducer.user.role) &&
            favoriteReducer.favorites.length ? (
              <>
                <ListItem
                  button
                  className={classes.menuListItem}
                  onClick={() => {
                    if (!isFavoritesMenuOpen) {
                      setIsFavoritesMenuOpen(!isFavoritesMenuOpen);
                      setIsDrawerOpen(true);
                    } else setIsFavoritesMenuOpen(!isFavoritesMenuOpen);
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <StarRate />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="Favoris"
                  />
                  {isFavoritesMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={isFavoritesMenuOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {favoriteReducer.favorites.map(favorite => (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => {
                          const link = getFavoriteLink(favorite, applications);
                          return favorite.type === "application"
                            ? window.open(link, "_blank")
                            : history.push(link);
                        }}
                        key={favorite.docId}
                      >
                        <ListItemIcon className={classes.menuListItemIcon}>
                          {renderFavoriteIcon(favorite)}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.menuListItemText}
                          primary={favorite.name}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : null}
            {!["admin", "collab"].includes(authReducer.user.role) && (
              <ListItem
                className={
                  ["assistance"].includes(activePath)
                    ? classes.menuListItemHighlighted
                    : classes.menuListItem
                }
                button
                onClick={() => {
                  if (isMobile) {
                    toggleDrawer();
                  }
                  history.push("/assistance");
                }}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  {newTicketsCount ? (
                    <Badge badgeContent={newTicketsCount} color="primary">
                      <LiveHelp />
                    </Badge>
                  ) : (
                    <LiveHelp />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.menuListItemText}
                  primary="Assistance"
                />
              </ListItem>
            )}
            {/* {isCollabOrAdmin(authReducer.user.role) &&
              !authReducer.isCollabSession && (
                <ListItem
                  className={
                    ["activite"].includes(activePath)
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    history.push("/activite");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="Activité"
                  />
                </ListItem>
              )} */}

            {authReducer.isCollabSession ? (
              <ListItem
                className={
                  ["parametres"].includes(activePath)
                    ? classes.menuListItemHighlighted
                    : classes.menuListItem
                }
                button
                onClick={() => {
                  if (isMobile) toggleDrawer();
                  history.push("/parametres");
                }}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  {newTicketsCount ? (
                    <Badge badgeContent={newTicketsCount} color="primary">
                      <LiveHelp />
                    </Badge>
                  ) : (
                    <SettingsIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.menuListItemText}
                  primary="Paramètres"
                />
              </ListItem>
            ) : (
              <>
                <ListItem
                  className={
                    ["parametres"].includes(activePath)
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    return history.push("/parametres");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    {isCollab(authReducer.user.role) && newTicketsCount ? (
                      <Badge badgeContent={newTicketsCount} color="primary">
                        <LiveHelp />
                      </Badge>
                    ) : (
                      <SettingsIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary={"Paramètres"}
                  />
                </ListItem>

                {isCollab(authReducer.user.role) && (
                  <ListItem
                    className={
                      ["assistance"].includes(activePath)
                        ? classes.menuListItemHighlighted
                        : classes.menuListItem
                    }
                    button
                    onClick={() => {
                      if (isMobile) {
                        toggleDrawer();
                      }
                      return history.push("/assistance");
                    }}
                  >
                    <ListItemIcon className={classes.menuListItemIcon}>
                      {isCollab(authReducer.user.role) && newTicketsCount ? (
                        <Badge badgeContent={newTicketsCount} color="primary">
                          <LiveHelp />
                        </Badge>
                      ) : (
                        <SettingsIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.menuListItemText}
                      primary={"Assistance"}
                    />
                  </ListItem>
                )}
              </>
            )}

            {// user is admin or collab && not collabSession
            isCollabOrAdmin(authReducer.user.role) &&
              missions.includes("comptable") &&
              !authReducer.isCollabSession && (
                <ListItem
                  className={
                    activePath.includes("stats")
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    history.push("/stats");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <BarChart />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="Stats dossiers"
                  />
                </ListItem>
              )}

            {isAdmin(authReducer.user.role) && (
              <>
                <ListItem
                  className={
                    ["/admin"].includes(activePath)
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    history.push("/admin");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <Build />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="Admin"
                  />
                </ListItem>

                <ListItem
                  className={
                    ["/public-docs"].includes(activePath)
                      ? classes.menuListItemHighlighted
                      : classes.menuListItem
                  }
                  button
                  onClick={() => {
                    if (isMobile) {
                      toggleDrawer();
                    }
                    history.push("/public-docs");
                  }}
                >
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <FileCopy />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuListItemText}
                    primary="Documents publics"
                  />
                </ListItem>
              </>
            )}
          </List>
          <List>
            {isCollab(authReducer.user.role) && authReducer.isCollabSession && (
              <ListItem
                button
                className={classes.menuListItem}
                onClick={exitClientPortal}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText
                  className={classes.menuListItemText}
                  primary="Retour au portail"
                />
              </ListItem>
            )}
            <ListItem
              button
              className={classes.menuListItem}
              onClick={() => {
                logout();
                history.push("/connexion");
              }}
            >
              <ListItemIcon className={classes.menuListItemIcon}>
                <Eject />
              </ListItemIcon>
              <ListItemText
                className={(classes.menuListItemText, "disconnect")}
                primary="Déconnexion"
              />
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  favoriteReducer: favoriteReducerType.isRequired,
  authReducer: authReducerType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  changeMarginWidth: PropTypes.func.isRequired,
  exitClientPortal: PropTypes.func.isRequired,
  toggleDrawerExternal: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      AuthManager.logout();
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: "EVENTS_CLEAR" });
      dispatch({ type: HIDE_TOAST_ACTION });
    },
    exitClientPortal: () => dispatch({ type: "EXIT_CLIENT_PORTAL" })
  };
};

const mapStateToProps = state => {
  return {
    favoriteReducer: state.favoriteReducer,
    authReducer: state.authReducer
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
