import React, { Component, forwardRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import alasql from "alasql";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import PageTitle from "../PageTitle/PageTitle";
import Loader from "../Loader/Loader";

import {
  clientReducerType,
  authReducerType
} from "../../_helpers/appProptypes";
import "./Stats.scss";
import FolderSearchInput from "./FolderSearchInput";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const HtmlTooltip = withStyles(thm => ({
  tooltip: {
    backgroundColor: thm.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: thm.shadows[1],
    fontSize: 14,
    minWidth: 150,
    maxWidth: 320,
    border: "1px solid #dadde9"
  }
}))(Tooltip);

function nbFr(number) {
  return new Intl.NumberFormat("fr-FR").format(number);
}

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientNoDossier: undefined,
      tabId: "indicators",
      balanceExercice: undefined,
      searchTerm: "",
      indicatorsColumns: undefined,
      indicatorsData: undefined,
      balanceColumns: undefined,
      balanceData: undefined,
      adminMode: false,
      isProcessing: false
    };
    this.clientChange = this.clientChange.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.balanceExerciceChange = this.balanceExerciceChange.bind(this);
    this.onIndicatorClick = this.onIndicatorClick.bind(this);
    this.buildBalanceTableContent = this.buildBalanceTableContent.bind(this);
    this.buildIndicatorsTableContent = this.buildIndicatorsTableContent.bind(
      this
    );
    this.removeFilter = this.removeFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.authReducer.user) {
      this.setState({
        adminMode: ["admin"].includes(this.props.authReducer.user.role)
      });
    }
  }

  buildBalanceTableContent(clientNoDossier, exercice) {
    let { balanceExercice } = this.state;
    const { clientReducer } = this.props;
    const { clients } = clientReducer;
    const { balance } = _.find(clients, {
      noDossier: clientNoDossier
    });
    // get distinct exercices
    const exerciceList = _.uniq(balance.map(l => l.exerciceEndMonth));

    // define exercice
    if (exercice) {
      balanceExercice = exercice;
    } else if (!balanceExercice) {
      balanceExercice = _.max(exerciceList);
    }

    const balanceColumns = [
      {
        title: "Compte",
        field: "accountCode"
      },
      {
        title: "Libellé",
        field: "accountLabel"
      }
    ];
    ["debit", "credit", "SD", "SC", "SM"].forEach(mes =>
      balanceColumns.push({
        title: `${mes}`,
        field: mes,
        customSort: (a, b) => {
          return a[mes] - b[mes];
        },
        render: rowData => nbFr(rowData[mes]),
        searchable: false
      })
    );

    // index indicators per exercice
    const balancePerExercice = {};
    const bpe = _.find(balance, e => e.exerciceEndMonth === balanceExercice);

    balancePerExercice[balanceExercice] = bpe ? bpe.accountValues : [];

    // loop through all accounts
    let totalDebit = 0;
    let totalCredit = 0;
    let totalSD = 0;
    let totalSC = 0;
    let totalSM = 0;
    const balanceData = [];

    if (balance[exerciceList.indexOf(balanceExercice)]) {
      balance[exerciceList.indexOf(balanceExercice)].accountValues.forEach(
        accountLine => {
          const { accountCode, accountLabel } = accountLine;
          const vExercice = _.find(
            balancePerExercice[balanceExercice],
            e => e.accountCode === accountCode
          );
          let credit;
          let debit;
          let SC;
          let SD;
          if (vExercice) {
            ({ credit, debit, SD, SC } = vExercice);
          }
          const SM = SD - SC;
          totalDebit += Number(debit) ? Number(debit) : 0;
          totalCredit += Number(credit) ? Number(credit) : 0;
          totalSD += Number(SD) ? Number(SD) : 0;
          totalSC += Number(SC) ? Number(SC) : 0;
          totalSM += Number(SM) ? Number(SM) : 0;
          const v = {
            debit,
            credit,
            SD,
            SC,
            SM,
            accountCode: accountCode.toUpperCase(),
            accountLabel
          };
          balanceData.push(v);
        }
      );
    }
    if (_.size(balanceData) > 0) {
      // add totals :
      balanceData.push({
        accountCode: "Total",
        accountLabel: "",
        debit: totalDebit.toFixed(2),
        credit: totalCredit.toFixed(2),
        SD: totalSD.toFixed(2),
        SC: totalSC.toFixed(2),
        SM: totalSM.toFixed(2)
      });
    }

    alasql(
      "CREATE TABLE IF NOT EXISTS balance (exercice string,  accountCode string, accountLabel string, debit number, credit number, SD number, SC number, SM number)"
    );
    return { balanceColumns, balanceData, balanceExercice, exerciceList };
  }

  tabChange(event, newValue) {
    const tabId = newValue;
    const { balanceData, indicatorsData, clientNoDossier } = this.state;
    this.setState({ isProcessing: true });

    if (clientNoDossier && tabId === "balance" && !balanceData) {
      const {
        balanceColumns,
        balanceData: newBalanceData,
        exerciceList,
        balanceExercice
      } = this.buildBalanceTableContent(clientNoDossier);
      // update state
      this.setState({
        balanceColumns,
        balanceData: newBalanceData,
        balanceExercice,
        clientNoDossier,
        tabId,
        exerciceList,
        isProcessing: false
      });
    } else if (clientNoDossier && tabId === "indicators" && !indicatorsData) {
      const {
        indicatorsColumns,
        indicatorsData: newIndicatorsData
      } = this.buildIndicatorsTableContent(clientNoDossier);
      // update state
      this.setState({
        indicatorsColumns,
        indicatorsData: newIndicatorsData,
        clientNoDossier,
        tabId,
        isProcessing: false
      });
    }
    this.setState({
      tabId,
      isProcessing: false
    });
  }

  buildIndicatorsTableContent(noDossier) {
    let { clientNoDossier } = this.state;
    const { adminMode } = this.state;
    const { clientReducer } = this.props;

    if (noDossier) {
      clientNoDossier = noDossier;
    }
    const { clients, indicatorDictionnary } = clientReducer;
    // get distinct exercices
    const { indicators, balance } = _.find(clients, {
      noDossier: clientNoDossier
    });

    if (_.size(balance) === 0) {
      return { indicatorsColumns: [], indicatorsData: [] };
    }
    const exerciceList = _.uniq(indicators.map(l => l.exerciceEndMonth)).sort();

    const indicatorsPerExercice = {};

    const indicatorsColumns = [
      {
        title: "N°",
        field: "indicateurPosition",
        searchable: false,
        width: 20,
        headerStyle: { width: 20 }
      },
      {
        title: "Indicateur",
        field: "indicatorId",
        customFilterAndSearch: (search, lineValue) => {
          // if search start with : "usedIn:", search in usedIn
          if (search.toUpperCase().startsWith("USEDIN:")) {
            const s = search.substr(7).toUpperCase();
            return (
              lineValue.indicatorId.toUpperCase().indexOf(s) >= 0 ||
              lineValue.usedIn.includes(s)
            );
          }
          // else search in indicatorId, indicateurLabel
          return (
            (lineValue.indicatorId + lineValue.indicateurLabel)
              .toUpperCase()
              .indexOf(search.toUpperCase()) >= 0
          );
        },
        render: rowData => {
          return (
            <HtmlTooltip
              onClick={e => {
                e.stopPropagation();
                if (rowData.niveau > 1) {
                  this.onIndicatorClick(rowData.indicatorId);
                }
              }}
              /* eslint-disable */
              // linter behaves strangely : it reformats differently on every save
              title={
                <div>
                  <div>
                    <b>{rowData.indicateurLabel}</b>
                  </div>
                  <hr />
                  <div>
                    <b>code</b> : {rowData.indicatorId}{" "}
                  </div>
                  <div>
                    <b>signe</b> : {rowData.indicateurSigneAffichage}{" "}
                  </div>
                  <div>
                    <b>niveau</b> : {rowData.niveau}{" "}
                  </div>
                  <div>
                    <b>SC</b> : {rowData.SC}{" "}
                  </div>
                  <div>
                    <b>SD</b> : {rowData.SD}{" "}
                  </div>
                  <div>
                    <b>SM</b> : {rowData.SM}{" "}
                  </div>
                  <div>
                    <b>comptesExclus</b> : {rowData.comptesExclus}{" "}
                  </div>
                  <div>
                    <b>Utilisé dans </b> : {rowData.usedIn.join(",")}{" "}
                  </div>
                </div>
              }
            >
              <div className="indicator"> {rowData.indicateurLabel} </div>
            </HtmlTooltip>
            /* eslint-enable */
          );
        }
      }
    ];

    exerciceList.forEach(exercice => {
      // create indicatorsColumns
      indicatorsColumns.push({
        title: exercice,
        field: `val_${exercice}`,
        searchable: false,
        customSort: (a, b) => {
          return a[`val_${exercice}`] - b[`val_${exercice}`];
        },
        render: rowData => {
          const storedVal = rowData[`val_${exercice}`]
            ? nbFr(rowData[`val_${exercice}`].toFixed(2))
            : "-";
          const calculatedVal = rowData[`calculated_val_${exercice}`]
            ? nbFr(rowData[`calculated_val_${exercice}`].toFixed(2))
            : "-";
          const delta =
            storedVal === "-" && calculatedVal === "-"
              ? "-"
              : nbFr(
                  (rowData[`val_${exercice}`] ?? 0).toFixed(2) -
                    (rowData[`calculated_val_${exercice}`] ?? 0).toFixed(2)
                );

          return (
            <>
              <div>{storedVal}</div>
              {adminMode && (
                <>
                  <div
                    className={
                      ["0", "-"].includes(delta) ? "deltaNull" : "deltaNotNull"
                    }
                  >
                    🖩&nbsp;{calculatedVal}
                  </div>
                  <div
                    className={
                      ["0", "-"].includes(delta) ? "deltaNull" : "deltaNotNull"
                    }
                  >
                    Δ&nbsp;
                    {delta}
                  </div>
                </>
              )}
            </>
          );
        }
      });
      // index indicators per exercice
      indicatorsPerExercice[exercice] = _.find(
        indicators,
        e => e.exerciceEndMonth === exercice
      ).indicatorValues;
    });

    const fullBalance = [];
    balance.forEach(exercice =>
      exercice.accountValues.forEach(e =>
        fullBalance.push({
          ...e,
          exercice: exercice.exerciceEndMonth,
          SM: e.debit - e.credit
        })
      )
    );
    alasql(
      "CREATE TABLE IF NOT EXISTS balance (exercice string,  accountCode string, accountLabel string, debit number, credit number, SD number, SC number, SM number)"
    );
    alasql.tables.balance.data = fullBalance;

    let fullIndicatorValues = [];
    let localIndicatorValues = {};

    // create indicatorMap

    // create index of indicators used in others indicators
    const usedIn = {};
    const indicatorMap = {};
    if (indicatorDictionnary) {
      indicatorDictionnary
        .sort((a, b) => b.niveau - a.niveau)
        .forEach(ind => {
          const {
            _id,
            indicateurPosition,
            indicateurLabel,
            indicateurSigneAffichage,
            niveau,
            SC,
            SD,
            SM,
            comptesExclus
          } = ind;
          if (niveau > 1) {
            // index current indicator in each indicator that composes it
            SM.split(new RegExp("[+-]", "g")).forEach(el => {
              if (!usedIn[el.trim()]) {
                usedIn[el.trim()] = [];
              }
              usedIn[el.trim()].push(_id);
            });
          }
          indicatorMap[_id.toUpperCase()] = {
            indicateurId: _id.toUpperCase(),
            indicateurPosition,
            indicateurLabel,
            indicateurSigneAffichage,
            niveau,
            SC,
            SD,
            SM,
            comptesExclus,
            usedIn: usedIn[_id] || []
          };
        });
    }

    const indicatorsData = [];

    alasql(
      "CREATE TABLE IF NOT EXISTS indicator (exercice string,  indicatorId string, indicatorValue number)"
    );

    // calc level 1
    indicatorDictionnary
      /* .filter((indicator) =>
        ["BDO_CREANCESFISCALES"].includes(indicator._id)
      ) */
      .filter(indicator => indicator.niveau === 1)
      .forEach(indicator => {
        const { _id: indicatorId } = indicator;
        const v = {
          ...indicatorMap[indicatorId.toUpperCase()],
          indicatorId: indicatorId.toUpperCase()
        };

        if (adminMode) {
          // level 1 : calc indicators value from balance
          const SD = !v.SD ? "" : v.SD;
          const SC = !v.SC ? "" : v.SC;
          const SM = !v.SM ? "" : v.SM;
          const exclusion = !v.comptesExclus ? "" : v.comptesExclus;
          const displaySign =
            v.indicateurSigneAffichage.trim() === "NEG" ? -1 : 1;

          const filtersExclude = [];
          // accountExclusion filter
          exclusion.split(";").forEach(s => {
            if (s && s.trim() !== "") {
              const comptes = s;
              if (comptes.indexOf(":") > -1) {
                const comptesArray = comptes.split(":");
                filtersExclude.push(
                  `accountCode NOT BETWEEN '${comptesArray[0]}%' AND '${comptesArray[1]}'`
                );
              } else {
                filtersExclude.push(`accountCode NOT LIKE '${comptes}%'`);
              }
            }
          });

          // gen subquery for each : SD, SC, SM
          let subQueriesResult = [];

          [{ SD }, { SC }, { SM }].forEach(column => {
            const columnName = Object.keys(column)[0];
            const columnVal = Object.values(column)[0];
            if (columnVal && columnVal.trim() !== "") {
              const filtersInclude = [];
              columnVal.split(";").forEach(comptes => {
                if (comptes && comptes.trim() !== "") {
                  if (comptes.indexOf(":") > -1) {
                    const comptesArray = comptes.split(":");
                    filtersInclude.push(
                      `accountCode BETWEEN '${comptesArray[0]}' AND '${comptesArray[1]}ZZZZZZ'`
                    );
                  } else {
                    filtersInclude.push(`accountCode LIKE '${comptes}%'`);
                  }
                }
              });

              const subQuery = `SELECT '${indicatorId}' indicatorId, exercice, sum(COALESCE(${columnName},0)) indicatorValue FROM balance
            WHERE (${filtersInclude.join(" OR ")}) ${
                filtersExclude.length > 0
                  ? `AND (${filtersExclude.join(" and ")}) `
                  : ""
              } GROUP BY '${indicatorId}', exercice`;

              const subQueryRes = alasql(subQuery);
              if (subQueryRes.filter(l => l.exercice).length > 0) {
                subQueriesResult = subQueriesResult.concat(
                  subQueryRes.filter(l => l.exercice)
                );
              }
            }
          });

          // sum subqueries result
          const resultByExercice = _.chain(subQueriesResult)
            // Group the elements
            .groupBy(e => `${e.exercice}_${e.indicatorId}`)
            // Create output object
            .map((value, key) => ({
              exercice: key.substring(0, 7),
              indicatorId: key.substring(8, key.length + 1),
              indicatorValue:
                value.map(e => e.indicatorValue).reduce((a, b) => a + b) *
                displaySign
            }))
            .value();
          // concat in global res object : fullIndicatorValues
          fullIndicatorValues = fullIndicatorValues.concat(resultByExercice);
          // save ref locally for reuse in exercice loop (next)
          localIndicatorValues = resultByExercice;
        }
        // define result for indicator for each exercice
        exerciceList.forEach(exercice => {
          const r = _.find(
            indicatorsPerExercice[exercice],
            e => e.indicatorId.toUpperCase() === indicatorId.toUpperCase()
          );
          v[`val_${exercice}`] = !r ? null : r.indicatorValue;
          const calculatedLine = !adminMode
            ? {}
            : localIndicatorValues.filter(l => l.exercice === exercice)[0];

          v[`calculated_val_${exercice}`] =
            !adminMode || !calculatedLine || !calculatedLine.indicatorValue
              ? 0
              : calculatedLine.indicatorValue;
        });
        indicatorsData.push(v);
      });

    // set 'indicator' table data
    alasql.tables.indicator.data = fullIndicatorValues;

    const getNextIndicator = str => {
      if (str.trim().length === 0) return { indicator: null };

      // index of next end of indicator : next +, -, or end of string
      let nextPlusIndex = str.indexOf("+");
      let nextMinusIndex = str.indexOf("-");
      let sign = 1;
      let nextIndicatorStartIndex = 0;
      if (nextPlusIndex === 0 || nextMinusIndex === 0) {
        // define sign
        sign = nextMinusIndex === 0 ? -1 : 1;
        nextIndicatorStartIndex = 1;
      }
      // define next indicator length
      nextPlusIndex = str.indexOf("+", nextIndicatorStartIndex);
      nextMinusIndex = str.indexOf("-", nextIndicatorStartIndex);
      let nextIndicatorEndIndex = Math.min(
        nextPlusIndex === -1 ? nextMinusIndex : nextPlusIndex,
        nextMinusIndex === -1 ? nextPlusIndex : nextMinusIndex
      );
      // if no more operator, end is end of string
      if (nextIndicatorEndIndex === -1) {
        nextIndicatorEndIndex = str.length;
      }
      const res = {
        sign,
        indicator: str
          .substring(nextIndicatorStartIndex, nextIndicatorEndIndex)
          .trim(),
        rest: str.substring(nextIndicatorEndIndex, str.length).trim()
      };

      return res;
    };

    // calc level 2 & 3
    for (let level = 2; level < 4; level += 1) {
      // parse indicators
      indicatorDictionnary
        // .filter((indicator) => ["BDO_BFRADECAISSER"].includes(indicator._id))
        .filter(indicator => indicator.niveau === level)
        /* eslint-disable no-loop-func */
        .forEach(ind => {
          const { _id: indicatorId } = ind;
          const v = {
            ...indicatorMap[indicatorId.toUpperCase()],
            indicatorId: indicatorId.toUpperCase()
          };
          const displaySign = v.indicateurSigneAffichage === "NEG" ? -1 : 1;

          let subQueriesResult = [];

          if (adminMode) {
            // level 2 : calc indicators value from level 1
            const SM = !v.SM ? "" : v.SM;

            const remainingIndicators = SM;
            let { indicator, sign, rest } = getNextIndicator(
              remainingIndicators
            );
            while (indicator) {
              const q = `SELECT '${indicatorId}' indicatorId, exercice,  sum(indicatorValue)*${sign} indicatorValue from indicator where indicatorId='${indicator}' group by '${indicatorId}', exercice`;
              const subQueryRes = alasql(q);
              if (subQueryRes.filter(l => l.exercice).length > 0) {
                subQueriesResult = subQueriesResult.concat(
                  subQueryRes.filter(l => l.exercice)
                );
              }
              ({ indicator, sign, rest } = getNextIndicator(rest));
            }

            // agg subqueries result
            const resultByExercice = _.chain(subQueriesResult)
              // Group the elements
              .groupBy(e => `${e.exercice}_${e.indicatorId}`)
              // create output object
              .map((value, key) => ({
                exercice: key.substring(0, 7),
                indicatorId: key.substring(8, key.length + 1),
                indicatorValue:
                  value.map(e => e.indicatorValue).reduce((a, b) => a + b) *
                  displaySign
              }))
              .value();
            fullIndicatorValues = fullIndicatorValues.concat(resultByExercice); // queryRes);
            localIndicatorValues = resultByExercice;
          }

          // set 'indicator' table data
          if (level === 2) {
            alasql.tables.indicator.data = fullIndicatorValues;
          }

          exerciceList.forEach(exercice => {
            const r = _.find(
              indicatorsPerExercice[exercice],
              e => e.indicatorId.toUpperCase() === indicatorId.toUpperCase()
            );
            v[`val_${exercice}`] = !r ? null : r.indicatorValue;
            const calculatedLine = !adminMode
              ? {}
              : localIndicatorValues.filter(l => l.exercice === exercice)[0];

            v[`calculated_val_${exercice}`] =
              !adminMode || !calculatedLine || !calculatedLine.indicatorValue
                ? 0
                : calculatedLine.indicatorValue;
          });

          indicatorsData.push(v);
        });
    }
    const sortedData = indicatorsData.sort(
      (a, b) => a.indicateurPosition - b.indicateurPosition
    );
    return { indicatorsColumns, indicatorsData: sortedData };
  }

  removeFilter() {
    this.setState({ searchTerm: "" });
  }

  clientChange(clientNoDossier) {
    const { tabId } = this.state;
    this.setState({ isProcessing: true });

    if (tabId === "balance") {
      const {
        balanceColumns,
        balanceData,
        balanceExercice,
        exerciceList
      } = this.buildBalanceTableContent(clientNoDossier);
      // update state
      this.setState({
        balanceColumns,
        balanceData,
        balanceExercice,
        clientNoDossier,
        exerciceList,
        isProcessing: false,
        searchTerm: ""
      });
    } else if (tabId === "indicators") {
      const {
        indicatorsColumns,
        indicatorsData
      } = this.buildIndicatorsTableContent(clientNoDossier);
      // update state
      this.setState({
        indicatorsColumns,
        indicatorsData,
        clientNoDossier,
        isProcessing: false,
        searchTerm: ""
      });
    }
  }

  balanceExerciceChange(event) {
    const exercice = event.target.value;
    //  update balance data for exercice
    this.setState({ isProcessing: true });

    const { balanceColumns, balanceData } = this.buildBalanceTableContent(
      this.state.clientNoDossier,
      exercice
    );
    // update state
    this.setState({
      balanceColumns,
      balanceData,
      balanceExercice: exercice,
      isProcessing: false
    });
  }

  onIndicatorClick(search) {
    this.setState({ searchTerm: `usedIn:${search}` });
  }

  render() {
    const { clientReducer, authReducer } = this.props;
    const { clients } = clientReducer;

    const {
      clientNoDossier,
      tabId,
      indicatorsColumns,
      indicatorsData,
      balanceColumns,
      balanceData,
      balanceExercice,
      exerciceList,
      isProcessing
    } = this.state;

    let loaderOverlay;
    if (isProcessing) {
      loaderOverlay = (
        <div className="loader-overlay">
          <div className="loader-spinner center" />
        </div>
      );
    }

    const localization = {
      body: {
        emptyDataSourceMessage: "Pas de lignes",
        filterRow: {
          filterTooltip: "Filtrer"
        }
      },
      toolbar: {
        exportTitle: "Exporter",
        exportAriaLabel: "Exporter",
        exportName: "Exporter en CSV",
        searchTooltip: "Recherche",
        searchPlaceholder: "Recherche"
      }
    };

    if (!authReducer.user) return <Loader />;

    let indicatorPanelContent;

    if (!clientNoDossier) {
      indicatorPanelContent = <div>{"Sélectionnez un dossier"}</div>;
    } else if (
      clients.length > 0 &&
      (!indicatorsData || _.size(indicatorsData) === 0)
    ) {
      loaderOverlay = null;
      indicatorPanelContent = <div>{"Pas d'indicateurs pour ce dossier"}</div>;
    } else if (tabId === "balance") {
      indicatorPanelContent = <></>;
    } else {
      indicatorPanelContent = (
        <div style={{ padding: "5px", maxWidth: "100%" }}>
          <MaterialTable
            // use searchTerm as key to force table re-render on search term change
            // only way found to input search term programmatically
            key={this.state.searchTerm + Date.now()}
            icons={tableIcons}
            options={{
              exportButton: true,
              sorting: true,
              paging: false,
              headerStyle: {
                backgroundColor: "#bbb",
                color: "#fff",
                fontWeight: "bold",
                position: "sticky",
                top: 0
              },
              maxBodyHeight: 400,
              debounceInterval: 400,
              searchText: this.state.searchTerm,
              rowStyle: rowData => {
                if (rowData.niveau === 2) {
                  return { backgroundColor: "#dde" };
                }
                if (rowData.niveau === 3) {
                  return { backgroundColor: "#aac" };
                }
                return {};
              }
            }}
            columns={indicatorsColumns}
            data={indicatorsData}
            title={
              <span className="tableTitle">
                {"Indicateurs pour le dossier "}
                <span className="tableTitle var">{clientNoDossier}</span>
              </span>
            }
            localization={localization}
          />
        </div>
      );
    }

    let balancePanelContent;

    if (!clientNoDossier) {
      balancePanelContent = <div>{"Sélectionnez un dossier"}</div>;
    } else if (clients.length > 0 && _.size(balanceData) === 0) {
      loaderOverlay = null;
      balancePanelContent = <div>{"Pas de balance pour ce dossier"}</div>;
    } else if (tabId === "indicators") {
      balancePanelContent = <></>;
    } else {
      balancePanelContent = (
        <>
          <FormControl>
            <InputLabel shrink id="balanceExerciceInput">
              {"Exercice"}
            </InputLabel>
            <Select
              labelId="balanceExercice"
              id="balanceExercice"
              value={balanceExercice}
              onChange={this.balanceExerciceChange}
            >
              {exerciceList.sort().map((e, eIndex) => {
                return (
                  <MenuItem value={e} key={eIndex}>
                    <em>{e}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div style={{ maxWidth: "90%" }}>
            <MaterialTable
              icons={tableIcons}
              options={{
                exportButton: true,
                sorting: true,
                paging: false,
                headerStyle: {
                  backgroundColor: "#ccc",
                  color: "#fff",
                  fontWeight: "bold",
                  position: "sticky",
                  top: 0
                },
                maxBodyHeight: 400,
                debounceInterval: 400
              }}
              columns={balanceColumns}
              data={balanceData}
              title={
                <span className="tableTitle">
                  {"Balance pour le dossier "}
                  <span className="tableTitle var">{clientNoDossier}</span>
                  {" - exercice "}
                  <span className="tableTitle var">{balanceExercice}</span>
                </span>
              }
              localization={localization}
            />
          </div>
        </>
      );
    }

    if (!clientReducer) return <Loader />;
    if (
      authReducer &&
      authReducer.user &&
      !["admin", "collab"].includes(authReducer.user.role)
    ) {
      return "Vous ne disposez pas des droits nécessaires pour afficher cette page.";
    }

    const currentClient = this.state.clientNoDossier
      ? clients.find(c => c.noDossier === this.state.clientNoDossier)
      : null;

    return (
      <div className="Stats">
        <PageTitle title="Statistiques des dossiers" />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Paper className="statsFilter">
              <FormControl>
                <div className="flex gap-4 items-center">
                  <div className="flex gap-2 items-center">
                    <div
                      style={{
                        fontSize: "0.9rem",
                        marginRight: "4px"
                      }}
                    >
                      {"Dossier : "}
                    </div>
                    <div style={{ minWidth: "330px" }}>
                      <FolderSearchInput
                        onSearch={this.handleInputChange}
                        onSelect={e => this.clientChange(e.value)}
                      />
                    </div>
                  </div>

                  {!!currentClient && (
                    <div>
                      <span>{`Comptabilité au : `}</span>
                      <span className="opacity-60">
                        {currentClient.lastAccountabilityUpdate
                          ? ` ${moment(
                              currentClient.lastAccountabilityUpdate
                            ).format("DD/MM/YYYY")}`
                          : " pas de données"}
                      </span>
                    </div>
                  )}
                </div>
              </FormControl>
            </Paper>
            {loaderOverlay}

            <Paper className="statsBody">
              <AppBar position="static">
                <Tabs
                  value={this.state.tabId}
                  onChange={this.tabChange}
                  aria-label="wrapped label tabs example"
                >
                  <Tab value="indicators" label="Indicateurs" wrapped />
                  <Tab value="balance" label="Balance" />
                  <ButtonBase
                    className="removeFilter"
                    onClick={this.removeFilter}
                    disabled={this.state.searchTerm === ""}
                  >
                    {"Enlever les filtres"}
                  </ButtonBase>
                </Tabs>
              </AppBar>

              <TabPanel value={this.state.tabId} index="indicators">
                {indicatorPanelContent}
              </TabPanel>
              <TabPanel value={this.state.tabId} index="balance">
                {balancePanelContent}
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Stats.propTypes = {
  clientReducer: clientReducerType.isRequired,
  classes: PropTypes.shape({}).isRequired,
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    clientReducer: state.clientReducer
  };
};

export default connect(mapStateToProps)(withStyles(theme)(Stats));
