import {
  ADD_DOCUMENT_SYNC,
  CREATE_FOLDER_SYNC,
  DOWNLOAD_FILE_LINK_SYNC,
  FETCH_COLLAB_DOCS_SYNC_ACTION,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SYNC,
  FETCH_DOC_INFO_SYNC,
  FETCH_LINK_INFO_SYNC,
  FETCH_NEW_PAGE,
  FETCH_ROOT_FOLDERS_SYNC,
  FILE_DOWNLOAD_END,
  FILE_DOWNLOAD_START,
  LOADING_FOLDER,
  REMOVE_DOCUMENT_SYNC,
  RENAME_DOCUMENT_SYNC,
  SHARE_DOCUMENT_SYNC,
  UPDATE_DOCUMENTS_FOLDER,
  UPLOAD_COMPLETED,
  UPLOAD_IN_PROGRESS,
  REMOVE_DOCUMENTS_SYNC
} from "../actions/document-actions";
import SpecialFolder from "../enums/special-folder.enum";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_SORT = "name";
const DEFAULT_ORDER = "asc;";

const initialState = {
  folder: null,
  isLoading: true,
  selectedFolderId: "private",
  parentFolders: [],
  rootFolders: [],
  page: 0,
  documentsCount: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
  order: DEFAULT_ORDER,
  document: null,
  nonProcessedDocs: [],
  recentlyProcessedDocuments: [],
  myDocs: [],
  downloading: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FOLDER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case FILE_DOWNLOAD_START: {
      const documentId = action.payload;
      const { downloading } = state;
      downloading[documentId] = true;
      return {
        ...state,
        downloading
      };
    }

    case FILE_DOWNLOAD_END: {
      const documentId = action.payload;
      const { downloading } = state;
      downloading[documentId] = false;
      return {
        ...state,
        downloading
      };
    }

    case FETCH_NEW_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        page
      };
    }

    case UPLOAD_COMPLETED: {
      return {
        ...state,
        isLoading: false
      };
    }

    case UPLOAD_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case UPDATE_DOCUMENTS_FOLDER: {
      const documents = action.payload;
      return {
        ...state,
        folder: {
          ...state.folder,
          documents
        }
      };
    }

    case FETCH_DOCUMENTS: {
      const { selectedFolderId, sort, order } = action.payload || {};

      const updateState = {
        ...state,
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        documentsCount: 0,
        sort: sort || DEFAULT_SORT,
        order: order || DEFAULT_ORDER
      };

      if (selectedFolderId) updateState.selectedFolderId = selectedFolderId;

      return updateState;
    }

    case FETCH_DOCUMENTS_SYNC: {
      const folder = action.payload;
      if (state.selectedFolderId === "private" || !state.selectedFolderId) {
        return {
          ...state,
          isLoading: false,
          folder,
          documentsCount: folder.documentsCount
        };
      }
      return {
        ...state,
        isLoading: false,
        folder,
        documentsCount: folder.documentsCount
      };
    }

    case FETCH_ROOT_FOLDERS_SYNC: {
      const rootFolders = action.payload;

      return {
        ...state,
        rootFolders
      };
    }

    case REMOVE_DOCUMENT_SYNC: {
      const document = action.payload;
      return {
        ...state,
        folder: {
          ...state.folder,
          documents: state.folder.documents.filter(
            doc => doc._id !== document._id
          )
        },
        rootFolders: state.rootFolders.filter(doc => doc._id !== document._id)
      };
    }

    case REMOVE_DOCUMENTS_SYNC: {
      const { documentIds, excludedDocumentIds, allSelected } = action.payload;
      let newDocuments = state.folder.documents;

      if (allSelected) {
        newDocuments = state.folder.documents.filter(doc =>
          excludedDocumentIds.includes(doc._id)
        );
      } else {
        newDocuments = state.folder.documents.filter(
          doc => !documentIds.includes(doc._id)
        );
      }

      return {
        ...state,
        folder: {
          ...state.folder,
          documents: newDocuments
        }
      };
    }

    case CREATE_FOLDER_SYNC: {
      const { oldId, folder } = action.payload;
      return {
        ...state,
        folder: {
          ...state.folder,
          documents: state.folder.documents.map(doc => {
            if (doc._id === oldId) {
              return folder;
            }
            return doc;
          })
        },
        rootFolders: state.rootFolders.map(doc => {
          if (doc._id === oldId) {
            return folder;
          }
          return doc;
        })
      };
    }

    case ADD_DOCUMENT_SYNC: {
      const document = action.payload;
      const isRootFolder =
        (!state.selectedFolderId || state.selectedFolderId === "private") &&
        document.documentType === "folder";
      return {
        ...state,
        folder: {
          ...state.folder,
          documents: [document, ...state.folder.documents]
        },
        rootFolders: isRootFolder
          ? [...state.rootFolders, document]
          : state.rootFolders
      };
    }

    case RENAME_DOCUMENT_SYNC: {
      const { document, newName } = action.payload;

      if (document.parentId === SpecialFolder.PUBLIC_FOLDER) return state;

      return {
        ...state,
        rootFolders: state.rootFolders.map(doc => {
          if (doc._id === document._id) {
            return {
              ...doc,
              name: newName
            };
          }
          return doc;
        }),
        folder: {
          ...state.folder,
          documents: state.folder.documents.map(doc => {
            if (doc._id === document._id) {
              return {
                ...doc,
                name: newName
              };
            }
            return doc;
          })
        }
      };
    }

    case FETCH_DOC_INFO_SYNC: {
      const document = action.payload;

      return {
        ...state,
        document,
        isLoading: false
      };
    }

    case FETCH_COLLAB_DOCS_SYNC_ACTION: {
      const {
        nonProcessedDocs,
        myDocs,
        recentlyProcessedDocuments,
        gedNotifications
      } = action.payload;

      return {
        ...state,
        recentlyProcessedDocuments,
        nonProcessedDocs,
        myDocs,
        gedNotifications,
        isLoading: false
      };
    }
    case SHARE_DOCUMENT_SYNC: {
      const downloadLink = action.payload;

      return {
        ...state,
        document: {
          ...state.document,
          downloadLink
        }
      };
    }

    case FETCH_LINK_INFO_SYNC: {
      const {
        hasPassword,
        maxDownloadsReached,
        documentName,
        documentSize
      } = action.payload;

      return {
        ...state,
        downloadLink: {
          hasPassword,
          maxDownloadsReached,
          documentName,
          documentSize
        }
      };
    }

    case DOWNLOAD_FILE_LINK_SYNC: {
      const { success } = action.payload;

      return {
        ...state,
        downloadSuccess: success
      };
    }

    default:
      return state;
  }
};

export default reducer;
