import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";

import Loader from "../Loader/Loader";
import TicketLine from "../Collaborator/TicketLine";

import {
  supportReducerType,
  authReducerType
} from "../../_helpers/appProptypes";

const HistoryListing = props => {
  const { supportReducer, authReducer } = props;

  const { isLoading, myTickets = [] } = supportReducer;

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {authReducer.user.role === "collab" && (
            <TableCell align="left" style={{ color: "#134594" }}>
              {"Société"}
            </TableCell>
          )}
          <TableCell align="center" style={{ color: "#134594" }}>
            {"Domaine"}
          </TableCell>
          <TableCell align="left" style={{ color: "#134594" }}>
            {"Catégorie"}
          </TableCell>
          <TableCell align="left" style={{ color: "#134594" }}>
            {"Objet"}
          </TableCell>
          <TableCell align="center" style={{ color: "#134594" }}>
            {"Statut"}
          </TableCell>
          <TableCell align="center" style={{ color: "#134594" }}>
            {"Date de création"}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  if (isLoading) {
    return (
      <div className="TicketsListing" style={{ paddingTop: "24px" }}>
        <div>
          <Typography
            component="h2"
            style={{ color: "#e6223f", fontSize: "24px", paddingTop: "24px" }}
          >
            {"Mes demandes"}
          </Typography>
          <Table>{renderTableHead()}</Table>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="TicketsListing">
      {!myTickets.length ? (
        <div>
          <Typography
            component="h2"
            style={{
              color: "#e6223f",
              fontSize: "24px",
              paddingTop: "24px"
            }}
          >
            {"Mes demandes"}
          </Typography>
          <Table>{renderTableHead()}</Table>
          <div className="emptyTable" style={{ paddingTop: "10px" }}>
            {"Pas de demande"}
          </div>
        </div>
      ) : (
        <div>
          <Typography
            component="h2"
            style={{
              color: "#e6223f",
              fontSize: "24px",
              paddingTop: "24px"
            }}
          >
            {"Mes demandes"}
          </Typography>
          <Table style={{ tableLayout: "fixed", width: "100%" }}>
            {renderTableHead()}
            <TableBody>
              {myTickets
                .sort((a, b) =>
                  moment(b.creationDate).diff(moment(a.creationDate))
                )
                .map(ticket => (
                  <TicketLine
                    key={ticket._id}
                    ticket={ticket}
                    role={authReducer.user.role}
                  />
                ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

HistoryListing.propTypes = {
  supportReducer: supportReducerType.isRequired,
  authReducer: authReducerType.isRequired
};

const mapStateToProps = state => {
  return {
    supportReducer: state.supportReducer,
    authReducer: state.authReducer
  };
};

export default connect(mapStateToProps)(HistoryListing);
