/* eslint-disable react/forbid-prop-types */

import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { connect } from "react-redux";
import PropsTypes from "prop-types";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import Loader from "../Loader/Loader";
import "./GedFilesListing.scss";
import GedFileLine from "./GedFileLine";
import GedFileLineMobile from "./GedFileLineMobile";
import GedRenameModal from "./GedRenameModal";
import GedDeleteModal from "./GedDeleteModal";
import GedPagination from "./GedPagination";
import { useState } from "react";
import { FETCH_DOCUMENTS } from "../../actions/document-actions";

const GedFilesListing = ({
  documentReducer,
  toggleSelectAll,
  multiSelectionReducer,
  isCollabSession,
  fetchDocuments
}) => {
  const { isLoading, folder } = documentReducer;
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("asc");

  const parentId =
    folder && folder.parents && folder.parents.length >= 0
      ? folder.parents[0]._id
      : null;

  const isFolderInDepot =
    !folder || !folder.parents
      ? false
      : !!(folder && folder.parents[folder.parents.length - 1]._id === "depot");

  const handleColumnClick = column => {
    const newSort = column;
    // if sorting on same column, toggle asc/desc
    // else asc
    const newOrder = sort === newSort && order === "asc" ? "desc" : "asc";
    setSort(newSort);
    setOrder(newOrder);
    fetchDocuments(parentId, newSort, newOrder);
  };

  const sortIcon = column => {
    if (sort === column && order === "asc")
      return <ArrowDownward className="sortIcon" />;
    if (sort === column && order === "desc")
      return <ArrowUpward className="sortIcon" />;
    return null;
  };

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <Checkbox
              checked={multiSelectionReducer.allSelect}
              onClick={toggleSelectAll}
            />
          </TableCell>
          {!isMobile && (
            <>
              <TableCell align="left">{/* for notification badge */}</TableCell>

              <TableCell
                align="left"
                className="sortableHeader"
                onClick={() => {
                  handleColumnClick("mimeType");
                }}
              >
                {"Type"}
                {sortIcon("mimeType")}
              </TableCell>
            </>
          )}
          <TableCell
            align="center"
            className="sortableHeader"
            onClick={() => {
              handleColumnClick("name");
            }}
          >
            <span>{"Nom"}</span>
            {sortIcon("name")}
          </TableCell>
          {!isMobile && (
            <>
              {!isCollabSession && (
                <TableCell align="left">{"Favori"}</TableCell>
              )}
              <TableCell
                align="left"
                className="sortableHeader"
                onClick={() => {
                  handleColumnClick("creationDate");
                }}
              >
                <span>{"Déposé le"}</span>

                {sortIcon("creationDate")}
              </TableCell>

              <TableCell align="left">
                <span>{"Date CEGID"}</span>
              </TableCell>

              <TableCell
                align="left"
                className="sortableHeader"
                onClick={() => {
                  handleColumnClick("owner");
                }}
              >
                <span>{"Propriétaire"}</span>
                {sortIcon("owner")}
              </TableCell>
              {isFolderInDepot && (
                <TableCell
                  align="left"
                  className="sortableHeader"
                  onClick={() => {
                    handleColumnClick("processingStatus");
                  }}
                >
                  <span>{"Statut"}</span>
                  {sortIcon("status")}
                </TableCell>
              )}
              <TableCell
                align="left"
                className="sortableHeader"
                onClick={() => {
                  handleColumnClick("size");
                }}
              >
                {"Taille"}
                {sortIcon("size")}
              </TableCell>
            </>
          )}
          <TableCell align="right" />
        </TableRow>
      </TableHead>
    );
  };

  if (isLoading || !documentReducer.folder) {
    return (
      <div className="GedFilesListing">
        <Table>{renderTableHead()}</Table>
        <Loader />
      </div>
    );
  }

  return (
    <div className="GedFilesListing">
      {!_.get(documentReducer, "folder.documents.length") ? (
        <>
          <Table>{renderTableHead()}</Table>
          <div className="emptyFolder">{"Ce dossier est vide"}</div>
        </>
      ) : (
        <>
          <Table stickyHeader>
            {renderTableHead()}
            <TableBody>
              {folder.documents.map((document, index) => {
                return (
                  <Fragment key={index}>
                    {isMobile ? (
                      <GedFileLineMobile
                        selectable
                        document={document}
                        onRename={doc => {
                          setSelectedDocument(doc);
                          setRenameModalOpen(true);
                        }}
                        onRemove={doc => {
                          setSelectedDocument(doc);
                          setDeleteModalOpen(true);
                        }}
                      />
                    ) : (
                      <GedFileLine
                        selectable
                        document={document}
                        onRename={doc => {
                          setSelectedDocument(doc);
                          setRenameModalOpen(true);
                        }}
                        onRemove={doc => {
                          setSelectedDocument(doc);
                          setDeleteModalOpen(true);
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
            <GedPagination />
          </Table>
          {isRenameModalOpen && (
            <GedRenameModal
              isOpen={isRenameModalOpen}
              onClose={() => setRenameModalOpen(false)}
              document={selectedDocument}
            />
          )}
          {isDeleteModalOpen && (
            <GedDeleteModal
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              selection={selectedDocument}
              selectionList={[selectedDocument]}
            />
          )}
        </>
      )}
    </div>
  );
};

GedFilesListing.propTypes = {
  documentReducer: PropsTypes.object.isRequired,
  multiSelectionReducer: PropsTypes.object.isRequired,
  toggleSelectAll: PropsTypes.func.isRequired,
  isCollabSession: PropsTypes.bool.isRequired,
  fetchDocuments: PropsTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    documentReducer: state.documentReducer,
    multiSelectionReducer: state.multiSelectionReducer,
    isCollabSession: state.authReducer.isCollabSession
  };
};

const dispatchStateToProps = dispatch => {
  return {
    fetchDocuments: (folderId, sort, order) =>
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: { selectedFolderId: folderId, sort, order }
      }),
    toggleSelectAll: () => dispatch({ type: "TOGGLE_SELECT_ALL" })
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(GedFilesListing);
