/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import _ from "underscore";
import PageTitle from "../PageTitle/PageTitle";

import {
  supportReducerType,
  authReducerType
} from "../../_helpers/appProptypes";

import "./Contact.scss";
import { TOAST_ERROR_ACTION } from "../../actions/toast-actions";
import { FETCH_SUPPORTS, SEND_SUPPORT } from "../../actions/ticket-actions";

const Contact = ({
  match,
  supportReducer,
  sendSupport,
  displayError,
  history,
  authReducer,
  fetchSupports
}) => {
  const { supportId, backurl } = (match || {}).params;
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [description, setDescription] = useState("");

  const [supports, setSupports] = useState((supportReducer || {}).supports);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const { tenantId } = authReducer.user;

  const prevTenantId = usePrevious(tenantId);

  useEffect(() => {
    if (authReducer && authReducer.user) {
      fetchSupports();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducer.user]);

  useEffect(() => {
    setSupports(supportReducer.supports);
  }, [supportReducer.supports]);

  useEffect(() => {
    // redirect to dashboard homes on tenant switch
    if (
      prevTenantId !== undefined &&
      prevTenantId.tenantId !== authReducer.user.tenantId
    ) {
      history.push("/assistance");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducer.user.tenantId]);

  if (!supportId) {
    return <Redirect to="/" />;
  }

  const selectedSupport = _.find(supports, {
    _id: supportId
  });

  if (!supportReducer || supportReducer.supports.length === 0) {
    return <div>Chargement en cours</div>;
  }

  if (!selectedSupport) return <div />;

  let collabName = "Collaborateur non-identifié";
  const {
    accountingCollabUserName,
    legalCollabUserName,
    socialCollabUserName,
    missionChiefUserName
  } = authReducer.user.tenants.find(t => t._id === tenantId);
  if (selectedSupport._id === "comptable") {
    collabName = accountingCollabUserName;
  } else if (selectedSupport._id === "social") {
    collabName = socialCollabUserName;
  } else if (selectedSupport._id === "juridique") {
    collabName = legalCollabUserName;
  }

  return (
    <div className="Contact">
      <PageTitle title="Assistance" />
      <Grid container>
        <Grid item md={9} style={{ margin: "auto" }}>
          <div>
            <Typography component="p" color="primary" className="help">
              {`Posez vos questions à propos de `}
              <strong>{selectedSupport.label}</strong>
              {` via ce formulaire et nous vous répondrons dans les plus brefs délais`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              style={{ paddingBottom: "10px" }}
            >
              {`Votre interlocuteur : `}
              <strong>{collabName}</strong> (chef de mission :{" "}
              <strong>{missionChiefUserName}</strong>)
            </Typography>
          </div>
          <Paper>
            <form autoComplete="false" noValidate>
              <FormControl
                variant="outlined"
                style={{ width: "100%", margin: "8px" }}
              >
                <InputLabel
                  htmlFor="categorySelect"
                  style={{ backgroundColor: "white", padding: "0 6px" }}
                >
                  {"Catégorie"}
                </InputLabel>
                <Select
                  onChange={event => setCategory(event.target.value)}
                  input={
                    <OutlinedInput
                      name="category"
                      id="categorySelect"
                      value={category}
                    />
                  }
                >
                  <MenuItem value="" disabled>
                    {"Veuillez sélectionner une catégorie"}
                  </MenuItem>
                  {selectedSupport.categories.map(cat => (
                    <MenuItem value={cat} key={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-full-width"
                label="Objet"
                style={{ margin: 8 }}
                placeholder="Ecrivez l'objet de votre message"
                fullWidth
                name="subject"
                onChange={event => setSubject(event.target.value)}
                value={subject}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="outlined-full-width"
                label="Message"
                style={{ margin: 8 }}
                placeholder="Ecrivez votre message"
                fullWidth
                name="description"
                onChange={event => setDescription(event.target.value)}
                value={description}
                margin="normal"
                variant="outlined"
                multiline
                rows="8"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <div className="actions">
                <Button
                  color="secondary"
                  onClick={async () => {
                    if (category && subject && description) {
                      setSubmitting(true);
                      await sendSupport({
                        category,
                        subject,
                        description,
                        missionId: supportId
                      });
                      setSubmitting(false);
                      history.push(`/${backurl || ""}`);
                    } else {
                      displayError("Veuillez compléter tous les champs");
                    }
                  }}
                >
                  {submitting && (
                    <div>
                      <CircularProgress />
                      &nbsp;
                    </div>
                  )}{" "}
                  Envoyer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Contact.propTypes = {
  match: PropTypes.shape({}).isRequired,
  supportReducer: supportReducerType.isRequired,
  sendSupport: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  authReducer: authReducerType.isRequired,
  fetchSupports: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    supportReducer: state.supportReducer,
    authReducer: state.authReducer
  };
};

const dispatchStateToProps = dispatch => {
  return {
    sendSupport: value => dispatch({ type: SEND_SUPPORT, payload: value }),
    displayError: value =>
      dispatch({ type: TOAST_ERROR_ACTION, payload: value }),
    fetchSupports: () => dispatch({ type: FETCH_SUPPORTS })
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withRouter(Contact));
