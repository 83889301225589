const initialState = {
  users: [],
  isLoading: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_USERS": {
      return {
        ...state,
        isLoading: true
      };
    }

    case "FETCH_USERS_SYNC": {
      const { users } = action.payload;
      return {
        ...state,
        isLoading: false,
        users
      };
    }

    case "ASK_USER_UPDATE_SYNC": {
      const { user } = action.payload;

      const userIndex = state.users.findIndex(us => us._id === user._id);
      const newUsers = [...state.users];
      newUsers[userIndex] = { ...newUsers[userIndex], ...user };

      return {
        ...state,
        users: newUsers
      };
    }

    default:
      return state;
  }
};

export default reducer;
