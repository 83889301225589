import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import { ticketType } from "../../_helpers/appProptypes";

const theme = {
  root: {
    padding: "10px 25px"
  },
  replyButton: {
    marginRight: "10px",
    backgroundColor: "#f59e53 !important"
  },
  closeButton: {
    backgroundColor: "#097b1f !important"
  }
};

const TicketForm = props => {
  const { role, classes, ticket, updateTicket } = props;
  const [value, setValue] = React.useState("");

  return ticket.status === "closed" ? (
    <div>
      {"La demande a été clôturée, veuillez ouvrir une nouvelle demande."}
    </div>
  ) : (
    <Paper className={classes.root} square>
      <div className="TicketForm">
        <div style={{ color: "#134594", fontWeight: "bold", margin: "10px" }}>
          {"Réponse"}
        </div>
        <TextField
          placeholder="Ecrire votre réponse ici"
          multiline
          fullWidth
          variant="outlined"
          value={value}
          rows={4}
          name="value"
          onChange={event => setValue(event.target.value)}
          disabled={ticket.status === "closed"}
        />
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <Button
            onClick={() => {
              updateTicket(ticket._id, "reply", value);
              setValue("");
            }}
            disabled={ticket.status === "closed" || value.trim() === ""}
            className={classes.replyButton}
          >
            {"Répondre"}
          </Button>

          {role === "collab" && (
            <Button
              onClick={() => {
                updateTicket(ticket._id, "reply", value);
                // updateTicket(ticket._id, "replyAndClose", value);
                setValue("");
              }}
              disabled={ticket.status === "closed" || value.trim() === ""}
              className={classes.closeButton}
            >
              {"Répondre et clôturer"}
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
};

TicketForm.defaultProps = {
  ticket: {}
};

TicketForm.propTypes = {
  updateTicket: PropTypes.func.isRequired,
  ticket: ticketType,
  role: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.shape({}),
    icon: PropTypes.shape({}),
    input: PropTypes.shape({}),
    closeButton: PropTypes.shape({}),
    replyButton: PropTypes.shape({})
  }).isRequired
};

const mapStateToProps = () => ({});

const dispatchStateToProps = dispatch => {
  return {
    updateTicket: (ticketId, actionType, value) => {
      dispatch({
        type: "UPDATE_TICKET",
        payload: { ticketId, action: { actionType, value } }
      });
    }
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(withStyles(theme)(TicketForm));
