const initialState = {
  isLoading: true,
  folders: [],
  parents: [],
  folderId: "private"
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MOVE_FOLDERS": {
      return {
        ...state,
        isLoading: true
      };
    }

    case "FETCH_MOVE_FOLDERS_SYNC": {
      const { folders, parents, folderId } = action.payload;
      return {
        ...state,
        isLoading: false,
        folders,
        parents,
        folderId
      };
    }

    default:
      return state;
  }
};

export default reducer;
