/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import ApiManager from "../../lib/apiManager";

import "./PublicDocsToolbar.scss";

const PublicDocsToolbar = ({ onUploadSucceeded }) => {
  const [loading, setLoading] = useState(false);
  const handleUploadFile = () =>
    document.getElementById("publicFileUploadInput").click();

  const handleUploadFileChange = async e => {
    setLoading(true);
    await ApiManager.uploadPublicFile({
      file: e.target.files[0]
    });
    onUploadSucceeded();
    setLoading(false);
  };

  return (
    <div className="PublicDocsToolbar">
      <div>
        <Input
          type="file"
          id="publicFileUploadInput"
          style={{ display: "none" }}
          onChange={handleUploadFileChange}
          // necessary to clear file selection, in order to
          // be able to upload same file twice
          onClick={event => {
            const e = event;
            e.target.value = null;
          }}
        />

        <Typography
          component="span"
          className="tool"
          onClick={handleUploadFile}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
        >
          <FileCopy />
          <span>{"Charger un fichier"}</span>
        </Typography>
      </div>

      {loading && <CircularProgress size={12} />}
    </div>
  );
};

PublicDocsToolbar.propTypes = {
  onUploadSucceeded: PropTypes.func.isRequired
};

export default PublicDocsToolbar;
