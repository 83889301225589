const initialState = {
  downloadLinks: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_DOWNLOAD_LINKS": {
      return {
        ...state,
        isLoading: true
      };
    }

    case "FETCH_DOWNLOAD_LINKS_ASYNC": {
      const { downloadLinks } = action.payload;
      return {
        ...state,
        downloadLinks,
        isLoading: false
      };
    }

    case "FETCH_DOWNLOAD_LINK_ASYNC": {
      const { downloadLink } = action.payload;
      return {
        ...state,
        downloadLink,
        isLoading: false
      };
    }

    case "UPDATE_DOWNLOAD_LINK_ASYNC": {
      const { downloadLink } = action.payload;
      return {
        ...state,
        downloadLink
      };
    }

    case "DELETE_DOWNLOAD_LINK_ASYNC": {
      const { downloadLinks } = action.payload;
      return {
        ...state,
        downloadLinks,
        isLoading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
