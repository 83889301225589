/* eslint-disable react/forbid-prop-types */

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Folder from "@material-ui/icons/Folder";
import LiveHelp from "@material-ui/icons/LiveHelp";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import MainLayout from "./MainLayout";
import EmptyLayout from "./EmptyLayout";

import "./Layout.scss";
import Version from "./Version";
import { HIDE_TOAST_ACTION } from "../../actions/toast-actions";
import { GET_NEW_DOCS } from "../../actions/document-actions";
import { UPDATE_TICKET_FILTERS } from "../../actions/ticket-actions";

const Layout = ({
  toastReducer,
  closeToast,
  children,
  history,
  getNewDocuments,
  getNewTickets,
  authReducer,
  isAuthenticated
}) => {
  const LayoutComponent = isAuthenticated ? MainLayout : EmptyLayout;

  const getWelcomeMessage = (
    messageStart,
    onNewDocs,
    onNewTickets,
    newDocsCount = 0,
    newTicketsCount = 0
  ) => {
    let message = messageStart;
    if (newDocsCount && newTicketsCount) {
      message = (
        <p>
          <span>{`${messageStart} Vous avez `}</span>
          <span
            onClick={onNewDocs}
            className="cursor-pointer"
            style={{ textDecoration: "underline", verticalAlign: "inherit" }}
          >
            {newDocsCount > 1
              ? `${newDocsCount} nouveaux documents`
              : `${newDocsCount} nouveau document`}
          </span>
          <span>{" et "}</span>
          <span
            onClick={onNewTickets}
            className="cursor-pointer"
            style={{ textDecoration: "underline", verticalAlign: "inherit" }}
          >
            {newTicketsCount > 1
              ? `${newTicketsCount} nouvelles demandes`
              : `${newTicketsCount} nouvelle demande`}
          </span>
          <span>{" à consulter."}</span>
        </p>
      );
    } else if (newDocsCount) {
      message = (
        <p>
          <span>{`${messageStart} Vous avez `}</span>
          <span
            onClick={onNewDocs}
            className="cursor-pointer"
            style={{ textDecoration: "underline", verticalAlign: "inherit" }}
          >
            {newDocsCount > 1
              ? `${newDocsCount} nouveaux documents`
              : `${newDocsCount} nouveau document`}
          </span>
          <span>{" à consulter."}</span>
        </p>
      );
    } else if (newTicketsCount) {
      message = (
        <p>
          <span>{`${messageStart} Vous avez `}</span>
          <span
            onClick={onNewTickets}
            className="cursor-pointer"
            style={{ textDecoration: "underline", verticalAlign: "inherit" }}
          >
            {newTicketsCount > 1
              ? `${newTicketsCount} nouvelles demandes`
              : `${newTicketsCount} nouvelle demande`}
          </span>
          <span>{" à consulter."}</span>
        </p>
      );
    }

    return message;
  };

  const onNewDocs = () => {
    closeToast();
    getNewDocuments();
    history.push(
      authReducer.user && authReducer.user.role === "collab"
        ? "/documents"
        : "/ged"
    );
  };

  const onNewTickets = () => {
    closeToast();
    getNewTickets();
    history.push("/assistance");
  };

  const displayNotifications =
    toastReducer && toastReducer.displayNotifications;
  const message = displayNotifications
    ? getWelcomeMessage(
        toastReducer.message,
        onNewDocs,
        onNewTickets,
        toastReducer.newDocsCount,
        toastReducer.newTicketsCount
      )
    : toastReducer.message;

  return (
    <LayoutComponent>
      <>
        <Snackbar
          autoHideDuration={toastReducer.disableAutoClose ? null : 2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className={`Toast Toast--${toastReducer.type || "info"}`}
          open={toastReducer.open}
          onClose={closeToast}
          message={<span id="message-id">{message}</span>}
          action={
            toastReducer.disableAutoClose ? (
              <>
                {toastReducer.newDocsCount > 0 && (
                  <Tooltip title="Voir les nouveaux documents">
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={onNewDocs}
                    >
                      <Folder />
                    </IconButton>
                  </Tooltip>
                )}
                {toastReducer.newTicketsCount > 0 && (
                  <Tooltip title="Voir les nouvelles demandes">
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={onNewTickets}
                    >
                      {authReducer.user &&
                      authReducer.user.role === "collab" ? (
                        <LiveHelp />
                      ) : (
                        <LiveHelp />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  size="small"
                  aria-label="Fermer"
                  color="inherit"
                  onClick={closeToast}
                  style={{ position: "absolute", top: "0px", right: "5px" }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            ) : null
          }
        />
        {children}
        <Version />
      </>
    </LayoutComponent>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  toastReducer: PropTypes.object.isRequired,
  closeToast: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getNewDocuments: PropTypes.func.isRequired,
  getNewTickets: PropTypes.func.isRequired,
  authReducer: PropTypes.shape({
    user: PropTypes.shape({ role: PropTypes.string })
  }).isRequired
};

const mapStateToProps = state => {
  return {
    toastReducer: state.toastReducer,
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeToast: () => dispatch({ type: HIDE_TOAST_ACTION }),
    getNewDocuments: () => dispatch({ type: GET_NEW_DOCS }),
    getNewTickets: () =>
      dispatch({ type: UPDATE_TICKET_FILTERS, payload: { unread: true } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
