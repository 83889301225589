const COLLABORATOR_INDICATORS = [
  {
    id: "ged",
    text: "demande à consulter",
    pluralText: "demandes à consulter",
    field: "ticketNotifications"
  },
  {
    id: "ticket",
    text: "document à traiter",
    pluralText: "documents à traiter",
    field: "gedNotifications"
  }
];

export default COLLABORATOR_INDICATORS;
